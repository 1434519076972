import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ContactInfo from './ContactInfo';
import EntityList from './EntityList';
import Emails from './Emails';
import StatementForm from './StatementForm';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Details({ className, ...rest }) {
  const classes = useStyles();
  const [openInvestorForm, setOpenInvestorForm] = useState(false);
  const [openStatementForm, setOpenStatementForm] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const { investor, isLoading } = useSelector(state => state.investor);

  const handleInvestorFormOpen = () => {
    setOpenInvestorForm(true);
  };

  const handleInvestorFormClose = () => {
    setOpenInvestorForm(false);
  };

  const handleStatementFormOpen = entityId => {
    setEntityId(entityId);
    setOpenStatementForm(true);
  };

  const handleStatementFormClose = () => {
    setOpenStatementForm(false);
  };

  if (isLoading) {
    return null;
  }
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid item lg={4} md={4} xl={4} xs={12}>
        <ContactInfo investor={investor} />
      </Grid>
      <Grid item lg={8} md={8} xl={8} xs={12}>
        <EntityList
          investor={investor}
          onOpenStatementForm={handleStatementFormOpen}
        />
        <StatementForm
          entityId={entityId}
          onAdd={handleStatementFormClose}
          onClose={handleStatementFormClose}
          open={openStatementForm}
        />
      </Grid>
    </Grid>
  );
}

Details.propTypes = {
  className: PropTypes.string
  // investor: PropTypes.object.isRequired
};

export default Details;
