import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from 'src/components/Page';
import Results from './Results';
import DeveloperForm from './DeveloperForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function DeveloperListView() {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [developers, setDevelopers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [selectedDeveloper, setSelectedDeveloper] = useState(null);

  const handleFresh = value => {
    if (developers.map(developer => developer.id).includes(value.id)) {
      setDevelopers(
        developers.map(developer =>
          developer.id == value.id ? value : developer
        )
      );
    } else {
      // push to developers didn't incur rerender
      setDevelopers(developers.concat([value]));
    }
  };

  const handleFormOpen = value => {
    setSelectedDeveloper(value);
    setOpenForm(true);
  };

  const handleFormClose = value => {
    setOpenForm(false);
    if (value) {
      handleFresh(value);
    }
  };

  const getDevelopers = useCallback(() => {
    axios.get('/api/developers').then(response => {
      if (isMountedRef.current) {
        setDevelopers(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getDevelopers();
  }, [getDevelopers]);

  return (
    <Page className={classes.root} title="Developer List">
      <Box mt={1}>
        <Results
          developers={developers}
          isLoading={isLoading}
          onEdit={handleFormOpen}
        />
      </Box>
      <DeveloperForm
        developer={selectedDeveloper}
        onAdd={handleFormClose}
        onClose={handleFormClose}
        open={openForm}
      />
    </Page>
  );
}

export default DeveloperListView;
