/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import {
  getLoanTransactions,
  getLoanBalance
} from 'src/actions/syndicateActions';
import LoanTransactionForm from './LoanTransactionForm';

import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

function LoanTransaction({ isLoading, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedLoanTransaction, setSelectedLoanTransaction] = useState(null);

  useEffect(() => {
    dispatch(getLoanTransactions(syndicateId));
    dispatch(getLoanBalance(syndicateId));
  }, [dispatch, syndicateId]);

  const handleOpen = value => {
    setSelectedLoanTransaction(value);
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  return (
    <Page className={classes.root} title="Loan Transactions">
      <Container maxWidth={false}>
        <Box>
          <Results onEdit={handleOpen} />
          <LoanTransactionForm
            loanTransaction={selectedLoanTransaction}
            onAdd={handleClose}
            onClose={handleClose}
            open={open}
          />
        </Box>
      </Container>
    </Page>
  );
}

LoanTransaction.propTypes = {
  className: PropTypes.string,
  investments: PropTypes.array
};

LoanTransaction.defaultProps = {
  investments: []
};

export default LoanTransaction;
