import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
  makeStyles,
  FormHelperText,
  Paper,
  Radio
} from '@material-ui/core';
import { updateInterestBatch } from 'src/actions/dashboardActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const actionTypes = [
  {
    value: 'download',
    title: 'Download',
    description: 'Download statements'
  },
  {
    value: 'email',
    title: 'Email',
    description: 'Email statements'
  }
];

function DocForm({ docParams, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(actionTypes[0].value);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleChange = value => {
    setActionType(value);
  };
  const handleSubmit = async event => {
    event.preventDefault();

    try {
      // Do api call
      if (actionType == 'download' && !loading) {
        setLoading(true);
        docParams.actionType = 'download';
        axios
          .get('/api/docs', {
            responseType: 'arraybuffer',
            params: docParams
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['filename']); //or any other extension
            document.body.appendChild(link);
            link.click();
            const msg =
              response.status == 201
                ? 'Document generated successfully'
                : response.status == 207
                ? 'Some document failed to generate'
                : 'Failed to generate the documents';
            enqueueSnackbar(msg, {
              variant:
                response.status == 201
                  ? 'success'
                  : response.status == 207
                  ? 'warning'
                  : 'error'
            });
            setLoading(false);
          })
          .catch(error => {
            enqueueSnackbar('Present error to admin: ' + error, {
              variant: 'error'
            });
            setLoading(false);
          });
      } else if (actionType == 'email' && !loading) {
        setLoading(true);
        docParams.actionType = 'email';
        axios
          .get('/api/docs', { params: docParams })
          .then(response => {
            // dispatch(updateInterestBatch(docParams.batchId));
            if (!_.isEmpty(response.data)) {
              enqueueSnackbar('Failed to send email', {
                variant: 'error'
              });
              alert(JSON.stringify(response.data));
            } else {
              enqueueSnackbar('Email sent', {
                variant: 'success'
              });
            }
            setLoading(false);
          })
          .catch(error => {
            enqueueSnackbar(
              'Action failed, talk to sys admin directly, DO NOT RETRY',
              {
                variant: 'error'
              }
            );
            setLoading(false);
          });
      }
      setSubmitting(false);
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Typography variant="h4" color="textPrimary">
              {docParams
                ? `Select one option for the ${docParams.docType}`
                : `Select one option for the file`}
            </Typography>
            <Box mt={2}>
              <Typography variant="subtitle1" color="textSecondary">
                {docParams
                  ? `Please check ${docParams.docType} before sending`
                  : `Please check file before sending`}
              </Typography>
            </Box>
            <Box mt={2}>
              {actionTypes.map((type, index) => (
                <Paper
                  key={index}
                  display="flex"
                  alignItems="flex-start"
                  p={2}
                  mb={2}
                  component={Box}
                  elevation={actionType === type.value ? 10 : 1}
                >
                  <Radio
                    checked={actionType === type.value}
                    onClick={() => handleChange(type.value)}
                  />
                  <Box ml={2}>
                    <Typography gutterBottom variant="h5" color="textPrimary">
                      {type.title}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {type.description}
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
            {error && (
              <Box mt={2}>
                <FormHelperText error>{error}</FormHelperText>
              </Box>
            )}
            <div className={classes.wrapper}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                type="submit"
                disabled={loading}
              >
                {actionType == 'download' ? 'Download' : 'Email'}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
        </Box>
      </div>
    </Dialog>
  );
}

DocForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

DocForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default DocForm;
