/* eslint-disable max-len */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

const actionOptions = [
  {
    value: true,
    text: 'Mark as paid'
  },
  {
    value: false,
    text: 'Mark as unpaid'
  },
  {
    value: 'delete',
    text: 'Delete'
  }
];

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'createdAt|desc',
    label: 'Creation date (newest first)'
  },
  {
    value: 'createdAt|asc',
    label: 'Creation date (oldest first)'
  }
];

function applyFilters(interestBatches, query, filters) {
  if (!_.isEmpty(interestBatches)) {
    return interestBatches.allIds.filter(id => {
      let matches = true;

      if (
        filters.status &&
        interestBatches.byId[id].investment_status_id !==
          parseInt(filters.status)
      ) {
        matches = false;
      }

      return matches;
    });
  } else {
    return [];
  }
}

function applyPagination(interestBatches, page, limit) {
  return interestBatches.slice(page * limit, page * limit + limit);
}

function Results({ className, emailLogs, ...rest }) {
  const classes = useStyles();
  const actionRef = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  return (
    <PerfectScrollbar>
      <Box minWidth={1200}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Sender</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell>Receiver</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Email Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailLogs.allIds.map(id => {
              return (
                <TableRow hover key={id}>
                  <TableCell>{emailLogs.byId[id].sender}</TableCell>
                  <TableCell>{emailLogs.byId[id].doc_type}</TableCell>
                  <TableCell>
                    {emailLogs.byId[id].investor_name ||
                      emailLogs.byId[id].developer_name}
                  </TableCell>
                  <TableCell>{emailLogs.byId[id].entity_name}</TableCell>
                  <TableCell>
                    {format(
                      parseISO(emailLogs.byId[id].sent_dt),
                      "dd/MM/yyyy 'at' h:mm a"
                    )}
                  </TableCell>
                  <TableCell>{emailLogs.byId[id].email_status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {emailLogs.isLoading && (
          <Box m={3} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        <TablePagination
          component="div"
          count={emailLogs.allIds.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Box>
    </PerfectScrollbar>
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
