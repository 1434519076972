/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormHelperText,
  FormControlLabel,
  IconButton,
  SvgIcon,
  TextField,
  Hidden,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { UserX as DeleteIcon } from 'react-feather';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { NumberMask } from 'src/utils/mask';
import { properName } from 'src/utils/properName';
import { validateABN, validateTFN, validateACN } from 'src/utils/validation';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  fieldset: {
    border: 0
  }
}));

function CompanyForm({ className, entity_type_id, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application } = useSelector(state => state.application);

  const initialValues = application.entity_id
    ? application.entity_id.entity_details
    : entitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          await dispatch(
            updateApplication(application.id, {
              ...application,
              entity_id: {
                ...application.entity_id,
                entity_type_id: entity_type_id,
                investor_id: application.investor_id.id,
                entity_details: values,
                is_tax_resident: values.company.rego_country == 'Australia',
                is_us_tax_resident:
                  values.company.rego_country == 'United States',
                entity_details: values,
                account_name: properName(values.company.business_name),
                address_1: properName(values.company.business_address_1),
                address_2: properName(values.company.business_address_2),
                city: properName(values.company.business_city),
                state: values.company.business_state,
                postcode: values.company.business_postcode,
                country: values.company.business_country
              }
            })
          );
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: JSON.stringify(err.response.data) });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <fieldset className={classes.fieldset}>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box mt={2} mb={1}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                COMPANY DETAILS
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled={application.entity_id !== null}
                  label="Company Name"
                  name="company.business_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.business_name || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled={application.entity_id !== null}
                  label="Incorporated Country"
                  name="company.tax_country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.company.tax_country || ''}
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  required={values.company.tax_country == 'Australia'}
                  helperText={
                    values.company.tax_country == 'Australia' &&
                    values.company.acn > 0 &&
                    !validateACN(values.company.acn) &&
                    'Invalid ACN'
                  }
                  error={
                    values.company.tax_country == 'Australia' &&
                    values.company.acn > 0 &&
                    !validateACN(values.company.acn)
                  }
                  label="ACN"
                  name="company.acn"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.acn || ''}
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('company.acn'),
                    maxLength: 9
                  }}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  // required={values.company.tax_country == 'Australia'}
                  disabled={application.entity_id !== null}
                  helperText={
                    values.company.tax_country == 'Australia' &&
                    values.company.abn > 0 &&
                    !validateABN(values.company.abn) &&
                    'Invalid ABN'
                  }
                  error={
                    values.company.tax_country == 'Australia' &&
                    values.company.abn > 0 &&
                    !validateABN(values.company.abn)
                  }
                  label="ABN"
                  name="company.abn"
                  onBlur={handleBlur}
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('company.abn'),
                    maxLength: 11
                  }}
                  onChange={handleChange}
                  value={values.company.abn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  label="TFN or Exemption number"
                  name="company.tfn"
                  required={values.company.tax_country == 'Australia'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.tfn || ''}
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('company.tfn'),
                    maxLength: 9
                  }}
                  helperText={
                    values.company.tax_country == 'Australia' &&
                    values.company.tfn > 0 &&
                    !validateTFN(values.company.tfn) &&
                    'Invalid TFN'
                  }
                  error={
                    values.company.tax_country == 'Australia' &&
                    values.company.tfn > 0 &&
                    !validateTFN(values.company.tfn)
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Registration Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  inputProps={{ maxLength: 38 }}
                  label="Registered Office Line 1 (cannot be a PO Box Address)"
                  name="company.rego_address_1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.rego_address_1 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 38 }}
                  size="small"
                  label="Registered Office Line 2"
                  name="company.rego_address_2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.rego_address_2 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="City"
                  size="small"
                  name="company.rego_city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.rego_city || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  freeSolo
                  name="rego_state"
                  value={values.company.rego_state || ''}
                  options={states}
                  onInputChange={(event, newValue) => {
                    setFieldValue(
                      'company.rego_state',
                      newValue ? newValue : ''
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="State/Province"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Postcode"
                  name="company.rego_postcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company.rego_postcode || ''}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Country"
                  name="company.rego_country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.company.rego_country || ''}
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Business Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values.company.business_addr_same_as_rego_addr || false
                      }
                      size="small"
                      onChange={handleChange}
                      name="company.business_addr_same_as_rego_addr"
                    />
                  }
                  label="Same as Registration Address"
                />
              </Grid>
              {!values.company.business_addr_same_as_rego_addr && (
                <>
                  <Grid item md={12} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      inputProps={{ maxLength: 38 }}
                      label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                      name="company.business_address_1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company.business_address_1 || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 38 }}
                      size="small"
                      label="Principal Place of Business Line 2"
                      name="company.business_address_2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company.business_address_2 || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="City"
                      name="company.business_city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company.business_city || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Autocomplete
                      freeSolo
                      name="company.business_state"
                      value={values.company.business_state || ''}
                      options={states}
                      onInputChange={(event, newValue) => {
                        setFieldValue(
                          'company.business_state',
                          newValue ? newValue : ''
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          size="small"
                          label="State/Province"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Postcode"
                      name="company.business_postcode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company.business_postcode || ''}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Country"
                      name="company.business_country"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.company.business_country || ''}
                      size="small"
                      variant="outlined"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
            <FieldArray
              name="company.beneficial_owners"
              subscription={{}} // This is required so that the whole array does not re-render on any change.
              render={({ insert, remove, push }) => (
                <>
                  <Box mt={2} mb={2}>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 600 }}
                      color="secondary"
                      gutterBottom
                    >
                      BENEFICIAL OWNERS OF THE COMPANY
                    </Typography>
                    <Typography variant="caption">
                      Please list Full Name/s or Class of ALL Beneficiaries (if
                      individual beneficial owners are not named) who through
                      one or more shareholdings own more than 25% of the
                      company’s issued capital
                    </Typography>
                  </Box>
                  {values.company.beneficial_owners.map(
                    (beneficial_owner, index) => (
                      <div key={index}>
                        <Grid container spacing={1}>
                          <Grid item md={11} xs={10}>
                            <TextField
                              fullWidth
                              label="Full Name (and address)"
                              name={`company.beneficial_owners.${index}`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.company.beneficial_owners[index] || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          {index > 0 && (
                            <Grid item md={1} xs={2}>
                              <Tooltip title="Delete beneficiary">
                                <IconButton
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <SvgIcon>
                                    <DeleteIcon />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    )
                  )}
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        push('');
                      }}
                    >
                      Add Beneficial Owner
                    </Button>
                  </Box>
                </>
              )}
            />
            <FieldArray
              name="company.directors"
              subscription={{}} // This is required so that the whole array does not re-render on any change.
              render={({ insert, remove, push }) => (
                <>
                  <Box mt={2} mb={2}>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 600 }}
                      color="secondary"
                      gutterBottom
                    >
                      DIRECTORS OF THE COMPANY
                    </Typography>
                    <Typography variant="caption">
                      If the company is propriety/private please provide full
                      names of all Directors
                    </Typography>
                  </Box>
                  {values.company.directors.map((director, index) => (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item md={11} xs={10}>
                          <TextField
                            fullWidth
                            required
                            label="Full Name"
                            name={`company.directors.${index}`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.company.directors[index] || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        {index > 0 && (
                          <Grid item md={1} xs={2}>
                            <Tooltip title="Delete director">
                              <IconButton
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <SvgIcon>
                                  <DeleteIcon />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  ))}
                  <Box mt={1}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        push('');
                      }}
                    >
                      Add Director
                    </Button>
                  </Box>
                </>
              )}
            />
            <FieldArray
              name="company.signatories"
              subscription={{}} // This is required so that the whole array does not re-render on any change.
              render={({ insert, remove, push }) => (
                <>
                  <Box mt={2} mb={2}>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 600 }}
                      color="secondary"
                      gutterBottom
                    >
                      AUTHORISED SIGNATORIES
                    </Typography>
                    <Typography variant="caption">
                      This Section is to be completed by the Authorised
                      Signatories on the Account. With the exception of where
                      there is a Sole Director (who must be the Authorised
                      Signatory) these must be at least two Authorised
                      Signatories on the account (eg two Directors or a Director
                      and a Company Secretary). You must nominate at least one
                      Director as an Authorised Signatory.
                    </Typography>
                  </Box>
                  {values.company.signatories.map((signatory, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 600 }}
                          gutterBottom
                        >
                          Signatory {index + 1}
                        </Typography>
                        {index > 0 && (
                          <Tooltip title="Delete signatory">
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <SvgIcon>
                                <DeleteIcon />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                      <Box mt={1}>
                        <Grid container spacing={1}>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              select
                              label="Title"
                              name={`company.signatories.${index}.title`}
                              onChange={handleChange}
                              SelectProps={{
                                native: true
                              }}
                              value={
                                values.company.signatories[index].title || ''
                              }
                              variant="outlined"
                              size="small"
                            >
                              <option></option>
                              {titles.map(option => (
                                <option key={option.code} value={option.code}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              label="First Name"
                              name={`company.signatories.${index}.first_name`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.company.signatories[index].first_name ||
                                ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              label="Last Name"
                              name={`company.signatories.${index}.last_name`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.company.signatories[index].last_name ||
                                ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enAU}
                            >
                              <DatePicker
                                label="Date of Birth"
                                openTo="year"
                                views={['year', 'month', 'day']}
                                name={`company.signatories.${index}.birth_dt`}
                                value={
                                  values.company.signatories[index].birth_dt ||
                                  null
                                }
                                onChange={newValue => {
                                  setFieldValue(
                                    `company.signatories.${index}.birth_dt`,
                                    !newValue ||
                                      newValue.toString() == 'Invalid Date'
                                      ? newValue
                                      : format(newValue, 'yyyy-MM-dd')
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              required
                              fullWidth
                              label="Country of Residency for Tax Purposes"
                              name={`company.signatories.${index}.tax_country`}
                              onChange={handleChange}
                              select
                              SelectProps={{ native: true }}
                              value={
                                values.company.signatories[index].tax_country ||
                                ''
                              }
                              size="small"
                              variant="outlined"
                            >
                              <option></option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.company.signatories[index].is_pep ||
                                    false
                                  }
                                  onChange={handleChange}
                                  size="small"
                                  name={`company.signatories.${index}.is_pep`}
                                />
                              }
                              label="Politically Exposed Person"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.company.signatories[index]
                                      .is_us_tax_resident || false
                                  }
                                  onChange={handleChange}
                                  size="small"
                                  name={`company.signatories.${index}.is_us_tax_resident`}
                                />
                              }
                              label="US Citizen / US Tax Resident"
                            />
                          </Grid>
                          {values.company.signatories[index]
                            .is_us_tax_resident && (
                            <>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  label="TIN"
                                  name={`company.signatories.${index}.tin`}
                                  onChange={handleChange}
                                  value={
                                    values.company.signatories[index].tin || ''
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              label="Email"
                              type="email"
                              name={`company.signatories.${index}.email`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.company.signatories[index].email || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Mobile"
                              name={`company.signatories.${index}.mobile`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.company.signatories[index].mobile || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextField
                              fullWidth
                              required
                              label="Residential Address (cannot be a PO Box address)"
                              name={`company.signatories.${index}.address`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.company.signatories[index].address || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  ))}
                  <Box mt={2}>
                    <Button
                      size="small"
                      variant="contained"
                      // disabled={values.company.signatories.length > 1}
                      onClick={() => {
                        push({ first_name: '', last_name: '' });
                      }}
                    >
                      Add Signatory
                    </Button>
                  </Box>
                </>
              )}
            />
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button variant="contained" onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CompanyForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default CompanyForm;
