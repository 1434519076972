import axios from 'axios';

export const INIT = '@investor/init';
export const GET_INVESTORS = '@investor/get-investors';
export const GET_INVESTOR = '@investor/get-investor';
export const ADD_INVESTOR = '@investor/add-investor';
export const UPDATE_INVESTOR = '@investor/update-investor';
export const DELETE_INVESTOR = '@investor/delete-investor';
export const ADD_ENTITY = '@investor/add-entity';
export const UPDATE_ENTITY = '@investor/update-entity';
export const DELETE_ENTITY = '@investor/delete-entity';
export const SET_ADDRESS = '@investor/set-address';
export const GET_EMAIL_LOGS = '@investor/get-email-logs';

export function getInvestors(params) {
  const request = axios.get('/api/investors', { params: params });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_INVESTORS,
        payload: response.data
      })
    );
  };
}

export function getInvestor(investorId) {
  return async dispatch => {
    if (investorId) {
      dispatch({ type: INIT });
      const response = await axios.get(`/api/investors/${investorId}`);
      dispatch(setAddress(response.data));
      dispatch({ type: GET_INVESTOR, payload: response.data });
    } else {
      dispatch({ type: INIT });
    }
  };
}

export function addInvestor(values) {
  return async dispatch => {
    const response = await axios.post('/api/investors', values);
    dispatch({ type: ADD_INVESTOR, payload: response.data });
  };
}

export function setAddress(values) {
  return async dispatch => {
    try {
      const address = {
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        state: values.state,
        postcode: values.postcode,
        country: values.country,
        full_address: [
          values.address_1,
          values.address_2,
          values.city,
          values.state,
          values.postcode,
          values.country
        ]
          .filter(s => s)
          .filter(function(entry) {
            return entry.trim() != '';
          })
          .join(', ')
      };
      dispatch({ type: SET_ADDRESS, payload: address });
    } catch (error) {
      throw error;
    }
  };
}

export function updateInvestor(investorId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/investors/${investorId}`,
        values
      );
      dispatch({ type: UPDATE_INVESTOR, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function getEmailLogs(investorId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/logs', {
        params: { investorId: investorId }
      });
      dispatch({ type: GET_EMAIL_LOGS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}
