import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SyndicateListView from './SyndicateListView';
import SecurityListView from './SecurityListView';
import Header from './Header';

const tabs = [
  { value: 'syndicates', label: 'Sydicates' },
  { value: 'securities', label: 'Securities' }
];

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function SyndicateListPage() {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('syndicates');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Page className={classes.root} title="Syndicate">
      <Container maxWidth={false}>
        <Header />
        <Box mt={1}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            textColor="secondary"
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box py={1} pb={2}>
          {currentTab === 'syndicates' && <SyndicateListView />}
          {currentTab === 'securities' && <SecurityListView />}
        </Box>
      </Container>
    </Page>
  );
}

export default SyndicateListPage;
