import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  SvgIcon,
  Card,
  CardContent,
  IconButton,
  Grid,
  Switch
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { Plus as PlusIcon } from 'react-feather';
import { addDays, format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import {
  addInvestment,
  refreshInvestments,
  updateInvestment
} from 'src/actions/syndicateActions';
import { CurrencyMask, PercentMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

const filter = createFilterOptions();

function InvestmentForm({
  investment,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { syndicateId } = useParams();
  const { commissionType, paymentFreq } = useSelector(state => state.setting);
  const { fundraisers } = useSelector(state => state.fundraiser);
  const { user } = useSelector(state => state.account);
  const { syndicate, investments, isLoading } = useSelector(
    state => state.syndicate
  );
  const { investors, isLoading: investorLoading } = useSelector(
    state => state.investor
  );
  const investorOptions = investors.allIds.map(id => {
    return { id: id, investor_name: investors.byId[id]['display_name'] };
  });

  const initialValues = investment
    ? investment
    : {
        syndicate_id: syndicateId,
        is_pool: syndicate && syndicate.is_pool,
        investor_id: null,
        entity_id: null,
        funds_called: false,
        is_liquidity: false,
        to_replace_liquidity: false,
        note: null,
        unit_class_id:
          syndicate && !syndicate.is_pool && syndicate.unit_classes[0].id,
        staff_id: user.id,
        fundraiser_id: null,
        created_dt: new Date()
      };

  if (isLoading) {
    return null;
  }
  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   amount: Yup.number('Must be a number')
            //     .min(0)
            //     .required('Required'),
            //   is_liquidity: Yup.bool().required('Required'),
            //   interest: Yup.number('Must be a number')
            //     .min(0)
            //     .nullable(),
            //   coupon: Yup.number('Must be a number')
            //     .min(0)
            //     .nullable(),
            //   unit_class_id: Yup.number()
            //     .when('is_pool', {
            //       is: true,
            //       then: Yup.number().required('Required')
            //     })
            //     .nullable()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                if (investment) {
                  await dispatch(updateInvestment(investment.id, values));
                  if (investment.call_order == 1) {
                    const childInvestmentIds = investments.allIds.filter(
                      id =>
                        investments.byId[id].parent_investment_id == values.id
                    );
                    await dispatch(refreshInvestments(childInvestmentIds));
                  }
                } else {
                  await dispatch(addInvestment(values));
                }
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar(
                  investment ? 'Investment updated' : 'Investment added',
                  {
                    variant: 'success'
                  }
                );
              } catch (error) {
                // TODO catch proper error msg
                setStatus({ success: false });
                setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="h4" color="primary">
                      Investment Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="h5" color="textPrimary">
                                EOI
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Switch
                                disabled={values.investment_status_id > 1}
                                checked={values.funds_called}
                                color="secondary"
                                edge="start"
                                name="funds_called"
                                onChange={handleChange}
                                value={values.funds_called}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h5" color="textPrimary">
                                Funds Called
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="EOI Date"
                            value={values.created_dt}
                            name="created_dt"
                            maxDate={new Date()}
                            onChange={newValue => {
                              setFieldValue('created_dt', newValue);
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disabled={values.id > 0}
                          name="investor_id"
                          value={
                            values.investor_id
                              ? investorOptions.filter(
                                  option => option.id == values.investor_id
                                )[0]
                              : null
                          }
                          options={investorOptions}
                          getOptionLabel={option => option.investor_name}
                          onChange={(event, newValue) => {
                            if (
                              newValue &&
                              newValue.investor_name === 'Add New Investor'
                            ) {
                              console.log('here link to investor create page');
                            } else {
                              setFieldValue(
                                'investor_id',
                                newValue ? newValue.id : null
                              );
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                inputValue: params.inputValue,
                                investor_name: 'Add New Investor'
                              });
                            }
                            return filtered;
                          }}
                          renderOption={option => {
                            return (
                              <>
                                {option.inputValue ? (
                                  <Button
                                    size="small"
                                    component={RouterLink}
                                    to="/app/management/investors/create"
                                  >
                                    <SvgIcon fontSize="small" color="primary">
                                      <PlusIcon />
                                    </SvgIcon>
                                    {option.investor_name}
                                  </Button>
                                ) : (
                                  option.investor_name
                                )}
                              </>
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              label="Investor"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Investment Entity"
                          name="entity_id"
                          disabled={
                            values.investment_status_id > 1 ||
                            ['signed', 'verified'].includes(
                              values.application_status
                            )
                          }
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: !!values.entity_id
                          }}
                          select
                          SelectProps={{ native: true }}
                          value={values.entity_id || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {values.investor_id &&
                            investors.byId[values.investor_id].entities
                              .filter(entity => entity.in_use)
                              .map(entity => (
                                <option key={entity.id} value={entity.id}>
                                  {entity.account_name}
                                </option>
                              ))}
                        </TextField>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          required
                          fullWidth
                          disabled={
                            (values.investment_status_id > 1 &&
                              !user.group_names.some(r =>
                                ['Fund Manager', 'Finance'].includes(r)
                              )) ||
                            values.call_order > 1
                          }
                          label="Amount"
                          name="amount"
                          InputProps={{
                            inputComponent: CurrencyMask,
                            onChange: handleChange('amount')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.amount || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          required
                          label="Unit Class"
                          name="unit_class_id"
                          disabled={values.investment_status_id > 1}
                          InputLabelProps={{
                            shrink: !!values.unit_class_id
                          }}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.unit_class_id || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {syndicate.unit_classes.map(unit_class => (
                            <option key={unit_class.id} value={unit_class.id}>
                              {unit_class.unit_class}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      {user.group_names.some(r =>
                        ['Fund Manager', 'Finance'].includes(r)
                      ) && (
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(
                              touched.rate_adjustment && errors.rate_adjustment
                            )}
                            disabled={
                              !user.group_names.some(r =>
                                ['Fund Manager', 'Finance'].includes(r)
                              )
                            }
                            fullWidth
                            helperText={
                              touched.rate_adjustment && errors.rate_adjustment
                            }
                            label="Rate Adjustment"
                            name="rate_adjustment"
                            InputProps={{
                              inputComponent: PercentMask,
                              onChange: handleChange('rate_adjustment')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.rate_adjustment}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Box mt={3} mb={1}>
                      <Typography variant="h4" color="primary">
                        Fundraiser Information
                      </Typography>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Fund Raiser"
                          name="fundraiser_id"
                          InputLabelProps={{
                            shrink: !!values.fundraiser_id
                          }}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.fundraiser_id || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {fundraisers.allIds.map(id => (
                            <option key={id} value={id}>
                              {fundraisers.byId[id].fundraiser_name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          error={Boolean(
                            touched.commission && errors.commission
                          )}
                          fullWidth
                          helperText={touched.commission && errors.commission}
                          label="Commission Rate"
                          name="commission"
                          InputProps={{
                            inputComponent: PercentMask,
                            onChange: handleChange('commission')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.commission || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Commission Type"
                          name="commission_type_id"
                          InputLabelProps={{
                            shrink: !!values.commission_type_id
                          }}
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.commission_type_id || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {commissionType.allIds.map(id => (
                            <option key={id} value={id}>
                              {commissionType.byId[id].name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Box mt={3} mb={1}>
                      <Typography variant="h4" color="primary">
                        Repayment Options
                      </Typography>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          error={Boolean(errors && errors.rollover_amt)}
                          helperText={errors && errors.rollover_amt}
                          fullWidth
                          label="Rollover Amount"
                          name="rollover_amt"
                          InputProps={{
                            inputComponent: CurrencyMask,
                            onChange: handleChange('rollover_amt')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.rollover_amt || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          error={Boolean(
                            touched.rollover_ref && errors.rollover_ref
                          )}
                          fullWidth
                          helperText={
                            touched.rollover_ref && errors.rollover_ref
                          }
                          label="Rollover Reference"
                          name="rollover_ref"
                          inputProps={{ maxLength: 18 }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.rollover_ref || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Grid
                          component="label"
                          container
                          alignItems="flex-start"
                          direction="column"
                        >
                          <Grid item>
                            <Typography variant="h5" color="textPrimary">
                              Liquidity
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Switch
                              checked={values.is_liquidity}
                              color="secondary"
                              edge="start"
                              name="is_liquidity"
                              onChange={handleChange}
                              value={values.is_liquidity}
                            />
                          </Grid>
                          {values.is_liquidity && (
                            <Grid item>
                              <Typography variant="caption">
                                Leave both dates blank if can redeem anytime
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {values.is_liquidity && (
                        <>
                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enAU}
                            >
                              <DatePicker
                                openTo="year"
                                views={['year', 'month', 'day']}
                                label="Liquidity Open Date"
                                minDate={addDays(new Date(), 1)}
                                value={values.liquidity_start_dt}
                                name="liquidity_start_dt"
                                onChange={newValue => {
                                  setFieldValue(
                                    'liquidity_start_dt',
                                    !newValue ||
                                      newValue.toString() == 'Invalid Date'
                                      ? newValue
                                      : format(newValue, 'yyyy-MM-dd')
                                  );
                                }}
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enAU}
                            >
                              <DatePicker
                                openTo="year"
                                views={['year', 'month', 'day']}
                                label="Liquidity Deadline Date"
                                value={values.liquidity_end_dt}
                                name="liquidity_end_dt"
                                minDate={
                                  values.liquidity_start_dt
                                    ? addDays(values.liquidity_start_dt, 1)
                                    : addDays(new Date(), 1)
                                }
                                onChange={newValue => {
                                  setFieldValue(
                                    'liquidity_end_dt',
                                    !newValue ||
                                      newValue.toString() == 'Invalid Date'
                                      ? newValue
                                      : format(newValue, 'yyyy-MM-dd')
                                  );
                                }}
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      )}
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.note && errors.note)}
                          fullWidth
                          multiline
                          rows={3}
                          helperText={touched.note && errors.note}
                          label="Note"
                          name="note"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.note || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {investment ? 'Update Investment' : 'Add Investment'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

InvestmentForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

InvestmentForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default InvestmentForm;
