import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Backdrop,
  CircularProgress,
  Button,
  IconButton,
  Box,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  DeleteOutlineOutlined as DeleteIcon,
  FileUploadOutlined as UploadIcon
} from '@mui/icons-material';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { format, parseISO } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import bytesToSize from 'src/utils/bytesToSize';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  highlight: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function Files({ className, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const { application } = useSelector(state => state.application);
  const { documentType } = useSelector(state => state.setting);

  const documentRef = useRef();
  const clearDocumentRef = () => {
    documentRef.current.value = '';
  };

  const initialValues = {
    document_type_id: '',
    expiry_dt: null,
    document: {}
  };

  const handleClose = () => {
    setUploading(false);
  };

  const file_dropbox_path = `investors/${application.investor_id.id}_${application.investor_id.display_name}/${application.entity_id.id}_${application.entity_id.account_name}`;

  const data = application.entity_id.files;
  const columns = [
    { field: 'document_type', title: 'Type' },
    {
      field: 'expiry_dt',
      title: 'Expiry Date',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    }
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          // document_type_id: Yup.number().required(),
          expiry_dt: Yup.date().nullable()
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }
        ) => {
          try {
            // Do API call to store step data in server session
            // It is important to have it on server to be able to reuse it if user
            // decides to continue later.
            setUploading(true);
            const formData = new FormData();
            formData.set('path', file_dropbox_path);
            formData.set(
              'document_type',
              documentType.byId[values.document_type_id].code
            );
            formData.set('expiry_dt', values.expiry_dt);
            formData.set('document', values.document);
            axios
              .post('/api/file', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                dispatch(
                  updateApplication(application.id, {
                    ...application,
                    entity_id: {
                      ...application.entity_id,
                      files: _.unionBy(
                        [response.data],
                        application.entity_id.files,
                        'id'
                      )
                    }
                  })
                );
                clearDocumentRef();
                resetForm();
                setUploading(false);
                setStatus({ success: true });
              })
              .catch(error => {
                throw error;
              });
          } catch (err) {
            resetForm();
            setUploading(false);
            setErrors({ submit: err.message });
            setStatus({ success: false });
            // setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          resetForm,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Box mb={2}>
              <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
                SUPPORTING DOCUMENTS
              </Typography>
            </Box>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  required
                  label="Document Type"
                  name="document_type_id"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.document_type_id}
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {documentType.allIds.map(id => (
                    <option key={id} value={id}>
                      {documentType.byId[id].code}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enAU}
                >
                  <DatePicker
                    label="Expiry Date (if applicable)"
                    value={values.expiry_dt}
                    onChange={newValue => {
                      setFieldValue(
                        'expiry_dt',
                        !newValue || newValue.toString() == 'Invalid Date'
                          ? newValue
                          : format(newValue, 'yyyy-MM-dd')
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={values.expiry_dt}
                        name="expiry_dt"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  name="document"
                  onChange={event => {
                    const file = event.target.files[0];
                    setFieldValue('document', file);
                  }}
                  inputRef={documentRef}
                  type="file"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                xs={12}
                md={2}
                spacing={1}
              >
                <Grid item xs={6} md={6}>
                  <IconButton
                    size="small"
                    variant="contained"
                    onClick={clearDocumentRef}
                    disabled={
                      uploading ||
                      !documentRef.current ||
                      (documentRef.current && documentRef.current.value == '')
                    }
                  >
                    Clear
                  </IconButton>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div className={classes.wrapper}>
                    <IconButton
                      size="small"
                      variant="contained"
                      color="secondary"
                      disabled={
                        uploading ||
                        !documentRef.current ||
                        (documentRef.current && documentRef.current.value == '')
                      }
                      type="submit"
                      size="small"
                    >
                      Upload
                      <UploadIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={4}>
              <Backdrop
                className={classes.backdrop}
                open={uploading}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <CustomMaterialTable
                title="Supporting Documents"
                data={data}
                columns={columns}
                actions={[
                  {
                    icon: DeleteIcon,
                    tooltip: 'Delete File',
                    onClick: (event, rowData) => {
                      dispatch(
                        updateApplication(application.id, {
                          entity_id: {
                            ...application.entity_id,
                            files: application.entity_id.files.filter(
                              file => file.id != rowData.id
                            )
                          }
                        })
                      );
                    }
                  }
                ]}
                options={{
                  paging: false,
                  padding: 'dense'
                }}
              />
            </Box>
            {parseFloat(application.amount) < 500000 && (
              <>
                <Box mt={2}>
                  <Typography
                    variant="h5"
                    color="secondary"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    SOPHISTICATED INVESTOR
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1" gutterBottom>
                    Since the investment amount is less than $500,000, the
                    individual applicants / individual trustees / company
                    directors / trustee company direcotrs are required to
                    provide an Accountant's Certificate certified by a qualified
                    accountant{' '}
                    <span className={classes.highlight}>
                      {' '}
                      within the past 2 years{' '}
                    </span>{' '}
                    to prove they're a sophisticated investor unless otherwised
                    approved by the fund manager:
                  </Typography>
                </Box>
                <Box ml={4} mt={2}>
                  <ul>
                    <li>
                      <Typography variant="body1" gutterBottom>
                        A person who has aggregated net assets of $2.5 million
                        or
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1" gutterBottom>
                        Has aggregated gross income for each of the last two
                        financial years of at least $250,000 a year
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </>
            )}
            <Box mt={4}>
              <Typography
                variant="h5"
                color="secondary"
                style={{ fontWeight: 600 }}
                gutterBottom
              >
                REQUIRED DOCUMENTS
              </Typography>
            </Box>
            <Box mt={2}>
              {[1, 2, 6].includes(application.entity_id.entity_type_id) && (
                <>
                  <Typography variant="body1" gutterBottom>
                    Please provide a certified copy of one of the following
                    photographic/photo ID documents for each individual
                    applicant
                  </Typography>
                  <Box ml={4} mt={2}>
                    <ul>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Current Passport or a similar document issued for the
                          purpose of international travel (if the passport has
                          been issued by the Commonwealth of Australia and has
                          expired within the preceding two years, this will be
                          acceptable)
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Current Driver’s licence (Australian residents only)
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Current National identity card (issued by a foreign
                          government, the United Nations or an agency of the
                          United Nations); or
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Proof of Age card (card issued for the purpose of
                          proving a person’s age).
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </>
              )}
              {[3, 4].includes(application.entity_id.entity_type_id) && (
                <>
                  <Typography variant="body1" gutterBottom>
                    Please provide certified copies of one of the following
                    documents (NB. The documents listed must confirm the Trust
                    name and existence):
                  </Typography>
                  <Box ml={4} my={2}>
                    <ul>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Trust Deed or Extract of Trust Deed; or
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Notice of assessment issued by the Australian Taxation
                          Office (or overseas equivalent) within the last 12
                          months; or
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          A letter signed by a solicitor or qualified accountant
                          regarding the establishment of the trust.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Typography variant="body1" gutterBottom>
                    To identify the Trustee, we will also need the following:
                  </Typography>
                  <Box ml={4} mt={2}>
                    <ul>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          If the identifying trustee is a company, please
                          provide a current ASIC or equivalent company search or
                          a certificate of registration issued by ASIC or
                          equivalent government body.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          If the identifying trustee is an individual, please
                          provide documentation as outlined below
                        </Typography>
                        <ul>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Current Passport or a similar document issued for
                              the purpose of international travel (if the
                              passport has been issued by the Commonwealth of
                              Australia and has expired within the preceding two
                              years, this will be acceptable)
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Current Driver’s licence (Australian residents
                              only)
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Current National identity card (issued by a
                              foreign government, the United Nations or an
                              agency of the United Nations); or
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="body1" gutterBottom>
                              Proof of Age card (card issued for the purpose of
                              proving a person’s age).
                            </Typography>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Box>
                </>
              )}
              {[5].includes(application.entity_id.entity_type_id) && (
                <>
                  <Typography variant="body1" gutterBottom>
                    Please provide certified copies of one of the following
                    documents AND a copy of your company signatory list. :
                  </Typography>
                  <Box ml={4} mt={2}>
                    <ul>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Current licence issued by the relevant Commonwealth or
                          Territory statutory regulator
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Public Document issued by the company; or
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1" gutterBottom>
                          Certificate of Registration issued by ASIC.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </>
              )}
            </Box>
            <Box mt={6} display="flex">
              {onBack && (
                <Button onClick={onBack} variant="contained" size="large">
                  Previous
                </Button>
              )}
              <Box flexGrow={1} />
              <Button
                color="secondary"
                disabled={isSubmitting}
                onClick={() => {
                  if (onNext) {
                    onNext();
                  }
                }}
                variant="contained"
                size="large"
              >
                Save & Next
              </Button>
            </Box>
            <Box mt={2} />
          </form>
        )}
      </Formik>
    </>
  );
}

Files.propTypes = {
  className: PropTypes.string
  // files: PropTypes.array.isRequired
};

export default Files;
