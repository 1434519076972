import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import getInitials from 'src/utils/getInitials';
import { Avatar, Box, TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// import { TextField } from '@material-ui/core';
import { updateCard } from 'src/actions/kanbanActions';
import format from 'date-fns/format';
// import { addComment } from '../../../slices/kanban';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'grey'
    }
  }
});

const cardSelector = (state, cardId) => {
  const { cards } = state.kanban;

  const card = cards.byId[cardId];

  return {
    ...card
    // members: card.assigned_to.map(memberId => staff.byId[memberId])
  };
};

export const KanbanCommentAdd = props => {
  const { cardId, ...other } = props;
  const dispatch = useDispatch();
  // To get the user from the authContext, you can use
  const { user } = useSelector(state => state.account);
  const [message, setMessage] = useState('');
  const card = useSelector(state => cardSelector(state, cardId));

  const handleChange = event => {
    setMessage(event.target.value);
  };

  const handleKeyUp = async event => {
    try {
      if (event.code === 'Enter' && message) {
        await dispatch(
          updateCard(cardId, {
            comments: card.comments.concat([
              {
                id: uuidv4(),
                message: message,
                created_dt: format(new Date(), "MMM dd, yyyy 'at' hh:mm a"),
                memberId: user.id
              }
            ])
          })
        );
        setMessage('');
        toast.success('Comment added!');
      }
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex'
      }}
      {...other}
    >
      <Avatar src={user.avatar} sx={{ mr: 2 }}>
        {getInitials(user.full_name)}
      </Avatar>
      <CssTextField
        fullWidth
        multiline
        rows={1}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder="Write a comment..."
        size="small"
        value={message}
      />
    </Box>
  );
};

KanbanCommentAdd.propTypes = {
  cardId: PropTypes.number.isRequired
};
