import produce from 'immer';
import _ from 'lodash';
import {
  INIT,
  GET_INVESTORS,
  GET_INVESTOR,
  ADD_INVESTOR,
  UPDATE_INVESTOR,
  SET_ADDRESS,
  GET_EMAIL_LOGS
} from 'src/actions/investorActions';
import objFromArray from 'src/utils/objFromArray';

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

const initialState = {
  isLoading: true,
  investor: null,
  investors: {
    byId: {},
    allIds: []
  },
  entities: {
    byId: {},
    allIds: []
  },
  address: null,
  emailLogs: { byId: {}, allIds: [], isLoading: true }
};

const investorReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT: {
      return initialState;
    }
    case GET_INVESTOR: {
      const investor = action.payload;

      return produce(state, draft => {
        draft.investor = investor;
        // draft.files = investor.files;
        draft.isLoading = false;
      });
    }
    case GET_INVESTORS: {
      const investors = action.payload;
      const entities = investors
        .map(investor => investor.entities)
        .flat(Infinity);

      return produce(state, draft => {
        draft.investors.byId = objFromArray(investors);
        draft.investors.allIds = investors.map(investor => investor.id);
        draft.entities.byId = objFromArray(entities);
        draft.entities.allIds = entities.map(entity => entity.id);
        draft.isLoading = false;
      });
    }

    case ADD_INVESTOR: {
      const investor = action.payload;

      return produce(state, draft => {
        draft.investors.byId[investor.id] = investor;
        draft.investors.allIds.push(investor.id);
      });
    }

    case UPDATE_INVESTOR: {
      const investor = action.payload;

      return produce(state, draft => {
        _.mergeWith(draft.investor, investor, customizer);
      });
    }

    case SET_ADDRESS: {
      const address = action.payload;
      return produce(state, draft => {
        draft.address = address;
      });
    }

    case GET_EMAIL_LOGS: {
      const logs = action.payload;
      return produce(state, draft => {
        draft.emailLogs.byId = objFromArray(logs);
        draft.emailLogs.allIds = logs.map(log => log.id);
        draft.emailLogs.isLoading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default investorReducer;
