import React from 'react';

function ColorLogo(props) {
  return (
    <img
      // alt="ColorLogo"
      style={{ height: 88, height: 88 }}
      src="/static/avatar.svg"
      {...props}
    />
  );
}

export default ColorLogo;
