import React, { useRef, useEffect } from 'react';
import { createTheme } from 'src/theme';
import { MuiThemeProvider } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import MaterialTable from 'material-table';
import {
  AddBoxOutlined as AddIcon,
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditIcon
} from '@mui/icons-material';

const CustomMaterialTable = props => {
  const { settings } = useSettings();
  const tableRef = useRef();
  const openedPanels = useRef({});
  const oldFunction = useRef();
  useEffect(() => {
    if (!oldFunction.current) {
      oldFunction.current = tableRef.current?.onToggleDetailPanel;
    }

    if (oldFunction.current === tableRef.current?.onToggleDetailPanel) {
      tableRef.current.onToggleDetailPanel = (path, render) => {
        if (tableRef.current.props.data[path[0]].tableData.showDetailPanel) {
          delete openedPanels.current[path[0]];
        } else {
          openedPanels.current = {
            ...openedPanels.current,
            [path[0]]: true
          };
        }

        oldFunction.current(path, render);
      };
    }
  }, [tableRef]);
  return (
    <MuiThemeProvider theme={createTheme(settings)}>
      <MaterialTable
        tableRef={tableRef}
        {...props}
        data={
          props.data?.map((d, i) => ({
            ...d,
            tableData: {
              showDetailPanel: openedPanels.current[i]
                ? props.detailPanel
                : null
            }
          })) || []
        }
        icons={{
          Add: () => <AddIcon fontSize="small" />,
          Edit: () => <EditIcon fontSize="small" />,
          Delete: () => <DeleteIcon fontSize="small" />
        }}
      />
    </MuiThemeProvider>
  );
};
export default CustomMaterialTable;
