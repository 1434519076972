/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  Chip,
  IconButton,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import {
  AddBoxOutlined as AddIcon,
  NoteAltOutlined as EditIcon,
  LockOutlined as LockIcon
} from '@mui/icons-material';
import Label from 'src/components/Label';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { LightTooltip } from 'src/components/LightTooltip';
import { currencyFormatter } from 'src/utils/mask';
import BarProgress from 'src/components/BarProgress';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0
  },
  queryField: {
    width: 500
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  popover: {
    display: 'flex',
    margin: theme.spacing(2),
    width: '300px'
  },
  chip: {
    marginRight: theme.spacing(0.5)
  }
}));

const statusColors = {
  false: 'error',
  true: 'success'
};

const statusOptions = [
  {
    value: true,
    label: 'Active'
  },
  {
    value: false,
    label: 'Inactive'
  }
];

function Results({ className, onAdd, onEdit, ...rest }) {
  const classes = useStyles();
  const { staff, isLoading } = useSelector(state => state.staff);
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(true);

  const handleStatusChange = () => {
    setStatus(!status);
  };

  // Usually query is done on backend with indexing solutions

  const data = staff.allIds
    .map(id => ({
      ...staff.byId[id]
    }))
    .filter(staff => staff.is_active == status);

  const columns = [
    {
      field: 'full_name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <IconButton size="small" onClick={() => onEdit(rowData)}>
                <EditIcon />
              </IconButton>
            </Box>
            {rowData.full_name}
          </Box>
        );
      }
    },
    {
      field: 'email',
      title: 'Email'
    },
    {
      field: 'group_names',
      title: 'User Group',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => (
        <Box component="ul" className={classes.paper}>
          {rowData.group_names.map((group, index) => {
            return (
              <li key={index}>
                <Chip size="small" label={group} className={classes.chip} />
              </li>
            );
          })}
        </Box>
      )
    }
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        title={
          <Box my={1} className={classes.stageField}>
            <TextField
              fullWidth
              size="small"
              label="Status"
              name="is_active"
              onChange={handleStatusChange}
              select
              SelectProps={{ native: true }}
              value={status}
              variant="outlined"
            >
              {statusOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        }
        data={data}
        isLoading={isLoading}
        columns={columns}
        actions={[
          {
            icon: AddIcon,
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: event => onAdd()
          },
          {
            icon: LockIcon,
            tooltip: 'Reset Password',
            onClick: (event, rowData) => {
              confirm(`Reset password for ${rowData.full_name}`);
              try {
                let newPassword = Math.random()
                  .toString(36)
                  .substring(2, 10);
                axios.post('/api/auth/account', {
                  ...rowData,
                  password: newPassword
                });
                enqueueSnackbar(
                  `Password reset for ${rowData.full_name}. New password is ${newPassword}`,
                  {
                    variant: 'success'
                  }
                );
              } catch {
                enqueueSnackbar(
                  `Failed to reset password for ${rowData.full_name}`,
                  {
                    variant: 'error'
                  }
                );
              }
            }
          }
        ]}
        options={{
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [10, 20, 50],
          actionsColumnIndex: -1
        }}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  investors: PropTypes.array
};

Results.defaultProps = {
  investors: []
};

export default Results;
