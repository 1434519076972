import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  IconButton,
  SvgIcon,
  Grid
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { Trash2 as TrashIcon } from 'react-feather';
import { addTransactions } from 'src/actions/syndicateActions';
import { CurrencyMask, currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

// function creditCap(investment) {
//   let cap = 0;
//   if (investment) {
//     cap = parseInt(investment.amount) - parseInt(investment.current_amt);
//   }
//   return cap;
// }

// function debitCap(investment) {
//   let cap = 0;
//   if (investment) {
//     cap = investment.current_amt;
//   }
//   return cap;
// }

function creditTotal(entries) {
  return entries
    .map(({ credits }) => parseFloat(credits || 0))
    .reduce((sum, i) => sum + i, 0)
    .toFixed(2);
}

function debitTotal(entries) {
  return entries
    .map(({ debits }) => parseFloat(debits || 0))
    .reduce((sum, i) => sum + i, 0)
    .toFixed(2);
}

function JournalForm({ investment, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { transactionType } = useSelector(state => state.setting);
  const { syndicate } = useSelector(state => state.syndicate);

  const initialValues = {
    investment_id: investment ? investment.investment_id : null,
    journal_dt: null,
    entries: [
      { transaction_type_id: null, debits: null, credits: null },
      { transaction_type_id: null, debits: null, credits: null }
    ]
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              // transaction_type_id: Yup.number().required(),
              // transaction_dt: Yup.date().min(
              //   syndicate ? syndicate.start_dt : moment().toDate()
              // ),
              // amount: Yup.number().when('transaction_type_id', {
              //   is: 1,
              //   then: fieldSchema =>
              //     fieldSchema
              //       .min(1)
              //       .max(creditCap(investment))
              //       .required(),
              //   otherwise: fieldSchema =>
              //     fieldSchema
              //       .min(0)
              //       .max(debitCap(investment))
              //       .required()
              // }),
              journal_dt: Yup.date().required()
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // if (transaction.id) {
                //   await dispatch(updateFund(transaction.id, values));
                // } else {
                //   await dispatch(addTransactions(values));
                // }
                // console.log(values);
                const transactions = values.entries
                  .map(entry => {
                    if (entry.credits) {
                      return {
                        investment_id: values.investment_id,
                        transaction_type_id: entry.transaction_type_id,
                        amount: entry.credits,
                        transaction_dt: values.journal_dt
                      };
                    } else {
                      return {
                        investment_id: values.investment_id,
                        transaction_type_id: entry.transaction_type_id,
                        amount: entry.debits,
                        transaction_dt: values.journal_dt
                      };
                    }
                  })
                  .filter(t => t.amount);
                await dispatch(addTransactions(transactions));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Journal entry added', {
                  variant: 'success'
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      justifyContent="space-between"
                      className={clsx(classes.root, className)}
                      {...rest}
                    >
                      <Grid item xs={12} md={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Journal Date"
                            value={values.journal_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'journal_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                size="small"
                                fullWidth
                                variant="outlined"
                                value={values.journal_dt}
                                name="journal_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <FieldArray
                      name="entries"
                      subscription={{}} // This is required so that the whole array does not re-render on any change.
                      render={({ insert, remove, push }) => (
                        <>
                          <Box mt={2}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">#</TableCell>
                                  <TableCell>Item</TableCell>
                                  <TableCell align="right">Debits</TableCell>
                                  <TableCell align="right">Credits</TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.entries.map((entry, index) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    <TableCell align="left">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell component="th" padding="none">
                                      <TextField
                                        size="small"
                                        name={`entries.${index}.transaction_type_id`}
                                        onChange={handleChange}
                                        select
                                        SelectProps={{ native: true }}
                                        value={
                                          values.entries[index]
                                            .transaction_type_id || ''
                                        }
                                        variant="outlined"
                                      >
                                        <option></option>
                                        {transactionType.allIds.map(id => (
                                          <option key={id} value={id}>
                                            {transactionType.byId[id].name}
                                          </option>
                                        ))}
                                      </TextField>
                                    </TableCell>
                                    <TableCell align="right">
                                      <TextField
                                        disabled={
                                          values.entries[index]
                                            .transaction_type_id
                                            ? transactionType.byId[
                                                values.entries[index]
                                                  .transaction_type_id
                                              ].accounting_type == 'Credit'
                                            : false
                                        }
                                        size="small"
                                        name={`entries.${index}.debits`}
                                        InputProps={{
                                          inputComponent: CurrencyMask,
                                          onChange: handleChange(
                                            `entries.${index}.debits`
                                          )
                                        }}
                                        inputProps={{
                                          style: { textAlign: 'right' }
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={
                                          values.entries[index].debits || ''
                                        }
                                        variant="outlined"
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <TextField
                                        disabled={
                                          values.entries[index]
                                            .transaction_type_id
                                            ? transactionType.byId[
                                                values.entries[index]
                                                  .transaction_type_id
                                              ].accounting_type == 'Debit'
                                            : false
                                        }
                                        size="small"
                                        name={`entries.${index}.credits`}
                                        InputProps={{
                                          inputComponent: CurrencyMask,
                                          onChange: handleChange(
                                            `entries.${index}.credits`
                                          )
                                        }}
                                        inputProps={{
                                          style: { textAlign: 'right' }
                                        }}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={
                                          values.entries[index].credits || ''
                                        }
                                        variant="outlined"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        variant="contained"
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        <SvgIcon fontSize="small">
                                          <TrashIcon />
                                        </SvgIcon>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow selected={true}>
                                  <TableCell />
                                  <TableCell align="right">Total</TableCell>
                                  <TableCell align="right">
                                    {currencyFormatter(
                                      debitTotal(values.entries)
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {currencyFormatter(
                                      creditTotal(values.entries)
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    {debitTotal(values.entries) -
                                      creditTotal(values.entries) ==
                                    0
                                      ? ''
                                      : currencyFormatter(
                                          Math.abs(
                                            debitTotal(values.entries) -
                                              creditTotal(values.entries)
                                          ).toFixed(2)
                                        )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                          <Box mt={1}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                push({});
                              }}
                            >
                              Add Line
                            </Button>
                          </Box>
                        </>
                      )}
                    />
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add Journal Entries
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

JournalForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

JournalForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default JournalForm;
