/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormHelperText,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { UserX as DeleteIcon } from 'react-feather';
import { Autocomplete } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { properName } from 'src/utils/properName';
import { NumberMask } from 'src/utils/mask';
import { updateEntity, addEntity } from 'src/actions/entityActions';
import { validateABN, validateTFN } from 'src/utils/validation';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function PartnershipForm({
  className,
  entity_type_id,
  onBack,
  onNext,
  ...rest
}) {
  const classes = useStyles();
  // sole trader is designed to be a form of sole director company
  const dispatch = useDispatch();
  const { investorId, entityId } = useParams();
  const { entity } = useSelector(state => state.entity);
  const { address } = useSelector(state => state.investor);

  const defaultPartnership = {
    individuals: [
      {
        title: '',
        first_name: '',
        last_name: '',
        birth_dt: '',
        is_us_tax_resident: false,
        tin: '',
        is_pep: false,
        tfn: '',
        tax_country: '',
        address: ''
      }
    ],
    business_name: '',
    abn: '',
    rego_address_1: address.address_1,
    rego_address_2: address.address_2 || '',
    rego_city: address.city,
    rego_state: address.state,
    rego_postcode: address.postcode,
    rego_country: address.country,
    business_addr_same_as_rego_addr: true,
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state: '',
    business_postcode: '',
    business_country: ''
  };

  const initialValues = entity
    ? entity.entity_details
    : {
        ...entitySchema,
        partnership: defaultPartnership
      };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (entity) {
            dispatch(
              updateEntity(entity.id, {
                ...entity,
                entity_details: values,
                is_tax_resident: values.partnership.tax_country == 'Australia',
                is_us_tax_resident: values.partnership.is_us_tax_resident,
                account_name:
                  properName(values.partnership.business_name) ||
                  properName(
                    values.partnership.individuals
                      .map(ind => ind.first_name.concat(' ', ind.last_name))
                      .join(' & ')
                  ),
                address_1: properName(values.partnership.rego_address_1),
                address_2: properName(values.partnership.rego_address_2),
                city: properName(values.partnership.rego_city),
                state: values.partnership.rego_state,
                postcode: values.partnership.rego_postcode,
                country: values.partnership.rego_country
              })
            );
          } else {
            dispatch(
              addEntity({
                investor_id: investorId,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident: values.partnership.tax_country == 'Australia',
                is_us_tax_resident: values.partnership.is_us_tax_resident,
                account_name:
                  properName(values.partnership.business_name) ||
                  properName(
                    values.partnership.individuals
                      .map(ind => ind.first_name.concat(' ', ind.last_name))
                      .join(' & ')
                  ),
                address_1: properName(values.partnership.rego_address_1),
                address_2: properName(values.partnership.rego_address_2),
                city: properName(values.partnership.rego_city),
                state: values.partnership.rego_state,
                postcode: values.partnership.rego_postcode,
                country: values.partnership.rego_country
              })
            );
          }
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                label="Full Business Name (if any)"
                name="partnership.business_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnership.business_name || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                helperText={
                  values.partnership.abn > 0 &&
                  !validateABN(values.partnership.abn) &&
                  'Invalid ABN'
                }
                error={
                  values.partnership.abn > 0 &&
                  !validateABN(values.partnership.abn)
                }
                label="ABN"
                name="partnership.abn"
                InputProps={{
                  inputComponent: NumberMask,
                  onChange: handleChange('partnership.abn'),
                  maxLength: 11
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnership.abn || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography variant="subtitle2">
                  Registration Address
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                inputProps={{ maxLength: 38 }}
                label="Registered Office Line 1 (cannot be a PO Box Address)"
                name="partnership.rego_address_1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnership.rego_address_1 || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 38 }}
                label="Registered Office Line 2"
                name="partnership.rego_address_2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnership.rego_address_2 || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                required
                label="City"
                name="partnership.rego_city"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnership.rego_city || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Autocomplete
                freeSolo
                size="small"
                name="partnership.rego_state"
                value={values.partnership.rego_state || ''}
                options={states}
                onInputChange={(event, newValue) => {
                  setFieldValue(
                    'partnership.rego_state',
                    newValue ? newValue : ''
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="State/Province"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                required
                label="Postcode"
                name="partnership.rego_postcode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.partnership.rego_postcode || ''}
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                label="Country"
                name="partnership.rego_country"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.partnership.rego_country || ''}
                size="small"
                variant="outlined"
              >
                <option></option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography variant="subtitle2">Business Address</Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      values.partnership.business_addr_same_as_rego_addr ||
                      false
                    }
                    size="small"
                    onChange={handleChange}
                    name="partnership.business_addr_same_as_rego_addr"
                  />
                }
                label="Same as Registration Address"
              />
            </Grid>
            {!values.partnership.business_addr_same_as_rego_addr && (
              <>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 38 }}
                    label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                    name="partnership.business_address_1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.partnership.business_address_1 || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 38 }}
                    label="Principal Place of Business Line 2"
                    name="partnership.business_address_2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.partnership.business_address_2 || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    label="City"
                    name="partnership.business_city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.partnership.business_city || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                    freeSolo
                    size="small"
                    name="partnership.business_state"
                    value={values.partnership.business_state || ''}
                    options={states}
                    onInputChange={(event, newValue) => {
                      setFieldValue(
                        'partnership.business_state',
                        newValue ? newValue : ''
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label="State/Province"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="partnership.business_postcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.partnership.business_postcode || ''}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="partnership.business_country"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.partnership.business_country || ''}
                    size="small"
                    variant="outlined"
                  >
                    <option></option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
          </Grid>
          <FieldArray
            name="partnership.individuals"
            subscription={{}} // This is required so that the whole array does not re-render on any change.
            render={({ insert, remove, push }) => (
              <Box mt={2}>
                {values.partnership.individuals.map((individual, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        Applicant {index + 1}
                      </Typography>
                      {index > 0 && (
                        <IconButton
                          variant="contained"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <SvgIcon>
                            <DeleteIcon />
                          </SvgIcon>
                        </IconButton>
                      )}
                    </div>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            select
                            label="Title"
                            name={`partnership.individuals.${index}.title`}
                            onChange={handleChange}
                            SelectProps={{
                              native: true
                            }}
                            value={
                              values.partnership.individuals[index].title || ''
                            }
                            variant="outlined"
                            size="small"
                          >
                            <option></option>
                            {titles.map(option => (
                              <option key={option.code} value={option.code}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="First Name"
                            name={`partnership.individuals.${index}.first_name`}
                            onChange={handleChange}
                            value={
                              values.partnership.individuals[index]
                                .first_name || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Last Name"
                            name={`partnership.individuals.${index}.last_name`}
                            onChange={handleChange}
                            value={
                              values.partnership.individuals[index].last_name ||
                              ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Date of Birth"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              name={`partnership.individuals.${index}.birth_dt`}
                              value={
                                values.partnership.individuals[index]
                                  .birth_dt || null
                              }
                              onChange={newValue => {
                                setFieldValue(
                                  `partnership.individuals.${index}.birth_dt`,
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Country of Residency for Tax Purposes"
                            name={`partnership.individuals.${index}.tax_country`}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={
                              values.partnership.individuals[index]
                                .tax_country || ''
                            }
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            maxLength={9}
                            // helperText={
                            //   values.partnership.individuals[index].tax_country ==
                            //     'Australia' &&
                            //   values.partnership.individuals[index].tfn.length > 0 &&
                            //   !validateTFN(values.partnership.individuals[index].tfn) &&
                            //   'Invalid TFN'
                            // }
                            // error={
                            //   values.partnership.individuals[index].tax_country ==
                            //     'Australia' &&
                            //   values.partnership.individuals[index].tfn.length > 0 &&
                            //   !validateTFN(values.partnership.individuals[index].tfn)
                            // }
                            label="TFN or Exemption number"
                            name={`partnership.individuals.${index}.tfn`}
                            InputProps={{
                              maxLength: 9,
                              inputComponent: NumberMask,
                              onChange: handleChange(
                                `partnership.individuals.${index}.tfn`
                              )
                            }}
                            onChange={handleChange}
                            value={
                              values.partnership.individuals[index].tfn || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.partnership.individuals[index]
                                    .is_pep || false
                                }
                                onChange={handleChange}
                                name={`partnership.individuals.${index}.is_pep`}
                                size="small"
                              />
                            }
                            label="Politically Exposed Person"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.partnership.individuals[index]
                                    .is_us_tax_resident || false
                                }
                                onChange={handleChange}
                                name={`partnership.individuals.${index}.is_us_tax_resident`}
                                size="small"
                              />
                            }
                            label="US Citizen / US Tax Resident"
                          />
                        </Grid>
                        {values.partnership.individuals[index]
                          .is_us_tax_resident && (
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              label="TIN"
                              name={`partnership.individuals.${index}.tin`}
                              onChange={handleChange}
                              value={
                                values.partnership.individuals[index].tin || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        )}
                        <Grid item md={12} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Residential Address (cannot be a PO Box address)"
                            name={`partnership.individuals.${index}.address`}
                            onChange={handleChange}
                            value={
                              values.partnership.individuals[index].address ||
                              ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                ))}
                <Box mt={2}>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      push({});
                    }}
                  >
                    Add Applicant
                  </Button>
                </Box>
              </Box>
            )}
          />
          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

PartnershipForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default PartnershipForm;
