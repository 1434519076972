/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { getEmailLogs } from 'src/actions/investorActions';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

function EmailLogs({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { investorId } = useParams();
  const { emailLogs } = useSelector(state => state.investor);
  const { user } = useSelector(state => state.account);

  useEffect(() => {
    dispatch(getEmailLogs(investorId));
  }, [dispatch]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Ad Hoc Email Logs" />
      <Divider />
      <Results emailLogs={emailLogs} />
    </Card>
  );
}

EmailLogs.propTypes = {
  className: PropTypes.string
};

export default EmailLogs;
