import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from 'src/components/Page';
import { getSetting } from 'src/actions/settingActions';
import Header from './Header';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: 100
  }
}));

function SyndicateListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [syndicates, setSyndicates] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getSyndicates = useCallback(() => {
    axios.get('/api/view/syndicate').then(response => {
      if (isMountedRef.current) {
        setSyndicates(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getSyndicates();
  }, [getSyndicates]);

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  return (
    <Page className={classes.root} title="Syndicate List">
      <Box mt={1}>
        <Results syndicates={syndicates} isLoading={isLoading} />
      </Box>
    </Page>
  );
}

export default SyndicateListView;
