/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  ArticleOutlined as StatementIcon,
  PictureAsPdfOutlined as CertificateIcon
} from '@mui/icons-material';
import axios from 'axios';
import Label from 'src/components/Label';
import { currencyFormatter } from 'src/utils/mask';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import BarProgress from 'src/components/BarProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const classColor = {
  A: 'success',
  B: 'warning',
  C: 'error'
};

function Results({ className, investments, loading, ...rest }) {
  const classes = useStyles();
  const { investmentStatus } = useSelector(state => state.setting);
  const [downloading, setDownloading] = useState(false);
  const columns = [
    {
      title: 'Investment Entity',
      field: 'account_name'
    },
    {
      title: 'Syndicate',
      field: 'syndicate_name',
      render: rowData => {
        return (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/app/management/syndicates/${rowData.syndicate_id}`}
            variant="h6"
          >
            {rowData.syndicate_name}
          </Link>
        );
      }
    },
    {
      title: 'Maturity Date',
      field: 'maturity_dt',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      title: 'Coupon',
      field: 'coupon',
      render: rowData => (
        <div>
          {rowData.coupon
            ? parseFloat(rowData.topup) > 0
              ? `${parseFloat(
                (
                  parseFloat(rowData.coupon) +
                  parseFloat(rowData.rate_adjustment)
                ).toFixed(2)
              ).toString()}% + ${parseFloat(rowData.topup)}%`
              : `${parseFloat(
                (
                  parseFloat(rowData.coupon) +
                  parseFloat(rowData.rate_adjustment)
                ).toFixed(2)
              ).toString()}%`
            : 'TBD'}
          {rowData.unit_class && rowData.unit_class !== 'Ordinary' && (
            <Label
              className={classes.label}
              color={classColor[rowData.unit_class]}
            >
              {rowData.unit_class}
            </Label>
          )}
        </div>
      )
    },
    { title: 'Status', field: 'investment_status' },
    {
      title: 'Investment Amount',
      field: 'current_amt',
      render: rowData => (
        <div>
          <BarProgress
            stripe={
              rowData.investment_status_id == null ||
              rowData.investment_status_id <= 2
            }
            progress={(
              (100 * parseFloat(rowData.current_amt)) /
              parseFloat(rowData.amount)
            )
              .toFixed(2)
              .toString()}
            height={20}
            value={parseFloat(rowData.current_amt)}
          />
          <Typography variant="caption">
            {`Total: ${currencyFormatter(rowData.amount)}`}
          </Typography>
        </div>
      )
    }
  ];
  const tranColumns = [
    {
      title: 'Interest Start Date',
      field: 'transaction_dt',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    { title: 'Updated By', field: 'operator' },
    { title: 'Particulars', field: 'transaction_type', type: 'date' },
    {
      title: 'Debit',
      field: 'amount',
      render: rowData =>
        rowData.tran_sign == '-1' ? currencyFormatter(rowData.amount) : ''
    },
    {
      title: 'Credit',
      field: 'amount',
      render: rowData =>
        rowData.tran_sign == '1' ? currencyFormatter(rowData.amount) : ''
    }
  ];

  const handleDownload = docParams => {
    axios
      .get('/api/docs', {
        responseType: 'arraybuffer',
        params: docParams
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['filename']); //or any other extension
        document.body.appendChild(link);
        link.click();
        const msg =
          response.status == 201
            ? 'Document generated successfully'
            : response.status == 207
              ? 'Some document failed to generate'
              : 'Failed to generate the documents';
        toast.success('File downloaded');
        setDownloading(false);
      })
      .catch(error => {
        toast.error('Failed to download files');
        setDownloading(false);
      });
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={downloading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomMaterialTable
        title="Investments"
        isLoading={loading}
        columns={columns}
        data={investments}
        detailPanel={rowData => {
          if (rowData.transactions.length == 0) {
            return null;
          }
          return (
            <Container maxWidth="md">
              <Box m={1} sx={{ color: 'text.disabled' }}>
                <CustomMaterialTable
                  title="Transactions"
                  columns={tranColumns}
                  data={rowData.transactions}
                  options={{
                    paging: false,
                    padding: 'dense'
                  }}
                />
              </Box>
            </Container>
          );
        }}
        actions={[
          {
            tooltip: 'Download Statements',
            icon: StatementIcon,
            onClick: (evt, data) =>
              handleDownload({
                docType: 'statement',
                actionType: 'download',
                investmentIds: JSON.stringify(
                  data.map(investment => investment.id)
                )
              })
          },
          {
            tooltip: 'Download Certificates',
            icon: CertificateIcon,
            onClick: (evt, data) =>
              handleDownload({
                docType: 'certificate',
                actionType: 'download',
                investmentIds: JSON.stringify(
                  data.map(investment => investment.id)
                )
              })
          }
        ]}
        options={{
          selection: true,
          padding: 'dense',
          pageSize: 15,
          pageSizeOptions: [15, 30, 50]
        }}
      />
    </>
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
