import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      style={{ maxHeight: 55 }}
      src="/static/images/logos/WEALTH PI_LOGO_PACKAGE_Artboard_7.png"
      {...props}
    />
  );
}

export default Logo;
