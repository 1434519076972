import React, { useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  CircularProgress,
  FormHelperText,
  Radio,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  appBar: {
    position: 'relative'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}));

const actionTypes = [
  {
    value: 'download',
    title: 'Download',
    description: 'Downloading statement'
  },
  {
    value: 'email',
    title: 'Email',
    description: 'Emailing statement to the investor'
  }
];

function StatementForm({ entityId, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [FY, setFY] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState(actionTypes[0].value);
  const { user } = useSelector(state => state.account);

  const handleChange = value => {
    setActionType(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      // Do api call
      if (actionType == 'download' && !loading) {
        setLoading(true);
        axios
          .get('/api/docs', {
            responseType: 'arraybuffer',
            params: {
              entityId: entityId,
              financialYear: FY,
              actionType: 'download',
              docType: 'financial statement'
            }
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['filename']); //or any other extension
            document.body.appendChild(link);
            link.click();
            const msg =
              response.status == 201
                ? 'Document generated successfully'
                : response.status == 207
                ? 'Some document failed to generate'
                : 'Failed to generate the documents';
            enqueueSnackbar(msg, {
              variant:
                response.status == 201
                  ? 'success'
                  : response.status == 207
                  ? 'warning'
                  : 'error'
            });
            setLoading(false);
          })
          .catch(error => {
            console.log(error);
            enqueueSnackbar('Present error to admin: ' + error, {
              variant: 'error'
            });
            setLoading(false);
          });
      } else if (actionType == 'email' && !loading) {
        setLoading(true);
        axios
          .get('/api/docs', {
            params: {
              entityId: entityId,
              financialYear: selectedDate,
              actionType: 'email',
              docType: 'financial statement'
            }
          })
          .then(response => {
            enqueueSnackbar('Action completed', {
              variant: 'success'
            });
            setLoading(false);
          })
          .catch(error => {
            enqueueSnackbar(
              'Action failed, talk to sys admin directly, DO NOT RETRY',
              {
                variant: 'error'
              }
            );
            setLoading(false);
          });
      }
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      // setError(err.message);
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <Formik
        enableReinitialize
        initialValues={{
          actionType: 'download',
          fy: format(new Date(), 'yyyy')
        }}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            // Do api call
            if (values.actionType == 'download' && !loading) {
              setLoading(true);
              axios
                .get('/api/docs', {
                  responseType: 'arraybuffer',
                  params: {
                    entityId: entityId,
                    financialYear: values.fy,
                    actionType: 'download',
                    docType: 'financial statement'
                  }
                })
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', response.headers['filename']); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  const msg =
                    response.status == 201
                      ? 'Document generated successfully'
                      : response.status == 207
                      ? 'Some document failed to generate'
                      : 'Failed to generate the documents';
                  enqueueSnackbar(msg, {
                    variant:
                      response.status == 201
                        ? 'success'
                        : response.status == 207
                        ? 'warning'
                        : 'error'
                  });
                  setLoading(false);
                })
                .catch(error => {
                  setErrors({
                    submit: JSON.parse(
                      String.fromCharCode.apply(
                        null,
                        new Uint8Array(error.response.data)
                      )
                    )
                  });
                  enqueueSnackbar('Present error to admin: ' + error, {
                    variant: 'error'
                  });
                  setLoading(false);
                });
            } else if (values.actionType == 'email' && !loading) {
              setLoading(true);
              axios
                .get('/api/docs', {
                  params: {
                    entityId: entityId,
                    financialYear: values.fy,
                    actionType: 'email',
                    docType: 'financial statement'
                  }
                })
                .then(response => {
                  if (!_.isEmpty(response.data)) {
                    enqueueSnackbar('Failed to send email', {
                      variant: 'error'
                    });
                    alert(JSON.stringify(response.data));
                  } else {
                    enqueueSnackbar('Email sent', {
                      variant: 'success'
                    });
                  }
                  setLoading(false);
                })
                .catch(error => {
                  enqueueSnackbar(
                    'Action failed, talk to sys admin directly, DO NOT RETRY',
                    {
                      variant: 'error'
                    }
                  );
                  setLoading(false);
                });
            }
            setSubmitting(false);
          } catch (err) {
            // setError(err.message);
            setLoading(false);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Card>
              <CardContent>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enAU}
                >
                  <DatePicker
                    views={['year']}
                    label="Choose Financial Year"
                    value={values.fy || null}
                    onChange={newValue => {
                      setFieldValue(
                        'fy',
                        !newValue || newValue.toString() == 'Invalid Date'
                          ? newValue
                          : format(newValue, 'yyyy')
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
                <Box mt={2}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Please check statement before sending
                  </Typography>
                </Box>
                {errors.submit && (
                  <Box mb={3}>
                    <FormHelperText error>
                      {JSON.stringify(errors.submit)}
                    </FormHelperText>
                  </Box>
                )}
                <Box mt={2}>
                  {actionTypes.map((type, index) => (
                    <Paper
                      key={index}
                      display="flex"
                      alignItems="flex-start"
                      p={2}
                      mb={2}
                      component={Box}
                      elevation={type.value === values.actionType ? 10 : 1}
                    >
                      <Radio
                        disabled={
                          type.value == 'email' &&
                          !user.groups.some(r => [5].includes(r))
                        }
                        checked={type.value === values.actionType}
                        onClick={() => {
                          setFieldValue('actionType', type.value);
                        }}
                      />
                      <Box ml={2}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          color="textPrimary"
                        >
                          {type.title}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {type.description}
                        </Typography>
                      </Box>
                    </Paper>
                  ))}
                </Box>
                <div className={classes.wrapper}>
                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting}
                    type="submit"
                    disabled={loading}
                  >
                    {values.actionType == 'download' ? 'Download' : 'Email'}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

StatementForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

StatementForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default StatementForm;
