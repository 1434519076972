/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

function Investments({ isLoading, className, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { investorId } = useParams();
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEntity = useCallback(() => {
    axios
      .get('/api/investments', { params: { investorId: investorId } })
      .then(response => {
        if (isMountedRef.current) {
          setInvestments(response.data);
          setLoading(false);
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getEntity();
  }, [getEntity]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Results loading={loading} investments={investments} />
    </Card>
  );
}

Investments.propTypes = {
  className: PropTypes.string,
  investments: PropTypes.array
};

Investments.defaultProps = {
  investments: []
};

export default Investments;
