import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import SlashScreen from 'src/components/SplashScreen';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { getSyndicate } from 'src/actions/syndicateActions';
import { getSetting } from 'src/actions/settingActions';
import { getSecurities } from 'src/actions/securityActions';
import { getStaff } from 'src/actions/staffActions';
import { getDevelopers } from 'src/actions/developerActions';
import Header from './Header';
import SyndicateForm from './SyndicateForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function SyndicateCRUDView({ ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const isMountedRef = useIsMountedRef();
  const { user } = useSelector(state => state.account);
  const [isLoading, setLoading] = useState(true);
  const [pools, setPools] = useState([]);
  const [poolLoading, setPoolLoading] = useState(true);

  const { syndicate, isLoading: syndicateLoading } = useSelector(
    state => state.syndicate
  );
  const { isLoading: securityLoading } = useSelector(state => state.security);

  const getPoolInvestors = useCallback(() => {
    // TODO: hardcoded self id
    axios.get('/api/investors/18', { params: { isSelf: 1 } }).then(response => {
      if (isMountedRef.current) {
        setPools(response.data.entities);
        setPoolLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getSecurities());
    dispatch(getDevelopers());
    getPoolInvestors();
    if (user.is_admin) {
      dispatch(getStaff());
    }
    if (syndicateId) {
      dispatch(getSyndicate(syndicateId));
    }
  }, [getPoolInvestors, dispatch]);

  if ((syndicateId && syndicateLoading) || securityLoading || poolLoading) {
    return <SlashScreen />;
  }

  return (
    <Page className={classes.root} title="Syndicate Form">
      <Container maxWidth="lg">
        <Header syndicate={syndicate} />
        <SyndicateForm
          pools={pools}
          poolLoading={poolLoading}
          securityLoading={securityLoading}
        />
      </Container>
    </Page>
  );
}

export default SyndicateCRUDView;
