import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Chart from './Chart';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    textAlign: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

function InvestorSegmentation({ className, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const getStats = useCallback(() => {
    axios.get('/api/view/dashboard').then(response => {
      if (isMountedRef.current) {
        setStats(response.data[0]);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const data = loading
    ? {
        datasets: [
          {
            data: [0, 0, 0],
            backgroundColor: ['#3d72eb', '#4b9e86', '#b658f5']
          }
        ],
        labels: ['Active Investor', 'Dormant Investor', 'Prospects']
      }
    : {
        datasets: [
          {
            data: [
              stats.active_investor_cnt,
              stats.dormant_investor_cnt,
              stats.eoi_investor_cnt
            ],
            backgroundColor: ['#00c7b0', '#dddddd', '#bff1eb']
          }
        ],
        labels: ['Active Investors', 'Dormant Investors', 'Prospects']
      };

  if (loading) {
    return null;
  }

  return (
    <Card>
      {loading ? (
        <Skeleton animation="wave" variant="rect" width="100%" height={300} />
      ) : (
        <>
          <Box position="relative">
            <Chart data={data} />
          </Box>
          <Divider />
          <Box display="flex">
            {data.labels.map((label, i) => (
              <div key={label} className={classes.item}>
                <Typography variant="h4" color="textPrimary">
                  {data.datasets[0].data[i]}
                </Typography>
                <Typography variant="overline" color="textSecondary">
                  {label}
                </Typography>
              </div>
            ))}
          </Box>
        </>
      )}
    </Card>
  );
}

InvestorSegmentation.propTypes = {
  className: PropTypes.string
};

export default InvestorSegmentation;
