/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import InterestAbaForm from 'src/components/InterestAbaForm';
import { getInterestBatches } from 'src/actions/syndicateActions';
import CalcForm from './CalcForm';
// import AbaForm from './AbaForm';
import DocForm from './DocForm';
import Results from './Results';
import InterestBatches from './InterestBatches';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

function Interest({ isLoading, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const [openCalcForm, setOpenCalcForm] = useState(false);
  const [openBatchForm, setOpenBatchForm] = useState(false);
  const [openAbaForm, setOpenAbaForm] = useState(false);
  const [openDocForm, setOpenDocForm] = useState(false);
  const [docParams, setDocParams] = useState(null);
  const [abaParams, setAbaParams] = useState(null);
  const [batchId, setBatchId] = useState(null);

  useEffect(() => {
    dispatch(getInterestBatches(syndicateId));
  }, [dispatch, syndicateId]);

  const handleCalcFormOpen = () => {
    setOpenCalcForm(true);
  };

  const handleCalcFormClose = () => {
    setOpenCalcForm(false);
  };

  const handleBatchFormOpen = batchId => {
    setBatchId(batchId);
    setOpenBatchForm(true);
  };

  const handleBatchFormClose = () => {
    setOpenBatchForm(false);
    setBatchId(null);
  };

  const handleAbaFormOpen = aba => {
    setAbaParams(aba);
    setOpenAbaForm(true);
  };

  const handleAbaFormClose = () => {
    setOpenAbaForm(false);
    setAbaParams(null);
  };

  const handleDocFormOpen = value => {
    setDocParams(value);
    setOpenDocForm(true);
  };

  const handleDocFormClose = () => {
    setOpenDocForm(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Results
        onOpenBatch={handleBatchFormOpen}
        onOpenAbaForm={handleAbaFormOpen}
        onOpenDocForm={handleDocFormOpen}
        onOpenCalculator={handleCalcFormOpen}
      />
      <CalcForm
        onAdd={handleCalcFormClose}
        onClose={handleCalcFormClose}
        open={openCalcForm}
      />
      <InterestAbaForm
        abaParams={abaParams}
        onAdd={handleAbaFormClose}
        onClose={handleAbaFormClose}
        open={openAbaForm}
      />
      <InterestBatches
        batchId={batchId}
        onClose={handleBatchFormClose}
        open={openBatchForm}
        onOpenDocForm={handleDocFormOpen}
      />
      <DocForm
        docParams={docParams}
        onAdd={handleDocFormClose}
        onClose={handleDocFormClose}
        open={openDocForm}
      />
    </Card>
  );
}

Interest.propTypes = {
  className: PropTypes.string
};

export default Interest;
