import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Avatar, Box, Paper, Typography, IconButton } from '@mui/material';
import { Trash as TrashIcon } from 'src/components/icons/trash';
import getInitials from 'src/utils/getInitials';
import { updateCard } from 'src/actions/kanbanActions';

const memberSelector = (state, memberId) => {
  const { staff } = state.staff;

  return staff.byId[memberId];
};

export const KanbanComment = props => {
  const { comment, card, ...other } = props;
  const dispatch = useDispatch();
  const member = useSelector(state => memberSelector(state, comment.memberId));

  const handleDelete = debounce(async () => {
    try {
      await dispatch(
        updateCard(card.id, {
          comments: card.comments.filter(_comment => _comment.id !== comment.id)
        })
      );
      toast.success('Comment deleted!');
    } catch (err) {
      console.error(err.response.data);
      toast.error(JSON.stringify(err.response.data));
    }
  }, 1000);

  return (
    <Box
      sx={{
        display: 'flex'
        // mb: 1
      }}
      {...other}
    >
      <Avatar src={member.avatar}>{getInitials(member.full_name)}</Avatar>
      <Box
        sx={{
          ml: 2,
          flexGrow: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography variant="subtitle2">{member.full_name}</Typography>
          <IconButton onClick={handleDelete} size="small">
            <TrashIcon fontSize="small" />
          </IconButton>
        </Box>
        <Paper
          sx={{
            backgroundColor: 'background.default',
            mt: 1,
            p: 1,
            flexWrap: 'wrap'
          }}
          variant="outlined"
        >
          <div>
            <Typography variant="body2" style={{ overflowWrap: 'break-word' }}>
              {comment.message}
            </Typography>
          </div>
        </Paper>
        <Typography
          color="textSecondary"
          component="p"
          sx={{ mt: 1 }}
          variant="caption"
        >
          {comment.created_dt}
        </Typography>
      </Box>
    </Box>
  );
};

KanbanComment.propTypes = {
  // created_dt: PropTypes.number.isRequired,
  // memberId: PropTypes.number.isRequired,
  comment: PropTypes.object.isRequired
};
