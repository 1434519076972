/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import axios from 'axios';
import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormHelperText,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { properName } from 'src/utils/properName';
import { NumberMask } from 'src/utils/mask';
import { validateABN, validateTFN } from 'src/utils/validation';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  fieldset: {
    border: 0
  }
}));

function SoleTraderForm({
  className,
  entity_type_id,
  onBack,
  onNext,
  ...rest
}) {
  const classes = useStyles();
  // sole trader is designed to be a form of sole director company
  const dispatch = useDispatch();
  const { application } = useSelector(state => state.application);

  const initialValues = application.entity_id
    ? application.entity_id.entity_details
    : entitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          await dispatch(
            updateApplication(application.id, {
              ...application,
              entity_id: {
                ...application.entity_id,
                investor_id: application.investor_id.id,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident: values.soleTrader.tax_country == 'Australia',
                is_us_tax_resident: values.soleTrader.is_us_tax_resident,
                account_name:
                  values.soleTrader.business_name ||
                  properName(
                    values.soleTrader.first_name.concat(
                      ' ',
                      values.soleTrader.last_name
                    )
                  ),
                address_1: properName(values.soleTrader.business_address_1),
                address_2: properName(values.soleTrader.business_address_2),
                city: properName(values.soleTrader.business_city),
                state: values.soleTrader.business_state,
                postcode: values.soleTrader.business_postcode,
                country: values.soleTrader.business_country
              }
            })
          );
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <fieldset className={classes.fieldset}>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  label="Full Business Name (if any)"
                  name="soleTrader.business_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.business_name || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled={application.entity_id !== null}
                  helperText={
                    values.soleTrader.abn > 0 &&
                    !validateABN(values.soleTrader.abn) &&
                    'Invalid ABN'
                  }
                  error={
                    values.soleTrader.abn > 0 &&
                    !validateABN(values.soleTrader.abn)
                  }
                  label="ABN"
                  name="soleTrader.abn"
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('soleTrader.abn'),
                    maxLength: 11
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.abn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Registration Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  inputProps={{ maxLength: 38 }}
                  label="Registered Office Line 1 (cannot be a PO Box Address)"
                  name="soleTrader.rego_address_1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.rego_address_1 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 38 }}
                  label="Registered Office Line 2"
                  name="soleTrader.rego_address_2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.rego_address_2 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="City"
                  name="soleTrader.rego_city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.rego_city || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  freeSolo
                  size="small"
                  name="soleTrader.rego_state"
                  value={values.soleTrader.rego_state || ''}
                  options={states}
                  onInputChange={(event, newValue) => {
                    setFieldValue(
                      'soleTrader.rego_state',
                      newValue ? newValue : ''
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="State/Province"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Postcode"
                  name="soleTrader.rego_postcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.rego_postcode || ''}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Country"
                  name="soleTrader.rego_country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.soleTrader.rego_country || ''}
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Business Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values.soleTrader.business_addr_same_as_rego_addr ||
                        false
                      }
                      size="small"
                      onChange={handleChange}
                      name="soleTrader.business_addr_same_as_rego_addr"
                    />
                  }
                  label="Same as Registration Address"
                />
              </Grid>
              {!values.soleTrader.business_addr_same_as_rego_addr && (
                <>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 38 }}
                      label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                      name="soleTrader.business_address_1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.soleTrader.business_address_1 || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 38 }}
                      label="Principal Place of Business Line 2"
                      name="soleTrader.business_address_2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.soleTrader.business_address_2 || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="City"
                      name="soleTrader.business_city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.soleTrader.business_city || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Autocomplete
                      freeSolo
                      size="small"
                      name="soleTrader.business_state"
                      value={values.soleTrader.business_state || ''}
                      options={states}
                      onInputChange={(event, newValue) => {
                        setFieldValue(
                          'soleTrader.business_state',
                          newValue ? newValue : ''
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          label="State/Province"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="Postcode"
                      name="soleTrader.business_postcode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.soleTrader.business_postcode || ''}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="soleTrader.business_country"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.soleTrader.business_country || ''}
                      size="small"
                      variant="outlined"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 600 }}
                    color="secondary"
                    gutterBottom
                  >
                    APPLICANT
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={application.entity_id !== null}
                  select
                  label="Title"
                  name="soleTrader.title"
                  onChange={handleChange}
                  SelectProps={{
                    native: true
                  }}
                  value={values.soleTrader.title || ''}
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {titles.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled={application.entity_id !== null}
                  label="First Name"
                  name="soleTrader.first_name"
                  onChange={handleChange}
                  value={values.soleTrader.first_name || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={application.entity_id !== null}
                  label="Last Name"
                  name="soleTrader.last_name"
                  onChange={handleChange}
                  value={values.soleTrader.last_name || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enAU}
                >
                  <DatePicker
                    label="Date of Birth"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    name="soleTrader.birth_dt"
                    value={values.soleTrader.birth_dt || null}
                    onChange={newValue => {
                      setFieldValue(
                        'soleTrader.birth_dt',
                        !newValue || newValue.toString() == 'Invalid Date'
                          ? newValue
                          : format(newValue, 'yyyy-MM-dd')
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        disabled={application.entity_id !== null}
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  required
                  fullWidth
                  disabled={application.entity_id !== null}
                  label="Country of Residency for Tax Purposes"
                  name="soleTrader.tax_country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.soleTrader.tax_country || ''}
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required={values.soleTrader.tax_country == 'Australia'}
                  disabled={application.entity_id !== null}
                  helperText={
                    values.soleTrader.tfn > 0 &&
                    !validateTFN(values.soleTrader.tfn) &&
                    'Invalid TFN'
                  }
                  error={
                    values.soleTrader.tfn > 0 &&
                    !validateTFN(values.soleTrader.tfn)
                  }
                  label="TFN or Exemption number"
                  name="soleTrader.tfn"
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('soleTrader.tfn'),
                    maxLength: 11
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.tfn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={application.entity_id !== null}
                      checked={values.soleTrader.is_pep || false}
                      onChange={handleChange}
                      size="small"
                      name="soleTrader.is_pep"
                    />
                  }
                  label="Politically Exposed Person"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={application.entity_id !== null}
                      checked={values.soleTrader.is_us_tax_resident || false}
                      onChange={handleChange}
                      size="small"
                      name="soleTrader.is_us_tax_resident"
                    />
                  }
                  label="US Citizen / US Tax Resident"
                />
              </Grid>
              {values.soleTrader.is_us_tax_resident && (
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    disabled={application.entity_id !== null}
                    label="TIN"
                    name="soleTrader.tin"
                    onChange={handleChange}
                    value={values.soleTrader.tin || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Residential Address (cannot be a PO Box address)"
                  name="soleTrader.address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.soleTrader.address || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button variant="contained" onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

SoleTraderForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default SoleTraderForm;
