
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.345,"time":80700,"words":269}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <p>{`Before proceeding you’ll need to have the laste stabe `}<a href="https://nodejs.org/en/" target="_blank">{`NodeJS`}</a>{` and `}<a href="https://www.npmjs.com" target="_blank">{`npm`}</a>{` installed on your machine.`}</p>
    <p>{`You can use `}<a href="https://github.com/creationix/nvm#installation" target="_blank">{`nvm`}</a>{` (macOS/Linux) or `}<a href="https://github.com/coreybutler/nvm-windows#node-version-manager-nvm-for-windows" target="_blank">{`nvm-windows`}</a>{` to switch Node versions between different projects.`}</p>
    <h2 {...{
      "id": "install-dependencies"
    }}>{`Install dependencies`}</h2>
    <p>{`Open the project folder and install it’s dependencies. You can use any package manager you want. We recommend `}<a href="https://yarnpkg.com" target="_blank">{`Yarn`}</a>{` or `}<a href="https://www.npmjs.com" target="_blank">{`npm`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh",
        "data-language": "sh",
        "data-highlighted-line-numbers": "",
        "dangerouslySetInnerHTML": {
          "__html": "cd project-folder\nnpm install\n"
        }
      }}></code></pre>
    <h2 {...{
      "id": "start-development-server"
    }}>{`Start development server`}</h2>
    <p>{`Once the installation is done, you can now run your app by running `}<inlineCode parentName="p">{`npm start`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh",
        "data-language": "sh",
        "data-highlighted-line-numbers": "",
        "dangerouslySetInnerHTML": {
          "__html": "npm start\n"
        }
      }}></code></pre>
    <p>{`You will see something similar to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh",
        "data-language": "sh",
        "data-highlighted-line-numbers": "",
        "dangerouslySetInnerHTML": {
          "__html": "Compiled successfully!\n\nYou can now view @devias-io/material-kit-pro-react in the browser.\n\n  Local:            http://localhost:3000\n  On Your Network:  http://192.168.1.5:3000\n\nNote that the development build is not optimized.\nTo create a production build, use npm run build.\n"
        }
      }}></code></pre>
    <p>{`This runs the app in development mode. Open `}<a href="http://localhost:3000" target="_blank">{`localhost:3000`}</a>{` to view it in the browser.`}</p>
    <p>{`The page will automatically reload if you make changes to the code. You will see the build errors and lint warnings in the console. Since we use `}<inlineCode parentName="p">{`ESlint`}</inlineCode>{` you will get detailed warnings.`}</p>
    <h2 {...{
      "id": "build-project-files"
    }}>{`Build project files`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh",
        "data-language": "sh",
        "data-highlighted-line-numbers": "",
        "dangerouslySetInnerHTML": {
          "__html": "npm run build\n"
        }
      }}></code></pre>
    <p>{`or `}<inlineCode parentName="p">{`yarn build`}</inlineCode></p>
    <p>{`Builds the app for production to the build folder. It correctly bundles React in production mode and optimizes the build for the best performance.`}</p>
    <p>{`The build is minified and the filenames include the hashes.`}</p>
    <p>{`Your app is ready to be deployed.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This project doesn’t handle backend logic or databases; it is just a frontend, so you can use it with any backend you want. `}</p>
    </blockquote>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "install-dependencies",
    level: 2,
    title: "Install dependencies",
    children: [
        
      ]
  },
{
    id: "start-development-server",
    level: 2,
    title: "Start development server",
    children: [
        
      ]
  },
{
    id: "build-project-files",
    level: 2,
    title: "Build project files",
    children: [
        
      ]
  }
]

export const frontMatter = {}

