import React from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { bulkRedeem } from 'src/actions/syndicateActions';
import Label from 'src/components/Label';
import { CurrencyMask, currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function BulkRedemptionForm({
  bulkRedemption,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = bulkRedemption;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   transaction_dt: Yup.date().required(),
            //   amount: Yup.number()
            //     .max(
            //       bulkRedemption ? bulkRedemption.total_balance : 0,
            //       'Cannot exceed available balance'
            //     )
            //     .required()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(bulkRedeem(values));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Units redeemed', {
                  variant: 'success'
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="textPrimary">
                      This form is for pro rata redemption amongst selected
                      investors
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <Label className={classes.label} color="primary">
                        Current Balance:
                        {currencyFormatter(
                          parseFloat(values.total_balance).toFixed(2)
                        )}
                      </Label>
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Total Amount Redeemed"
                            name="amount"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('amount')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Redemption Date"
                              value={values.transaction_dt}
                              onChange={newValue => {
                                setFieldValue(
                                  'transaction_dt',
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                  value={values.transaction_dt}
                                  name="transaction_dt"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Redeem Units
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

BulkRedemptionForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

BulkRedemptionForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default BulkRedemptionForm;
