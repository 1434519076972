import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import {
  AppBar,
  Box,
  Chip,
  Button,
  Collapse,
  Dialog,
  IconButton,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Typography,
  Toolbar,
  makeStyles,
  Link,
  FormHelperText,
  Card,
  CardContent,
  Grid,
  Slide,
  Switch
} from '@material-ui/core';
import {
  X as XIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Send as EmailIcon
} from 'react-feather';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Label from 'src/components/Label';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  appBar: {
    position: 'relative'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

const statusColors = {
  false: 'error',
  true: 'success'
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getEmailStatus(status) {
  // TODO update investment labels
  const map = {
    queued: 'gold',
    sent: 'green',
    opened: 'green',
    clicked: 'green',
    delivered: 'green'
  };

  const color = map[status] || 'red';

  if (!status) {
    return <Chip size="small" label="TBS" />;
  }
  return (
    <Chip
      size="small"
      label={status}
      style={{ backgroundColor: `${color}`, color: 'white' }}
    />
  );
}

function Row(props) {
  const { row, onOpenDocForm } = props;
  const { syndicateId } = useParams();
  const { syndicate } = useSelector(state => state.syndicate);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={row.batch_entity_id}>
      <TableRow hover key={row.batch_entity_id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {row.investor_name}
            <Typography variant="body2" color="textSecondary">
              <Label className={classes.label} color="primary">
                {row.account_name}
              </Label>
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{getEmailStatus(row.statement_email_status)}</TableCell>
        <TableCell>
          {row.is_aus_bank
            ? `${row.bsb} || ${row.bank_acct_no}`
            : `${row.swift_code} || ${row.foreign_bank_acct_no}`}
        </TableCell>
        <TableCell>
          <Label color={statusColors[row.is_tax_resident]}>
            {row.is_tax_resident ? 'Yes' : 'No'}
          </Label>
        </TableCell>
        <TableCell>{row.interest_type}</TableCell>
        <TableCell align="right">
          {currencyFormatter(parseFloat(row.interest_payable))}
        </TableCell>
        <TableCell align="right">
          {currencyFormatter(parseFloat(row.withholding_tax))}
        </TableCell>
        <TableCell align="right">
          {currencyFormatter(parseFloat(row.interest_paid))}
        </TableCell>
        <TableCell align="right">
          <Box
            display="flex"
            color="text.secondary"
            alignItems="center"
            justifyContent="flex-start"
          >
            <div className={classes.badge}>
              <IconButton
                onClick={() => {
                  onOpenDocForm({
                    docType: 'statement',
                    batchEntityId: row.id,
                    syndicateId: syndicateId,
                    batch_id: row.batch_id // used to refresh batch status
                  });
                }}
              >
                <EmailIcon fontSize="small" />
              </IconButton>
            </div>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={3} display="block">
              <Typography variant="h6" gutterBottom component="div">
                Interest Auditing
              </Typography>
              <Table size="small" aria-label="transactions">
                <TableHead>
                  <TableRow hover>
                    <TableCell>Entity</TableCell>
                    <TableCell align="right">Investment Balance</TableCell>
                    <TableCell align="right">Interest Start Date</TableCell>
                    <TableCell align="right">Interest End Date</TableCell>
                    <TableCell align="right"># of Days</TableCell>
                    <TableCell align="right">Interest</TableCell>
                    <TableCell align="right">Interest Payable</TableCell>
                    <TableCell align="right">Withholding Tax</TableCell>
                    <TableCell align="right">Interest Paid</TableCell>
                    {syndicate && syndicate.is_contributory && (
                      <TableCell>SPV Trust</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.audits &&
                    row.audits.map(audit => (
                      <TableRow hover key={audit.id}>
                        <TableCell>{audit.account_name}</TableCell>
                        <TableCell align="right">
                          {currencyFormatter(audit.balance)}
                        </TableCell>
                        <TableCell align="right">{audit.start_dt}</TableCell>
                        <TableCell align="right">{audit.end_dt}</TableCell>
                        <TableCell align="right">{audit.days}</TableCell>
                        <TableCell align="right">{audit.interest}%</TableCell>
                        <TableCell align="right">
                          {currencyFormatter(audit.interest_payable)}
                        </TableCell>
                        <TableCell align="right">
                          {currencyFormatter(audit.withholding_tax)}
                        </TableCell>
                        <TableCell align="right">
                          {currencyFormatter(audit.interest_paid)}
                        </TableCell>
                        {syndicate && syndicate.is_contributory && (
                          <TableCell>{audit.subtrust_spv_name}</TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function InterestBatches({
  batchId,
  open,
  onClose,
  onOpenDocForm,
  className,
  ...rest
}) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const batch = useSelector(
    state =>
      state.syndicate.interestBatches.interestBatches.filter(
        batch => batch.id == batchId
      )[0]
  );

  if (!batchId) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
          >
            <XIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container>
        <Grid item>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography variant="h3" color="textPrimary">
              {`${batch.period_start_dt} to ${batch.period_end_dt}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {batch.entity_batches && (
        <Box p={3}>
          <Card>
            <PerfectScrollbar>
              <Box minWidth={900}>
                <Table>
                  <TableHead>
                    <TableRow hover>
                      <TableCell />
                      <TableCell>Investor</TableCell>
                      <TableCell>Statement</TableCell>
                      <TableCell>Bank Details</TableCell>
                      <TableCell>Australian Tax Resident</TableCell>
                      <TableCell>Interest Type</TableCell>
                      <TableCell align="right">Interest Payable</TableCell>
                      <TableCell align="right">Withholding Tax</TableCell>
                      <TableCell align="right">Interest Paid</TableCell>
                      <TableCell align="right">Email Statement</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {batch.entity_batches.map(entity_batch => {
                      return (
                        <Row
                          key={entity_batch.batch_entity_id}
                          row={entity_batch}
                          onOpenDocForm={onOpenDocForm}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      )}
    </Dialog>
  );
}

InterestBatches.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

InterestBatches.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default InterestBatches;
