import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { addStaff } from 'src/actions/staffActions';

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(3) }
}));

function RegisterForm({ className, onClose, open, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string()
              .max(255)
              .required('First name is required'),
            last_name: Yup.string()
              .max(255)
              .required('Last name is required'),
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            password: Yup.string()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
              )
              .max(14)
              .required('Password is required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await dispatch(addStaff(values));
              onClose();
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.response.data });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              {errors.submit && (
                <Box mb={3}>
                  <FormHelperText error>
                    {JSON.stringify(errors.submit)}
                  </FormHelperText>
                </Box>
              )}
              <TextField
                required
                error={Boolean(touched.first_name && errors.first_name)}
                fullWidth
                helperText={touched.first_name && errors.first_name}
                label="First Name"
                margin="normal"
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="first_name"
                value={values.first_name}
                variant="outlined"
              />
              <TextField
                required
                error={Boolean(touched.last_name && errors.last_name)}
                fullWidth
                helperText={touched.last_name && errors.last_name}
                label="Last Name"
                margin="normal"
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="last_name"
                value={values.last_name}
                variant="outlined"
              />
              <TextField
                required
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Company Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                required
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={values.password}
                variant="outlined"
              />
              <Box mt={2}>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}

RegisterForm.propTypes = {
  className: PropTypes.string
};

RegisterForm.default = {
  onSubmitSuccess: () => {}
};

export default RegisterForm;
