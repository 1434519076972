import React, {
  useState,
  useRef,
  forwardRef,
  useCallback,
  useEffect
} from 'react';
import { render } from 'react-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import toast from 'react-hot-toast';
import EmailEditor from 'react-email-editor';
import {
  Backdrop,
  Dialog,
  AppBar,
  Grid,
  TextField,
  Slide,
  makeStyles
} from '@material-ui/core';
import { Button, Box, Toolbar } from '@mui/material';
import { Formik } from 'formik';
import { X as XIcon } from 'react-feather';
import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete } from '@material-ui/lab';
import {
  addCampaign,
  closeCampaign,
  updateCampaign
} from 'src/actions/campaignActions';
import Label from 'src/components/Label';
import { language } from 'src/utils/options';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    width: 600,
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500
  },
  fullScreen: {
    height: '100%',
    width: '100%'
  },
  input: {
    width: '100%'
  },
  editor: {
    height: '100%',
    display: 'flex',
    position: 'relative'
  },
  action: {
    marginRight: theme.spacing(1)
  }
}));

function getStageLabel(syndicate_stage_id) {
  const map = {
    1: {
      text: 'Due Diligence',
      color: 'error'
    },
    2: {
      text: 'Under Management',
      color: 'warning'
    },
    3: {
      text: 'Completed',
      color: 'success'
    },
    4: {
      text: 'Cancelled',
      color: 'greyout'
    }
  };

  const { text, color } = map[syndicate_stage_id];

  return <Label color={color}>{text}</Label>;
}

function Campaign() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { open, campaign } = useSelector(state => state.campaign.openCampaign);
  const { campaignLabel, isLoading } = useSelector(state => state.setting);
  const { syndicates } = useSelector(state => state.campaign);
  const syndicateOptions = syndicates.allIds.map(id => {
    return {
      id: id,
      syndicate_name: syndicates.byId[id]['syndicate_name'],
      syndicate_stage_id: syndicates.byId[id].syndicate_stage_id
    };
  });

  const handleClose = () => {
    dispatch(closeCampaign());
  };

  const emailEditorRef = useRef(null);

  const onLoad = () => {
    window.unlayer.addEventListener('design:updated', data => {
      console.log('event registered');
    });
  };

  const onReady = () => {
    if (campaign && emailEditorRef.current) {
      emailEditorRef.current.loadDesign(JSON.parse(campaign.design));
      console.log('Campaign loaded on ready');
    }
  };

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  if (!open || isLoading) {
    return null;
  }

  const initialValues = campaign || {
    title: null,
    label_id: null
  };

  console.log('heyyeyeye', window.location.protocol, window.location.host);
  console.log(
    'thisssss::subscribe::',
    window.location.protocol + '//' + window.location.host + '/subscribeTool.js'
  );
  console.log(
    'thisssss::social::',
    window.location.protocol + '//' + window.location.host + '/socialTool.js'
  );

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            //TODO: to catch error properly
            // await dispatch(addInterestBatch(values));
            emailEditorRef.current.editor.exportHtml(async data => {
              const { design, html } = data;
              if (campaign) {
                await dispatch(
                  updateCampaign(campaign.id, {
                    ...values,
                    design: JSON.stringify(design),
                    html: html
                  })
                );
              } else {
                await dispatch(
                  addCampaign({
                    ...values,
                    design: JSON.stringify(design),
                    html: html
                  })
                );
              }
              handleClose();
              setStatus({ success: true });
              setSubmitting(true);
              toast.success(campaign ? 'Campaign updated' : 'Campaign created');
            });
          } catch (error) {
            toast.error(
              campaign
                ? 'Failed to update campaign'
                : 'Failed to create campaign'
            );
            setStatus({ success: false });
            setErrors({ submit: error.response.data });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {errors.submit && (
              <Box mb={3}>
                <FormHelperText error>{errors.submit.err_msg}</FormHelperText>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <AppBar className={classes.appBar} position="static">
                <Toolbar
                  variant="dense"
                  sx={{ justifyContent: 'space-between', width: '100%' }}
                >
                  <Button color="inherit" disabled={isSubmitting} type="submit">
                    Save
                  </Button>
                  <Button color="inherit" onClick={handleClose}>
                    Discard Changes
                  </Button>
                </Toolbar>
              </AppBar>
            </Box>
            <Box my={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Email Subject"
                    name="subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subject || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    select
                    label="Investor Language Preference"
                    name="language"
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                    value={values.language || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {language.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    label="Scope Label"
                    name="label_id"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: !!values.label_id
                    }}
                    helperText={
                      values.label_id &&
                      `Scope: ${campaignLabel.byId[values.label_id].scope}`
                    }
                    select
                    SelectProps={{ native: true }}
                    value={values.label_id || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {campaignLabel.allIds.map(id => (
                      <option key={id} value={id}>
                        {campaignLabel.byId[id].name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {values.label_id && parseInt(values.label_id) !== 2 && (
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      name="syndicate_id"
                      value={
                        values.syndicate_id
                          ? syndicateOptions.filter(
                              option => option.id == values.syndicate_id
                            )[0]
                          : null
                      }
                      options={syndicateOptions}
                      getOptionLabel={option => option.syndicate_name}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          'syndicate_id',
                          newValue ? newValue.id : null
                        );
                      }}
                      renderOption={option => {
                        return (
                          <Box component="li">
                            {option.syndicate_name}{' '}
                            {getStageLabel(option.syndicate_stage_id)}
                          </Box>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required={values.label_id == 1}
                          fullWidth
                          size="small"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          label="Syndicate"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      <Box className={classes.editor}>
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      </Box>
    </Dialog>
  );
}

export default Campaign;
