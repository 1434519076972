import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Grid, makeStyles } from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import FundAllocation from './FundAllocation';
import PoolOverview from './PoolOverview';
import OpenSyndicates from './OpenSyndicates';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

function PoolManagement() {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [pool, setPool] = useState([]);
  const [loading, setLoading] = useState(true);
  const getPool = useCallback(() => {
    axios.get('/api/view/pool').then(response => {
      if (isMountedRef.current) {
        setPool(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getPool();
  }, [getPool]);

  return (
    <Page className={classes.root} title="Pool Management">
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item lg={12} xl={12} md={12} xs={12}>
            <PoolOverview loading={loading} pool={pool} />
          </Grid>
          <Grid item lg={12} xl={12} xs={12}>
            <OpenSyndicates />
          </Grid>
          <Grid item lg={12} xl={12} xs={12}>
            <FundAllocation loading={loading} pool={pool} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default PoolManagement;
