import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SplashScreen from 'src/components/SplashScreen';
import InvestmentApplication from 'src/views/management/InvestmentApplicationView';
import { setUserData } from 'src/actions/accountActions';
import { secretLogin } from 'src/actions/accountActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function ApplicationSecretLogin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { investmentId, secret } = useParams();
  // const [isLoading, setLoading] = useState(true);
  const { user, loading, error } = useSelector(state => state.account);

  useEffect(() => {
    dispatch(secretLogin(investmentId, secret));
  }, [dispatch]);

  if (loading) {
    return <SplashScreen />;
  }
  if (error) {
    return (
      <Page className={classes.root} title="Investment Application Form">
        <Container maxWidth="md">
          <Card sx={{ maxWidth: 500, minHeight: 500 }}>
            <CardContent>
              <Box p={3}>
                <Box display="flex" alignItems="center" mt={2}>
                  <Avatar
                    alt="Wealth Pi"
                    sx={{ width: 56, height: 56 }}
                    src="/static/images/avatars/avatar.png"
                  />
                  <Box ml={2}>
                    <Typography variant="h5" gutterBottom>
                      This is a wrong application link. Please contact
                      info@wealthpifund.com for the right application link
                      assigned to your investment.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Page>
    );
  }
  if (user) {
    return <Redirect to={`/applications/${investmentId}/${secret}/details`} />;
  }
}

ApplicationSecretLogin.propTypes = {
  children: PropTypes.any
};

export default ApplicationSecretLogin;
