/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Card, Tooltip, IconButton, makeStyles } from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  NoteAltOutlined as EditIcon
} from '@mui/icons-material';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stateField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'createdAt|desc',
    label: 'Creation date (newest first)'
  },
  {
    value: 'createdAt|asc',
    label: 'Creation date (oldest first)'
  }
];

function applyFilters(securities, query, filters) {
  return securities.filter(security => {
    let matches = true;

    if (
      query &&
      !security.security.toLowerCase().includes(query.toLowerCase())
    ) {
      matches = false;
    }

    if (filters.state && security.state_id !== parseInt(filters.state)) {
      matches = false;
    }

    return matches;
  });
}

function applyPagination(securities, page, limit) {
  return securities.slice(page * limit, page * limit + limit);
}

function Results({ securities, onEdit, isLoading, className, ...rest }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const { state } = useSelector(state => state.setting);
  const [selectedSecuritys, setSelectedSecuritys] = useState([]);
  const [filters, setFilters] = useState({
    state: null
  });

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleStateChange = event => {
    event.persist();

    let value = null;

    if (event.target.value !== 'All') {
      value = event.target.value;
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      state: value
    }));
  };

  const handleSortChange = event => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllSecuritys = event => {
    setSelectedSecuritys(
      event.target.checked ? securities.map(security => security.id) : []
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const filteredSecuritys = applyFilters(securities, query, filters);
  const paginatedSecuritys = applyPagination(filteredSecuritys, page, limit);
  const selectedSomeSecuritys =
    selectedSecuritys.length > 0 &&
    selectedSecuritys.length < securities.length;
  const selectedAllSecuritys = selectedSecuritys.length === securities.length;
  const columns = [
    {
      field: 'address',
      title: 'Security',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Tooltip title="Edit">
                <IconButton size="small" onClick={() => onEdit(rowData)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {rowData.address}
          </Box>
        );
      }
    },
    {
      field: 'state',
      title: 'State'
    },
    {
      field: 'valuation_dt',
      title: 'Valuation Date',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },

    {
      field: 'value',
      title: 'Valuation',
      type: 'currency'
    }
  ];
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        title=""
        isLoading={isLoading}
        columns={columns}
        data={securities}
        actions={[
          {
            icon: AddIcon,
            tooltip: 'Add Security',
            isFreeAction: true,
            onClick: (event, rowData) => onEdit()
          }
        ]}
        options={{
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [10, 20, 50]
        }}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  securities: PropTypes.array
};

Results.defaultProps = {
  securities: []
};

export default Results;
