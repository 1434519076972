import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
// import { Button } from '@mui/material';

export const KanbanCardAction = props => {
  const { icon, children, ...other } = props;

  return (
    <Box mt={2} justifyContent="felx-start">
      <Button
        fullWidth
        color="primary"
        startIcon={icon}
        style={{ justifyContent: 'flex-start' }}
        variant="contained"
        {...other}
      >
        {children}
      </Button>
    </Box>
  );
};

KanbanCardAction.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.node
};
