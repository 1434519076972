import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function DeveloperForm({
  developer,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const { staff } = useSelector(state => state.staff);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = developer
    ? developer
    : {
        staff_id: user.id,
        developer_name: null,
        email: null,
        mobile: null
      };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                if (developer) {
                  axios
                    .patch(`/api/developers/${developer.id}`, values)
                    .then(response => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      onAdd(response.data);
                      enqueueSnackbar('Fund raiser updated', {
                        variant: 'success'
                      });
                    });
                } else {
                  axios.post('/api/developers', values).then(response => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    onAdd(response.data);
                    enqueueSnackbar('Fund raiser added', {
                      variant: 'success'
                    });
                  });
                }
              } catch (error) {
                console.log(error);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          required
                          fullWidth
                          label="Name"
                          name="developer_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.developer_name || ''}
                          variant="outlined"
                        />
                      </Grid>
                      {user.is_admin && (
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Contact Manager"
                            name="staff_id"
                            InputLabelProps={{
                              shrink: !!values.staff_id
                            }}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.staff_id || ''}
                            variant="outlined"
                          >
                            <option></option>
                            {staff.allIds
                              .filter(id => staff.byId[id].is_employee)
                              .map(id => (
                                <option key={id} value={id}>
                                  {staff.byId[id].full_name}
                                </option>
                              ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Contact First Name"
                          name="contact_first_name"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact_first_name || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Contact Last Name"
                          name="contact_last_name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact_last_name || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          type="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Mobile"
                          name="mobile"
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.mobile || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {developer ? 'Update Developer' : 'Add Developer'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

DeveloperForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

DeveloperForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default DeveloperForm;
