/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Box, Card, makeStyles } from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  DeleteOutlineOutlined as DeleteIcon,
  NoteAltOutlined as EditIcon
} from '@mui/icons-material';
import Label from 'src/components/Label';
import { currencyFormatter } from 'src/utils/mask';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { LightTooltip } from 'src/components/LightTooltip';
import { deleteManagementFee } from 'src/actions/syndicateActions';

const useStyles = makeStyles(theme => ({
  root: {},
  menu: {
    width: 256,
    maxWidth: '100%'
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  alert: {
    width: '50%'
  }
}));

function Results({ className, onEdit, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { managementfees } = useSelector(state => state.syndicate);

  const columns = [
    {
      field: 'updated_dt',
      title: 'Date',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'amount',
      title: 'Amount',
      type: 'currency'
    }
  ];

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CustomMaterialTable
          title={'Management Fees'}
          isLoading={managementfees.isLoading}
          data={managementfees.managementfees}
          columns={columns}
          actions={[
            {
              icon: AddIcon,
              tooltip: 'Add Management Fee',
              isFreeAction: true,
              onClick: event => onEdit()
            },
            {
              icon: EditIcon,
              tooltip: 'Update Management Fee',
              onClick: (event, rowData) => onEdit(rowData)
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete Management Fee',
              onClick: async (event, rowData) => {
                try {
                  await dispatch(deleteManagementFee(rowData));
                  toast.success('Management fee deleted');
                } catch {
                  toast.error('Management fee deletion failed');
                }
              }
            }
          ]}
          options={{
            paging: false,
            padding: 'dense'
          }}
        />
      </Card>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
