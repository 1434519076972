import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  FormHelperText,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { BSBMask, NumberMask } from 'src/utils/mask';
import { countries, states } from 'src/utils/options';
import { getEntity, updateEntity } from 'src/actions/entityActions';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    '& .ql-editor': {
      height: 400
    }
  }
}));

function BankDetail({ className, onBack, onNext, investor, ...rest }) {
  const classes = useStyles();
  const { investorId, entityId } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { entity } = useSelector(state => state.entity);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues(
      entity || {
        is_aus_bank: true,
        bsb: null,
        bank_acct_name: null,
        bank_acct_no: null
      }
    );
  }, [entity]);

  if (!entity) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.

          dispatch(updateEntity(entity.id, values));
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Typography variant="h5" color="textPrimary">
            Bank Account Details
          </Typography>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_aus_bank || false}
                      onChange={handleChange}
                      value={values.is_aus_bank || true}
                      name="is_aus_bank"
                    />
                  }
                  label="Australian Bank"
                />
              </Grid>
            </Grid>
          </Box>
          {values.is_aus_bank && (
            <>
              <Box mt={2}>
                <Typography variant="h5" color="textPrimary">
                  Nominated Australian Bank Account Details
                </Typography>
              </Box>
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required={values.is_aus_bank}
                      fullWidth
                      label="Bank Account Name"
                      name="bank_acct_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bank_acct_name || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      required={values.is_aus_bank}
                      error={Boolean(errors && errors.bsb)}
                      helperText={errors && errors.bsb}
                      label="BSB"
                      name="bsb"
                      InputProps={{
                        inputComponent: BSBMask,
                        onChange: handleChange('bsb')
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bsb || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      required={values.is_aus_bank}
                      label="Bank Account Number"
                      name="bank_acct_no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: NumberMask,
                        onChange: handleChange('bank_acct_no')
                      }}
                      value={values.bank_acct_no || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
          {!values.is_aus_bank && (
            <>
              <Box mt={2}>
                <Typography variant="h5" color="textPrimary">
                  Nominated Oversea Bank Account Details
                </Typography>
                <Box mt={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        required={!values.is_aus_bank}
                        label="Bank Name"
                        name="foreign_bank_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.foreign_bank_name || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        required={!values.is_aus_bank}
                        label="Swift Code"
                        name="swift_code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.swift_code || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        required={!values.is_aus_bank}
                        label="Bank Account Number"
                        name="foreign_bank_acct_no"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: NumberMask,
                          onChange: handleChange('foreign_bank_acct_no')
                        }}
                        value={values.foreign_bank_acct_no || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        required={!values.is_aus_bank}
                        label="Bank Address"
                        name="foreign_bank_address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.foreign_bank_address || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

BankDetail.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func,
  onBack: PropTypes.func
};

export default BankDetail;
