
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.57,"time":34200,"words":114}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "environment-variables"
    }}>{`Environment Variables`}</h1>
    <p>{`You must create custom environment variables beginning with `}<inlineCode parentName="p">{`REACT_APP_`}</inlineCode>{`. Any other variables except `}<inlineCode parentName="p">{`NODE_ENV`}</inlineCode>{` will be ignored to avoid accidentally exposing a private key on the machine that could have the same name. Changing any environment variables will require you to restart the development server if it is running.`}</p>
    <blockquote>
      <p parentName="blockquote">{`WARNING: Do not store any secrets (such as private API keys) in your React app!
Environment variables are embedded into the build, meaning anyone can view them by inspecting your app’s files.`}</p>
    </blockquote>
    <p>{`Having access to the `}<inlineCode parentName="p">{`NODE_ENV`}</inlineCode>{` is also useful for performing actions conditionally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "data-language": "jsx",
        "data-highlighted-line-numbers": "",
        "dangerouslySetInnerHTML": {
          "__html": "<span class=\"token keyword\">if</span> <span class=\"token punctuation\">(</span>process<span class=\"token punctuation\">.</span>env<span class=\"token punctuation\">.</span><span class=\"token constant\">NODE_ENV</span> <span class=\"token operator\">!==</span> <span class=\"token string\">'production'</span><span class=\"token punctuation\">)</span> <span class=\"token punctuation\">{</span>\n  analytics<span class=\"token punctuation\">.</span><span class=\"token function\">disable</span><span class=\"token punctuation\">(</span><span class=\"token punctuation\">)</span><span class=\"token punctuation\">;</span>\n<span class=\"token punctuation\">}</span>\n"
        }
      }}></code></pre>
    <p>{`Read more about environment variables `}<a href="https://create-react.dev/docs/adding-custom-environment-variables/">{`here`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

