import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';

function Unsubscribe() {
  const dispatch = useDispatch();
  const [unsub, setUnsub] = useState(false);
  const { email } = useParams();

  useEffect(() => {
    axios
      .post('/api/unsubscribe', { email: email })
      .then(response => {
        console.log(unsub);
        setUnsub(true);
      })
      .catch(error => {
        console.log('unsub failed');
      });
  }, [email]);

  if (!unsub) {
    return null;
  }

  return (
    <Page title="Unsubscribe">
      <Container maxWidth="md">
        <Box m={5}>
          <Card sx={{ maxWidth: 500 }}>
            <CardMedia>
              <Box ml={2} mt={2}>
                <img
                  style={{ maxHeight: 55 }}
                  src="/static/images/logos/WEALTH PI_LOGO_PACKAGE_Artboard_1.png"
                  alt="Wealth Pi Fund Pty Ltd"
                />
              </Box>
            </CardMedia>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  Your email {email} has been removed from our communication
                  list for industry updates and new investment opportunities.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}

export default Unsubscribe;
