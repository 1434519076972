import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Typography,
  makeStyles,
  FormHelperText,
  Grid,
  TextField,
  Switch
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { updateCommission } from 'src/actions/syndicateActions';
import { currencyFormatter, CurrencyMask } from 'src/utils/mask';
import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function CommissionForm({
  commission,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = commission;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   commission_amt: Yup.number().required()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                await dispatch(updateCommission(values.id, values));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Commission updated', {
                  variant: 'success'
                });
              } catch (error) {
                // TODO catch proper error msg
                setStatus({ success: false });
                setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={handleSubmit}
                className={clsx(classes.root, className)}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary">
                        Commission
                      </Typography>
                      {values.fundraiser}
                      <Label className={classes.label} color="success">
                        {`${parseFloat(values.commission).toString()}% ${
                          values.commission_type
                        } ${values.investor_name} ${currencyFormatter(
                          values.investment_amt
                        )}`}
                      </Label>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Amount"
                            name="commission_amt"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('commission_amt')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.commission_amt || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Typography component="div">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography variant="h5" color="textPrimary">
                                  Unpaid
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Switch
                                  checked={values.is_paid}
                                  color="secondary"
                                  edge="start"
                                  name="is_paid"
                                  onChange={handleChange}
                                  value={values.is_paid}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="h5" color="textPrimary">
                                  Paid
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Grid>
                        {values.commission_type == 'p.a.' && (
                          <>
                            <Grid item md={6} xs={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enAU}
                              >
                                <DatePicker
                                  label="Start Date"
                                  value={values.period_start_dt}
                                  onChange={newValue => {
                                    setFieldValue(
                                      'period_start_dt',
                                      !newValue ||
                                        newValue.toString() == 'Invalid Date'
                                        ? newValue
                                        : format(newValue, 'yyyy-MM-dd')
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      variant="outlined"
                                      value={values.period_start_dt}
                                      name="period_start_dt"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enAU}
                              >
                                <DatePicker
                                  label="Start Date"
                                  value={values.period_end_dt}
                                  onChange={newValue => {
                                    setFieldValue(
                                      'period_end_dt',
                                      !newValue ||
                                        newValue.toString() == 'Invalid Date'
                                        ? newValue
                                        : format(newValue, 'yyyy-MM-dd')
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      variant="outlined"
                                      value={values.period_end_dt}
                                      name="period_end_dt"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </>
                        )}
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            required
                            label="Note"
                            name="note"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.note || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Update Commission
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

CommissionForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

CommissionForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default CommissionForm;
