/* eslint-disable max-len */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  IconButton,
  Link,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  FlagRounded as PaidIcon,
  FlagOutlined as UnpaidIcon,
  FileDownloadOutlined as DownloadIcon,
  ExpandMore,
  FmdBad as AlertIcon
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import {
  updateInterestBatch,
  deleteInterestBatch
} from 'src/actions/syndicateActions';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  entity: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  image: {
    height: 68,
    width: 68
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

const actionOptions = [
  {
    value: 'delete',
    text: 'Delete'
  },
  {
    value: 'aba',
    text: 'Download payment file'
  },
  {
    value: 'statement',
    text: 'Statements'
  }
];

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'createdAt|desc',
    label: 'Creation date (newest first)'
  },
  {
    value: 'createdAt|asc',
    label: 'Creation date (oldest first)'
  }
];

function getEmailStatus(status) {
  // TODO update investment labels
  const map = {
    Sent: 'green',
    'Partially sent': 'red',
    TBS: 'gold'
  };

  const color = map[status];

  return (
    <Chip
      size="small"
      label={status}
      style={{ backgroundColor: `${color}`, color: 'white' }}
    />
  );
}

function InterestPayments({
  className,
  onOpenBatch,
  onOpenAbaForm,
  onOpenDocForm,
  onOpenCalculator,
  ...rest
}) {
  const classes = useStyles();
  const actionRef = useRef(null);
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState(null);
  const { interestBatches, isLoading } = useSelector(
    state => state.syndicate.interestBatches
  );
  const { stats } = useSelector(state => state.syndicate.stats);
  const { user } = useSelector(state => state.account);

  const columns = [
    {
      title: 'Payment Period',
      field: 'period_start_dt',
      defaultSort: 'desc',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => (
        <Link
          variant="subtitle2"
          color="textPrimary"
          underline="none"
          component="button"
          onClick={() => {
            if (
              user.group_names.some(r =>
                ['Fund Manager', 'Finance'].includes(r)
              )
            ) {
              onOpenBatch(rowData.id);
            }
          }}
        >
          {format(parseISO(rowData.period_start_dt), 'dd/MM/yyyy')}
          {' to '}
          {format(parseISO(rowData.period_end_dt), 'dd/MM/yyyy')}
        </Link>
      )
    },
    {
      title: 'Interest Type',
      field: 'interest_type'
    },
    {
      title: 'Number of Entities',
      field: 'entity_cnt',
      render: rowData => (
        <div className={classes.entity}>
          {rowData.entity_cnt}
          {!rowData.is_paid && rowData.entity_cnt !== stats.entity_cnt && (
            <Tooltip title="Check number of entities to pay" placement="top">
              <SvgIcon fontSize="small" className={classes.badgeIcon}>
                <AlertIcon color="error" />
              </SvgIcon>
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: 'Interest Payble',
      field: 'interest_payable',
      type: 'currency'
    },
    {
      title: 'Withholding Tax',
      field: 'withholding_tax',
      type: 'currency'
    },
    {
      title: 'Interest Paid',
      field: 'interest_paid',
      type: 'currency'
    },
    {
      title: 'Statement',
      field: 'statement_email_status',
      render: rowData => getEmailStatus(rowData.statement_email_status)
    },
    {
      title: 'Payment Status',
      field: 'is_paid',
      render: rowData => (
        <Box
          display="flex"
          color="text.secondary"
          alignItems="center"
          justifyContent="flex-start"
        >
          <div className={classes.badge}>
            <IconButton
              disabled={
                !user.group_names.some(r =>
                  ['Fund Manager', 'Finance'].includes(r)
                )
              }
              className={
                !rowData.is_paid
                  ? classes.checkedButton
                  : classes.uncheckedButton
              }
              onClick={() => {
                dispatch(
                  updateInterestBatch(rowData.id, {
                    is_paid: !rowData.is_paid
                  })
                );
              }}
            >
              {rowData.is_paid ? (
                <UnpaidIcon fontSize="small" />
              ) : (
                <PaidIcon fontSize="small" />
              )}
            </IconButton>
            <Typography variant="body2" color="inherit" component="span">
              {rowData.is_paid ? 'Paid' : 'Unpaid'}
            </Typography>
          </div>
        </Box>
      )
    },
    {
      title: 'Actions',
      field: 'id',
      render: rowData => (
        <>
          {'Options'}
          <IconButton
            ref={actionRef}
            disabled={
              rowData.managing_staff !== user.id &&
              !user.group_names.some(r =>
                ['Fund Manager', 'Finance'].includes(r)
              )
            }
            onClick={e => {
              setMenuOpen(true);
              setAnchorEl(e.currentTarget);
              setAnchorId(rowData.id);
            }}
          >
            <ExpandMore size="small" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            onClose={() => {
              setMenuOpen(false);
              setAnchorEl(null);
            }}
            open={isMenuOpen}
            PaperProps={{ className: classes.menu }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setMenuOpen(false);
                  option.onClick(
                    interestBatches.filter(batch => batch.id == anchorId)[0]
                  );
                }}
              >
                {option.text}
              </MenuItem>
            ))}
          </Menu>
        </>
      )
    }
  ];

  const options = [
    {
      value: 'delete',
      text: 'Delete',
      superUser: user.groups.some(r => [1, 5].includes(r)),
      disabled: function disableOption(data) {
        return data.is_paid;
      },
      onClick: function onClick(data) {
        dispatch(deleteInterestBatch(data));
      }
    },
    {
      value: 'aba',
      text: 'Download Payment File',
      superUser: user.groups.some(r => [1, 5].includes(r)),
      disabled: function disableOption(data) {
        return false;
      },
      onClick: function onClick(data) {
        onOpenAbaForm({
          batchIds: [data.id],
          syndicateId: syndicateId
        });
      }
    },
    {
      value: 'statement',
      text: 'Statement',
      superUser: user.groups.some(r => [1, 5].includes(r)),
      disabled: function disableOption(data) {
        return !data.is_paid;
      },
      onClick: function onClick(data) {
        onOpenDocForm({
          docType: 'statement',
          batchId: data.id,
          syndicateId: syndicateId
        });
      }
    }
  ];

  return (
    <CustomMaterialTable
      title="Interest Payments"
      isLoading={isLoading}
      columns={columns}
      data={interestBatches}
      actions={[
        {
          icon: AddIcon,
          tooltip: 'Add Interest Payment',
          isFreeAction: true,
          onClick: event => onOpenCalculator()
        },
        {
          tooltip: 'Download ABA file',
          icon: DownloadIcon,
          onClick: (evt, data) =>
            onOpenAbaForm({
              batchIds: data.map(batch => batch.id),
              syndicateId: syndicateId
            })
        }
      ]}
      options={{
        selection: true,
        padding: 'dense',
        pageSize: 15,
        pageSizeOptions: [15, 30, 50]
      }}
    />
  );
}

InterestPayments.propTypes = {
  className: PropTypes.string
};

export default InterestPayments;
