import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  },
  logo: {
    width: 200,
    maxWidth: '100%'
  }
}));

function ApplicationAuthGuard({ children }) {
  const classes = useStyles();
  const { error } = useSelector(state => state.account);

  if (error) {
    // return <Redirect to="/login" />;
    // return <div>test</div>;
    return (
      <div className={classes.root}>
        <Box display="flex" justifyContent="center" mb={6}>
          <Typography>Invalid Application Link</Typography>
        </Box>
      </div>
    );
  }
  return children;
}

ApplicationAuthGuard.propTypes = {
  children: PropTypes.any
};

export default ApplicationAuthGuard;
