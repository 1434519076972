import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { updateInvestment, addInvestment } from 'src/actions/syndicateActions';
import Label from 'src/components/Label';
import { CurrencyMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function DrawdownForm({ drawdown, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { syndicate } = useSelector(state => state.syndicate);
  const { user } = useSelector(state => state.account);

  const initialValues = drawdown;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              drawdown_amt: Yup.number()
                .max(
                  drawdown && drawdown.amount,
                  'Cannot draw more than the balance'
                )
                .required('Required')
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (values.drawdown_amt == values.amount) {
                  await dispatch(updateInvestment(drawdown.id, values));
                } else {
                  await dispatch(
                    updateInvestment(drawdown.id, {
                      ...values,
                      parent_investment_id: values.parent_investment_id || values.id,
                      amount: values.drawdown_amt,
                      is_parent: true,
                      call_order: values.call_order || 1
                    })
                  );
                  await dispatch(
                    addInvestment({
                      ...values,
                      parent_investment_id: values.parent_investment_id || values.id,
                      funds_called: false,
                      drawdown_dt: null,
                      call_order: (values.call_order || 1) + 1,
                      is_parent: false,
                      amount:
                        parseFloat(values.amount) -
                        parseFloat(values.drawdown_amt)
                    })
                  );
                }
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Drawdown added', {
                  variant: 'success'
                });
              } catch (error) {
                setErrors({ submit: error.response.data });
                enqueueSnackbar('Drawdown failed', {
                  variant: 'success'
                });
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="caption" color="secondary">
                      {drawdown.investor_name} || {drawdown.account_name}
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item md={syndicate.is_pool ? 12 : 6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              (errors.submit && errors.submit.drawdown_amt) ||
                              (errors && errors.drawdown_amt)
                            )}
                            helperText={
                              (errors.submit &&
                                errors.submit.drawdown_amt &&
                                errors.submit.drawdown_amt[0]) ||
                              (errors && errors.drawdown_amt)
                            }
                            label="Drawdown Amount"
                            name="drawdown_amt"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('drawdown_amt')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.drawdown_amt || ''}
                            variant="outlined"
                          />
                        </Grid>
                        {!syndicate.is_pool && (
                          <Grid item md={6} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enAU}
                            >
                              <DatePicker
                                label="Expected Drawdown Date"
                                value={values.drawdown_dt}
                                onChange={newValue => {
                                  setFieldValue(
                                    'drawdown_dt',
                                    !newValue ||
                                      newValue.toString() == 'Invalid Date'
                                      ? newValue
                                      : format(newValue, 'yyyy-MM-dd')
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    required
                                    fullWidth
                                    error={Boolean(
                                      errors.submit && errors.submit.drawdown_dt
                                    )}
                                    helperText={
                                      errors.submit && errors.submit.drawdown_dt
                                        ? errors.submit &&
                                        errors.submit.drawdown_dt &&
                                        errors.submit.drawdown_dt[0]
                                        : 'To determine investor interest if construction loan'
                                    }
                                    variant="outlined"
                                    name="drawdown_dt"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Drawdown Funds
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

DrawdownForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

DrawdownForm.defaultProps = {
  onAdd: () => { },
  onClose: () => { }
};

export default DrawdownForm;
