import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';
import { getFundraisers } from 'src/actions/fundraiserActions';
import { getStaff } from 'src/actions/staffActions';
import { getSetting } from 'src/actions/settingActions';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Header from './Header';
import Details from './Details';
import Investments from './Investments';
import Files from './Files';
import EmailLogs from './EmailLogs';
import SplashScreen from 'src/components/SplashScreen';
import { getInvestor } from 'src/actions/investorActions';
import { initEntity } from 'src/actions/entityActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function InvestorDetailsView() {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { isLoading: settingLoading } = useSelector(state => state.setting);
  const { investor, isLoading: investorLoading } = useSelector(
    state => state.investor
  );
  const { investorId } = useParams();

  const [currentTab, setCurrentTab] = useState('details');
  const tabs = [
    { value: 'details', label: 'Details' },
    { value: 'investments', label: 'Investments' },
    { value: 'files', label: 'Files' },
    { value: 'emailLogs', label: 'Email Logs' }
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getInvestor(investorId));
    dispatch(getFundraisers());
    // dispatch(getStaff());
  }, [dispatch, investorId]);

  if (!investor || investorLoading || settingLoading) {
    return <SplashScreen />;
  }

  return (
    <Page className={classes.root} title="Investor Details">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'details' && <Details />}
          {currentTab === 'investments' && <Investments />}
          {currentTab === 'files' && <Files />}
          {currentTab === 'emailLogs' && <EmailLogs />}
        </Box>
      </Container>
    </Page>
  );
}

export default InvestorDetailsView;
