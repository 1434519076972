import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from 'src/components/Page';
import Header from './Header';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function InvestorListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [investors, setInvestors] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getInvestors = useCallback(() => {
    axios.get('/api/view/investor').then(response => {
      if (isMountedRef.current) {
        setInvestors(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getInvestors();
  }, [getInvestors]);

  return (
    <Page className={classes.root} title="Investor List">
      <Box mt={1}>
        <Results investors={investors} isLoading={isLoading} />
      </Box>
    </Page>
  );
}

export default InvestorListView;
