/* eslint-disable max-len */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Menu,
  MenuItem,
  IconButton,
  Link,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
  Approval as CertificateIcon,
  HistoryEduOutlined as SignedIcon,
  AddIcCall as CallIcon,
  DoNotDisturbOff as UncallIcon,
  Send as SendIcon,
  ContentPasteSearch as ReviewIcon,
  HourglassBottom as WaitIcon,
  Error as ErrorIcon,
  PriceChangeOutlined as RedeemIcon,
  FileDownloadOutlined as DownloadIcon,
  EventAvailable as DateIcon,
  EventBusy as DateMissingIcon,
  AddBoxOutlined as AddIcon,
  DeleteOutlineOutlined as DeleteIcon,
  NoteAltOutlined as EditIcon,
  ForkRight as DistributeIcon,
  ExpandLess,
  ExpandMore,
  LooksOne,
  LooksTwo,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  CurrencyExchange as LiqudityIcon
} from '@mui/icons-material';
import Label from 'src/components/Label';
import { LightTooltip } from 'src/components/LightTooltip';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { currencyFormatter } from 'src/utils/mask';
import getInitials from 'src/utils/getInitials';
import { dateDiffInDays } from 'src/utils/validation';
import BarProgress from 'src/components/BarProgress';
import {
  deleteInvestment,
  updateInvestment,
  deleteTransaction,
  deleteChildCalls
} from 'src/actions/syndicateActions';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  bulkOperations: {
    position: 'relative',
    zIndex: 8
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },

  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  badgeIcon: {
    marginRight: theme.spacing(1)
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  popover: {
    display: 'flex',
    margin: theme.spacing(2),
    width: '300px'
  },
  subscriptions: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12
  },
  value: {
    color: '#20B2AA',
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function getCallOrderIcon(call_order) {
  const map = {
    1: <LooksOne color="disabled" />,
    2: <LooksTwo color="disabled" />,
    3: <Looks3 color="disabled" />,
    4: <Looks4 color="disabled" />,
    5: <Looks5 color="disabled" />,
    6: <Looks6 color="disabled" />
  };

  return map[call_order];
}

const classColor = {
  A: 'success',
  B: 'warning',
  C: 'error'
};

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

function Results({
  className,
  onEditInvestment,
  onOpenTransactionForm,
  onOpenDrawdownForm,
  onOpenSellForm,
  onOpenBuyForm,
  onOpenJournalForm,
  onOpenBulkRedemptionForm,
  onOpenBulkDrawdownForm,
  onOpenDocForm,
  onOpenAbaForm,
  onOpenMail,
  onOpenReview,
  onOpenDistributionForm,
  ...rest
}) {
  const classes = useStyles();
  const { syndicateId } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(state => state.account);
  const { syndicate, investments, isLoading: syndicateLoading } = useSelector(
    state => state.syndicate
  );
  const { isLoading: investmentLoading } = useSelector(
    state => state.syndicate.investments
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const actionRef = useRef(null);

  const callFunds = data => {
    if (!data.funds_called) {
      return (
        <IconButton
          onClick={() => {
            if (
              syndicate &&
              (syndicate.syndicate_purpose_id == 2 || syndicate.is_pool) &&
              !data.funds_called
            ) {
              onOpenDrawdownForm({
                ...data,
                drawdown_dt: null,
                drawdown_amt: data.amount,
                funds_called: !data.funds_called
              });
            } else {
              dispatch(
                updateInvestment(data.id, {
                  funds_called: !data.funds_called
                })
              );
            }
          }}
        >
          <LightTooltip title="Call Funds">
            <CallIcon />
          </LightTooltip>
        </IconButton>
      );
    }
    return null;
  };

  const applicationSigning = data => {
    if (!data.funds_called) {
      return null;
    } else if (!data.application_status) {
      return (
        <LightTooltip
          title={
            data.coupon == null
              ? 'Interest is required to send application'
              : 'Send Application'
          }
        >
          <IconButton
            disabled={data.coupon == null}
            onClick={() => {
              onOpenMail(data);
            }}
          >
            <SendIcon />
          </IconButton>
        </LightTooltip>
      );
    } else if (data.application_status == 'signed') {
      return (
        <LightTooltip title="Review Application">
          <IconButton onClick={() => onOpenReview(data)}>
            <ReviewIcon />
          </IconButton>
        </LightTooltip>
      );
    } else if (
      ['sent', 'delivered', 'opened', 'clicked'].includes(
        data.application_status
      )
    ) {
      return (
        <LightTooltip title="Investor to sign application">
          <IconButton
            disabled={data.coupon == null}
            onClick={() => {
              onOpenMail(data);
            }}
          >
            <SignedIcon />
          </IconButton>
        </LightTooltip>
      );
    } else if (data.application_status == 'verified') {
      return null;
    }
    return (
      <LightTooltip title={`Application email ${data.application_status}`}>
        <ErrorIcon color="error" />
      </LightTooltip>
    );
  };

  const sendCertificate = data => {
    if (
      data.application_status == 'verified' &&
      !data.certificate_email_status &&
      parseFloat(data.current_amt) > 0
    ) {
      return (
        <IconButton>
          <LightTooltip title="Certificate will be automatically sent once funds called and application verified">
            <CertificateIcon />
          </LightTooltip>
        </IconButton>
      );
    } else if (
      data.certificate_email_status &&
      !['sent', 'delivered', 'opened', 'clicked'].includes(
        data.certificate_email_status
      )
    ) {
      return (
        <LightTooltip
          title={`Certificate email ${data.certificate_email_status}`}
        >
          <ErrorIcon color="error" />
        </LightTooltip>
      );
    }
    return null;
  };

  const showLiquidity = data => {
    if (
      data.is_liquidity &&
      parseFloat(data.current_amt) > 0 &&
      ((!data.liquidity_start_dt && !data.liquidity_end_dt) ||
        (data.liquidity_start_dt !== null &&
          dateDiffInDays(new Date(), parseISO(data.liquidity_start_dt)) <=
            30) ||
        (data.liquidity_start_dt == null &&
          data.liquidity_end_dt !== null &&
          dateDiffInDays(new Date(), parseISO(data.liquidity_end_dt)) <= 30))
    ) {
      return (
        <LightTooltip
          title={
            data.liquidity_start_dt && data.liquidity_end_dt
              ? `Agree to redeem between ${format(
                  parseISO(data.liquidity_start_dt),
                  'dd/MM/yyyy'
                )} and ${format(parseISO(data.liquidity_end_dt), 'dd/MM/yyyy')}`
              : data.liquidity_start_dt && !data.liquidity_end_dt
              ? `Agree to redeem from ${format(
                  parseISO(data.liquidity_start_dt),
                  'dd/MM/yyyy'
                )}`
              : !data.liquidity_start_dt && data.liquidity_end_dt
              ? `Agree to redeem before ${format(
                  parseISO(data.liquidity_end_dt),
                  'dd/MM/yyyy'
                )}`
              : 'Can redeem anytime'
          }
        >
          <LiqudityIcon color="error" />
        </LightTooltip>
      );
    }
    return null;
  };

  const setTransactionDate = data => {
    if (data.undated_flag) {
      return (
        <LightTooltip title="Transaction missing date">
          <DateMissingIcon color="error" />
        </LightTooltip>
      );
    }
    return null;
  };

  const todoList = [
    {
      action: 'call',
      renderMethod: callFunds,
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id
    },
    {
      action: 'application',
      renderMethod: applicationSigning,
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id
    },
    {
      action: 'certificate',
      renderMethod: sendCertificate,
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id
    },
    {
      action: 'interest',
      renderMethod: setTransactionDate,
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id
    },
    {
      action: 'liquidity',
      renderMethod: showLiquidity,
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id
    }
  ];

  const options = [
    {
      value: 'edit',
      text: 'Edit Investment',
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id,
      disabled: function disableOption(data) {
        return data.investment_status_id == 5;
      },
      onClick: function onClick(data) {
        onEditInvestment(data);
      }
    },
    {
      value: 'buy',
      text: 'Buy Unit',
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: null,
      disabled: function disableOption(data) {
        return parseFloat(data.current_amt) == parseFloat(data.amount);
      },
      onClick: function onClick(data) {
        onOpenBuyForm(data);
      }
    },
    {
      value: 'sell',
      text: 'Sell Unit',
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: null,
      disabled: function disableOption(data) {
        return parseFloat(data.current_amt) == 0;
      },
      onClick: function onClick(data) {
        onOpenSellForm(data);
      }
    },
    {
      value: 'statement',
      text: 'Statement',
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id,
      disabled: function disableOption(data) {
        return (
          data.investment_status_id == 1 || data.investment_status_id == null
        );
      },

      onClick: function onClick(data) {
        onOpenDocForm({
          docType: 'statement',
          syndicateId: data.syndicate_id,
          entityId: data.entity_id
        });
      }
    },
    {
      value: 'certificate',
      text: 'Certificate',
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id,
      disabled: function disableOption(data) {
        return (
          data.investment_status_id == 1 || data.investment_status_id == null
        );
      },
      onClick: function onClick(data) {
        onOpenDocForm({
          docType: 'certificate',
          syndicateId: data.syndicate_id,
          investmentId: data.id
        });
      }
    },
    {
      value: 'delete',
      text: 'Delete',
      superUser: user.group_names.some(r =>
        ['Fund Manager', 'Finance'].includes(r)
      ),
      user: user.id,
      disabled: function disableOption(data) {
        return data.investment_status_id > 1;
      },
      onClick: async function onClick(data) {
        try {
          await dispatch(deleteInvestment(data));
          if (data.call_order == 1) {
            const childInvestmentIds = investments.allIds.filter(
              id => investments.byId[id].parent_investment_id == data.id
            );
            await dispatch(deleteChildCalls(childInvestmentIds));
          }
          toast.success('Investment deleted');
        } catch {
          toast.error('Investment deletion failed');
        }
      }
    }
  ];

  const columns = [
    {
      title: 'Investor',
      field: 'investor_name',
      render: rowData =>
        rowData.managing_staff_id == user.id ||
        user.group_names.some(r => ['Fund Manager', 'Finance'].includes(r)) ? (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/app/management/investors/${rowData.investor_id}`}
            variant="h6"
          >
            <div>
              {rowData.investor_name}
              {rowData.entity_id && (
                <Typography variant="body2" color="textSecondary">
                  {rowData.account_name}
                </Typography>
              )}
            </div>
          </Link>
        ) : (
          getInitials(rowData.investor_name)
        )
    },
    { title: 'Entity', field: 'account_name', hidden: true, searchable: true },
    {
      title: 'Lead Manager',
      field: 'managing_staff',
      render: rowData => (
        <div>
          {rowData.managing_staff}
          {rowData.fundraiser &&
            (rowData.managing_staff_id == user.id ||
              user.group_names.some(r =>
                ['Fund Manager', 'Finance'].includes(r)
              )) && (
              <Typography variant="body2" color="textSecondary">
                <span className={classes.subscriptions}>
                  {`${rowData.fundraiser} ${parseFloat(
                    rowData.commission
                  ).toString()}% ${rowData.commission_type}`}
                </span>
              </Typography>
            )}
        </div>
      )
    },
    {
      title: 'EOI Date',
      field: 'created_dt',
      defaultSort: 'desc',
      render: rowData => (
        <div style={{ display: 'inline-block' }}>
          {format(parseISO(rowData.created_dt), 'dd/MM/yyyy')}
          {syndicate && rowData.call_order && (
            <LightTooltip
              title={`${ordinal_suffix_of(
                rowData.call_order
              )} call of the funds`}
            >
              {getCallOrderIcon(rowData.call_order)}
            </LightTooltip>
          )}
        </div>
      )
    },
    {
      title: 'Coupon',
      field: 'coupon',
      render: rowData => (
        <div>
          {rowData.coupon
            ? parseFloat(rowData.topup) > 0
              ? `${parseFloat(
                  (
                    parseFloat(rowData.coupon) +
                    parseFloat(rowData.rate_adjustment)
                  ).toFixed(2)
                ).toString()}% + ${parseFloat(rowData.topup)}%`
              : `${parseFloat(
                  (
                    parseFloat(rowData.coupon) +
                    parseFloat(rowData.rate_adjustment)
                  ).toFixed(2)
                ).toString()}%`
            : 'TBD'}
          {rowData.unit_class && rowData.unit_class !== 'Ordinary' && (
            <Label
              className={classes.label}
              color={classColor[rowData.unit_class]}
            >
              {rowData.unit_class}
            </Label>
          )}
        </div>
      )
    },
    { title: 'Status', field: 'investment_status' },
    {
      title: 'Investment Amount',
      field: 'current_amt',
      render: rowData =>
        syndicate && syndicate.is_contributory ? (
          <div>
            <BarProgress
              stripe={
                rowData.investment_status_id == null ||
                rowData.investment_status_id <= 2
              }
              progress={(
                (100 * parseFloat(rowData.cash_amt)) /
                parseFloat(rowData.amount)
              )
                .toFixed(2)
                .toString()}
              height={20}
              value={parseFloat(rowData.cash_amt)}
            />
            <Typography variant="caption">
              {`Balance: ${currencyFormatter(rowData.current_amt)}`}
            </Typography>
          </div>
        ) : (
          <div>
            <BarProgress
              stripe={
                rowData.investment_status_id == null ||
                rowData.investment_status_id <= 2
              }
              progress={(
                (100 * parseFloat(rowData.current_amt)) /
                parseFloat(rowData.amount)
              )
                .toFixed(2)
                .toString()}
              height={20}
              value={parseFloat(rowData.current_amt)}
            />
            <Typography variant="caption">
              {`Total: ${currencyFormatter(rowData.amount)}`}
            </Typography>
          </div>
        )
    },
    {
      title: 'Todo',
      field: 'funds_called',
      render: rowData => (
        <div className={classes.badge}>
          {todoList
            .filter(
              item => (item.user == rowData.managing_staff_id) | item.superUser
            )
            .map((item, index) => {
              if (!item.renderMethod(rowData)) {
                return null;
              }
              return (
                <div key={index} className={classes.badge}>
                  {item.renderMethod(rowData)}
                </div>
              );
            })}
        </div>
      )
    },
    {
      title: 'Actions',
      field: 'id',
      render: rowData => {
        const filteredOptions = options.filter(
          option =>
            option.superUser | (rowData.managing_staff_id == option.user)
        );
        if (filteredOptions.length == 0) {
          return null;
        }
        return (
          <>
            {'Options'}
            <IconButton
              ref={actionRef}
              onClick={e => {
                setMenuOpen(true);
                setAnchorEl(e.currentTarget);
                setAnchorId(rowData.id);
              }}
            >
              <ExpandMore size="small" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              onClose={() => {
                setMenuOpen(false);
                setAnchorEl(null);
              }}
              open={isMenuOpen}
              PaperProps={{ className: classes.menu }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              {filteredOptions.map(option => (
                <MenuItem
                  disabled={
                    anchorId && investments.byId[anchorId]
                      ? option.disabled(investments.byId[anchorId])
                      : true
                  }
                  onClick={() => {
                    setMenuOpen(false);
                    if (investments.byId[anchorId]) {
                      option.onClick(investments.byId[anchorId]);
                    }
                  }}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      }
    }
  ];

  const data = investments.allIds.map(id => ({
    ...investments.byId[id],
    investment_status: investments.byId[id].investment_status || 'EOI'
  }));

  const tranColumns = [
    {
      title: 'Interest Start Date',
      field: 'transaction_dt',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    { title: 'Updated By', field: 'operator' },
    { title: 'Particulars', field: 'transaction_type', type: 'date' },
    {
      title: 'Debit',
      field: 'amount',
      render: rowData =>
        rowData.tran_sign == '-1' ? currencyFormatter(rowData.amount) : ''
    },
    {
      title: 'Credit',
      field: 'amount',
      render: rowData =>
        rowData.tran_sign == '1' ? currencyFormatter(rowData.amount) : ''
    },
    {
      title: 'SPV',
      field: 'subtrust_spv_name',
      hidden: syndicate && !syndicate.is_contributory,
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    }
  ];

  return (
    <CustomMaterialTable
      title="Investments"
      isLoading={investmentLoading || syndicateLoading}
      columns={columns}
      data={data}
      detailPanel={rowData => {
        if (
          rowData.managing_staff_id !== user.id &&
          !user.group_names.some(r => ['Fund Manager', 'Finance'].includes(r))
        ) {
          return null;
        }
        return (
          <Container maxWidth="md">
            <Box m={1} sx={{ color: 'text.disabled' }}>
              <CustomMaterialTable
                title="Transactions"
                columns={tranColumns}
                data={rowData.transactions.map(entry => ({
                  ...entry,
                  investor_name: rowData.investor_name,
                  account_name: rowData.account_name,
                  current_amt: rowData.current_amt
                }))}
                actions={[
                  {
                    icon: AddIcon,
                    tooltip: 'Add Transaction',
                    isFreeAction: true,
                    disabled: !user.group_names.some(r =>
                      ['Fund Manager', 'Finance'].includes(r)
                    ),
                    onClick: event =>
                      onOpenTransactionForm({
                        investment_id: rowData.id,
                        transaction_type_id:
                          rowData.investment_status_id >= 3 ? 2 : 1,
                        investor_name: rowData.investor_name,
                        account_name: rowData.account_name,
                        amount:
                          rowData.investment_status_id >= 3
                            ? parseFloat(rowData.current_amt).toFixed(2)
                            : (
                                parseFloat(rowData.amount) -
                                parseFloat(rowData.current_amt)
                              ).toFixed(2),
                        is_liquidity: rowData.is_liquidity,
                        transaction_dt: null
                      })
                  },
                  rowData => ({
                    icon: EditIcon,
                    tooltip: 'Update Transaction',
                    disabled:
                      !user.group_names.some(r =>
                        ['Fund Manager', 'Finance'].includes(r)
                      ) || rowData.subtrust_investment_id,
                    onClick: (event, rowData) =>
                      onOpenTransactionForm({
                        ...rowData,
                        investor_name: rowData.investor_name,
                        account_name: rowData.account_name,
                        is_liquidity: rowData.is_liquidity,
                        current_amt: rowData.current_amt
                      })
                  }),
                  rowData => ({
                    icon: DeleteIcon,
                    tooltip: 'Delete Transaction',
                    onClick: async (event, rowData) => {
                      var result = confirm('Delete this transation?');
                      if (result) {
                        try {
                          await dispatch(deleteTransaction(rowData));
                          // TODO need to change to sync so that throw error works
                          enqueueSnackbar('Transaction deleted', {
                            variant: 'success'
                          });
                        } catch {
                          enqueueSnackbar('Transaction failed to be deleted', {
                            variant: 'error'
                          });
                        }
                      }
                    },
                    disabled:
                      !user.group_names.some(r =>
                        ['Fund Manager', 'Finance'].includes(r)
                      ) || rowData.subtrust_investment_id
                  })
                ]}
                options={{
                  paging: false,
                  padding: 'dense'
                }}
              />
            </Box>
          </Container>
        );
      }}
      actions={[
        {
          icon: AddIcon,
          tooltip: 'Add Investment',
          isFreeAction: true,
          onClick: event => onEditInvestment(null)
        },
        {
          tooltip: 'Bulk Redemption',
          icon: RedeemIcon,
          onClick: (evt, data) =>
            onOpenBulkRedemptionForm({
              syndicateId: syndicateId,
              investmentIds: data.map(investment => investment.id),
              amount: null,
              transaction_dt: null,
              total_balance: data
                .map(investment => parseFloat(investment.current_amt))
                .reduce((a, b) => a + b, 0)
                .toFixed(2)
            })
        },
        {
          tooltip: 'Download ABA file',
          icon: DownloadIcon,
          onClick: (evt, data) =>
            onOpenAbaForm({
              syndicateId: syndicateId,
              investmentIds: data.map(investment => investment.id),
              payment_dt: format(new Date(), 'yyyy-MM-dd'),
              description: 'Redemption',
              lodgement_ref: null,
              rollover_ref: null,
              from_spv_account: true,
              transaction_dt: format(new Date(), 'yyyy-MM-dd')
            })
        },
        {
          tooltip: 'Distribute to Sub-Trust',
          disabled: syndicate && !syndicate.is_contributory,
          icon: DistributeIcon,
          onClick: (evt, data) =>
            onOpenDistributionForm({
              syndicateId: syndicateId,
              investmentIds: data.map(investment => investment.id),
              amount: null,
              total_balance: data
                .map(investment => parseFloat(investment.cash_amt))
                .reduce((a, b) => a + b, 0)
                .toFixed(2)
            })
        },
        {
          tooltip: 'Bulk Set Transaction Date',
          icon: DateIcon,
          onClick: (evt, data) =>
            onOpenBulkDrawdownForm({
              syndicateId: syndicateId,
              investmentIds: data.map(investment => investment.id),
              transaction_dt: null
            })
        }
      ]}
      options={{
        selection: true,
        padding: 'dense',
        pageSize: 15,
        pageSizeOptions: [15, 30, 50]
      }}
    />
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
