import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import {
  Avatar,
  Box,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { Button } from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { updateCard } from 'src/actions/kanbanActions';
import { Search as SearchIcon } from 'src/components/icons/search';
import { Check as CheckIcon } from 'src/components/icons/check';

export const KanbanMemberAdd = props => {
  const dispatch = useDispatch();
  const { cardId } = useSelector(state => state.kanban.openCard);
  const card = useSelector(state => state.kanban.cards.byId[cardId]);
  const { staff } = useSelector(state => state.staff);
  const [query, setQuery] = useState('');

  const handleDetailsUpdate = debounce(async update => {
    try {
      await dispatch(updateCard(card.id, update));
      // toast.success('Card updated!');
    } catch (err) {
      console.error(err.response.data);
      toast.error(JSON.stringify(err.response.data));
    }
  }, 1000);

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  const applyFilters = (staff, query) => {
    return staff.allIds
      .filter(id => staff.byId[id].is_active && staff.byId[id].is_employee)
      .filter(id => {
        let matches = true;

        if (
          query &&
          !staff.byId[id].full_name.toLowerCase().includes(query.toLowerCase())
        ) {
          matches = false;
        }

        return matches;
      });
  };

  const filteredStaff = applyFilters(staff, query);

  if (!cardId) {
    return null;
  }
  return (
    <Box
      sx={{
        p: 1
      }}
    >
      <TextField
        fullWidth
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          )
        }}
        onChange={handleQueryChange}
        placeholder="Search staff"
        value={query}
        variant="outlined"
      />
      <Box mt={1}>
        {filteredStaff.length > 0 &&
          filteredStaff.map(id => (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                mb: 1
              }}
            >
              <Avatar sx={{ mr: 1, width: 32, height: 32 }}>
                {getInitials(staff.byId[id].full_name)}
              </Avatar>
              <Button
                fullWidth
                style={{ justifyContent: 'space-between' }}
                endIcon={
                  card.assigned_to.includes(id) && (
                    <CheckIcon fontSize="small" />
                  )
                }
                onClick={() => {
                  const updated = card.assigned_to.includes(id)
                    ? card.assigned_to.filter(_id => _id !== id)
                    : card.assigned_to.concat([id]);
                  handleDetailsUpdate({
                    assigned_to: updated
                  });
                }}
              >
                {staff.byId[id].full_name}
              </Button>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

KanbanMemberAdd.propTypes = {
  // cardId: PropTypes.number.isRequired,
  // onClose: PropTypes.func,
  open: PropTypes.bool
};

KanbanMemberAdd.defaultProps = {
  open: false
};
