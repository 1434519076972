/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormHelperText,
  FormControlLabel,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { UserX as DeleteIcon } from 'react-feather';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { properName } from 'src/utils/properName';
import { NumberMask } from 'src/utils/mask';
import { validateTFN } from 'src/utils/validation';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  fieldset: {
    border: 0
  }
}));

function IndividualForm({
  className,
  entity_type_id,
  onBack,
  onNext,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application } = useSelector(state => state.application);

  const initialValues = application.entity_id
    ? application.entity_id.entity_details
    : entitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          await dispatch(
            updateApplication(application.id, {
              ...application,
              entity_id: {
                ...application.entity_id,
                investor_id: application.investor_id.id,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident:
                  values.individuals[0].tax_country == 'Australia',
                is_us_tax_resident: values.individuals[0].is_us_tax_resident,
                account_name: properName(
                  values.individuals
                    .map(ind => ind.first_name.concat(' ', ind.last_name))
                    .join(' & ')
                ),
                address_1: properName(values.individuals[0].address_1 || ''),
                address_2: properName(values.individuals[0].address_2 || ''),
                city: properName(values.individuals[0].city || ''),
                state: values.individuals[0].state || '',
                postcode: values.individuals[0].postcode,
                country: values.individuals[0].country
              }
            })
          );
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: JSON.stringify(err.response.data) });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <fieldset className={classes.fieldset}>
            <FieldArray
              name="individuals"
              subscription={{}} // This is required so that the whole array does not re-render on any change.
              render={({ insert, remove, push }) => (
                <>
                  {values.individuals.map((individual, index) => (
                    <Box mt={2} mb={1} key={index}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                          color="secondary"
                          gutterBottom
                        >
                          APPLICANT {index + 1}
                        </Typography>
                        {(index > 0 || !application.entity_id) && (
                          <Tooltip title="Delete applicant">
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <SvgIcon>
                                <DeleteIcon />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                      <Grid container spacing={1}>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            select
                            disabled={application.entity_id !== null}
                            label="Title"
                            name={`individuals.${index}.title`}
                            onChange={handleChange}
                            SelectProps={{
                              native: true
                            }}
                            value={values.individuals[index].title || ''}
                            variant="outlined"
                            size="small"
                          >
                            <option></option>
                            {titles.map(option => (
                              <option key={option.code} value={option.code}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            disabled={application.entity_id !== null}
                            label="First Name"
                            name={`individuals.${index}.first_name`}
                            onChange={handleChange}
                            value={values.individuals[index].first_name || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            disabled={application.entity_id !== null}
                            label="Last Name"
                            name={`individuals.${index}.last_name`}
                            onChange={handleChange}
                            value={values.individuals[index].last_name || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Date of Birth"
                              disabled={application.entity_id !== null}
                              openTo="year"
                              views={['year', 'month', 'day']}
                              name={`individuals.${index}.birth_dt`}
                              value={values.individuals[index].birth_dt || null}
                              onChange={newValue => {
                                setFieldValue(
                                  `individuals.${index}.birth_dt`,
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            disabled={application.entity_id !== null}
                            label="Country of Residency for Tax Purposes"
                            name={`individuals.${index}.tax_country`}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.individuals[index].tax_country || ''}
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            disabled={application.entity_id !== null}
                            required={
                              values.individuals[index].tax_country ==
                              'Australia'
                            }
                            helperText={
                              values.individuals[index].tax_country ==
                                'Australia' &&
                              values.individuals[index].tfn > 0 &&
                              !validateTFN(values.individuals[index].tfn) &&
                              'Invalid TFN'
                            }
                            error={
                              values.individuals[index].tax_country ==
                                'Australia' &&
                              values.individuals[index].tfn > 0 &&
                              !validateTFN(values.individuals[index].tfn)
                            }
                            label="TFN or Exemption number"
                            name={`individuals.${index}.tfn`}
                            InputProps={{
                              maxLength: 9,
                              inputComponent: NumberMask,
                              onChange: handleChange(`individuals.${index}.tfn`)
                            }}
                            onChange={handleChange}
                            value={values.individuals[index].tfn || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={application.entity_id !== null}
                                checked={
                                  values.individuals[index].is_pep || false
                                }
                                onChange={handleChange}
                                name={`individuals.${index}.is_pep`}
                                size="small"
                              />
                            }
                            label="Politically Exposed Person"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={application.entity_id !== null}
                                checked={
                                  values.individuals[index]
                                    .is_us_tax_resident || false
                                }
                                onChange={handleChange}
                                name={`individuals.${index}.is_us_tax_resident`}
                                size="small"
                              />
                            }
                            label="US Citizen / US Tax Resident"
                          />
                        </Grid>
                        {values.individuals[index].is_us_tax_resident && (
                          <Grid item md={4} xs={12}>
                            <TextField
                              fullWidth
                              disabled={application.entity_id !== null}
                              required={
                                values.individuals[index].is_us_tax_resident
                              }
                              label="TIN"
                              name={`individuals.${index}.tin`}
                              onChange={handleChange}
                              value={values.individuals[index].tin || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        )}
                        <Grid item md={12} xs={12}>
                          <TextField
                            required
                            fullWidth
                            inputProps={{
                              maxLength: 38
                            }}
                            helperText="Provide oversea address if not Australian tax
                            resident (cannot be a PO Box address)"
                            label="Address Line 1"
                            name={`individuals.${index}.address_1`}
                            onChange={handleChange}
                            value={values.individuals[index].address_1 || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            inputProps={{ maxLength: 38 }}
                            label="Address Line 2"
                            name={`individuals.${index}.address_2`}
                            onChange={handleChange}
                            value={values.individuals[index].address_2 || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="City"
                            name={`individuals.${index}.city`}
                            onChange={handleChange}
                            value={values.individuals[index].city || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Autocomplete
                            freeSolo
                            size="small"
                            name={`individuals.${index}.state`}
                            value={values.individuals[index].state || ''}
                            options={states}
                            onInputChange={(event, newValue) => {
                              setFieldValue(
                                `individuals.${index}.state`,
                                newValue ? newValue : ''
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                label="State/Province"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Postcode"
                            name={`individuals.${index}.postcode`}
                            onChange={handleChange}
                            value={values.individuals[index].postcode || ''}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Country"
                            name={`individuals.${index}.country`}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.individuals[index].country || ''}
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                  {application.entity_id == null && (
                    <Box mt={2}>
                      <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                          push({});
                        }}
                      >
                        Add Applicant
                      </Button>
                    </Box>
                  )}
                </>
              )}
            />
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onBack}
                size="large"
              >
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

IndividualForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default IndividualForm;
