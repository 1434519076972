/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { CurrencyMask, PercentMask } from 'src/utils/mask';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  highlight: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function InvestmentDetail({ className, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { paymentFreq } = useSelector(state => state.setting);
  const { application, isLoading } = useSelector(state => state.application);

  const initialValues = application;

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({
      //   abn: Yup.string()
      //     .max(11, 'ABN needs to be 11 digits ')
      //     .nullable(),
      //   business_address_1: Yup.string()
      //     .max(38, 'Must be less than 38 characters')
      //     .required()
      // })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const new_entity = values.entity_id
          ? application.investor_id.entities.filter(
              entity => entity.id == values.entity_id
            )[0]
          : null;
        await dispatch(
          updateApplication(application.id, {
            ...values,
            entity_id: new_entity
          })
        );
        setSubmitting(false);
        setStatus({ success: true });
        if (onNext) {
          onNext();
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Typography variant="body1" gutterBottom>
            Thank you for your interest in applying for Units in{' '}
            <span className={classes.highlight}>{values.spv_name}</span>.
          </Typography>
          <Typography variant="body1" gutterBottom>
            The application has been prefilled with information extracted from
            our system. If you notice any errors in the form or if you have any
            questions, please do not hesitate to contact our Client Services
            team at clientservices@wealthpifund.com.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Following receipt of your application, we will contact you
            requesting payment.Once the payment is made, we will include your
            investment in the unit registry and send you a note or unit
            certificate for your records.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you for investing with Wealth Pi Fund.
          </Typography>
          <Box mt={4}>
            <Box mb={2}>
              <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
                INVESTMENT AMOUNT
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  InputProps={{
                    inputComponent: CurrencyMask,
                    onChange: handleChange('amount')
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.amount || ''}
                  variant="outlined"
                />
              </Grid>
              {application && application.specify_interest && (
                <>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      disabled={true}
                      label="Interest p.a."
                      name="interest"
                      InputProps={{
                        inputComponent: PercentMask
                      }}
                      onBlur={handleBlur}
                      value={
                        parseFloat(values.interest) +
                        parseFloat(values.rate_adjustment)
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      disabled={true}
                      label="Payment Frequency"
                      name="payment_freq_id"
                      InputLabelProps={{
                        shrink: !!values.payment_freq_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={values.payment_freq_id}
                      variant="outlined"
                    >
                      <option></option>
                      {paymentFreq.allIds.map(id => (
                        <option key={id} value={id}>
                          {paymentFreq.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  {parseFloat(values.coupon) > 0 && (
                    <>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Coupon p.a."
                          name="coupon"
                          InputProps={{
                            inputComponent: PercentMask
                          }}
                          onBlur={handleBlur}
                          value={values.coupon || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          disabled={true}
                          label="Coupon Payment Frequency"
                          name="coupon_payment_freq_id"
                          InputLabelProps={{
                            shrink: !!values.coupon_payment_freq_id
                          }}
                          select
                          SelectProps={{ native: true }}
                          value={values.coupon_payment_freq_id || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {paymentFreq.allIds.map(id => (
                            <option key={id} value={id}>
                              {paymentFreq.byId[id].name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Box mt={2}>
              <Typography variant="caption" gutterBottom>
                {application &&
                  !application.specify_interest &&
                  `Coupon rate is based on the actual drawdown date. Please refer to IM for coupon rate schedule. `}
                The issue price for each Unit is $1.00. The minimum initial
                investment amount is $100,000 (i.e. 100,000 units). The Trustee
                reserves the right to accept different amounts at its absolute
                discretion.
              </Typography>
            </Box>
            {application && application.investor_id.entities.length > 0 && (
              <Box mt={2}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600 }}
                  gutterBottom
                >
                  INVESTMENT VEHICLE
                </Typography>
                {application.entity_id == null ? (
                  <Typography variant="body1" gutterBottom>
                    You have invested with us before. Please choose a investment
                    vehicle from the list or leave blank if you wish to invest
                    under a new vehicle.
                  </Typography>
                ) : (
                  <Typography variant="body1" gutterBottom>
                    You're investing under the name of{' '}
                    <span className={classes.highlight}>
                      {application.entity_id.account_name}
                    </span>
                    , if you wish to invest under a different vehicle, choose
                    from the list below or leave blank for a new vehicle.
                  </Typography>
                )}
                <Grid container spacing={1}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Investment Entity"
                      name="entity_id"
                      InputLabelProps={{
                        shrink: !!values.entity_id
                      }}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.entity_id ? values.entity_id.id : ''}
                      variant="outlined"
                    >
                      <option></option>
                      {application.investor_id.entities.map(entity => (
                        <option key={entity.id} value={entity.id}>
                          {entity.account_name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} variant="contained" size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

InvestmentDetail.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default InvestmentDetail;
