import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
  withStyles,
  colors
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  Bookmark as BookmarkIcon,
  DollarSign as DollarSignIcon,
  User as UserIcon,
  Star as StarIcon,
  Briefcase as BriefcaseIcon,
  File as FileIcon
} from 'react-feather';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { getSetting } from 'src/actions/settingActions';
import { getEntity, setAddress } from 'src/actions/entityActions';
import { getInvestor } from 'src/actions/investorActions';
import Page from 'src/components/Page';
import SlashScreen from 'src/components/SplashScreen';
import Header from './Header';
import EntityDetails from './EntityDetails';
import BankDetail from './BankDetail';
import Files from './Files';

const steps = [
  {
    label: 'Investment Vehicle Details',
    icon: BriefcaseIcon
  },
  {
    label: 'Bank Account Details',
    icon: DollarSignIcon
  },
  {
    label: 'Supporting Documents',
    icon: FileIcon
  }
];

const CustomStepConnector = withStyles(theme => ({
  vertical: {
    marginLeft: 19,
    padding: 0
  },
  line: {
    borderColor: theme.palette.divider
  }
}))(StepConnector);

const useCustomStepIconStyles = makeStyles(theme => ({
  root: {},
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10]
  },
  completed: {
    backgroundColor: theme.palette.secondary.main
  }
}));

function CustomStepIcon({ active, completed, icon }) {
  const classes = useCustomStepIconStyles();

  const Icon = steps[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      <Icon size="20" />
    </Avatar>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  avatar: {
    backgroundColor: colors.red[600]
  }
}));

function EntityCRUDView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { investorId, entityId } = useParams();
  const { entity, isLoading: entityLoading, address } = useSelector(
    state => state.entity
  );
  const { investor, isLoading: investorLoading } = useSelector(
    state => state.investor
  );
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getInvestor(investorId));
    dispatch(getEntity(entityId));
  }, [dispatch]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleComplete = () => {
    history.push(`/app/management/investors/${investorId}`);
  };

  if (investorLoading || entityLoading) {
    return <SlashScreen />;
  }

  return (
    <Page className={classes.root} title="Entity Create">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12} md={2}>
              <Stepper
                activeStep={activeStep}
                connector={<CustomStepConnector />}
                orientation="vertical"
                component={Box}
                bgcolor="transparent"
              >
                {steps.map(step => (
                  <Step key={step.label}>
                    <StepLabel StepIconComponent={CustomStepIcon}>
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={10}>
              {activeStep === 0 && (
                <EntityDetails
                  entity={entity}
                  investor={investor}
                  onBack={handleBack}
                  onNext={handleNext}
                />
              )}
              {activeStep === 1 && (
                <BankDetail
                  onBack={handleBack}
                  investor={investor}
                  onNext={handleNext}
                />
              )}
              {activeStep === 2 && (
                <Files onBack={handleBack} onComplete={handleComplete} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default EntityCRUDView;
