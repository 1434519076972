import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import ColorLogo from 'src/components/ColorLogo';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  },
  logo: {
    width: 200,
    maxWidth: '100%'
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function SlashScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Box display="flex" justifyContent="center" mb={6}>
          <ColorLogo className={classes.logo} />
        </Box>
        <CircularProgress className={classes.buttonProgress} />
      </div>
    </div>
  );
}

export default SlashScreen;
