import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Box,
  Divider,
  Tab,
  Tabs,
  Container,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Skeleton } from '@material-ui/lab';
import Page from 'src/components/Page';
import { getSetting } from 'src/actions/settingActions';
import { getSyndicate, getStats } from 'src/actions/syndicateActions';
import { getInvestors } from 'src/actions/investorActions';
import { getStaff } from 'src/actions/staffActions';
import { getFundraisers } from 'src/actions/fundraiserActions';
import Header from './Header';
import InvestorInvestments from './InvestorInvestments';
import Interest from './Interest';
import Commission from './Commission';
import FundInvestments from './FundInvestments';
import LoanTransaction from './LoanTransaction';
import Files from './Files';
import EmailLogs from './EmailLogs';
import ManagementFee from './ManagementFee';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const tabs = [
  {
    value: 'investorInvestments',
    label: 'Investor Investments',
    allowed_groups: []
  },
  { value: 'fundInvestments', label: 'Fund Investments', allowed_groups: [] },
  {
    value: 'loanTransactions',
    label: 'Loan Transanctions',
    allowed_groups: ['Fund Manager', 'Loan Analyst', 'Finance']
  },
  {
    value: 'managementFees',
    label: 'Management Fees',
    allowed_groups: ['Fund Manager', 'Finance']
  },
  {
    value: 'interests',
    label: 'Interest Payments',
    allowed_groups: ['Fund Manager', 'Finance']
  },
  { value: 'commissions', label: 'Commission Payments', allowed_groups: [] },
  { value: 'files', label: 'Files', allowed_groups: [] },
  { value: 'emailLogs', label: 'Email Logs', allowed_groups: [] }
];

function SyndicateDetailsView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { syndicate, isLoading } = useSelector(state => state.syndicate);
  const { user } = useSelector(state => state.account);
  const [currentTab, setCurrentTab] = useState('investorInvestments');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getSyndicate(syndicateId));
    dispatch(getStats(syndicateId));
    dispatch(getInvestors());
    if (user.is_admin) {
      dispatch(getStaff());
    }
    dispatch(getFundraisers());
    setCurrentTab('investorInvestments');
  }, [dispatch, syndicateId]);

  return (
    <Page className={classes.root} title="Syndicate Details">
      <Container maxWidth={false}>
        <Header />
        {syndicate && syndicate.syndicate_stage_id <= 2 && (
          <Box mt={3} sx={{ width: '50%' }}>
            <div className={classes.alert}>
              {syndicate && syndicate.is_internal && !syndicate.spv_name && (
                <Alert severity="error" variant="filled">
                  Name of the SPV is required
                </Alert>
              )}
              {syndicate && syndicate.is_internal && !syndicate.trust_deed_dt && (
                <Alert severity="error" variant="filled">
                  Date of the trust deed is required
                </Alert>
              )}
              {syndicate &&
                !syndicate.is_pool &&
                syndicate.business_stream_id == 1 &&
                !syndicate.borrower_spv_name && (
                  <Alert severity="error" variant="filled">
                    Borrower SPV name is required for issuing loan statements
                  </Alert>
                )}
            </div>
          </Box>
        )}
        <Box mt={3}>
          {!syndicate ? (
            <Skeleton />
          ) : (
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              textColor="secondary"
              variant="scrollable"
            >
              {tabs
                .filter(
                  tab =>
                    (tab.allowed_groups.length == 0) |
                    user.group_names.some(g => tab.allowed_groups.includes(g))
                )
                .map(tab => {
                  if (tab.value == 'fundInvestments' && !syndicate.is_pool) {
                    return null;
                  } else if (
                    tab.value == 'loanTransactions' &&
                    (syndicate.is_pool || !syndicate.is_internal)
                  ) {
                    return null;
                  } else if (
                    tab.value == 'managementFees' &&
                    syndicate.syndicate_purpose !== 'Construction'
                  ) {
                    return null;
                  } else {
                    return (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    );
                  }
                })}
            </Tabs>
          )}
        </Box>
        <Divider />
        <Box py={1} pb={2}>
          {currentTab === 'investorInvestments' && <InvestorInvestments />}
          {currentTab === 'fundInvestments' && <FundInvestments />}
          {currentTab === 'interests' && <Interest />}
          {currentTab === 'commissions' && <Commission />}
          {currentTab === 'loanTransactions' && <LoanTransaction />}
          {currentTab === 'managementFees' && <ManagementFee />}
          {currentTab === 'files' && <Files />}
          {currentTab === 'emailLogs' && <EmailLogs />}
        </Box>
      </Container>
    </Page>
  );
}

export default SyndicateDetailsView;
