import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { alpha, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%'
  }
}));

function Chart({ data: dataProp, labels, className, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        label: 'Since Inception',
        backgroundColor: alpha(theme.palette.secondary.main, 0.25),
        data: dataProp.overall,
        // barThickness: 10,
        // maxBarThickness: 40,
        barPercentage: 0.5,
        categoryPercentage: 0.5
      },
      {
        label: 'Current',
        backgroundColor: theme.palette.secondary.main,
        data: dataProp.current,
        // barThickness: 10,
        // maxBarThickness: 40,
        barPercentage: 0.5,
        categoryPercentage: 0.5
      }
    ],
    labels
  };

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    animation: false,
    // cornerRadius: 20,
    legend: {
      display: true,
      position: 'bottom'
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            max: 20,
            maxTicksLimit: 6,
            callback: value => (value > 0 ? `${value}%` : value)
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: true,
        color: theme.palette.text.secondary,
        anchor: 'end',
        align: 'end'
      }
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Bar data={data} options={options} />
    </div>
  );
}

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired
};

export default Chart;
