import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  CircularProgress,
  Dialog,
  Divider,
  SvgIcon,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Popover,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { Avatar } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format, addMonths, subDays, parseISO } from 'date-fns';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import { Plus as PlusIcon } from 'src/components/icons/plus';
import { Clipboard as CopyIcon } from 'src/components/icons/clipboard';
import { CurrencyMask, PercentMask } from 'src/utils/mask';
import { Archive as ArchiveIcon } from 'src/components/icons/archive';
import { Check as CheckIcon } from 'src/components/icons/check';
import { DocumentText as DocumentTextIcon } from 'src/components/icons/document-text';
import { Eye as EyeIcon } from 'src/components/icons/eye';
import { EyeOff as EyeOffIcon } from 'src/components/icons/eye-off';
import { Users as UsersIcon } from 'src/components/icons/users';
import { Save as SaveIcon } from 'src/components/icons/save';
import {
  createCard,
  deleteCard,
  updateCard,
  updateList,
  CLOSE_CARD
} from 'src/actions/kanbanActions';
import { GET_SYNDICATE } from 'src/actions/syndicateActions';
import getInitials from 'src/utils/getInitials';
import { KanbanCardAction } from './kanban-card-action';
import { KanbanChecklist } from './kanban-checklist';
import { KanbanComment } from './kanban-comment';
import { KanbanCommentAdd } from './kanban-comment-add';
import { KanbanMemberAdd } from './kanban-member-add';
import SecurityForm from './SecurityForm';

const filter = createFilterOptions();

export const KanbanCardModal = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { lists } = useSelector(state => state.kanban);
  const { open, cardId, listId } = useSelector(state => state.kanban.openCard);
  const card = useSelector(state => state.kanban.cards.byId[cardId]);
  const list = useSelector(state => state.kanban.lists.byId[listId]);
  const { syndicatePurpose, riskLevel } = useSelector(state => state.setting);
  const { user } = useSelector(state => state.account);
  const { syndicate } = useSelector(state => state.syndicate);
  const { staff } = useSelector(state => state.staff);
  const { securities, isLoading } = useSelector(state => state.security);
  const [openSecurityForm, setOpenSecurityForm] = useState(false);
  const [security, setSecurity] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAddMember = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMember = () => {
    setAnchorEl(null);
  };

  const openMember = Boolean(anchorEl);
  const id = openMember ? 'simple-popover' : undefined;

  const handleClose = () => {
    // setOpen(false);
    dispatch({
      type: CLOSE_CARD,
      payload: { cardId: null, listId: null }
    });
  };

  const handleDetailsUpdate = debounce(async update => {
    try {
      await dispatch(updateCard(card.id, update));
      // toast.success('Card updated!');
    } catch (err) {
      console.error(err.response.data);
      toast.error(JSON.stringify(err.response.data));
    }
  }, 1000);

  const handleSecurityFormOpen = values => {
    setSecurity(values);
    setOpenSecurityForm(true);
  };

  const handleSecurityFormClose = () => {
    setOpenSecurityForm(false);
  };

  const handleDelete = async () => {
    try {
      handleClose();
      await dispatch(deleteCard(cardId, list));
      toast.success('Card archived!');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };

  const handleNewSyndicate = async () => {
    try {
      handleClose();
      await dispatch({
        type: GET_SYNDICATE,
        payload: {
          ...card,
          end_dt: null,
          syndicate_stage_id: 1,
          business_stream_id: 1,
          syndicate_name: card.name,
          is_internal: true,
          is_pool: false,
          staff_id: user.id,
          end_dt:
            card.start_dt && card.term
              ? format(
                  subDays(
                    addMonths(parseISO(card.start_dt, 'yyyy-MM-dd'), card.term),
                    1
                  ),
                  'yyyy-MM-dd'
                )
              : null,
          trust_deed_dt: null,
          default_surcharge: 5,
          payment_freq_id: 1,
          accu_type_id: 1,
          is_public_offering: false,
          entity_id: null,
          assigned_to: [user.id],
          staff_id: user.id,
          entity_type_id: 4,
          interest_collect_freq_id: 2,
          borrower_interest_accu_type_id: 1,
          bank_acct_name: 'Wealth Pi Investor Account',
          bsb: '033-086',
          bank_acct_no: '497118',
          spv_tfn: null,
          spv_abn: null,
          unit_classes: [
            {
              unit_class: 'Ordinary',
              portion: 100,
              is_senior: true,
              premium_cutoff: 500000
            }
          ],
          staged_coupon: [],
          offer: []
        }
      });
      history.push('/app/management/syndicates/create');
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddChecklist = async () => {
    try {
      await dispatch(addChecklist(card.id, 'Untitled Checklist'));
      toast.success('Checklist added!');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };
  if (!cardId) {
    return null;
  }
  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <Box
        sx={{
          py: 4,
          px: 3
        }}
      >
        <SecurityForm
          card={card}
          security={security}
          onClose={handleSecurityFormClose}
          open={openSecurityForm}
          securities={card.security}
          setSecurity={handleDetailsUpdate}
        />
        <Grid container>
          <Grid item container sm={9} xs={12} spacing={1}>
            <Grid item sm={12}>
              <Box ml={1} mb={3}>
                <Typography variant="body2">Assigned To</Typography>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mt: 1
                  }}
                >
                  {card.assigned_to.length > 0 &&
                    card.assigned_to.map(id => (
                      <Avatar key={id} sx={{ mr: 1, width: 32, height: 32 }}>
                        {getInitials(staff.byId[id].full_name)}
                      </Avatar>
                    ))}

                  <Avatar sx={{ width: 32, height: 32 }}>
                    <IconButton onClick={handleAddMember}>
                      <PlusIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Avatar>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                size="small"
                required
                color="primary"
                variant="outlined"
                fullWidth
                InputLabelProps={{ style: { fontSize: 12 } }}
                InputProps={{ style: { fontSize: 12 } }}
                label="Loan Name"
                name="name"
                defaultValue={card.name || ''}
                onChange={event =>
                  handleDetailsUpdate({ name: event.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enAU}>
                <DatePicker
                  value={card.start_dt}
                  onChange={event => {
                    handleDetailsUpdate({
                      start_dt:
                        !event || event.toString() == 'Invalid Date'
                          ? event
                          : format(event, 'yyyy-MM-dd')
                    });
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      label="Expected Settlement Date"
                      fullWidth
                      InputLabelProps={{
                        style: { fontSize: 12 }
                      }}
                      // InputProps={{ style: { fontSize: 12 } }}
                      variant="outlined"
                      name="start_dt"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                name="security"
                value={
                  card.security
                    ? card.security.map(id => {
                        return securities.byId[id];
                      })
                    : []
                }
                options={securities.allIds.map(id => {
                  return securities.byId[id];
                })}
                getOptionLabel={option => option.full_address}
                onChange={(event, newValues) => {
                  const address = newValues.map(value => value.full_address);
                  if (!address.includes('Add New Security')) {
                    handleDetailsUpdate({
                      security: newValues
                        .filter(value => !value.hasOwnProperty('inputValue'))
                        .map(value => value.id)
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      full_address: 'Add New Security'
                    });
                  }
                  return filtered;
                }}
                renderOption={(option, newValues) => {
                  return (
                    <>
                      {option.inputValue ? (
                        <Button
                          fullWidth
                          size="small"
                          onClick={() =>
                            handleSecurityFormOpen({
                              address: newValues.inputValue
                            })
                          }
                        >
                          <SvgIcon fontSize="small" color="primary">
                            <PlusIcon />
                          </SvgIcon>
                          {option.full_address}
                        </Button>
                      ) : (
                        option.full_address
                      )}
                    </>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    label="Security"
                    InputProps={{ style: { fontSize: 12 } }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                required
                label="Risk Level"
                name="risk_level_id"
                onChange={event =>
                  handleDetailsUpdate({ risk_level_id: event.target.value })
                }
                InputLabelProps={{
                  style: { fontSize: 12 }
                }}
                select
                SelectProps={{ native: true, style: { fontSize: 12 } }}
                defaultValue={card.risk_level_id || ''}
                variant="outlined"
              >
                <option></option>
                {riskLevel.allIds.map(id => (
                  <option key={id} value={id}>
                    {riskLevel.byId[id].name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                required
                label="Syndicate Purpose"
                name="syndicate_purpose_id"
                InputLabelProps={{
                  style: { fontSize: 12 }
                }}
                onChange={event =>
                  handleDetailsUpdate({
                    syndicate_purpose_id: event.target.value
                  })
                }
                select
                SelectProps={{ native: true, style: { fontSize: 12 } }}
                defaultValue={card.syndicate_purpose_id || ''}
                variant="outlined"
              >
                <option></option>
                {syndicatePurpose.allIds.map(id => (
                  <option key={id} value={id}>
                    {syndicatePurpose.byId[id].name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                required
                InputLabelProps={{ style: { fontSize: 12 } }}
                label="Loan Amount"
                name="sought_amt"
                InputProps={{
                  inputComponent: CurrencyMask,
                  style: { fontSize: 12 }
                }}
                onChange={event =>
                  handleDetailsUpdate({
                    sought_amt: event
                  })
                }
                defaultValue={card.sought_amt || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                InputLabelProps={{ style: { fontSize: 12 } }}
                label="LVR"
                name="lvr"
                InputProps={{
                  inputComponent: PercentMask,
                  style: { fontSize: 12 }
                }}
                onChange={event =>
                  handleDetailsUpdate({
                    lvr: event
                  })
                }
                defaultValue={card.lvr || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                InputProps={{ style: { fontSize: 12 } }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                label="Loan Term"
                name="term"
                onChange={event =>
                  handleDetailsUpdate({
                    term: event.target.value === '' ? null : event.target.value
                  })
                }
                defaultValue={card.term || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                label="Establishment Fee"
                InputLabelProps={{ style: { fontSize: 12 } }}
                name="est_fee"
                InputProps={{
                  inputComponent: PercentMask,
                  style: { fontSize: 12 }
                }}
                onChange={event =>
                  handleDetailsUpdate({
                    est_fee: event
                  })
                }
                defaultValue={card.est_fee || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                size="small"
                fullWidth
                label="Interest"
                InputLabelProps={{ style: { fontSize: 12 } }}
                name="interest"
                InputProps={{
                  inputComponent: PercentMask,
                  // onChange: handleChange('interest'),
                  style: { fontSize: 12 }
                }}
                onChange={event =>
                  handleDetailsUpdate({
                    interest: event
                  })
                }
                defaultValue={card.interest || ''}
                variant="outlined"
              />
            </Grid>
            {card.syndicate_purpose_id == 2 && (
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  fullWidth
                  label="Line Fee"
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  name="line_fee"
                  InputProps={{
                    inputComponent: PercentMask,
                    style: { fontSize: 12 }
                  }}
                  onChange={event => handleDetailsUpdate({ line_fee: event })}
                  defaultValue={card.line_fee || ''}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item sm={12}>
              <TextField
                defaultValue={card.description || ''}
                InputProps={{ style: { fontSize: 12 } }}
                name="description"
                variant="outlined"
                color="primary"
                fullWidth
                label="Description"
                multiline
                onChange={event =>
                  handleDetailsUpdate({
                    description: event.target.value
                  })
                }
                placeholder="Brief description"
                rows={6}
              />
            </Grid>
            {card.checklists.length > 0 && (
              <>
                <Box my={3}>
                  <Typography variant="h6">Checklist</Typography>
                </Box>
                <div>
                  {card.checklists.map(checklist => (
                    <Box mb={3}>
                      <KanbanChecklist
                        card={card}
                        checklist={checklist}
                        key={checklist.id}
                      />
                    </Box>
                  ))}
                </div>
              </>
            )}
            <Grid item sm={12}>
              <Box my={3}>
                <Typography variant="h6">Comments</Typography>
              </Box>
              <KanbanCommentAdd cardId={card && card.id} />
            </Grid>
            {card.comments.map((comment, index) => (
              <Grid item sm={12} key={index} zeroMinWidth>
                <KanbanComment comment={comment} card={card} />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                px: 3,
                height: '100%'
              }}
            >
              <Typography color="textSecondary" variant="overline">
                Add
              </Typography>
              <Box sx={{ mt: 2 }}>
                <KanbanCardAction
                  disabled
                  icon={<CheckIcon fontSize="small" />}
                  onClick={handleAddChecklist}
                >
                  Checklist
                </KanbanCardAction>
                <KanbanCardAction
                  onClick={handleAddMember}
                  icon={<UsersIcon fontSize="small" />}
                >
                  Members
                </KanbanCardAction>
                <Popover
                  id={id}
                  open={openMember}
                  anchorEl={anchorEl}
                  onClose={handleCloseMember}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <KanbanMemberAdd />
                </Popover>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  color="textSecondary"
                  component="h4"
                  sx={{
                    fontWeight: 600,
                    mb: 2
                  }}
                  variant="overline"
                >
                  Actions
                </Typography>
                <KanbanCardAction
                  icon={<CopyIcon fontSize="small" />}
                  onClick={handleNewSyndicate}
                >
                  New Syndicate
                </KanbanCardAction>
                <KanbanCardAction
                  disabled
                  icon={<ArchiveIcon fontSize="small" />}
                  onClick={handleDelete}
                >
                  Archive
                </KanbanCardAction>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

KanbanCardModal.propTypes = {
  // cardId: PropTypes.number.isRequired,
  // onClose: PropTypes.func,
  open: PropTypes.bool
};

KanbanCardModal.defaultProps = {
  open: false
};
