import React, { useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  TextField,
  makeStyles,
  FormHelperText,
  Grid
} from '@material-ui/core';
import {
  format,
  startOfMonth,
  startOfQuarter,
  endOfMonth,
  endOfQuarter
} from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { addInterestBatch, getStats } from 'src/actions/syndicateActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { PercentMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  appBar: {
    position: 'relative'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}));

function CalcForm({ open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { interestType } = useSelector(state => state.setting);
  const { syndicate } = useSelector(state => state.syndicate);

  const initialValues = {
    syndicate_id: syndicateId,
    period_start_dt:
      syndicate.payment_freq == 'Monthly'
        ? format(startOfMonth(new Date()), 'yyyy-MM-dd')
        : format(startOfQuarter(new Date()), 'yyyy-MM-dd'),
    period_end_dt:
      syndicate.syndicate_stage_id == 3
        ? format(new Date(), 'yyyy-MM-dd')
        : syndicate.payment_freq == 'Monthly'
        ? format(endOfMonth(new Date()), 'yyyy-MM-dd')
        : format(endOfQuarter(new Date()), 'yyyy-MM-dd'),
    interest_type_id: 1,
    default_interest: 0
  };

  const addBatch = (batch, dispatch) =>
    new Promise((resolve, reject) => {
      // do anything here
      dispatch(addInterestBatch(batch));
      resolve();
    });
  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <Formik
        initialValues={initialValues}
        // validationSchema={Yup.object().shape({
        //   interest_type_id: Yup.number().required(),
        //   period_start_dt: Yup.date().required(),
        //   period_end_dt: Yup.date().required()
        // })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            //TODO: to catch error properly
            await dispatch(addInterestBatch(values));
            setStatus({ success: true });
            setSubmitting(true);
            enqueueSnackbar('Interest batch created', {
              variant: 'success'
            });
            onClose();
          } catch (error) {
            enqueueSnackbar('Interest batch created failed', {
              variant: 'error'
            });
            setStatus({ success: false });
            setErrors({ submit: error.response.data });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Card>
              <CardContent>
                {errors.submit && (
                  <Box mb={3}>
                    <FormHelperText error>
                      {errors.submit.err_msg}
                    </FormHelperText>
                  </Box>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Interest Type"
                      name="interest_type_id"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: !!values.interest_type_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={values.interest_type_id || ''}
                      variant="outlined"
                    >
                      {interestType.allIds.map(id => (
                        <option key={id} value={id}>
                          {interestType.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  {values.interest_type_id == 3 && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        required={values.interest_type_id == 3}
                        label="Defaul Interest"
                        name="default_interest"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('default_interest')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.default_interest || ''}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={enAU}
                    >
                      <DatePicker
                        label="Period Start Date"
                        name="period_start_dt"
                        value={values.period_start_dt}
                        onChange={newValue => {
                          setFieldValue(
                            'period_start_dt',
                            !newValue || newValue.toString() == 'Invalid Date'
                              ? newValue
                              : format(newValue, 'yyyy-MM-dd')
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={enAU}
                    >
                      <DatePicker
                        label="Period End Date"
                        value={values.period_end_dt}
                        name="period_end_dt"
                        onChange={newValue => {
                          setFieldValue(
                            'period_end_dt',
                            !newValue || newValue.toString() == 'Invalid Date'
                              ? newValue
                              : format(newValue, 'yyyy-MM-dd')
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Box mt={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting}
                    type="submit"
                    // disabled={loading}
                  >
                    Calculate Interest
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

CalcForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

CalcForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default CalcForm;
