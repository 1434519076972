import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { Archive as ArchiveIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {},
  confirmButton: {
    marginLeft: theme.spacing(2)
  }
}));

function AddEditEventForm({ event, mode, onAdd, onCancel, onDelete, onEdit }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        id: event.id,
        groupId: event.groupId,
        description: event.extendedProps.description || '',
        start: event.start || new Date(),
        title: event.title || '',
        allDay: true,
        backgroundColor: event.backgroundColor,
        borderColor: event.borderColor
      }}
      validationSchema={Yup.object().shape({
        start: Yup.date()
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        const data =
          values.description == 'Settlement'
            ? { start_dt: format(values.start, 'yyyy-MM-dd') }
            : { end_dt: format(values.start, 'yyyy-MM-dd') };
        try {
          axios.patch(`/api/syndicates/${values.groupId}`, data).then(() => {
            onEdit(values);
          });

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Saved changes', {
            variant: 'success'
          });
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box p={3}>
            <Typography
              align="center"
              gutterBottom
              variant="h5"
              color="textPrimary"
            >
              Event Details
            </Typography>
          </Box>
          <Box p={3}>
            <TextField
              fullWidth
              disabled
              label="Title"
              name="title"
              value={values.description}
              variant="outlined"
            />
            <Box mt={2}>
              <TextField
                fullWidth
                disabled
                label="Description"
                name="description"
                value={values.title}
                variant="outlined"
              />
            </Box>
            <Box mt={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enAU}>
                <DatePicker
                  label="Event Date"
                  value={values.start}
                  name="start"
                  onChange={date => setFieldValue('start', date)}
                  renderInput={params => (
                    <TextField {...params} fullWidth variant="outlined" />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Divider />
          <Box p={2} display="flex" alignItems="center">
            <Button
              color="secondary"
              onClick={() => onDelete(values)}
              variant="contained"
              startIcon={<ArchiveIcon />}
            >
              {values.description == 'Repayment'
                ? 'Loan repaid'
                : values.description == 'Settlement'
                ? 'Loan settled'
                : ''}
            </Button>
            <Box flexGrow={1} />
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              color="secondary"
              className={classes.confirmButton}
            >
              Confirm
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AddEditEventForm.propTypes = {
  event: PropTypes.object,
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

AddEditEventForm.defaultProps = {
  event: {},
  onAdd: () => {},
  onCancel: () => {},
  onDelete: () => {},
  onEdit: () => {}
};

export default AddEditEventForm;
