import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Link,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Skeleton } from '@material-ui/lab';
import CircularProgress from './CircularProgress';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    flexShrink: 0,
    height: 56,
    width: 56
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  highlight: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  },
  navigateNextIcon: {
    marginLeft: theme.spacing(1)
  }
}));

function PoolOverview({ className, loading, pool, ...rest }) {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Pool Snapshot" />
      <Divider />
      {loading ? (
        <Skeleton animation="wave" variant="rect" width="100%" height={300} />
      ) : (
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableBody>
                {pool.map(pool => (
                  <TableRow hover key={pool.entity_id}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography variant="h6" color="textPrimary">
                            <Link
                              component={RouterLink}
                              underline="none"
                              to={`/app/management/syndicates/${pool.syndicate_id}/`}
                            >
                              {pool.syndicate_name}
                            </Link>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <span className={classes.subtitle}>
                              {currencyFormatter(pool.received_amt)}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" color="textPrimary">
                        {currencyFormatter(pool.invested_amt)} allocated in SPVs
                      </Typography>
                      <Typography noWrap variant="body2" color="textSecondary">
                        <span
                          className={
                            parseFloat(pool.idle_amt) > 0
                              ? classes.highlight
                              : classes.subtitle
                          }
                        >
                          {currencyFormatter(pool.idle_amt)}
                        </span>{' '}
                        idle
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" color="textPrimary">
                        {currencyFormatter(pool.management_fee_since_inception)}
                        {' man fee since inception'}
                      </Typography>
                      <Typography noWrap variant="body2" color="textSecondary">
                        <span
                          className={
                            parseFloat(pool.management_fee_last_90d) < 0
                              ? classes.highlight
                              : classes.subtitle
                          }
                        >
                          {currencyFormatter(pool.management_fee_last_90d)}
                        </span>{' '}
                        last 90d
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" color="textPrimary">
                        {parseFloat(pool.investor_roi).toString()}% investor
                        return
                      </Typography>
                      <Typography noWrap variant="body2" color="textSecondary">
                        <span
                          className={
                            parseFloat(pool.investor_roi) >
                            parseFloat(pool.pool_roi)
                              ? classes.highlight
                              : classes.subtitle
                          }
                        >
                          {parseFloat(pool.pool_roi).toString()}%
                        </span>{' '}
                        pool return
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography variant="h6" color="textPrimary">
                            {`Max exposure: ${parseFloat(
                              pool.max_exposure
                            ).toString()}%`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <span className={classes.subtitle}>
                              {currencyFormatter(pool.max_exposure_amt)}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography variant="h6" color="textPrimary">
                            {`LVR: ${parseFloat(pool.lvr).toString()}%`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <span className={classes.subtitle}>
                              {`${parseFloat(pool.lvr).toString()}%`}
                            </span>{' '}
                            max per loan
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box mr={2}>
                          <Typography
                            align="right"
                            variant="h6"
                            color="textPrimary"
                          >
                            {pool.lvr_wgt}%
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Weighted LVR
                          </Typography>
                        </Box>
                        <CircularProgress value={parseFloat(pool.lvr_wgt)} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      )}
    </Card>
  );
}

PoolOverview.propTypes = {
  className: PropTypes.string
};

export default PoolOverview;
