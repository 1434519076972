import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
  CircularProgress,
  Backdrop,
  Box,
  Hidden,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import {
  ContactMail as CheckIcon,
  Send as SendIcon,
  ScheduleSend as ScheduleSendIcon
} from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Search as SearchIcon, Menu as MenuIcon } from 'react-feather';
import {
  openSidebar,
  addCampaign,
  updateCampaign
} from 'src/actions/campaignActions';
import ScheduleForm from './ScheduleForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function Toolbar({
  className,
  campaigns,
  onSearch,
  onDeselectAll,
  onSelectAll,
  selectedCampaign,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const sendCampaign = async params => {
    try {
      setSending(true);
      axios
        .post('/api/emailcampaign', params)
        .then(response => {
          toast.success(`Campaign sent to ${response.data.count} investors`);
          setSending(false);
        })
        .catch(error => {
          toast.error('Failed to send campaign');
          setSending(false);
        });
    } catch (error) {
      console.log(error);
      setErrors(JSON.stringify(error.response.data));
      toast.error('Failed to save campaign');
      setSending(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = async () => {
    await dispatch(
      addCampaign({
        ...campaigns.byId[selectedCampaign],
        subject: campaigns.byId[selectedCampaign].subject.concat(' copy')
      })
    );
  };

  const handleArchive = async () => {
    await dispatch(updateCampaign(selectedCampaign, { is_archive: true }));
  };

  const handleUnarchive = async () => {
    await dispatch(updateCampaign(selectedCampaign, { is_archive: false }));
  };

  const handleOpenSidebar = () => {
    dispatch(openSidebar());
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <ScheduleForm
        campaignId={selectedCampaign}
        open={open}
        onClose={handleClose}
      />
      <Hidden mdUp>
        <IconButton onClick={handleOpenSidebar}>
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
      </Hidden>

      {selectedCampaign && (
        <Box display="flex" alignItems="center">
          <Backdrop
            className={classes.backdrop}
            open={sending}
            onClick={() => setSending(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Tooltip title="Send Test Email">
            <IconButton
              onClick={() =>
                sendCampaign({ campaignId: selectedCampaign, test: true })
              }
              style={{ color: 'red' }}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {user.group_names.some(r => ['CRM', 'Fund Manager'].includes(r)) && (
            <>
              <Tooltip title="Schedule Email">
                <IconButton onClick={() => handleOpen()} color="secondary">
                  <ScheduleSendIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Now">
                <IconButton
                  onClick={() => sendCampaign({ campaignId: selectedCampaign })}
                  color="secondary"
                >
                  <SendIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      )}
      <Box flexGrow={1} />
      <Paper
        component={Box}
        display="flex"
        alignItems="center"
        variant="outlined"
        py={0.5}
        px={1}
      >
        <SvgIcon color="action" fontSize="small">
          <SearchIcon />
        </SvgIcon>
        <Input
          onChange={event => {
            onSearch(event.target.value);
          }}
          className={classes.searchInput}
          disableUnderline
          placeholder="Search campaign"
        />
      </Paper>
      <Tooltip title="Copy">
        <IconButton onClick={() => handleCopy()}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Archive">
        <IconButton onClick={() => handleArchive()}>
          <ArchiveIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Unarchive">
        <IconButton onClick={() => handleUnarchive()}>
          <UnarchiveIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Box display="flex" alignItems="center">
        <Tooltip title="Next page">
          <IconButton>
            <KeyboardArrowLeftIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {campaigns.allIds.length > 0 && (
          <>
            <Typography noWrap variant="body2" color="textSecondary">
              1 - {campaigns.allIds.length} of {campaigns.allIds.length}
            </Typography>
            <Tooltip title="Previous page">
              <IconButton>
                <KeyboardArrowRightIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string
  // campaigns: PropTypes.number.isRequired,
  // onDeselectAll: PropTypes.func,
  // onSelectAll: PropTypes.func,
  // selectedCampaigns: PropTypes.number.isRequired
};

Toolbar.defualtProps = {
  onDeselectAll: () => {},
  onSelectAll: () => {}
};

export default Toolbar;
