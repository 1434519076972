/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Grid,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { UserX as DeleteIcon } from 'react-feather';
import {
  countries,
  states,
  titles,
  trustee_types,
  entitySchema
} from 'src/utils/options';
import { NumberMask } from 'src/utils/mask';
import { properName } from 'src/utils/properName';
import { validateABN, validateTFN, validateACN } from 'src/utils/validation';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  fieldset: {
    border: 0
  }
}));

function TrustForm({ className, entity_type_id, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { application } = useSelector(state => state.application);
  const { trustType } = useSelector(state => state.setting);

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const initialValues = application.entity_id
    ? application.entity_id.entity_details
    : entitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        trust: Yup.object().shape({
          trust_name: Yup.string()
            .min(3, 'Must be at least 3 characters')
            .max(100)
            .required('Required'),
          address_1: Yup.string().max(38, 'Must be less than 38 characters'),
          address_2: Yup.string()
            .max(38, 'Must be less than 38 characters')
            .nullable()
        })
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          if (entity_type_id == 3) {
            values = {
              ...values,
              trust: {
                ...values.trust,
                trust_type: '2'
              }
            };
          }
          await dispatch(
            updateApplication(application.id, {
              ...application,
              entity_id: {
                ...application.entity_id,
                investor_id: application.investor_id.id,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident: values.trust.country == 'Australia',
                is_us_tax_resident: values.trust.country == 'United States',
                account_name: properName(values.trust.trust_name),
                address_1: properName(values.trust.address_1 || ''),
                address_2: properName(values.trust.address_2 || ''),
                city: properName(values.trust.city || ''),
                state: values.trust.state || '',
                postcode: values.trust.postcode,
                country: values.trust.country
              }
            })
          );
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: JSON.stringify(err.response.data) });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <fieldset className={classes.fieldset}>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box mt={2} mb={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                TRUST DETAILS
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled={application.entity_id !== null}
                  label="Trust Name"
                  name="trust.trust_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.trust.trust_name || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              {entity_type_id !== 3 && (
                <>
                  <Grid item md={8} xs={12}>
                    <TextField
                      fullWidth
                      disabled={application.entity_id !== null}
                      required
                      label="Trust Type"
                      name="trust.trust_type"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.trust.trust_type || ''}
                      variant="outlined"
                      size="small"
                    >
                      <option></option>
                      {trustType.allIds.map(id => (
                        <option key={id} value={id}>
                          {trustType.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {values.trust.trust_type == 3 && (
                      <TextField
                        fullWidth
                        disabled={application.entity_id !== null}
                        required={values.trust.trust_type == 3}
                        label="Please Specify Fund Number"
                        name="trust.fund_number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.trust.fund_number || ''}
                        variant="outlined"
                        size="small"
                      />
                    )}
                    {values.trust.trust_type == 4 && (
                      <TextField
                        fullWidth
                        disabled={application.entity_id !== null}
                        required
                        label="Please Specify ARSN"
                        name="trust.arsn"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.trust.arsn || ''}
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  required
                  label="Trustee Type"
                  name="trust.trustee_type"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.trust.trustee_type || ''}
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {trustee_types.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  required
                  label="Country of Establishment"
                  name="trust.tax_country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.trust.tax_country || ''}
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enAU}
                >
                  <DatePicker
                    label="Date of Trust Deed"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    name="trust.trust_deed_dt"
                    value={values.trust.trust_deed_dt || null}
                    onChange={newValue => {
                      setFieldValue(
                        'trust.trust_deed_dt',
                        !newValue || newValue.toString() == 'Invalid Date'
                          ? newValue
                          : format(newValue, 'yyyy-MM-dd')
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        disabled={application.entity_id !== null}
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="ABN"
                  disabled={application.entity_id !== null}
                  // required={values.trust.tax_country == 'Australia'}
                  helperText={
                    values.trust.abn > 0 &&
                    !validateABN(values.trust.abn) &&
                    'Invalid ABN'
                  }
                  error={values.trust.abn > 0 && !validateABN(values.trust.abn)}
                  name="trust.abn"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('trust.abn'),
                    maxLength: 11
                  }}
                  value={values.trust.abn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required={values.trust.tax_country == 'Australia'}
                  disabled={application.entity_id !== null}
                  label="TFN or Exemption number"
                  name="trust.tfn"
                  helperText={
                    values.trust.tfn > 0 &&
                    !validateTFN(values.trust.tfn) &&
                    'Invalid TFN'
                  }
                  error={values.trust.tfn > 0 && !validateTFN(values.trust.tfn)}
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('trust.tfn'),
                    maxLength: 9
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.trust.tfn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Address Line 1"
                  name="trust.address_1"
                  onChange={handleChange}
                  value={values.trust.address_1 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 38 }}
                  label="Address Line 2"
                  name="trust.address_2"
                  onChange={handleChange}
                  value={values.trust.address_2 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="City"
                  name="trust.city"
                  onChange={handleChange}
                  value={values.trust.city || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  freeSolo
                  size="small"
                  name="trust.state"
                  value={values.trust.state || ''}
                  options={states}
                  onInputChange={(event, newValue) => {
                    setFieldValue('trust.state', newValue ? newValue : '');
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="State/Province"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Postcode"
                  name="trust.postcode"
                  onChange={handleChange}
                  value={values.trust.postcode || ''}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Country"
                  name="trust.country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.trust.country || ''}
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {values.trust.trust_type == 5 && (
              <FieldArray
                name="trust.beneficiaries"
                subscription={{}} // This is required so that the whole array does not re-render on any change.
                render={({ insert, remove, push }) => (
                  <Box mt={2}>
                    <Box mb={1}>
                      <Typography variant="h6" style={{ fontWeight: 600 }}>
                        Trust Beneficiaries
                      </Typography>
                      <Typography variant="caption">
                        Please detail all beneficiaries who, through one or more
                        shareholdings, own more than 25% of the trust's issued
                        capital.
                      </Typography>
                    </Box>
                    {values.trust.beneficiaries.map((individual, index) => (
                      <div key={index}>
                        <Grid container spacing={1}>
                          <Grid item md={11} xs={10}>
                            <TextField
                              fullWidth
                              required
                              label="Full Name (and address)"
                              name={`trust.beneficiaries.${index}`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.trust.beneficiaries[index] || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          {index > 0 && (
                            <Grid item md={1} xs={2}>
                              <Tooltip title="Delete beneficiary">
                                <IconButton
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <SvgIcon>
                                    <DeleteIcon />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    ))}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          push('');
                        }}
                      >
                        Add Beneficiary
                      </Button>
                    </Box>
                  </Box>
                )}
              />
            )}
            <Box mt={2} mb={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                TRUSTEE DETAILS
              </Typography>
            </Box>
            {values.trust.trustee_type == 'Individual' && (
              <FieldArray
                name="trust.trustee_individuals"
                subscription={{}} // This is required so that the whole array does not re-render on any change.
                render={({ insert, remove, push }) => (
                  <>
                    {values.trust.trustee_individuals.map((trust, index) => (
                      <div key={index}>
                        <Box
                          mb={1}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 600 }}
                            gutterBottom
                          >
                            Individual Trustee {index + 1}
                          </Typography>
                          {index > 0 && (
                            <Tooltip title="Delete individual">
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <SvgIcon>
                                  <DeleteIcon />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                        <Box mb={1}>
                          <Grid container spacing={1}>
                            <Grid item md={3} xs={12}>
                              <TextField
                                fullWidth
                                required={
                                  values.trust.trustee_type == 'Individual'
                                }
                                select
                                label="Title"
                                name={`trust.trustee_individuals.${index}.title`}
                                onChange={handleChange}
                                SelectProps={{
                                  native: true
                                }}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .title || ''
                                }
                                variant="outlined"
                                size="small"
                              >
                                <option></option>
                                {titles.map(option => (
                                  <option key={option.code} value={option.code}>
                                    {option.name}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <TextField
                                fullWidth
                                required={
                                  values.trust.trustee_type == 'Individual'
                                }
                                label="First Name"
                                name={`trust.trustee_individuals.${index}.first_name`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .first_name || ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <TextField
                                fullWidth
                                required={
                                  values.trust.trustee_type == 'Individual'
                                }
                                label="Last Name"
                                name={`trust.trustee_individuals.${index}.last_name`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .last_name || ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enAU}
                              >
                                <DatePicker
                                  label="Date of Birth"
                                  openTo="year"
                                  views={['year', 'month', 'day']}
                                  name={`trust.trustee_individuals.${index}.birth_dt`}
                                  value={
                                    values.trust.trustee_individuals[index]
                                      .birth_dt || null
                                  }
                                  onChange={newValue => {
                                    setFieldValue(
                                      `trust.trustee_individuals.${index}.birth_dt`,

                                      !newValue ||
                                        newValue.toString() == 'Invalid Date'
                                        ? newValue
                                        : format(newValue, 'yyyy-MM-dd')
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      required={
                                        values.trust.trustee_type ==
                                        'Individual'
                                      }
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                required={
                                  values.trust.trustee_type == 'Individual'
                                }
                                label="Country of Residency for Tax Purpose"
                                name={`trust.trustee_individuals.${index}.tax_country`}
                                onChange={handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .tax_country || ''
                                }
                                variant="outlined"
                                size="small"
                              >
                                <option></option>
                                {countries.map((country, index) => (
                                  <option key={index} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.trust.trustee_individuals[index]
                                        .is_pep || false
                                    }
                                    onChange={handleChange}
                                    size="small"
                                    name={`trust.trustee_individuals.${index}.is_pep`}
                                  />
                                }
                                label="Politically Exposed Person"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      values.trust.trustee_individuals[index]
                                        .is_us_tax_resident || false
                                    }
                                    onChange={handleChange}
                                    value={
                                      values.trust.trustee_individuals[index]
                                        .is_us_tax_resident || false
                                    }
                                    name={`trust.trustee_individuals.${index}.is_us_tax_resident`}
                                  />
                                }
                                label="US Citizen / US Tax Resident"
                              />
                            </Grid>
                            {values.trust.trustee_individuals[index]
                              .is_us_tax_resident && (
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  required={
                                    values.trust.trustee_individuals[index]
                                      .is_us_tax_resident
                                  }
                                  label="TIN"
                                  name={`trust.trustee_individuals.${index}.tin`}
                                  onChange={handleChange}
                                  value={
                                    values.trust.trustee_individuals[index]
                                      .tin || ''
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            )}
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                required={
                                  values.trust.trustee_type == 'Individual'
                                }
                                type="email"
                                label="Email"
                                name={`trust.trustee_individuals.${index}.email`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .email || ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="Mobile"
                                name={`trust.trustee_individuals.${index}.mobile`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .mobile || ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                required={
                                  values.trust.trustee_type == 'Individual'
                                }
                                label="Residential Address (cannot be a PO Box address)"
                                name={`trust.trustee_individuals.${index}.address`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.trust.trustee_individuals[index]
                                    .address || ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    ))}
                    <Box>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          push({});
                        }}
                      >
                        Add Individual Trustee
                      </Button>
                    </Box>
                  </>
                )}
              />
            )}
            {values.trust.trustee_type == 'Corporate' && (
              <>
                <Box mb={2}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Corporate Trustee
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      required={values.trust.trustee_type == 'Corporate'}
                      label="Company Name"
                      name="trust.trustee_company.business_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.trust.trustee_company.business_name || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      required={values.trust.trustee_type == 'Corporate'}
                      label="Incorporated Country"
                      name="trust.trustee_company.tax_country"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.trust.trustee_company.tax_country || ''}
                      variant="outlined"
                      size="small"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      required={
                        values.trust.trustee_type == 'Corporate' &&
                        values.trust.trustee_company.tax_country == 'Australia'
                      }
                      helperText={
                        values.trust.trustee_type == 'Corporate' &&
                        values.trust.trustee_company.tax_country ==
                          'Australia' &&
                        values.trust.trustee_company.acn > 0 &&
                        !validateACN(values.trust.trustee_company.acn) &&
                        'Invalid ACN'
                      }
                      error={
                        values.trust.trustee_type == 'Corporate' &&
                        values.trust.trustee_company.tax_country ==
                          'Australia' &&
                        values.trust.trustee_company.acn > 0 &&
                        !validateACN(values.trust.trustee_company.acn)
                      }
                      label="ACN"
                      name="trust.trustee_company.acn"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: NumberMask,
                        onChange: handleChange('trust.trustee_company.acn'),
                        maxLength: 11
                      }}
                      value={values.trust.trustee_company.acn || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="ABN"
                      name="trust.trustee_company.abn"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: NumberMask,
                        onChange: handleChange('trust.trustee_company.abn'),
                        maxLength: 11
                      }}
                      value={values.trust.trustee_company.abn || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      required={values.trust.trustee_type == 'Corporate'}
                      label="Registered Office (cannot be a PO Box Address)"
                      name="trust.trustee_company.rego_address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.trust.trustee_company.rego_address || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            values.trust.trustee_company
                              .business_addr_same_as_rego_addr || false
                          }
                          size="small"
                          onChange={handleChange}
                          name="trust.trustee_company.business_addr_same_as_rego_addr"
                        />
                      }
                      label="Principal place of business is the same as registration address"
                    />
                  </Grid>
                  {!values.trust.trustee_company
                    .business_addr_same_as_rego_addr && (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Principal Place of Business (cannot be a PO Box address)"
                        name="trust.trustee_company.business_address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={
                          values.trust.trustee_company.business_address || ''
                        }
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  )}
                </Grid>
                <FieldArray
                  name="trust.trustee_company.beneficial_owners"
                  subscription={{}} // This is required so that the whole array does not re-render on any change.
                  render={({ insert, remove, push }) => (
                    <>
                      <Box mt={2} mb={2}>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 600 }}
                          gutterBottom
                        >
                          Beneficial Owners of the Trustee Company
                        </Typography>
                        <Typography variant="caption">
                          Please list Full Name/s or Class of ALL Beneficiaries
                          (if individual beneficial owners are not named) who
                          through one or more shareholdings own more than 25% of
                          the company’s issued capital
                        </Typography>
                      </Box>
                      {values.trust.trustee_company.beneficial_owners.map(
                        (beneficial_owner, index) => (
                          <div key={index}>
                            <Grid container spacing={1}>
                              <Grid item md={11} xs={10}>
                                <TextField
                                  fullWidth
                                  required
                                  label="Full Name (and address)"
                                  name={`trust.trustee_company.beneficial_owners.${index}`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.trust.trustee_company
                                      .beneficial_owners[index] || ''
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                              {index > 0 && (
                                <Grid item md={1} xs={2}>
                                  <Tooltip title="Delete beneficial owner">
                                    <IconButton
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <SvgIcon>
                                        <DeleteIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        )
                      )}
                      <Box mt={1}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            push('');
                          }}
                        >
                          Add Beneficial Owner
                        </Button>
                      </Box>
                    </>
                  )}
                />
                <FieldArray
                  name="trust.trustee_company.directors"
                  subscription={{}} // This is required so that the whole array does not re-render on any change.
                  render={({ insert, remove, push }) => (
                    <>
                      <Box mt={2} mb={2}>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 600 }}
                          gutterBottom
                        >
                          Directors of the Trustee Company
                        </Typography>
                        <Typography variant="caption">
                          If the company is propriety/private please provide
                          full names of all Directors
                        </Typography>
                      </Box>
                      {values.trust.trustee_company.directors.map(
                        (director, index) => (
                          <div key={index}>
                            <Grid container spacing={1}>
                              <Grid item md={11} xs={10}>
                                <TextField
                                  fullWidth
                                  required={
                                    values.trust.trustee_type == 'Corporate'
                                  }
                                  label="Full Name"
                                  name={`trust.trustee_company.directors.${index}`}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.trust.trustee_company.directors[
                                      index
                                    ] || ''
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                              {index > 0 && (
                                <Grid item md={1} xs={2}>
                                  <Tooltip title="Delete director">
                                    <IconButton
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    >
                                      <SvgIcon>
                                        <DeleteIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        )
                      )}
                      <Box mt={1}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            push('');
                          }}
                        >
                          Add Director
                        </Button>
                      </Box>
                    </>
                  )}
                />

                <FieldArray
                  name="trust.trustee_company.signatories"
                  subscription={{}} // This is required so that the whole array does not re-render on any change.
                  render={({ insert, remove, push }) => (
                    <>
                      <Box mt={2} mb={2}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                          color="secondary"
                          gutterBottom
                        >
                          AUTHORISED SIGNATORIES
                        </Typography>
                        <Typography variant="caption">
                          This Section is to be completed by the Authorised
                          Signatories on the Account. With the exception of
                          where there is a Sole Director (who must be the
                          Authorised Signatory) these must be at least two
                          Authorised Signatories on the account (eg two
                          Directors or a Director and a Company Secretary). You
                          must nominate at least one Director as an Authorised
                          Signatory.
                        </Typography>
                      </Box>
                      {values.trust.trustee_company.signatories.map(
                        (signatory, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                color="textPrimary"
                              >
                                Signatory {index + 1}
                              </Typography>
                              {index > 0 && (
                                <Tooltip title="Delete signatory">
                                  <IconButton
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <SvgIcon>
                                      <DeleteIcon />
                                    </SvgIcon>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                            <Box mt={1}>
                              <Grid container spacing={1}>
                                <Grid item md={3} xs={12}>
                                  <TextField
                                    fullWidth
                                    required={
                                      values.trust.trustee_type ==
                                        'Corporate' &&
                                      !values.trust.trustee_company.is_regulated
                                    }
                                    select
                                    label="Title"
                                    name={`trust.trustee_company.signatories.${index}.title`}
                                    onChange={handleChange}
                                    SelectProps={{
                                      native: true
                                    }}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].title || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                  >
                                    <option></option>
                                    {titles.map(option => (
                                      <option
                                        key={option.code}
                                        value={option.code}
                                      >
                                        {option.name}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <TextField
                                    required={
                                      values.trust.trustee_type ==
                                        'Corporate' &&
                                      !values.trust.trustee_company.is_regulated
                                    }
                                    fullWidth
                                    label="First Name"
                                    name={`trust.trustee_company.signatories.${index}.first_name`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].first_name || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <TextField
                                    fullWidth
                                    required={
                                      values.trust.trustee_type ==
                                        'Corporate' &&
                                      !values.trust.trustee_company.is_regulated
                                    }
                                    label="Last Name"
                                    name={`trust.trustee_company.signatories.${index}.last_name`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].last_name || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={enAU}
                                  >
                                    <DatePicker
                                      label="Date of Birth"
                                      openTo="year"
                                      views={['year', 'month', 'day']}
                                      name={`trust.trustee_company.signatories.${index}.birth_dt`}
                                      value={
                                        values.trust.trustee_company
                                          .signatories[index].birth_dt || null
                                      }
                                      onChange={newValue => {
                                        setFieldValue(
                                          `trust.trustee_company.signatories.${index}.birth_dt`,

                                          !newValue ||
                                            newValue.toString() ==
                                              'Invalid Date'
                                            ? newValue
                                            : format(newValue, 'yyyy-MM-dd')
                                        );
                                      }}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          required={
                                            values.trust.trustee_type ==
                                              'Corporate' &&
                                            !values.trust.trustee_company
                                              .is_regulated
                                          }
                                          fullWidth
                                          variant="outlined"
                                          size="small"
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    fullWidth
                                    required={
                                      values.trust.trustee_type ==
                                        'Corporate' &&
                                      !values.trust.trustee_company.is_regulated
                                    }
                                    label="Country of Residency for Tax Purposes"
                                    name={`trust.trustee_company.signatories.${index}.tax_country`}
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].tax_country || ''
                                    }
                                    size="small"
                                    variant="outlined"
                                  >
                                    <option></option>
                                    {countries.map((country, index) => (
                                      <option key={index} value={country.name}>
                                        {country.name}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.trust.trustee_company
                                            .signatories[index].is_pep || false
                                        }
                                        onChange={handleChange}
                                        size="small"
                                        name={`trust.trustee_company.signatories.${index}.is_pep`}
                                      />
                                    }
                                    label="Politically Exposed Person"
                                  />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.trust.trustee_company
                                            .signatories[index]
                                            .is_us_tax_resident || false
                                        }
                                        onChange={handleChange}
                                        size="small"
                                        name={`trust.trustee_company.signatories.${index}.is_us_tax_resident`}
                                      />
                                    }
                                    label="US Citizen / US Tax Resident"
                                  />
                                </Grid>
                                {values.trust.trustee_company.signatories[index]
                                  .is_us_tax_resident && (
                                  <Grid item md={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      required={
                                        values.trust.trustee_type ==
                                          'Corporate' &&
                                        values.trust.trustee_company
                                          .signatories[index]
                                          .is_us_tax_resident &&
                                        !values.trust.trustee_company
                                          .is_regulated
                                      }
                                      label="TIN"
                                      name={`trust.trustee_company.signatories.${index}.tin`}
                                      onChange={handleChange}
                                      value={
                                        values.trust.trustee_company
                                          .signatories[index].tin || ''
                                      }
                                      variant="outlined"
                                      size="small"
                                    />
                                  </Grid>
                                )}
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    fullWidth
                                    required={
                                      values.trust.trustee_type ==
                                        'Corporate' &&
                                      !values.trust.trustee_company.is_regulated
                                    }
                                    type="email"
                                    label="Email"
                                    name={`trust.trustee_company.signatories.${index}.email`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].email || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <TextField
                                    fullWidth
                                    label="Mobile"
                                    name={`trust.trustee_company.signatories.${index}.mobile`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].mobile || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <TextField
                                    fullWidth
                                    required={
                                      values.trust.trustee_type ==
                                        'Corporate' &&
                                      !values.trust.trustee_company.is_regulated
                                    }
                                    label="Residential Address"
                                    name={`trust.trustee_company.signatories.${index}.address`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.trust.trustee_company.signatories[
                                        index
                                      ].address || ''
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        )
                      )}
                      <Box mt={2}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            push({
                              first_name: '',
                              last_name: ''
                            });
                          }}
                        >
                          Add Signatory
                        </Button>
                      </Box>
                    </>
                  )}
                />
              </>
            )}
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button variant="contained" onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

TrustForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default TrustForm;
