import produce from 'immer';
import _ from 'lodash';
import {
  GET_SETTING,
  GET_SYNDICATETYPE,
  GET_SYNDICATESTAGE,
  GET_SYNDICATEPURPOSE,
  GET_BUSINESSSTREAM,
  GET_INVESTMENTSTATUS
} from 'src/actions/settingActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  riskLevel: {
    byId: {},
    allIds: []
  },
  syndicatePurpose: {
    byId: {},
    allIds: []
  },
  syndicateStage: {
    byId: {},
    allIds: []
  },
  businessStream: {
    byId: {},
    allIds: []
  },
  investmentStatus: {
    byId: {},
    allIds: []
  },
  entityType: {
    byId: {},
    allIds: []
  },
  paymentFreq: {
    byId: {},
    allIds: []
  },
  accrualType: {
    byId: {},
    allIds: []
  },
  transactionType: {
    byId: {},
    allIds: []
  },
  loanTransactionType: {
    byId: {},
    allIds: []
  },
  state: {
    byId: {},
    allIds: []
  },
  interestType: {
    byId: {},
    allIds: []
  },
  commissionType: {
    byId: {},
    allIds: []
  },
  documentType: {
    byId: {},
    allIds: []
  },
  trustType: {
    byId: {},
    allIds: []
  },
  campaignLabel: {
    byId: {},
    allIds: []
  }
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTING: {
      const { settings } = action.payload;

      return produce(state, draft => {
        //TODO delete in settings Reducer
        draft.riskLevel.byId = objFromArray(settings.riskLevel);
        draft.riskLevel.allIds = Object.keys(draft.riskLevel.byId).map(Number);
        draft.syndicatePurpose.byId = objFromArray(settings.syndicatePurpose);
        draft.syndicatePurpose.allIds = Object.keys(
          draft.syndicatePurpose.byId
        ).map(Number);
        draft.syndicateStage.byId = objFromArray(settings.syndicateStage);
        draft.syndicateStage.allIds = Object.keys(
          draft.syndicateStage.byId
        ).map(Number);
        draft.businessStream.byId = objFromArray(settings.businessStream);
        draft.businessStream.allIds = Object.keys(
          draft.businessStream.byId
        ).map(Number);
        draft.investmentStatus.byId = objFromArray(settings.investmentStatus);
        draft.investmentStatus.allIds = Object.keys(
          draft.investmentStatus.byId
        ).map(Number);
        draft.entityType.byId = objFromArray(settings.entityType);
        draft.entityType.allIds = Object.keys(draft.entityType.byId).map(
          Number
        );
        draft.paymentFreq.byId = objFromArray(settings.paymentFreq);
        draft.paymentFreq.allIds = Object.keys(draft.paymentFreq.byId).map(
          Number
        );
        draft.accrualType.byId = objFromArray(settings.accrualType);
        draft.accrualType.allIds = Object.keys(draft.accrualType.byId).map(
          Number
        );
        draft.transactionType.byId = objFromArray(settings.transactionType);
        draft.transactionType.allIds = Object.keys(
          draft.transactionType.byId
        ).map(Number);
        draft.loanTransactionType.byId = objFromArray(
          settings.loanTransactionType
        );
        draft.loanTransactionType.allIds = Object.keys(
          draft.loanTransactionType.byId
        ).map(Number);
        draft.interestType.byId = objFromArray(settings.interestType);
        draft.interestType.allIds = Object.keys(draft.interestType.byId).map(
          Number
        );
        draft.commissionType.byId = objFromArray(settings.commissionType);
        draft.commissionType.allIds = Object.keys(
          draft.commissionType.byId
        ).map(Number);
        draft.documentType.byId = objFromArray(settings.documentType);
        draft.documentType.allIds = Object.keys(draft.documentType.byId).map(
          Number
        );
        draft.trustType.byId = objFromArray(settings.trustType);
        draft.trustType.allIds = Object.keys(draft.trustType.byId).map(Number);
        draft.campaignLabel.byId = objFromArray(settings.campaignLabel);
        draft.campaignLabel.allIds = Object.keys(draft.campaignLabel.byId).map(
          Number
        );

        draft.isLoading = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default settingReducer;
