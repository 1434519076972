import produce from 'immer';
import _ from 'lodash';
import {
  GET_SECURITIES,
  ADD_SECURITY,
  UPDATE_SECURITY
} from 'src/actions/securityActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  securities: {
    byId: {},
    allIds: []
  }
};

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECURITIES: {
      const securities = action.payload;

      return produce(state, draft => {
        draft.securities.byId = objFromArray(securities);
        draft.securities.allIds = Object.keys(draft.securities.byId);
        draft.isLoading = false;
      });
    }

    case ADD_SECURITY: {
      const security = action.payload;

      return produce(state, draft => {
        draft.securities.byId[security.id] = security;
        draft.securities.allIds.push(security.id);
      });
    }

    case UPDATE_SECURITY: {
      const security = action.payload;

      return produce(state, draft => {
        _.merge(draft.securities.byId[security.id], security);
      });
    }

    default: {
      return state;
    }
  }
};

export default securityReducer;
