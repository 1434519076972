import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { changePassword, logout } from 'src/actions/accountActions';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Security({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        old_password: '',
        new_password1: '',
        new_password2: ''
      }}
      validationSchema={Yup.object().shape({
        old_password: Yup.string()
          .min(7, 'Must be at least 8 characters')
          .max(255)
          .required('Required'),
        new_password1: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
          )
          .max(14)
          .required('Required'),
        new_password2: Yup.string()
          .oneOf([Yup.ref('new_password1'), null], 'Passwords must match')
          .required('Required')
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          // Make API request
          await dispatch(changePassword(values));
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          await dispatch(logout());
        } catch (error) {
          toast.error('Something is wrong, please try later');
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader title="Change Password" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={4} sm={4} xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Current Password"
                    name="old_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.old_password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.new_password1 && errors.new_password1
                    )}
                    fullWidth
                    helperText={touched.new_password1 && errors.new_password1}
                    label="New Password"
                    name="new_password1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.new_password1}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.new_password2 && errors.new_password2
                    )}
                    fullWidth
                    helperText={touched.new_password2 && errors.new_password2}
                    label="Password Confirmation"
                    name="new_password2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.new_password2}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </CardContent>
            <Divider />
            <Box p={2} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Change Password
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

Security.propTypes = {
  className: PropTypes.string
};

export default Security;
