import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addInvestor, updateInvestor } from 'src/actions/investorActions';
import { countries, states, titles, language } from 'src/utils/options';

const useStyles = makeStyles(() => ({
  root: {}
}));

function InvestorForm({ className, investor, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { investorId } = useParams();
  const { riskLevel, isLoading: settingLoading } = useSelector(
    state => state.setting
  );
  const { fundraisers, isLoading: fundraiserLoading } = useSelector(
    state => state.fundraiser
  );
  const { user } = useSelector(state => state.account);
  const { staff, isLoading: staffLoading } = useSelector(state => state.staff);
  const newInvestorId = useSelector(
    state => state.investor.investors.allIds[0]
  );
  const [redict, setRedirect] = useState(false);

  useEffect(() => {
    if (redict) {
      if (investorId) {
        history.push(`/app/management/investors/${investorId}`);
      } else if (newInvestorId) {
        history.push(`/app/management/investors/${newInvestorId}`);
      }
    }
  }, [redict]);

  const initialValues = investor
    ? investor
    : {
        display_name: '',
        contact_first_name: '',
        contact_last_name: '',
        primary_email: '',
        other_emails: '',
        accept_marketing: true,
        risk_preferences: [],
        staff_id: user.id,
        fundraiser_id: null,
        accountant_letter_dt: null
      };
  if (settingLoading || fundraiserLoading || (user.is_admin && staffLoading)) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({
      //   display_name: Yup.string()
      //     .max(255)
      //     .required('Display name is required'),
      //   primary_email: Yup.string()
      //     .email()
      //     .nullable(),
      //   address_1: Yup.string()
      //     .max(38, 'Must be less than 38 characters')
      //     .nullable(),
      //   address_2: Yup.string()
      //     .max(38, 'Must be less than 38 characters')
      //     .nullable()
      // })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          // Make API request
          if (investor) {
            await dispatch(updateInvestor(investor.id, values));
          } else {
            await dispatch(addInvestor(values));
          }
          setStatus({ success: true });
          setSubmitting(true);
          enqueueSnackbar(investor ? 'Investor updated' : 'Investor Created', {
            variant: 'success'
          });
          setRedirect(true);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: JSON.stringify(error.response.data) });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              {errors.submit && (
                <Box mb={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Display Name"
                    name="display_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.display_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Title"
                    name={`title`}
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                    value={values.title || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {titles.map(option => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Contact First Name"
                    name="contact_first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contact_first_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Contact Last Name"
                    name="contact_last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contact_last_name || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Primary Email"
                    name="primary_email"
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.primary_email || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Other Emails"
                    name="other_emails"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.other_emails || ''}
                    variant="outlined"
                  />
                </Grid>
                {user.is_admin && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      required
                      label="Managing Staff"
                      name="staff_id"
                      InputLabelProps={{
                        shrink: !!values.staff_id
                      }}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.staff_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {staff.allIds
                        .filter(id => staff.byId[id].is_employee)
                        .map(id => (
                          <option key={id} value={id}>
                            {staff.byId[id].full_name}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Fund Raiser"
                    name="fundraiser_id"
                    InputLabelProps={{
                      shrink: !!values.fundraiser_id
                    }}
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.fundraiser_id || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {fundraisers.allIds.map(id => (
                      <option key={id} value={id}>
                        {fundraisers.byId[id].fundraiser_name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography variant="h5" color="textPrimary">
                    Accept Markteting Campaigns
                  </Typography>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          No
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={values.accept_marketing || false}
                          color="secondary"
                          edge="start"
                          name="accept_marketing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          Yes
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {user.is_admin && (
                  <Grid item md={3} xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      Is company managed SPV
                    </Typography>
                    <Typography component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography variant="body2" color="textSecondary">
                            No
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={values.is_self || false}
                            color="secondary"
                            edge="start"
                            name="is_self"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" color="textSecondary">
                            Yes
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                )}
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Note"
                    name="note"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.note || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    required
                    select
                    label="Language Preference"
                    name="language"
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                    value={values.language || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {language.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Autocomplete
                    multiple
                    name="risk_preferences"
                    value={
                      values.risk_preferences
                        ? values.risk_preferences.map(id => {
                            return riskLevel.byId[id];
                          })
                        : []
                    }
                    options={riskLevel.allIds.map(id => {
                      return riskLevel.byId[id];
                    })}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValues) => {
                      setFieldValue(
                        'risk_preferences',
                        newValues.map(value => value.id) || []
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Risk Preferences"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 38 }}
                    label="Postal Address Line 1"
                    name="address_1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address_1 || ''}
                    variant="outlined"
                    // size="small"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 38 }}
                    label="Postal Address Line 2"
                    name="address_2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address_2 || ''}
                    variant="outlined"
                    // size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city || ''}
                    variant="outlined"
                    // size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                    freeSolo
                    // size="small"
                    name="state"
                    value={values.state || ''}
                    options={states}
                    onInputChange={(event, newValue) => {
                      setFieldValue('state', newValue ? newValue : '');
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="State/Province"
                        variant="outlined"
                        // size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="postcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postcode || ''}
                    // size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.country || ''}
                    // size="small"
                    variant="outlined"
                  >
                    <option></option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {investor ? 'Update investor' : 'Create investor'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

InvestorForm.propTypes = {
  className: PropTypes.string
};

export default InvestorForm;
