import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  SvgIcon,
  Card,
  CardContent,
  Grid,
  Divider
} from '@material-ui/core';
import { Copy as CopyIcon } from 'react-feather';
import { MonetizationOnRounded as MoneyIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 60,
    width: 60
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
  badgeIcon: {
    marginRight: theme.spacing(1)
  }
}));

const interestType = [
  {
    id: 'interest',
    name: 'Interest'
  },
  {
    id: 'line fee',
    name: 'Line Fee'
  },
  {
    id: 'default interest',
    name: 'Default Interest'
  }
];

function CalculatorForm({ open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { syndicateId } = useParams();
  const { syndicate } = useSelector(state => state.syndicate);
  const { accrualType } = useSelector(state => state.setting);
  const [loading, setLoading] = useState(false);
  const [calculated_interest, setCalculatedInterest] = useState(null);

  const initialValues = {
    syndicate_id: syndicateId,
    accu_type_id: 1,
    interest_type: null,
    term: null,
    start_dt: null,
    end_dt: null
  };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              //   interest: Yup.number().required('Interest rate is required')
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                setLoading(true);
                axios
                  .post('/api/loaninterestcalc', values)
                  .then(response => {
                    setCalculatedInterest(response.data.msg);
                    setLoading(false);
                  })
                  .catch(error => {
                    enqueueSnackbar(
                      'Present error to admin: ' + error.message,
                      {
                        variant: 'error'
                      }
                    );
                    setLoading(false);
                  });
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Calculation Finished', {
                  variant: 'success'
                });
                setLoading(false);
              } catch (error) {
                // TODO catch proper error msg
                setStatus({ success: false });
                // setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6}>
                        <Box mt={1}>
                          <Typography variant="h6" color="secondary">
                            {`Interest: ${syndicate.interest}%`}
                          </Typography>
                        </Box>
                        {syndicate.syndicate_purpose_id == 2 && (
                          <Box mt={1}>
                            <Typography variant="h6" color="secondary">
                              {`Line Fee: ${syndicate.line_fee}%`}
                            </Typography>
                          </Box>
                        )}
                        <Box mt={1}>
                          <Typography variant="h6" color="secondary">
                            {`Default Surcharge: ${syndicate.default_surcharge ||
                              ''}%`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {calculated_interest && (
                          <div className={classes.badge}>
                            <SvgIcon
                              fontSize="large"
                              className={classes.badgeIcon}
                            >
                              <MoneyIcon />
                            </SvgIcon>
                            <Typography
                              variant="h4"
                              color="textPrimary"
                              component="span"
                            >
                              {`${currencyFormatter(
                                parseFloat(calculated_interest).toFixed(2)
                              )}`}
                            </Typography>
                            <Divider />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary">
                        Interest Calculator
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={4} md={6}>
                          <TextField
                            fullWidth
                            label="Interest Type"
                            name="interest_type"
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: !!values.interest_type
                            }}
                            select
                            SelectProps={{ native: true }}
                            value={values.interest_type || ''}
                            variant="outlined"
                          >
                            <option></option>
                            {interestType.map(type => (
                              <option key={type.id} value={type.id}>
                                {type.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4} md={6}>
                          <TextField
                            fullWidth
                            label="Interest Accrual Method"
                            name="accu_type_id"
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: !!values.accu_type_id
                            }}
                            select
                            SelectProps={{ native: true }}
                            value={values.accu_type_id || ''}
                            variant="outlined"
                          >
                            {accrualType.allIds.map(id => (
                              <option key={id} value={id}>
                                {accrualType.byId[id].name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        {values.accu_type_id == 1 && (
                          <>
                            <Grid item md={6} xs={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enAU}
                              >
                                <DatePicker
                                  label="Interest Start Date"
                                  value={values.start_dt}
                                  onChange={newValue => {
                                    setFieldValue(
                                      'start_dt',
                                      !newValue ||
                                        newValue.toString() == 'Invalid Date'
                                        ? newValue
                                        : format(newValue, 'yyyy-MM-dd')
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      variant="outlined"
                                      value={values.start_dt}
                                      name="start_dt"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enAU}
                              >
                                <DatePicker
                                  label="Interest End Date"
                                  value={values.end_dt}
                                  onChange={newValue => {
                                    setFieldValue(
                                      'end_dt',
                                      !newValue ||
                                        newValue.toString() == 'Invalid Date'
                                        ? newValue
                                        : format(newValue, 'yyyy-MM-dd')
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      variant="outlined"
                                      value={values.end_dt}
                                      name="end_dt"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </>
                        )}
                        {values.accu_type_id == 2 && (
                          <Grid item xs={12} md={6}>
                            <TextField
                              error={Boolean(errors && errors.term)}
                              fullWidth
                              helperText={errors && errors.term}
                              label="Term (in months)"
                              name="term"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.term || ''}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={isSubmitting}
                        type="submit"
                        disabled={loading}
                      >
                        Calculate Interest
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

CalculatorForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

CalculatorForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default CalculatorForm;
