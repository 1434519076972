/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_DEVELOPERS,
  GET_DEVELOPER,
  ADD_DEVELOPER,
  UPDATE_DEVELOPER
} from 'src/actions/developerActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  developers: {
    byId: {},
    allIds: []
  }
};

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

const developerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVELOPERS: {
      const developers = action.payload;

      return produce(state, draft => {
        draft.developers.byId = objFromArray(developers);
        draft.developers.allIds = Object.keys(draft.developers.byId);
      });
    }

    case GET_DEVELOPER: {
      const developer = action.payload;

      return produce(state, draft => {
        draft.developers.byId[developer.id] = developer;

        if (!draft.developers.allIds.includes(developer.id)) {
          draft.developers.allIds.push(developer.id);
        }
      });
    }

    case ADD_DEVELOPER: {
      const developer = action.payload;

      return produce(state, draft => {
        draft.developers.byId[developer.id] = developer;
        draft.developers.allIds.unshift(developer.id);
      });
    }

    case UPDATE_DEVELOPER: {
      const developer = action.payload;
      return produce(state, draft => {
        draft.developers.allIds = _.mergeWith(
          draft.developers.byId[developer.id],
          developer,
          customizer
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default developerReducer;
