/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import axios from 'axios';
import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormHelperText,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { properName } from 'src/utils/properName';
import { updateApplication, setAddress } from 'src/actions/applicationActions';
import { countries, states, titles } from 'src/utils/options';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  fieldset: {
    border: 0
  }
}));

function PrimaryContact({ className, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { riskLevel } = useSelector(state => state.setting);
  const { fundraisers } = useSelector(state => state.fundraiser);
  const { application, isLoading } = useSelector(state => state.application);

  // sole trader is designed to be a form of sole director company
  const initialValues = application;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({
      //   abn: Yup.string()
      //     .max(11, 'ABN needs to be 11 digits ')
      //     .nullable(),
      //   business_address_1: Yup.string()
      //     .max(38, 'Must be less than 38 characters')
      //     .required()
      // })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(
            updateApplication(application.id, {
              investor_id: values.investor_id
            })
          );
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: JSON.stringify(err.response.data) });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <fieldset className={classes.fieldset}>
            <Box mb={2}>
              <Typography variant="caption" gutterBottom>
                * Required fields
              </Typography>
              <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
                PRIMARY CONTACT
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  select
                  label="Title"
                  name="investor_id.title"
                  onChange={handleChange}
                  SelectProps={{
                    native: true
                  }}
                  value={values.investor_id.title || ''}
                  variant="outlined"
                >
                  <option></option>
                  {titles.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  name="investor_id.contact_first_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.contact_first_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Last Name"
                  name="investor_id.contact_last_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.contact_last_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Mobile"
                  name="investor_id.mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.mobile || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Primary Email"
                  name="primary_email"
                  onBlur={handleBlur}
                  value={values.investor_id.primary_email || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box mt={2} mb={2}>
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                Postal Address
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  inputProps={{ maxLength: 38 }}
                  label="Postal Address Line 1"
                  name="investor_id.address_1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.address_1 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 38 }}
                  label="Postal Address Line 2"
                  name="investor_id.address_2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.address_2 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="City"
                  name="investor_id.city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.city || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  freeSolo
                  name="investor_id.state"
                  value={values.investor_id.state || ''}
                  options={states}
                  onInputChange={(event, newValue) => {
                    setFieldValue(
                      'investor_id.state',
                      newValue ? newValue : ''
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="State/Province"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Postcode"
                  name="investor_id.postcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.investor_id.postcode || ''}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Country"
                  name="investor_id.country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.investor_id.country || ''}
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} variant="contained" size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

PrimaryContact.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default PrimaryContact;
