import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import {
  addManagementFee,
  updateManagementFee
} from 'src/actions/syndicateActions';
import { CurrencyMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function ManagementFeeForm({ fee, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();

  const initialValues = fee || { syndicate_id: syndicateId };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                if (fee) {
                  await dispatch(updateManagementFee(fee.id, values));
                } else {
                  await dispatch(addManagementFee(values));
                }
                onClose();
                setStatus({ success: true });
                setSubmitting(true);
              } catch (error) {
                // TODO catch proper error msg
                setStatus({ success: false });
                // setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary">
                        Management Fee
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Amount"
                            name="amount"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('amount')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {fee ? 'Update Management Fee' : 'Add Management Fee'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

ManagementFeeForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

ManagementFeeForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default ManagementFeeForm;
