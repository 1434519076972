import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  SvgIcon,
  Card,
  CardContent,
  Grid,
  Divider
} from '@material-ui/core';
import { MonetizationOnRounded as MoneyIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { currencyFormatter } from 'src/utils/mask';
import {
  getLoanBalance,
  getLoanTransactions
} from 'src/actions/syndicateActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 60,
    width: 60
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
  badgeIcon: {
    marginRight: theme.spacing(1)
  }
}));

function PayoutForm({ open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { syndicateId } = useParams();
  const { syndicate } = useSelector(state => state.syndicate);
  const { loan_balance } = useSelector(state => state.syndicate.loan_balance);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    syndicate_id: syndicateId,
    repayment_dt: null,
    payout: true
  };

  const handleCalc = async values => {
    axios
      .post('/api/loaninterestcalc', values)
      .then(response => {
        toast.success('Payout figure calculated');
      })
      .catch(error => {
        toast.error('Calculation failed');
      });
  };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              //   interest: Yup.number().required('Interest rate is required')
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                setLoading(true);
                await handleCalc(values);
                await dispatch(getLoanTransactions(syndicateId));
                await dispatch(getLoanBalance(syndicateId));
                setStatus({ success: true });
                setSubmitting(true);
                setLoading(false);
                onClose();
              } catch (error) {
                // TODO catch proper error msg
                setStatus({ success: false });
                // setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
                setLoading(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6}>
                        <Box mt={1}>
                          <Typography variant="h6" color="secondary">
                            {`Interest: ${syndicate.interest}%`}
                          </Typography>
                        </Box>
                        {syndicate.syndicate_purpose_id == 2 && (
                          <Box mt={1}>
                            <Typography variant="h6" color="secondary">
                              {`Line Fee: ${syndicate.line_fee}%`}
                            </Typography>
                          </Box>
                        )}
                        <Box mt={1}>
                          <Typography variant="h6" color="secondary">
                            {`Default Surcharge: ${syndicate.default_surcharge ||
                              ''}%`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={classes.badge}>
                          <SvgIcon
                            fontSize="large"
                            className={classes.badgeIcon}
                          >
                            <MoneyIcon />
                          </SvgIcon>
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            component="span"
                          >
                            {`${currencyFormatter(
                              parseFloat(loan_balance.loan_balance).toFixed(2)
                            )}`}
                          </Typography>
                          <Divider />
                        </div>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary" gutterBottom>
                        Payout Figure Calculator
                      </Typography>
                      {loan_balance.interest_balance > 0 && (
                        <Typography variant="button" color="error" gutterBottom>
                          Please delete unpaid invoiced interest first
                        </Typography>
                      )}
                    </Box>
                    <Box mt={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={enAU}
                      >
                        <DatePicker
                          label="Repayment Date"
                          value={values.repayment_dt}
                          onChange={newValue => {
                            setFieldValue(
                              'repayment_dt',
                              !newValue || newValue.toString() == 'Invalid Date'
                                ? newValue
                                : format(newValue, 'yyyy-MM-dd')
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              variant="outlined"
                              value={values.repayment_dt}
                              name="repayment_dt"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={
                          isSubmitting ||
                          parseFloat(loan_balance.interest_balance) > 0 ||
                          loading
                        }
                        type="submit"
                      >
                        Calculate Payout Figure
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

PayoutForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

PayoutForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default PayoutForm;
