import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  SvgIcon,
  Card,
  CardContent,
  IconButton,
  Grid,
  Switch
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import {
  addLoanTransaction,
  updateLoanTransaction,
  getLoanBalance
} from 'src/actions/syndicateActions';
import { currencyFormatter } from 'src/utils/mask';
import { CurrencyMask, PercentMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function LoanTransactionForm({
  loanTransaction,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { syndicateId } = useParams();
  const { loanTransactionType } = useSelector(state => state.setting);
  const { syndicate } = useSelector(state => state.syndicate);
  const { user } = useSelector(state => state.account);

  const initialValues = loanTransaction
    ? loanTransaction
    : { syndicate_id: syndicateId, transaction_dt: null };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                if (loanTransaction) {
                  await dispatch(
                    updateLoanTransaction(loanTransaction.id, values)
                  );
                } else {
                  await dispatch(addLoanTransaction(values));
                }
                dispatch(getLoanBalance(syndicateId));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar(
                  drawdown
                    ? 'Loan transaction updated'
                    : 'Loan transaction added',
                  {
                    variant: 'success'
                  }
                );
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary">
                        Loan Transaction
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            // disabled={values.id}
                            label="Transaction Type"
                            name="loan_transaction_type_id"
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: !!values.loan_transaction_type_id
                            }}
                            select
                            SelectProps={{ native: true }}
                            value={values.loan_transaction_type_id || ''}
                            variant="outlined"
                          >
                            <option></option>
                            {loanTransactionType.allIds.map(id => (
                              <option key={id} value={id}>
                                {loanTransactionType.byId[id].name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Transaction Amount"
                            name="transaction_amt"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('transaction_amt')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.transaction_amt || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Transaction Date"
                              value={values.transaction_dt}
                              onChange={newValue => {
                                setFieldValue(
                                  'transaction_dt',
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  value={values.transaction_dt}
                                  name="transaction_dt"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        {values.loan_transaction_type == 'Interest' &&
                          !values.is_capitalised && (
                            <Grid item md={4} xs={12}>
                              <TextField
                                fullWidth
                                label="Payment Received"
                                name="received_amt"
                                InputProps={{
                                  inputComponent: CurrencyMask,
                                  onChange: handleChange('received_amt')
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.received_amt}
                                variant="outlined"
                              />
                            </Grid>
                          )}
                        {user.groups.some(r => [5].includes(r)) && values.id && (
                          <Grid item md={12} xs={12}>
                            <Typography variant="h5" color="textPrimary">
                              Is Included in Interst Calculation
                            </Typography>
                            <Switch
                              checked={values.is_capitalised}
                              color="secondary"
                              edge="start"
                              name="is_capitalised"
                              onChange={handleChange}
                              value={values.is_capitalised}
                            />
                          </Grid>
                        )}

                        {syndicate.syndicate_purpose_id == 2 &&
                          values.loan_transaction_type_id == 1 && (
                            <>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  fullWidth
                                  label="QS Construction Cost"
                                  name="construction_cost_amt"
                                  InputProps={{
                                    inputComponent: CurrencyMask,
                                    onChange: handleChange(
                                      'construction_cost_amt'
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.construction_cost_amt}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Approved Variation"
                                  name="variation_amt"
                                  InputProps={{
                                    inputComponent: CurrencyMask,
                                    onChange: handleChange('variation_amt')
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.variation_amt}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Cash Retention"
                                  name="retained_amt"
                                  InputProps={{
                                    inputComponent: CurrencyMask,
                                    onChange: handleChange('retained_amt')
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.retained_amt}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Other Fees"
                                  name="other_amt"
                                  InputProps={{
                                    inputComponent: CurrencyMask,
                                    onChange: handleChange('other_amt')
                                  }}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.other_amt}
                                  variant="outlined"
                                />
                              </Grid>
                            </>
                          )}
                        <Grid item md={12} xs={12}>
                          <TextField
                            error={Boolean(touched.note && errors.note)}
                            fullWidth
                            multiline
                            rows={3}
                            helperText={touched.note && errors.note}
                            label="Note"
                            name="note"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.note || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {loanTransaction
                          ? 'Update Loan Transaction'
                          : 'Add Loan Transaction'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

LoanTransactionForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

LoanTransactionForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default LoanTransactionForm;
