import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import RegisterForm from './RegisterForm';
import AccessForm from './AccessForm';

const useStyles = makeStyles(() => ({
  root: {}
}));

function UserManagement({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [groups, setGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getGroups = useCallback(() => {
    axios.get('/api/groups').then(response => {
      if (isMountedRef.current) {
        setGroups(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const [openRegisterForm, setOpenRegisterForm] = useState(false);
  const handleRegisterFormOpen = () => {
    setOpenRegisterForm(true);
  };
  const handleRegisterFormClose = () => {
    setOpenRegisterForm(false);
  };

  const [openAccessForm, setOpenAccessForm] = useState(false);
  const [staff, setStaff] = useState(null);
  const handleAccessFormOpen = value => {
    setStaff(value);
    setOpenAccessForm(true);
  };
  const handleAccessFormClose = () => {
    setOpenAccessForm(false);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Page className={classes.root} title="User List">
      <Box mt={1}>
        <Results onAdd={handleRegisterFormOpen} onEdit={handleAccessFormOpen} />
        <RegisterForm
          onClose={handleRegisterFormClose}
          open={openRegisterForm}
        />
        <AccessForm
          staff={staff}
          groups={groups}
          onClose={handleAccessFormClose}
          open={openAccessForm}
        />
      </Box>
    </Page>
  );
}

UserManagement.propTypes = {
  className: PropTypes.string
};

export default UserManagement;
