import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';
import { getStaff } from 'src/actions/staffActions';
import Page from 'src/components/Page';
import DeveloperListView from './DeveloperListView';
import FundRaiserListView from './FundRaiserListView';
import Header from './Header';

const tabs = [
  { value: 'developers', label: 'Developers' },
  { value: 'fundraisers', label: 'Fund Raisers' }
];

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function SupplierListPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);
  const [currentTab, setCurrentTab] = useState('developers');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (user.is_admin) {
      dispatch(getStaff());
    }
  }, [dispatch]);

  return (
    <Page className={classes.root} title="Developer">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            textColor="secondary"
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box py={1} pb={2}>
          {currentTab === 'developers' && <DeveloperListView />}
          {currentTab === 'fundraisers' && <FundRaiserListView />}
        </Box>
      </Container>
    </Page>
  );
}

export default SupplierListPage;
