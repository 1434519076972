import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import createDOMPurify from 'dompurify';

import { getCampaigns } from 'src/actions/campaignActions';

const DOMPurify = createDOMPurify(window);

function Campaign() {
  const { campaignUUID, email } = useParams();
  const dispatch = useDispatch();
  const { campaigns } = useSelector(state => state.campaign);

  const campaignId = campaigns.allIds.filter(
    id => campaigns.byId[id].uuid == campaignUUID
  )[0];

  const campaign = campaigns.byId[campaignId];

  useEffect(() => {
    dispatch(getCampaigns({ uuid: campaignUUID }));
  }, [dispatch]);

  DOMPurify.addHook('afterSanitizeAttributes', function(node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  if (!campaign) {
    return null;
  }
  return (
    <div>
      {
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              campaign.html
                .replace(/recipientEmail/g, email)
                .replace(/[\[\]']+/g, '')
            )
          }}
        />
      }
    </div>
  );

  // return <div className={classes.root}>{campaign.html}</div>;
}

export default Campaign;
