import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Input,
  Paper,
  Portal,
  SvgIcon,
  Link,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {
  X as XIcon,
  Maximize as MaximizeIcon,
  Minimize as MinimizeIcon,
  Loader as LoadingIcon
} from 'react-feather';
import { ContentPasteSearch as ReviewIcon } from '@mui/icons-material';
import { PictureAsPdfOutlined as ApplicationFormIcon } from '@material-ui/icons';
import { refreshInvestments } from 'src/actions/syndicateActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import QuillEditor from 'src/components/QuillEditor';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(6)}px)`,
    width: 600,
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: 500,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500
  },
  fullScreen: {
    height: '100%',
    width: '100%'
  },
  input: {
    width: '100%'
  },
  editor: {
    flexGrow: 1,
    '& .ql-editor': {
      minHeight: 300
    }
  },
  action: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function MailApplication({
  investment,
  onOpenReview,
  open,
  onSend,
  onClose,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [mail, setMail] = useState({ to: '', subject: '' });
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [applicationId, setApplicationId] = useState(null);

  useEffect(() => {
    if (open) {
      axios
        .get('/api/applicationemail', {
          params: {
            investmentId: investment.id,
            investmentParentRefId: investment.investment_parent_ref_id
          }
        })
        .then(response => {
          if (isMountedRef.current) {
            setMail(response.data);
            setBody(response.data.body);
          }
        });
    } else {
      setMail({ to: '', subject: '' });
      setBody('');
    }
  }, [open]);

  const handleChange = value => {
    setBody(value);
  };

  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  const handleEnterFullScreen = () => {
    setFullScreen(true);
  };

  const handleDownload = () => {
    setPDFLoading(true);
    axios
      .get('/api/docs', {
        responseType: 'arraybuffer',
        params: {
          investmentId: investment.id,
          actionType: 'download',
          docType: 'application form'
        }
      })
      .then(response => {
        // setPDF(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['filename']);
        document.body.appendChild(link);
        link.click();
        setPDFLoading(false);
      })
      .catch(error => {
        setPDFLoading(false);
      });
  };

  if (!open || !investment) {
    return null;
  }
  return (
    <Portal>
      <Backdrop open={fullScreen} />
      <Paper
        className={clsx(classes.root, { [classes.fullScreen]: fullScreen })}
        elevation={12}
      >
        <Box
          bgcolor="background.dark"
          display="flex"
          alignItems="center"
          py={1}
          px={2}
        >
          <Typography variant="h5" color="textPrimary">
            Send Application Form
          </Typography>
          <Box flexGrow={1} />
          <Tooltip title="Review application">
            <IconButton onClick={() => onOpenReview(investment)}>
              <SvgIcon fontSize="small">
                <ReviewIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={() => {
              if (investment.entity_id) {
                handleDownload();
              }
            }}
          >
            <Tooltip
              title={
                investment.entity_id
                  ? 'Download PDF application'
                  : 'Investment entity is required to download PDF application'
              }
            >
              <SvgIcon fontSize="small">
                {pdfLoading ? <LoadingIcon /> : <ApplicationFormIcon />}
              </SvgIcon>
            </Tooltip>
          </IconButton>
          {fullScreen ? (
            <IconButton onClick={handleExitFullScreen}>
              <SvgIcon fontSize="small">
                <MinimizeIcon />
              </SvgIcon>
            </IconButton>
          ) : (
            <IconButton onClick={handleEnterFullScreen}>
              <SvgIcon fontSize="small">
                <MaximizeIcon />
              </SvgIcon>
            </IconButton>
          )}
          <IconButton onClick={onClose}>
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box p={2}>
          <Input
            disabled={true}
            className={classes.input}
            disableUnderline
            placeholder="To"
            value={mail.to}
          />
          <Input
            // disabled={true}
            className={classes.input}
            disableUnderline
            placeholder="Subject"
            value={mail.subject}
          />
        </Box>
        <Divider />
        <QuillEditor
          className={classes.editor}
          onChange={handleChange}
          value={body}
        />
        <Divider />
        <Box display="flex" alignItems="center" py={1} px={2}>
          <div className={classes.wrapper}>
            <Button
              // disabled={true}
              color="secondary"
              variant="contained"
              className={classes.action}
              onClick={() => {
                // setLoading(true);
                try {
                  axios
                    .post('/api/applicationemail', {
                      subject: mail.subject,
                      to: mail.to,
                      body: body,
                      investmentId: investment.id
                    })
                    .then(response => {
                      console.log(response);
                      dispatch(refreshInvestments([investment.id]));
                      onClose();
                      enqueueSnackbar('Application form is sent', {
                        variant: 'success'
                      });
                      setLoading(false);
                    })
                    .catch(error => {
                      enqueueSnackbar(
                        'Present error to admin: ' + error.message,
                        {
                          variant: 'error'
                        }
                      );
                    });
                  setLoading(false);
                } catch (error) {
                  console.log(error);
                  enqueueSnackbar('Present error to admin: ' + error.message, {
                    variant: 'error'
                  });
                  setLoading(false);
                }
              }}
            >
              Send
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Box>
      </Paper>
    </Portal>
  );
}

export default MailApplication;
