import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  makeStyles
} from '@material-ui/core';
import { countries, states, titles, trustee_types } from 'src/utils/options';
import { CurrencyMask, PercentMask, NumberMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  fieldset: {
    border: 0
  },
  Card: {
    fontWeight: '500',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: '4vw',
    margin: '2vw',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.1)'
  },
  span: {
    fontWeight: theme.typography.fontWeightBold
  },
  [theme.breakpoints.down('lg')]: {
    textFieldStyle: {
      fontSize: 19
    }
  },
  [theme.breakpoints.down('md')]: {
    textFieldStyle: {
      fontSize: 17
    }
  },
  [theme.breakpoints.down('sm')]: {
    textFieldStyle: {
      fontSize: 15
    }
  }
}));

const applicant = {
  1: 'INDIVIDUAL / JOINT APPLICANT',
  2: 'Sole Trader',
  3: 'TRUST OR SUPERANNUATION FUND',
  4: 'TRUST OR SUPERANNUATION FUND',
  5: 'COMPANY APPLICANT',
  6: 'PARTNERSHIP'
};

function Review({ className, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const { paymentFreq, entityType, trustType } = useSelector(
    state => state.setting
  );
  const { application } = useSelector(state => state.application);

  const formatDate = dt => {
    if (dt) {
      return format(parseISO(dt), 'dd/MM/yyyy');
    } else {
      return dt;
    }
  };

  return (
    // <PerfectScrollbar>
    <Box>
      <Box
        mb={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h3" color="primary" gutterBottom>
          Investment Application
        </Typography>
        <Box mt={2}>
          <Typography gutterBottom>
            {`This Application relates to the Offer of Units in the
                        ${application.spv_name} ("Trust"). Unless otherwise
                        specified, all capitalised terms in relation to this
                        Application Form have the meaning given to them in the
                        information memorandum for the Issue of these Units dated ${format(
                          parseISO(application.trust_deed_dt),
                          'MMM yyyy'
                        )}
                        ("IM").`}
          </Typography>
        </Box>
      </Box>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
              1) INVESTMENT AMOUNT
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              disabled
              fullWidth
              label="Amount"
              InputProps={{
                inputComponent: CurrencyMask
              }}
              value={application.amount || ''}
              variant="outlined"
            />
          </Grid>
          {application.specify_interest && (
            <>
              <Grid item md={4} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label="Interest p.a."
                  name="interest"
                  InputProps={{
                    inputComponent: PercentMask
                  }}
                  value={
                    parseFloat(application.interest) +
                    parseFloat(application.rate_adjustment)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  fullWidth
                  label="Payment Frequency"
                  name="payment_freq_id"
                  InputLabelProps={{
                    shrink: !!application.payment_freq_id
                  }}
                  select
                  SelectProps={{ native: true }}
                  value={application.payment_freq_id}
                  variant="outlined"
                >
                  <option></option>
                  {paymentFreq.allIds.map(id => (
                    <option key={id} value={id}>
                      {paymentFreq.byId[id].name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              {parseFloat(application.coupon) > 0 && (
                <>
                  <Grid item md={4} xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      label="Coupon p.a."
                      name="coupon"
                      InputProps={{
                        inputComponent: PercentMask
                      }}
                      value={application.coupon || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled
                      fullWidth
                      label="Coupon Payment Frequency"
                      name="coupon_payment_freq_id"
                      InputLabelProps={{
                        shrink: !!application.coupon_payment_freq_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={application.coupon_payment_freq_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {paymentFreq.allIds.map(id => (
                        <option key={id} value={id}>
                          {paymentFreq.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={12} md={12}>
            <Typography variant="caption" gutterBottom>
              {!application.specify_interest &&
                `Coupon rate is based on the actual drawdown date. Please refer to IM for coupon rate schedule. `}
              The issue price for each Unit is $1.00. The minimum initial
              investment amount is $100,000 (i.e. 100,000 units). The Trustee
              reserves the right to accept different amounts at its absolute
              discretion.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Box mt={2}>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            2) {applicant[application.entity_id.entity_type_id]}
          </Typography>
        </Box>
        {application.entity_id.entity_type_id == 1 && (
          <>
            {application.entity_id.entity_details.individuals.map(
              (individual, index) => (
                <div key={index}>
                  <Box mt={2} mb={1}>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 600 }}
                      color="secondary"
                      gutterBottom
                    >
                      APPLICANT {index + 1}
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        required
                        disabled
                        select
                        label="Title"
                        SelectProps={{
                          native: true
                        }}
                        value={individual.title || ''}
                        variant="outlined"
                        size="small"
                      >
                        <option></option>
                        {titles.map(option => (
                          <option key={option.code} value={option.code}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        required
                        disabled
                        fullWidth
                        label="First Name"
                        value={individual.first_name || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        required
                        disabled
                        fullWidth
                        label="Last Name"
                        value={individual.last_name || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        required
                        label="Date of Birth"
                        disabled
                        value={formatDate(individual.birth_dt)}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        required
                        disabled
                        fullWidth
                        label="Country of Residency for Tax Purposes"
                        select
                        SelectProps={{ native: true }}
                        value={individual.tax_country || ''}
                        size="small"
                        variant="outlined"
                      >
                        <option></option>
                        {countries.map((country, index) => (
                          <option key={index} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled
                        required={individual.tax_country == 'Australia'}
                        fullWidth
                        maxLength={9}
                        label="TFN or Exemption number"
                        value={individual.tfn || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={individual.is_pep || false}
                            size="small"
                          />
                        }
                        label="Politically Exposed Person"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={individual.is_us_tax_resident || false}
                            size="small"
                          />
                        }
                        label="US Citizen / US Tax Resident"
                      />
                    </Grid>
                    {individual.is_us_tax_resident && (
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          required={individual.is_us_tax_resident}
                          disabled
                          label="TIN"
                          value={individual.tin || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    )}
                    <Grid item md={12} xs={12}>
                      <TextField
                        required
                        disabled
                        fullWidth
                        helperText="Provide oversea address if not Australian tax
                            resident (cannot be a PO Box address)"
                        label="Address Line 1"
                        value={individual.address_1 || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        label="Address Line 2"
                        value={individual.address_2 || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        required
                        disabled
                        fullWidth
                        label="City"
                        value={individual.city || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        required
                        fullWidth
                        disabled
                        value={individual.state || ''}
                        label="State/Province"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="Postcode"
                        value={individual.postcode || ''}
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        required
                        disabled
                        fullWidth
                        label="Country"
                        select
                        SelectProps={{ native: true }}
                        value={individual.country || ''}
                        size="small"
                        variant="outlined"
                      >
                        <option></option>
                        {countries.map((country, index) => (
                          <option key={index} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </div>
              )
            )}
          </>
        )}
        {application.entity_id.entity_type_id == 2 && (
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                disabled
                label="Full Business Name (if any)"
                value={
                  application.entity_id.entity_details.soleTrader
                    .business_name || ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                disabled
                required
                label="ABN"
                value={
                  application.entity_id.entity_details.soleTrader.abn || ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography variant="subtitle2">
                  Registration Address
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                disabled
                label="Registered Office Line 1 (cannot be a PO Box Address)"
                value={
                  application.entity_id.entity_details.soleTrader
                    .rego_address_1 || ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                disabled
                label="Registered Office Line 2"
                value={
                  application.entity_id.entity_details.soleTrader
                    .rego_address_2 || ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                required
                disabled
                label="City"
                value={
                  application.entity_id.entity_details.soleTrader.rego_city ||
                  ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                required
                disabled
                value={
                  application.entity_id.entity_details.soleTrader.rego_state ||
                  ''
                }
                label="State/Province"
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                disabled
                required
                label="Postcode"
                value={
                  application.entity_id.entity_details.soleTrader
                    .rego_postcode || ''
                }
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                disabled
                required
                label="Country"
                select
                SelectProps={{ native: true }}
                value={
                  application.entity_id.entity_details.soleTrader
                    .rego_country || ''
                }
                size="small"
                variant="outlined"
              >
                <option></option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography variant="subtitle2">Business Address</Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={
                      application.entity_id.entity_details.soleTrader
                        .business_addr_same_as_rego_addr || false
                    }
                    size="small"
                  />
                }
                label="Same as Registration Address"
              />
            </Grid>
            {!application.entity_id.entity_details.soleTrader
              .business_addr_same_as_rego_addr && (
              <>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    required
                    label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                    value={
                      application.entity_id.entity_details.soleTrader
                        .business_address_1 || ''
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    required
                    label="Principal Place of Business Line 2"
                    value={
                      application.entity_id.entity_details.soleTrader
                        .business_address_2 || ''
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    required
                    label="City"
                    value={
                      application.entity_id.entity_details.soleTrader
                        .business_city || ''
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    disabled
                    required
                    fullWidth
                    value={
                      application.entity_id.entity_details.soleTrader
                        .business_state || ''
                    }
                    label="State/Province"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    required
                    label="Postcode"
                    value={
                      application.entity_id.entity_details.soleTrader
                        .business_postcode || ''
                    }
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    required
                    label="Country"
                    select
                    SelectProps={{ native: true }}
                    value={
                      application.entity_id.entity_details.soleTrader
                        .business_country || ''
                    }
                    size="small"
                    variant="outlined"
                  >
                    <option></option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600 }}
                  color="secondary"
                  gutterBottom
                >
                  APPLICANT
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                required
                fullWidth
                select
                label="Title"
                SelectProps={{
                  native: true
                }}
                value={
                  application.entity_id.entity_details.soleTrader.title || ''
                }
                variant="outlined"
                size="small"
              >
                <option></option>
                {titles.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                disabled
                required
                label="First Name"
                value={
                  application.entity_id.entity_details.soleTrader.first_name ||
                  ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                required
                fullWidth
                label="Last Name"
                value={
                  application.entity_id.entity_details.soleTrader.last_name ||
                  ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                label="Date of Birth"
                disabled
                required
                fullWidth
                value={formatDate(
                  application.entity_id.entity_details.soleTrader.birth_dt
                )}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label="Country of Residency for Tax Purposes"
                select
                SelectProps={{ native: true }}
                value={
                  application.entity_id.entity_details.soleTrader.tax_country ||
                  ''
                }
                size="small"
                variant="outlined"
              >
                <option></option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                disabled
                required
                label="TFN or Exemption number"
                value={
                  application.entity_id.entity_details.soleTrader.tfn || ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={
                      application.entity_id.entity_details.soleTrader.is_pep ||
                      false
                    }
                    size="small"
                  />
                }
                label="Politically Exposed Person"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      application.entity_id.entity_details.soleTrader
                        .is_us_tax_resident || false
                    }
                    disabled
                    size="small"
                  />
                }
                label="US Citizen / US Tax Resident"
              />
            </Grid>
            {application.entity_id.entity_details.soleTrader
              .is_us_tax_resident && (
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="TIN"
                  disabled
                  value={
                    application.entity_id.entity_details.soleTrader.tin || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                disabled
                label="Residential Address (cannot be a PO Box address)"
                name="soleTrader.address"
                value={
                  application.entity_id.entity_details.soleTrader.address || ''
                }
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>
        )}
        {(application.entity_id.entity_type_id == 3 ||
          application.entity_id.entity_type_id == 4) && (
          <>
            <Box mt={2} mb={1}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                TRUST DETAILS
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Trust Name"
                  value={
                    application.entity_id.entity_details.trust.trust_name || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              {application.entity_id.entity_type_id !== 3 && (
                <>
                  <Grid item md={8} xs={12}>
                    <TextField
                      fullWidth
                      required
                      disabled
                      label="Trust Type"
                      select
                      SelectProps={{ native: true }}
                      value={
                        application.entity_id.entity_details.trust.trust_type
                      }
                      variant="outlined"
                      size="small"
                    >
                      <option></option>
                      {trustType.allIds.map(id => (
                        <option key={id} value={id}>
                          {trustType.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    {application.entity_id.entity_details.trust.trust_type ==
                      3 && (
                      <TextField
                        fullWidth
                        required={
                          application.entity_id.entity_details.trust
                            .trust_type == 3
                        }
                        disabled
                        label="Please Specify Fund Number"
                        name="trust.fund_number"
                        value={
                          application.entity_id.entity_details.trust
                            .fund_number || ''
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                    {application.entity_id.entity_details.trust.trust_type ==
                      4 && (
                      <TextField
                        fullWidth
                        required={
                          application.entity_id.entity_details.trust
                            .trust_type == 4
                        }
                        disabled
                        label="Please Specify ARSN"
                        name="trust.arsn"
                        value={
                          application.entity_id.entity_details.trust.arsn || ''
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Trustee Type"
                  name="trust.trustee_type"
                  required
                  disabled
                  select
                  SelectProps={{ native: true }}
                  value={
                    application.entity_id.entity_details.trust.trustee_type ||
                    ''
                  }
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {trustee_types.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Country of Establishment"
                  name="trust.tax_country"
                  select
                  SelectProps={{ native: true }}
                  value={
                    application.entity_id.entity_details.trust.tax_country || ''
                  }
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  required
                  disabled
                  label="Date of Trust Deed"
                  fullWidth
                  value={formatDate(
                    application.entity_id.entity_details.trust.trust_deed_dt
                  )}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="ABN"
                  required
                  disabled
                  name="trust.abn"
                  value={application.entity_id.entity_details.trust.abn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="TFN or Exemption number"
                  name="trust.tfn"
                  value={application.entity_id.entity_details.trust.tfn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Address Line 1"
                  name="trust.address_1"
                  value={
                    application.entity_id.entity_details.trust.address_1 || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Address Line 2"
                  name="trust.address_2"
                  value={
                    application.entity_id.entity_details.trust.address_2 || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="City"
                  name="trust.city"
                  value={application.entity_id.entity_details.trust.city || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  disabled
                  value={application.entity_id.entity_details.trust.state || ''}
                  label="State/Province"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  required
                  disabled
                  fullWidth
                  label="Postcode"
                  name="trust.postcode"
                  value={
                    application.entity_id.entity_details.trust.postcode || ''
                  }
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Country"
                  name="trust.country"
                  select
                  SelectProps={{ native: true }}
                  value={
                    application.entity_id.entity_details.trust.country || ''
                  }
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            {application.entity_id.entity_details.trust.trust_type == 5 && (
              <Box mt={2}>
                <Box mb={1}>
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    Trust Beneficiaries
                  </Typography>
                  <Typography variant="caption">
                    Please detail all beneficiaries who, through one or more
                    shareholdings, own more than 25% of the trust's issued
                    capital.
                  </Typography>
                </Box>
                {application.entity_id.entity_details.trust.beneficiaries.map(
                  (beneficiary, index) => (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            required={
                              application.entity_id.entity_details.trust
                                .trust_type == 5
                            }
                            disabled
                            label="Full Name (and address)"
                            value={beneficiary || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )
                )}
              </Box>
            )}
            <Box mt={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                TRUSTEE DETAILS
              </Typography>
            </Box>
            {application.entity_id.entity_details.trust.trustee_type ==
              'Individual' && (
              <Box mt={2}>
                {application.entity_id.entity_details.trust.trustee_individuals.map(
                  (individual, index) => (
                    <div key={index}>
                      <Box
                        mt={2}
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 600 }}
                          gutterBottom
                        >
                          Individual Trustee {index + 1}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Grid container spacing={1}>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              select
                              label="Title"
                              SelectProps={{
                                native: true
                              }}
                              value={individual.title || ''}
                              variant="outlined"
                              size="small"
                            >
                              <option></option>
                              {titles.map(option => (
                                <option key={option.code} value={option.code}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              required
                              disabled
                              fullWidth
                              label="First Name"
                              value={individual.first_name || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              required
                              disabled
                              fullWidth
                              label="Last Name"
                              value={individual.last_name || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              required
                              disabled
                              label="Date of Birth"
                              value={formatDate(individual.birth_dt)}
                              fullWidth
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="Country of Residency for Tax Purpose"
                              select
                              SelectProps={{ native: true }}
                              value={individual.tax_country || ''}
                              variant="outlined"
                              size="small"
                            >
                              <option></option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={individual.is_pep || false}
                                  size="small"
                                />
                              }
                              label="Politically Exposed Person"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    individual.is_us_tax_resident || false
                                  }
                                />
                              }
                              label="US Citizen / US Tax Resident"
                            />
                          </Grid>
                          {individual.is_us_tax_resident && (
                            <>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  fullWidth
                                  required={individual.is_us_tax_resident}
                                  disabled
                                  label="TIN"
                                  value={individual.tin || ''}
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              type="email"
                              label="Email"
                              value={individual.email || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              disabled
                              label="Mobile"
                              value={individual.mobile || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="Residential Address (cannot be a PO Box address)"
                              value={individual.address || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  )
                )}
              </Box>
            )}
            {application.entity_id.entity_details.trust.trustee_type ==
              'Corporate' && (
              <>
                <Box mb={2}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Corporate Trustee
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      required
                      disabled
                      label="Company Name"
                      value={
                        application.entity_id.entity_details.trust
                          .trustee_company.business_name || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      required
                      disabled
                      label="Incorporated Country"
                      select
                      SelectProps={{ native: true }}
                      value={
                        application.entity_id.entity_details.trust
                          .trustee_company.tax_country || ''
                      }
                      variant="outlined"
                      size="small"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      required
                      disabled
                      label="ACN"
                      name="trust.trustee_company.acn"
                      value={
                        application.entity_id.entity_details.trust
                          .trustee_company.acn || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="ABN"
                      value={
                        application.entity_id.entity_details.trust
                          .trustee_company.abn || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      required
                      disabled
                      label="Registered Office (cannot be a PO Box Address)"
                      value={
                        application.entity_id.entity_details.trust
                          .trustee_company.rego_address || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={
                            application.entity_id.entity_details.trust
                              .trustee_company
                              .business_addr_same_as_rego_addr || false
                          }
                          size="small"
                        />
                      }
                      label="Principal place of business is the same as registration address"
                    />
                  </Grid>
                  {!application.entity_id.entity_details.trust.trustee_company
                    .business_addr_same_as_rego_addr && (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="Principal Place of Business (cannot be a PO Box address)"
                        value={
                          application.entity_id.entity_details.trust
                            .trustee_company.business_address || ''
                        }
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  )}
                </Grid>

                <Box mt={2} mb={2}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Beneficial Owners of the Trustee Company
                  </Typography>
                  <Typography variant="caption">
                    Please list Full Name/s or Class of ALL Beneficiaries (if
                    individual beneficial owners are not named) who through one
                    or more shareholdings own more than 25% of the company’s
                    issued capital
                  </Typography>
                </Box>
                {application.entity_id.entity_details.trust.trustee_company.beneficial_owners.map(
                  (beneficial_owner, index) => (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            disabled
                            fullWidth
                            label="Full Name (and address)"
                            value={beneficial_owner || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )
                )}
                <Box mt={2} mb={2}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Directors of the Trustee Company
                  </Typography>
                  <Typography variant="caption">
                    If the company is propriety/private please provide full
                    names of all Directors
                  </Typography>
                </Box>
                {application.entity_id.entity_details.trust.trustee_company.directors.map(
                  (director, index) => (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item md={11} xs={10}>
                          <TextField
                            fullWidth
                            required
                            disabled
                            label="Full Name"
                            value={director || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )
                )}
                <Box mt={2} mb={2}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 600 }}
                    color="secondary"
                    gutterBottom
                  >
                    AUTHORISED SIGNATORIES
                  </Typography>
                  <Typography variant="caption">
                    This Section is to be completed by the Authorised
                    Signatories on the Account. With the exception of where
                    there is a Sole Director (who must be the Authorised
                    Signatory) these must be at least two Authorised Signatories
                    on the account (eg two Directors or a Director and a Company
                    Secretary). You must nominate at least one Director as an
                    Authorised Signatory.
                  </Typography>
                </Box>
                {application.entity_id.entity_details.trust.trustee_company.signatories.map(
                  (signatory, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="textPrimary">
                          Signatory {index + 1}
                        </Typography>
                      </div>
                      <Box mt={1}>
                        <Grid container spacing={1}>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              select
                              label="Title"
                              SelectProps={{
                                native: true
                              }}
                              value={signatory.title || ''}
                              variant="outlined"
                              size="small"
                            >
                              <option></option>
                              {titles.map(option => (
                                <option key={option.code} value={option.code}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="First Name"
                              value={signatory.first_name || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="Last Name"
                              value={signatory.last_name || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              required
                              disabled
                              label="Date of Birth"
                              fullWidth
                              variant="outlined"
                              size="small"
                              value={formatDate(signatory.birth_dt)}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="Country of Residency for Tax Purposes"
                              select
                              SelectProps={{ native: true }}
                              value={signatory.tax_country || ''}
                              size="small"
                              variant="outlined"
                            >
                              <option></option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={signatory.is_pep || false}
                                  size="small"
                                />
                              }
                              label="Politically Exposed Person"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={
                                    signatory.is_us_tax_resident || false
                                  }
                                  size="small"
                                />
                              }
                              label="US Citizen / US Tax Resident"
                            />
                          </Grid>
                          {signatory.is_us_tax_resident && (
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                required
                                disabled
                                label="TIN"
                                value={signatory.tin || ''}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          )}
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              type="email"
                              label="Email"
                              value={signatory.email || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Mobile"
                              value={signatory.mobile || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={12} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="Residential Address"
                              value={signatory.address || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  )
                )}
              </>
            )}
          </>
        )}
        {application.entity_id.entity_type_id == 5 && (
          <>
            <Box mt={2} mb={1}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                COMPANY DETAILS
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Company Name"
                  value={
                    application.entity_id.entity_details.company
                      .business_name || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  required
                  label="Incorporated Country"
                  name="company.tax_country"
                  select
                  SelectProps={{ native: true }}
                  value={
                    application.entity_id.entity_details.company.tax_country ||
                    ''
                  }
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  required={
                    application.entity_id.entity_details.company.tax_country ==
                    'Australia'
                  }
                  label="ACN"
                  name="company.acn"
                  value={application.entity_id.entity_details.company.acn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  required
                  label="ABN"
                  name="company.abn"
                  value={application.entity_id.entity_details.company.abn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  required
                  label="TFN or Exemption number"
                  name="company.tfn"
                  value={application.entity_id.entity_details.company.tfn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Registration Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  size="small"
                  label="Registered Office Line 1 (cannot be a PO Box Address)"
                  name="company.rego_address_1"
                  value={
                    application.entity_id.entity_details.company
                      .rego_address_1 || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Registered Office Line 2"
                  value={
                    application.entity_id.entity_details.company
                      .rego_address_2 || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="City"
                  size="small"
                  value={
                    application.entity_id.entity_details.company.rego_city || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  disabled
                  value={
                    application.entity_id.entity_details.company.rego_state ||
                    ''
                  }
                  label="State/Province"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Postcode"
                  name="company.rego_postcode"
                  value={
                    application.entity_id.entity_details.company
                      .rego_postcode || ''
                  }
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Country"
                  name="company.rego_country"
                  select
                  SelectProps={{ native: true }}
                  value={
                    application.entity_id.entity_details.company.rego_country ||
                    ''
                  }
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Business Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={
                        application.entity_id.entity_details.company
                          .business_addr_same_as_rego_addr || false
                      }
                      size="small"
                    />
                  }
                  label="Same as Registration Address"
                />
              </Grid>
              {!application.entity_id.entity_details.company
                .business_addr_same_as_rego_addr && (
                <>
                  <Grid item md={12} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      disabled
                      label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                      value={
                        application.entity_id.entity_details.company
                          .business_address_1 || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      label="Principal Place of Business Line 2"
                      value={
                        application.entity_id.entity_details.company
                          .business_address_2 || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      label="City"
                      name="company.business_city"
                      value={
                        application.entity_id.entity_details.company
                          .business_city || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      disabled
                      size="small"
                      value={
                        application.entity_id.entity_details.company
                          .business_state || ''
                      }
                      label="State/Province"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      label="Postcode"
                      name="company.business_postcode"
                      value={
                        application.entity_id.entity_details.company
                          .business_postcode || ''
                      }
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      size="small"
                      label="Country"
                      name="company.business_country"
                      select
                      SelectProps={{ native: true }}
                      value={
                        application.entity_id.entity_details.company
                          .business_country || ''
                      }
                      size="small"
                      variant="outlined"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
            <Box mt={2} mb={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                BENEFICIAL OWNERS OF THE COMPANY
              </Typography>
              <Typography variant="caption">
                Please list Full Name/s or Class of ALL Beneficiaries (if
                individual beneficial owners are not named) who through one or
                more shareholdings own more than 25% of the company’s issued
                capital
              </Typography>
            </Box>
            {application.entity_id.entity_details.company.beneficial_owners.map(
              (beneficial_owner, index) => (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="Full Name (and address)"
                        value={beneficial_owner || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </div>
              )
            )}
            <Box mt={2} mb={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                DIRECTORS OF THE COMPANY
              </Typography>
              <Typography variant="caption">
                If the company is propriety/private please provide full names of
                all Directors
              </Typography>
            </Box>
            {application.entity_id.entity_details.company.directors.map(
              (director, index) => (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="Full Name"
                        value={director || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </div>
              )
            )}
            <Box mt={2} mb={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: 600 }}
                color="secondary"
                gutterBottom
              >
                AUTHORISED SIGNATORIES
              </Typography>
              <Typography variant="caption">
                This Section is to be completed by the Authorised Signatories on
                the Account. With the exception of where there is a Sole
                Director (who must be the Authorised Signatory) these must be at
                least two Authorised Signatories on the account (eg two
                Directors or a Director and a Company Secretary). You must
                nominate at least one Director as an Authorised Signatory.
              </Typography>
            </Box>
            {application.entity_id.entity_details.company.signatories.map(
              (signatory, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 600 }}
                      gutterBottom
                    >
                      Signatory {index + 1}
                    </Typography>
                  </div>
                  <Box mt={1}>
                    <Grid container spacing={1}>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          required
                          disabled
                          select
                          label="Title"
                          SelectProps={{
                            native: true
                          }}
                          value={signatory.title || ''}
                          variant="outlined"
                          size="small"
                        >
                          <option></option>
                          {titles.map(option => (
                            <option key={option.code} value={option.code}>
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          required
                          label="First Name"
                          value={signatory.first_name || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          required
                          label="Last Name"
                          value={signatory.last_name || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          disabled
                          required
                          fullWidth
                          label="Date of Birth"
                          variant="outlined"
                          value={formatDate(signatory.birth_dt)}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          required
                          disabled
                          fullWidth
                          label="Country of Residency for Tax Purposes"
                          select
                          SelectProps={{ native: true }}
                          value={signatory.tax_country || ''}
                          size="small"
                          variant="outlined"
                        >
                          <option></option>
                          {countries.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={signatory.is_pep || false}
                              size="small"
                            />
                          }
                          label="Politically Exposed Person"
                        />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={signatory.is_us_tax_resident || false}
                              size="small"
                            />
                          }
                          label="US Citizen / US Tax Resident"
                        />
                      </Grid>
                      {signatory.is_us_tax_resident && (
                        <>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="TIN"
                              disabled
                              value={signatory.tin || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          required
                          disabled
                          label="Email"
                          type="email"
                          value={signatory.email || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          disabled
                          label="Mobile"
                          value={signatory.mobile || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          disabled
                          required
                          label="Residential Address (cannot be a PO Box address)"
                          value={signatory.address || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              )
            )}
          </>
        )}
        {application.entity_id.entity_type_id == 6 && (
          <>
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Full Business Name (if any)"
                  value={
                    application.entity_id.entity_details.partnership
                      .business_name || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="ABN"
                  value={
                    application.entity_id.entity_details.partnership.abn || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Registration Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Registered Office Line 1 (cannot be a PO Box Address)"
                  value={
                    application.entity_id.entity_details.partnership
                      .rego_address_1 || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  disabled
                  inputProps={{ maxLength: 38 }}
                  label="Registered Office Line 2"
                  value={
                    application.entity_id.entity_details.partnership
                      .rego_address_2 || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="City"
                  value={
                    application.entity_id.entity_details.partnership
                      .rego_city || ''
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  value={
                    application.entity_id.entity_details.partnership
                      .rego_state || ''
                  }
                  label="State/Province"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Postcode"
                  value={
                    application.entity_id.entity_details.partnership
                      .rego_postcode || ''
                  }
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  disabled
                  label="Country"
                  select
                  SelectProps={{ native: true }}
                  value={
                    application.entity_id.entity_details.partnership
                      .rego_country || ''
                  }
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Business Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        application.entity_id.entity_details.partnership
                          .business_addr_same_as_rego_addr || false
                      }
                      disabled
                      size="small"
                    />
                  }
                  label="Same as Registration Address"
                />
              </Grid>
              {!application.entity_id.entity_details.partnership
                .business_addr_same_as_rego_addr && (
                <>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                      value={
                        application.entity_id.entity_details.partnership
                          .business_address_1 || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="Principal Place of Business Line 2"
                      value={
                        application.entity_id.entity_details.partnership
                          .business_address_2 || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="City"
                      value={
                        application.entity_id.entity_details.partnership
                          .business_city || ''
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      required
                      label="State/Province"
                      variant="outlined"
                      value={
                        application.entity_id.entity_details.partnership
                          .business_state || ''
                      }
                      size="small"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="Postcode"
                      value={
                        application.entity_id.entity_details.partnership
                          .business_postcode || ''
                      }
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="Country"
                      select
                      SelectProps={{ native: true }}
                      value={
                        application.entity_id.entity_details.partnership
                          .business_country || ''
                      }
                      size="small"
                      variant="outlined"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
            <Box mt={2}>
              {application.entity_id.entity_details.partnership.individuals.map(
                (individual, index) => (
                  <Box mt={2} key={index}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{ fontWeight: 600 }}
                        color="secondary"
                        gutterBottom
                      >
                        APPLICANT {index + 1}
                      </Typography>
                    </div>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            disabled
                            select
                            label="Title"
                            SelectProps={{
                              native: true
                            }}
                            value={individual.title || ''}
                            variant="outlined"
                            size="small"
                          >
                            <option></option>
                            {titles.map(option => (
                              <option key={option.code} value={option.code}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            disabled
                            fullWidth
                            label="First Name"
                            value={individual.first_name || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            disabled
                            fullWidth
                            label="Last Name"
                            value={individual.last_name || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            disabled
                            required
                            fullWidth
                            variant="outlined"
                            value={formatDate(individual.birth_dt)}
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            disabled
                            fullWidth
                            label="Country of Residency for Tax Purposes"
                            select
                            SelectProps={{ native: true }}
                            value={individual.tax_country || ''}
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            disabled
                            label="TFN or Exemption number"
                            value={individual.tfn || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={individual.is_pep || false}
                                size="small"
                              />
                            }
                            label="Politically Exposed Person"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={individual.is_us_tax_resident || false}
                                size="small"
                              />
                            }
                            label="US Citizen / US Tax Resident"
                          />
                        </Grid>
                        {individual.is_us_tax_resident && (
                          <Grid item md={4} xs={12}>
                            <TextField
                              fullWidth
                              required
                              disabled
                              label="TIN"
                              value={individual.tin || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        )}
                        <Grid item md={12} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Residential Address (cannot be a PO Box address)"
                            value={individual.address || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </>
        )}
      </Box>
      <Box mt={4}>
        <Box mb={2}>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            3) PRIMARY CONTACT
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              select
              label="Title"
              SelectProps={{
                native: true
              }}
              value={application.investor_id.title || ''}
              variant="outlined"
            >
              <option></option>
              {titles.map(option => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="First Name"
              value={application.investor_id.contact_first_name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="Last Name"
              name="investor_id.contact_last_name"
              value={application.investor_id.contact_last_name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              label="Mobile"
              required
              disabled
              name="investor_id.mobile"
              value={application.investor_id.mobile || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="Primary Email"
              name="primary_email"
              value={application.investor_id.primary_email || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              inputProps={{ maxLength: 38 }}
              label="Postal Address Line 1"
              name="investor_id.address_1"
              value={application.investor_id.address_1 || ''}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              disabled
              inputProps={{ maxLength: 38 }}
              label="Postal Address Line 2"
              name="investor_id.address_2"
              value={application.investor_id.address_2 || ''}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="City"
              name="investor_id.city"
              value={application.investor_id.city || ''}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="State/Province"
              variant="outlined"
              value={application.investor_id.state || ''}
              size="small"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="Postcode"
              name="investor_id.postcode"
              value={application.investor_id.postcode || ''}
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              required
              disabled
              label="Country"
              name="investor_id.country"
              select
              SelectProps={{ native: true }}
              value={application.investor_id.country || ''}
              size="small"
              variant="outlined"
            >
              <option></option>
              {countries.map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Box>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            4) BANK ACCOUNT DETAILS FOR FOR DISTRIBUTION OF INTEREST PAYMENTS &
            REDEMPTIONS
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    disabled
                    checked={application.entity_id.is_aus_bank || false}
                    name="is_aus_bank"
                    size="small"
                  />
                }
                label="Australian Bank"
              />
            </Grid>
            {application.entity_id.is_aus_bank && (
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    disabled
                    fullWidth
                    value={application.entity_id.bank_acct_name || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    required
                    disabled
                    fullWidth
                    label="BSB"
                    name="bsb"
                    value={application.entity_id.bsb || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    required
                    disabled
                    label="Bank Account Number"
                    name="bank_acct_no"
                    value={application.entity_id.bank_acct_no || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </>
            )}
            {!application.entity_id.is_aus_bank && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    disabled
                    label="Bank Name"
                    value={application.entity_id.foreign_bank_name || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    required
                    disabled
                    label="Swift Code"
                    value={application.entity_id.swift_code || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    required
                    disabled
                    label="Bank Account Number"
                    value={application.entity_id.foreign_bank_acct_no || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    required
                    disabled
                    label="Bank Address"
                    value={application.entity_id.foreign_bank_address || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              <Typography variant="caption">
                You bank details will be kept confidential. Should you wish to
                change your bank account details in the future, we will require
                the request in writing and will need to verify your instructions
                via a call back.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={6} display="flex">
          {onBack && (
            <Button onClick={onBack} variant="contained" size="large">
              Previous
            </Button>
          )}
          <Box flexGrow={1} />
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            size="large"
            onClick={onNext}
          >
            Confirm & Next
          </Button>
        </Box>
      </Box>
    </Box>
    // </PerfectScrollbar>
  );
}

Review.propTypes = {
  className: PropTypes.string
};

export default Review;
