import axios from 'axios';
import _ from 'lodash';

export const INIT = '@entity/init';
export const INIT_ENTITY = '@entity/init-entity';
export const GET_ENTITY = '@entity/get-entity';
export const ADD_ENTITY = '@entity/add-entity';
export const UPDATE_ENTITY = '@entity/update-entity';
export const ERROR = '@entity/error';

export function getEntity(entityId) {
  return async dispatch => {
    try {
      if (entityId) {
        dispatch({ type: INIT_ENTITY });
        const response = await axios.get(`/api/entities/${entityId}`);
        dispatch({ type: GET_ENTITY, payload: response.data });
      } else {
        dispatch({ type: INIT_ENTITY });
      }
    } catch (error) {
      throw error;
    }
  };
}

export function addEntity(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/entities', values);
      dispatch({ type: ADD_ENTITY, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function updateEntity(entityId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(`/api/entities/${entityId}`, values);
      dispatch({ type: UPDATE_ENTITY, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}
