import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
  makeStyles,
  FormHelperText,
  Paper,
  Radio
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function Alert({ open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <Box minHeight={50} m={4}>
        <Typography variant="h5" color="textPrimary">
          Your application has been saved. You can close the window now.
        </Typography>
      </Box>
    </Dialog>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

Alert.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default Alert;
