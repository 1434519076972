import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Hidden,
  Paper,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  withStyles,
  colors
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  Key as BankIcon,
  Bookmark as BookmarkIcon,
  DollarSign as InvestmentIcon,
  User as UserIcon,
  Star as StarIcon,
  Briefcase as BriefcaseIcon,
  File as FileIcon,
  Compass as WelcomeIcon,
  CheckCircle as ReviewIcon,
  Feather as SignIcon,
  UserCheck as SignatoryIcon
} from 'react-feather';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { getApplication } from 'src/actions/applicationActions';
import { getSetting } from 'src/actions/settingActions';
import Page from 'src/components/Page';
import SplashScreen from 'src/components/SplashScreen';
import Welcome from './Wecome';
import InvestmentDetail from './InvestmentDetail';
import PrimaryContact from './PrimaryContact';
import EntityDetails from './EntityDetails';
import BankDetail from './BankDetail';
import Files from './Files';
import Review from './Review';
import Declaration from './Declaration';

const CustomStepConnector = withStyles(theme => ({
  vertical: {
    marginLeft: 19,
    padding: 0
  },
  line: {
    borderColor: theme.palette.divider
  }
}))(StepConnector);

const useCustomStepIconStyles = makeStyles(theme => ({
  root: {},
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10]
  },
  completed: {
    backgroundColor: theme.palette.secondary.main
  }
}));

const steps = [
  { label: 'Welcome', icon: WelcomeIcon, show: true },
  { label: 'Investment Details', icon: InvestmentIcon, show: true },
  {
    label: 'Investment Vehicle',
    icon: BriefcaseIcon,
    show: true
  },
  { label: 'Primary Contact', icon: UserIcon, show: true },
  {
    label: 'Bank Account Details',
    icon: BankIcon,
    show: true
  },
  {
    label: 'Files',
    icon: FileIcon,
    show: true
  },
  {
    label: 'Review',
    icon: ReviewIcon,
    show: true
  },
  {
    label: 'Declaration',
    icon: SignIcon,
    show: true
  }
];

function CustomStepIcon({ active, completed, icon }) {
  const classes = useCustomStepIconStyles();

  const Icon = steps[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      <Icon size="20" />
    </Avatar>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function InvestmentApplicationView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { investmentId, secret } = useParams();
  const { application, isLoading } = useSelector(state => state.application);

  const [activeStep, setActiveStep] = useState(secret ? 0 : 6);
  const [completed, setCompleted] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getApplication(investmentId));
  }, [dispatch]);

  useEffect(() => {
    setCompleted(
      (application && application.application_status == 'verified') ||
        (activeStep === 7 && application.application_status == 'signed')
    );
  }, [application]);

  const handleDownload = () => {
    setPDFLoading(true);
    axios
      .get('/api/docs', {
        responseType: 'arraybuffer',
        params: {
          investmentId: investmentId,
          actionType: 'download',
          docType: 'application form'
        }
      })
      .then(response => {
        // setPDF(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['filename']);
        document.body.appendChild(link);
        link.click();
        setPDFLoading(false);
      })
      .catch(error => {
        // enqueueSnackbar('Present error to admin: ' + error, {
        //   variant: 'error'
        // });
        setPDFLoading(false);
      });
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted(true);
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <Page title="Investment Application Form">
      <Box mt={3}>
        {!completed ? (
          <Paper>
            <Grid container>
              <Hidden mdDown>
                <Grid item xs={12} md={2}>
                  <Stepper
                    activeStep={activeStep}
                    connector={<CustomStepConnector />}
                    orientation="vertical"
                    component={Box}
                    bgcolor="transparent"
                  >
                    {steps.map(step => (
                      <Step key={step.label}>
                        <StepLabel StepIconComponent={CustomStepIcon}>
                          {step.label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={10}>
                <Box p={3}>
                  {activeStep === 0 && <Welcome onNext={handleNext} />}
                  {activeStep === 1 && (
                    <InvestmentDetail onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 2 && (
                    <EntityDetails onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 3 && (
                    <PrimaryContact onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 4 && (
                    <BankDetail onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 5 && (
                    <Files onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 6 && (
                    <Review onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 7 && (
                    <Declaration
                      onBack={handleBack}
                      onComplete={handleComplete}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Page title="Investment Application Form">
            <Container maxWidth="md">
              <Card sx={{ maxWidth: 500 }}>
                <CardContent>
                  <Box p={3}>
                    <Box display="flex" alignItems="center" mt={2}>
                      <Avatar
                        alt="Wealth Pi"
                        sx={{ width: 56, height: 56 }}
                        src="/static/images/avatars/avatar.png"
                      />
                      <Box ml={2}>
                        <Typography variant="h5" gutterBottom>
                          Thank you for investing with Wealth Pi. EFT payments
                          ($1.00per Unit) should be made in respect of all the
                          Units for which you are applying, as follows:
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box pb={2} px={3}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 600 }}
                              gutterBottom
                            >
                              Account Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <div>
                              <Typography
                                variant="h5"
                                style={{ fontWeight: 600 }}
                                gutterBottom
                              >
                                {application.bank_acct_name}
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 600 }}
                              gutterBottom
                            >
                              BSB
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <div>
                              <Typography
                                variant="h5"
                                style={{ fontWeight: 600 }}
                                gutterBottom
                              >
                                {application.bsb}
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 600 }}
                              gutterBottom
                            >
                              Account Number
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 600 }}
                              gutterBottom
                            >
                              {application.bank_acct_no}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Box py={2} px={3}>
                    <Typography variant="body1">
                      If the Arranger does not accept your Application in
                      respect of all the Units for which you have applied, it
                      will refund that part of your payment in excess of the
                      amount to be paid in respect of the number of Units
                      allocated to you.
                      <span>
                        THIS REFUND WILL BE PAID TO THE ACCOUNT SET OUT UNDER
                        ‘SECTION 4 – DISTRIBUTION PAYMENTS’ UNLESS OTHERWISE
                        DIRECTED IN WRITING BY YOU.
                      </span>
                    </Typography>
                  </Box>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDownload}
                      fullWidth
                      disabled={pdfLoading}
                    >
                      Click to download your application form with payment
                      instruction
                    </Button>
                    {pdfLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Container>
          </Page>
        )}
      </Box>
    </Page>
  );
}

export default InvestmentApplicationView;
