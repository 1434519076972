
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.215,"time":12900,"words":43}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "about-the-documentation"
    }}>{`About the documentation`}</h1>
    <p>{`Welcome to our documentation!`}</p>
    <h2 {...{
      "id": "something-missing"
    }}>{`Something Missing?`}</h2>
    <p>{`If you have ideas for more “How To” recipes that should be on this page, let us know or contribute some!`}</p>
    <h2 {...{
      "id": "feedback"
    }}>{`Feedback`}</h2>
    <p>{`We are always open to your feedback. `}<a href="mailto:contact@devias.io"><a parentName="p" {...{
          "href": "mailto:contact@devias.io"
        }}>{`contact@devias.io`}</a></a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "something-missing",
    level: 2,
    title: "Something Missing?",
    children: [
        
      ]
  },
{
    id: "feedback",
    level: 2,
    title: "Feedback",
    children: [
        
      ]
  }
]

export const frontMatter = {}

