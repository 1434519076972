/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';
import {
  Dashboard as DashboardIcon,
  HolidayVillage as SyndicateIcon,
  AssuredWorkload as PoolIcon,
  PeopleAlt as InvestorIcon,
  ConnectWithoutContact as SupplierIcon
} from '@mui/icons-material';
import { Campaign as EDMIcon } from '@mui/icons-material';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Reports',
    prod: true,
    items: [
      {
        title: 'Dashboard',
        prod: true,
        icon: DashboardIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Dashboard Alternative',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative'
      },
      {
        title: 'Pool Management',
        icon: PoolIcon,
        prod: true,
        href: '/app/reports/pool'
      }
    ]
  },
  {
    subheader: 'Management',
    prod: true,
    items: [
      {
        title: 'Syndicates',
        prod: true,
        icon: SyndicateIcon,
        href: '/app/management/syndicates'
      },
      {
        title: 'Investors',
        prod: true,
        icon: InvestorIcon,
        href: '/app/management/investors'
      },
      {
        title: 'Suppliers',
        prod: true,
        icon: SupplierIcon,
        href: '/app/management/suppliers'
      },
      // {
      //   title: 'Suppliers',
      //   icon: ShareIcon,
      //   href: '/app/management/customers',
      //   items: [
      //     {
      //       title: 'Developers',
      //       href: '/app/management/customers'
      //     },
      //     {
      //       title: 'Brokers',
      //       href: '/app/management/customers/1'
      //     },
      //     {
      //       title: 'Fund raisers',
      //       href: '/app/management/customers/1/edit'
      //     }
      //   ]
      // },
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    prod: true,
    items: [
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Kanban',
        prod: true,
        href: '/app/kanban',
        icon: TrelloIcon,
        info: () => <Chip color="secondary" size="small" label="New" />
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon,
        info: () => <Chip color="secondary" size="small" label="Updated" />
      },
      {
        title: 'EDM',
        prod: true,
        href: '/app/crm',
        icon: EDMIcon,
        info: () => <Chip color="secondary" size="small" label="New" />
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => <Chip color="secondary" size="small" label="Updated" />
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon
      }
    ]
  },
  {
    subheader: 'Auth',
    prod: false,
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      },
      {
        title: 'Login: Guest Protected',
        href: '/login',
        icon: ShieldIcon
      },
      {
        title: 'Register: Guest Protected',
        href: '/register',
        icon: ShieldIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    prod: false,
    href: '/app/pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  {
    subheader: 'Extra',
    prod: false,
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          }
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
    ]
  }
];

function renderNavItems({ user, items, ...rest }) {
  if (user.is_superuser) {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ user, acc, item, ...rest }),
          []
        )}
      </List>
    );
  } else {
    return (
      <List disablePadding>
        {items
          .filter(item => item.prod)
          .reduce(
            (acc, item) => reduceChildRoutes({ user, acc, item, ...rest }),
            []
          )}
      </List>
    );
  }
}

function reduceChildRoutes({ user, acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          user,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.first_name} ${user.last_name}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.group_names.map(grp => grp)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig
            .filter(sub => sub.prod)
            .map(config => (
              <List
                key={config.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  user,
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
