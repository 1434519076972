import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { BSBMask, NumberMask } from 'src/utils/mask';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    '& .ql-editor': {
      height: 400
    }
  },
  fieldset: {
    border: 0
  }
}));

function BankDetail({ className, review, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application } = useSelector(state => state.application);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues(
      application.entity_id || {
        is_aus_bank: true,
        bsb: null,
        bank_acct_name: null,
        bank_acct_no: null
      }
    );
  }, [application]);

  if (!application) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      disabled={review}
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({
      //   bsb: Yup.string()
      //     .min(7, 'Must be 6 numbers')
      //     .max(7, 'Must be 6 numbers')
      //     .when('is_aus_bank', {
      //       is: true,
      //       then: Yup.string()
      //         .required('Required')
      //         .nullable()
      //     })
      //     .nullable(),
      //   bank_acct_name: Yup.string()
      //     .when('is_aus_bank', {
      //       is: true,
      //       then: Yup.string()
      //         .required('Required')
      //         .nullable()
      //     })
      //     .nullable(),
      //   bank_acct_no: Yup.string()
      //     .when('is_aus_bank', {
      //       is: true,
      //       then: Yup.string()
      //         .required('Required')
      //         .nullable()
      //     })
      //     .nullable(),
      //   foreign_bank_name: Yup.string()
      //     .when('is_aus_bank', {
      //       is: false,
      //       then: Yup.string()
      //         .required('Required')
      //         .nullable()
      //     })
      //     .nullable(),
      //   foreign_bank_acct_no: Yup.string()
      //     .nullable()
      //     .when('is_aus_bank', {
      //       is: false,
      //       then: Yup.string()
      //         .required('Required')
      //         .nullable()
      //     }),
      //   swift_code: Yup.string()
      //     .when('is_aus_bank', {
      //       is: false,
      //       then: Yup.string()
      //         .required('Required')
      //         .nullable()
      //     })
      //     .nullable(),
      //   address_1: Yup.string()
      //     .max(38, 'Must be less then 38 characters')
      //     .required('Required')
      //     .nullable(),
      //   address_2: Yup.string()
      //     .max(38, 'Must be less then 38 characters')
      //     .nullable(),
      //   city: Yup.string()
      //     .required('Required')
      //     .nullable(),
      //   country: Yup.string()
      //     .required('Required')
      //     .nullable()
      // })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (!review) {
            dispatch(
              updateApplication(application.id, {
                ...application,
                entity_id: values
              })
            );
          }
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <fieldset disabled={review} className={classes.fieldset}>
            <Typography variant="caption" gutterBottom>
              * Required fields
            </Typography>
            <Box>
              <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
                BANK ACCOUNT DETAILS FOR FOR DISTRIBUTION OF INTEREST PAYMENTS &
                REDEMPTIONS
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_aus_bank || false}
                        onChange={handleChange}
                        value={values.is_aus_bank || true}
                        name="is_aus_bank"
                      />
                    }
                    label="Australian Bank"
                  />
                </Grid>
                {values.is_aus_bank && (
                  <>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        required={values.is_aus_bank}
                        error={Boolean(errors && errors.bank_acct_name)}
                        helperText={errors && errors.bank_acct_name}
                        label="Bank Account Name"
                        name="bank_acct_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bank_acct_name || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        fullWidth
                        required={values.is_aus_bank}
                        error={Boolean(errors && errors.bsb)}
                        helperText={errors && errors.bsb}
                        label="BSB"
                        name="bsb"
                        InputProps={{
                          inputComponent: BSBMask,
                          onChange: handleChange('bsb')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bsb || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required={values.is_aus_bank}
                        error={Boolean(errors && errors.bank_acct_no)}
                        helperText={errors && errors.bank_acct_no}
                        label="Bank Account Number"
                        name="bank_acct_no"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: NumberMask,
                          onChange: handleChange('bank_acct_no')
                        }}
                        value={values.bank_acct_no || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </>
                )}
                {!values.is_aus_bank && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        disabled={values.foreign_bank_name !== null}
                        required={!values.is_aus_bank}
                        error={Boolean(errors && errors.foreign_bank_name)}
                        helperText={errors && errors.foreign_bank_name}
                        label="Bank Name"
                        name="foreign_bank_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.foreign_bank_name || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={values.swift_code !== null}
                        required={!values.is_aus_bank}
                        error={Boolean(errors && errors.swift_code)}
                        helperText={errors && errors.swift_code}
                        label="Swift Code"
                        name="swift_code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.swift_code || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={values.foreign_bank_acct_no !== null}
                        required={!values.is_aus_bank}
                        error={Boolean(errors && errors.foreign_bank_acct_no)}
                        helperText={errors && errors.foreign_bank_acct_no}
                        label="Bank Account Number"
                        name="foreign_bank_acct_no"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: NumberMask,
                          onChange: handleChange('foreign_bank_acct_no')
                        }}
                        value={values.foreign_bank_acct_no || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        required={!values.is_aus_bank}
                        disabled={values.foreign_bank_address !== null}
                        error={Boolean(
                          touched.foreign_bank_address &&
                            errors.foreign_bank_address
                        )}
                        fullWidth
                        helperText={
                          touched.foreign_bank_address &&
                          errors.foreign_bank_address
                        }
                        label="Bank Address"
                        name="foreign_bank_address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.foreign_bank_address || ''}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <Typography>
                    You bank details will be kept confidential. Should you wish
                    to change your bank account details in the future, we will
                    require the request in writing and will need to verify your
                    instructions via a call back.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} variant="contained" size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

BankDetail.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default BankDetail;
