/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  InputAdornment,
  IconButton,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  NoteAltOutlined as EditIcon
} from '@mui/icons-material';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stateField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'createdAt|desc',
    label: 'Creation date (newest first)'
  },
  {
    value: 'createdAt|asc',
    label: 'Creation date (oldest first)'
  }
];

function Results({ developers, onEdit, isLoading, className, ...rest }) {
  const classes = useStyles();

  const columns = [
    {
      field: 'developer_name',
      title: 'Developer',
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <IconButton size="small" onClick={() => onEdit(rowData)}>
                <EditIcon />
              </IconButton>
            </Box>
            {rowData.developer_name}
          </Box>
        );
      }
    },
    {
      field: 'contact_manager',
      title: 'Contact Manager'
    },
    {
      field: 'contact_first_name',
      title: 'Primary Contact',
      render: rowData =>
        `${rowData.contact_first_name} ${rowData.contact_last_name || ''}`
    },
    {
      field: 'email',
      title: 'Email'
    },
    {
      field: 'phone',
      title: 'Phone'
    },
    {
      field: 'updated_dt',
      title: 'Updated On',
      type: 'date',
      hidden: true,
      dateSetting: { locale: 'en-AU' },
      defaultSort: 'desc'
    }
  ];
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        title=""
        data={developers}
        isLoading={isLoading}
        columns={columns}
        options={{
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [10, 20, 50]
        }}
        actions={[
          {
            icon: AddIcon,
            tooltip: 'Add Fundraiser',
            isFreeAction: true,
            onClick: (event, rowData) => onEdit()
          }
        ]}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  developers: PropTypes.array
};

Results.defaultProps = {
  developers: []
};

export default Results;
