/* eslint-disable max-len */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  IconButton,
  Link,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  NoteAltOutlined as EditIcon
} from '@mui/icons-material';
import { SpeakerNotesOutlined as NotesIcon } from '@material-ui/icons';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { LightTooltip } from 'src/components/LightTooltip';
import { currencyFormatter } from 'src/utils/mask';
import BarProgress from 'src/components/BarProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    width: 500
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  popover: {
    display: 'flex',
    margin: theme.spacing(2),
    width: '300px'
  }
}));

const languages = {
  English: '/static/icons/au_flag.svg',
  Chinese: '/static/icons/china_flag.svg'
};

function Results({ className, investors, isLoading, ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const columns = [
    {
      field: 'display_name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <IconButton
                size="small"
                component={RouterLink}
                to={`/app/management/investors/${rowData.id}/edit`}
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Link
              color="inherit"
              component={RouterLink}
              to={`/app/management/investors/${rowData.id}`}
              variant="h6"
            >
              {rowData.display_name}
            </Link>
            <Box
              mx={1}
              sx={{
                display: 'flex',
                height: 20,
                width: 20,
                '& img': {
                  width: '100%'
                }
              }}
            >
              <LightTooltip title={`Preferred Lanauge is ${rowData.language}`}>
                <img alt="flag" src={languages[rowData.language]} />
              </LightTooltip>
            </Box>

            {rowData.note && (
              <div className={classes.badge}>
                <LightTooltip title={rowData.note}>
                  <NotesIcon color="secondary" />
                </LightTooltip>
              </div>
            )}
          </Box>
        );
      }
    },
    {
      field: 'contact_manager',
      title: 'Managing Staff',
      render: rowData => (
        <div>
          {rowData.contact_manager}
          {rowData.fundraiser && (
            <Typography variant="body2" color="textSecondary">
              <span className={classes.subscriptions}>
                {`${rowData.fundraiser}`}
              </span>
            </Typography>
          )}
        </div>
      )
    },
    {
      field: 'current_amt',
      title: 'Current Investment',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => (
        <div>
          <BarProgress
            bgcolor="#00C7B1"
            progress={(
              (100 * parseFloat(rowData.current_amt)) /
              parseFloat(rowData.max_amt)
            )
              .toFixed(2)
              .toString()}
            height={20}
            value={parseFloat(rowData.current_amt)}
          />
          <Typography variant="caption">
            {`Max Capacity: ${currencyFormatter(rowData.max_amt)}`}
          </Typography>
        </div>
      )
    },
    {
      field: 'eoi_amt',
      title: 'Current EOI',
      type: 'currency'
    },
    {
      field: 'accept_marketing',
      title: 'Accept Marketing Comms',
      type: 'boolean'
    },
    {
      field: 'onboarding_dt',
      title: 'Onboarding',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'last_transaction_dt',
      title: 'Last Seen',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    }
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        title=""
        data={investors}
        isLoading={isLoading}
        columns={columns}
        actions={[
          {
            icon: AddIcon,
            tooltip: 'Add Investor',
            isFreeAction: true,
            onClick: (event, rowData) =>
              history.push('/app/management/investors/create')
          }
        ]}
        options={{
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [10, 20, 50]
        }}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  investors: PropTypes.array
};

Results.defaultProps = {
  investors: []
};

export default Results;
