import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Badge,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Divider,
  Link,
  List,
  colors,
  makeStyles,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { currencyFormatter } from 'src/utils/mask';
import Label from 'src/components/Label';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  entity: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  highlight: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function getStageLabel(syndicate_stage_id) {
  const map = {
    1: {
      text: 'Due Diligence',
      color: 'error'
    },
    2: {
      text: 'Under Management',
      color: 'warning'
    },
    3: {
      text: 'Completed',
      color: 'success'
    },
    4: {
      text: 'Cancelled',
      color: 'greyout'
    }
  };

  const { text, color } = map[syndicate_stage_id];

  return <Label color={color}>{text}</Label>;
}

function OpenSyndicates({ className, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [syndicates, setSyndicates] = useState([]);
  const [loading, setLoading] = useState(true);
  const getSyndicates = useCallback(() => {
    axios
      .get('/api/view/syndicate', { params: { open: true } })
      .then(response => {
        if (isMountedRef.current) {
          setSyndicates(response.data);
          setLoading(false);
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getSyndicates();
  }, [getSyndicates]);
  const { user } = useSelector(state => state.account);

  const columns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              variant="rounded"
              style={{ height: '20px', width: '20px' }}
              src={
                rowData.is_internal ? '/static/images/avatars/avatar.png' : ''
              }
            ></Avatar>
            <Link
              component={RouterLink}
              underline="none"
              to={`/app/management/syndicates/${rowData.syndicate_id}/`}
            >
              {rowData.syndicate_name}
            </Link>
          </Box>
        );
      }
    },
    {
      field: 'syndicate_purpose',
      title: 'Purpose'
    },
    {
      field: 'syndicate_stage_id',
      title: 'Stage',
      render: rowData => {
        return getStageLabel(rowData.syndicate_stage_id);
      }
    },
    {
      field: 'start_dt',
      title: 'Settlement Date',
      type: 'date',
      defaultSort: 'asc',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'open_amt',
      title: 'Open Amount',
      type: 'currency'
    }
  ];

  return (
    <CustomMaterialTable
      title={
        <Typography variant="h5" color="primary">
          Syndicates Open for Subsription
        </Typography>
      }
      columns={columns}
      data={syndicates}
      isLoading={loading}
      options={{
        padding: 'dense',
        paging: false
      }}
    />
  );
}

OpenSyndicates.propTypes = {
  className: PropTypes.string
};

export default OpenSyndicates;
