import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Dialog,
  FormHelperText,
  FormControlLabel,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updateStaff } from 'src/actions/staffActions';

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(3) }
}));

function AccessForm({ className, staff, groups, onClose, open, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Formik
          initialValues={staff}
          vadddationSchema={Yup.object().shape({
            first_name: Yup.string()
              .max(255)
              .required('First name is required'),
            last_name: Yup.string()
              .max(255)
              .required('Last name is required'),
            email: Yup.string()
              .email('Must be a vaddd email')
              .max(255)
              .required('Email is required'),
            password: Yup.string()
              .min(7)
              .max(255)
              .required('Password is required')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              console.log(values);
              await dispatch(updateStaff(values));
              onClose(true);
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.response.data });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            setFieldValue,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              {errors.submit && (
                <Box mb={3}>
                  <FormHelperText error>
                    {JSON.stringify(errors.submit)}
                  </FormHelperText>
                </Box>
              )}
              <Grid container spacing={3}>
                <Grid item container xs={12} md={4}>
                  <Grid item>
                    <TextField
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      label="First Name"
                      margin="normal"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      label="Last Name"
                      margin="normal"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Aliress"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} display="grid">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={values.is_active}
                          onChange={handleChange}
                          name="is_active"
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12} md={8}>
                  <Grid item>
                    <Autocomplete
                      fullWidth
                      multiple
                      name="groups"
                      value={values.groups}
                      options={groups}
                      getOptionLabel={option => option.name}
                      getOptionSelected={option =>
                        values.groups.map(group => group.id).includes(option.id)
                      }
                      onChange={(event, newValue) => {
                        setFieldValue('groups', newValue);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label="Access Level"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    {values.groups
                      .map(group => group.name)
                      .includes('Standard') && (
                      <Box my={1}>
                        <Typography variant="subtitle2" component="div">
                          Standard User
                        </Typography>
                        <ul>
                          <li>Can view Dashboard</li>
                          <li>Can view Syndicates</li>
                          <li>Can add and update their own investors</li>
                          <li>
                            Can add and update entities for the investors under
                            their management
                          </li>
                          <li>
                            Can add and update investments for the investors
                            under their management
                          </li>
                          <li>
                            Can view all investments but only investors under
                            their management are disclosed
                          </li>
                        </ul>
                      </Box>
                    )}
                    {values.groups
                      .map(group => group.name)
                      .includes('Loan Analyst') && (
                      <Box my={1}>
                        <Typography variant="subtitle2" component="div">
                          Loan Analyst
                        </Typography>
                        <ul>
                          <li style={{ color: 'red' }}>
                            Can add and update loan transactions
                          </li>
                          <li>View Dashboard</li>
                          <li>Can add and update syndicates</li>

                          <li>Can add and update their own investors</li>
                          <li>
                            Can add and update entities for the investors under
                            their management
                          </li>
                          <li>
                            Can add and update investments for the investors
                            under their management
                          </li>
                          <li>
                            Can view all investments but only investors under
                            their management are disclosed
                          </li>
                        </ul>
                      </Box>
                    )}
                    {values.groups
                      .map(group => group.name)
                      .includes('Fund Manager') && (
                      <Box my={1}>
                        <Typography variant="subtitle2" component="div">
                          Fund Manager
                        </Typography>
                        <ul>
                          <li style={{ color: 'red' }}>
                            Can set up interest rate offered to investors
                          </li>
                          <li style={{ color: 'red' }}>
                            Can make interest adjusment on investments
                          </li>
                          <li>Can view Dashboard</li>
                          <li>Can add and update syndicates</li>
                          <li>Can add and update loan transactions</li>
                          <li>
                            Full access to all investors and their entities
                          </li>
                          <li>Full access to all investments</li>
                        </ul>
                      </Box>
                    )}
                    {values.groups
                      .map(group => group.name)
                      .includes('Finance') && (
                      <Box my={1}>
                        <Typography variant="subtitle2" component="div">
                          Finance
                        </Typography>
                        <ul>
                          <li style={{ color: 'red' }}>
                            Full access to interest payments and commission
                            payments
                          </li>
                          <li style={{ color: 'red' }}>
                            Can make interest adjusment on investments
                          </li>
                          <li>Can view Dashboard</li>
                          <li>Can view syndicates</li>

                          <li>
                            Full access to all investors and their entities
                          </li>
                          <li>Full access to all investments</li>
                        </ul>
                      </Box>
                    )}
                    {values.groups.map(group => group.name).includes('CRM') && (
                      <Box my={1}>
                        <Typography variant="subtitle2" component="div">
                          CRM
                        </Typography>
                        <ul>
                          <li style={{ color: 'red' }}>Access to EDM module</li>
                          <li>Can view Dashboard</li>
                          <li>Can view Syndicates</li>
                          <li>Can add and update their own investors</li>
                          <li>
                            Can add and update entities for the investors under
                            their management
                          </li>
                          <li>
                            Can add and update investments for the investors
                            under their management
                          </li>
                          <li>
                            Can view all investments but only investors under
                            their management are disclosed
                          </li>
                        </ul>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Box mt={2}>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Update User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}

AccessForm.propTypes = {
  className: PropTypes.string
};

AccessForm.default = {
  onSubmitSuccess: () => {}
};

export default AccessForm;
