import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import { Distribute } from 'src/actions/syndicateActions';
import Label from 'src/components/Label';
import { CurrencyMask, currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function DistributionForm({
  distribution,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dropdownopen, setDropdownOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { investments, syndicate } = useSelector(state => state.syndicate);
  const loading = dropdownopen && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await axios.get('/api/view/syndicate', {
        params: { subTrust: true }
      });
      const syndicates = await response.data;

      if (active) {
        setOptions(syndicates);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  function distributionTotal(distributions) {
    return distributions
      .map(({ amount }) => parseFloat(amount || 0))
      .reduce((sum, i) => sum + i, 0)
      .toFixed(2);
  }

  const initialValues = {
    ...distribution,
    syndicate_id: null,
    entity_id: syndicate && syndicate.entity_id,
    fromInvestments:
      distribution &&
      distribution.investmentIds.map(id => {
        return {
          investment_id: id,
          investor_name: investments.byId[id].investor_name,
          account_name: investments.byId[id].account_name,
          investor_id: investments.byId[id].investor_id,
          entity_id: investments.byId[id].entity_id,
          amount: null,
          transaction_dt: null,
          cash_amt: investments.byId[id].cash_amt
        };
      })
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              fromInvestments: Yup.array().of(
                Yup.object().shape({
                  amount: Yup.number('A number is required')
                    .max(
                      Yup.ref('cash_amt'),
                      'Distribution amount exceeds available cash amount'
                    )
                    .nullable()
                })
              )
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(
                  Distribute({
                    ...values,
                    amount: distributionTotal(values.fromInvestments)
                  })
                );
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                toast.success('Funds distributed to SPV trusts');
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="textPrimary">
                      This form is for allocate cash reserve to SPV trusts
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <Label className={classes.label} color="primary">
                        Current Balance:
                        {currencyFormatter(
                          parseFloat(values.total_balance).toFixed(2)
                        )}
                      </Label>
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Autocomplete
                            open={dropdownopen}
                            onOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onClose={() => {
                              setDropdownOpen(false);
                            }}
                            value={
                              values.syndicate_id
                                ? options.filter(
                                    option => option.id == values.syndicate_id
                                  )[0]
                                : null
                            }
                            // getOptionSelected={(option, value) =>
                            //   option.id == values.syndicate_id
                            // }
                            getOptionLabel={option => option.syndicate_name}
                            onChange={(event, newValue) => {
                              console.log(newValue);
                              setFieldValue(
                                'syndicate_id',
                                newValue ? newValue.id : null
                              );
                            }}
                            options={options}
                            loading={loading}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                required
                                label="SPV Trust"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Box mt={2}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">#</TableCell>
                              <TableCell>From Investor</TableCell>
                              <TableCell align="right">
                                Distribution Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.fromInvestments.map((investment, index) => {
                              // const isItemSelected = isSelected(investment.id);
                              const labelId = `enhanced-table-checkbox-${investment.id}`;
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={investment.id}
                                >
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="investment"
                                    padding="none"
                                  >
                                    <Box>
                                      {investment.investor_name}
                                      {investment.account_name && (
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          <Label
                                            className={classes.label}
                                            color="primary"
                                          >
                                            {investment.account_name} |{' '}
                                            {currencyFormatter(
                                              parseFloat(investment.cash_amt)
                                            )}
                                            {' left'}
                                          </Label>
                                        </Typography>
                                      )}
                                    </Box>
                                  </TableCell>
                                  <TableCell align="right">
                                    <TextField
                                      size="small"
                                      error={
                                        touched &&
                                        touched.fromInvestments &&
                                        touched.fromInvestments[index] &&
                                        errors &&
                                        errors.fromInvestments &&
                                        errors.fromInvestments[index] &&
                                        errors.fromInvestments[index].amount
                                      }
                                      helperText={
                                        touched &&
                                        touched.fromInvestments &&
                                        touched.fromInvestments[index] &&
                                        errors &&
                                        errors.fromInvestments &&
                                        errors.fromInvestments[index] &&
                                        errors.fromInvestments[index].amount
                                      }
                                      name={`fromInvestments.${index}.amount`}
                                      InputProps={{
                                        inputComponent: CurrencyMask,
                                        onChange: handleChange(
                                          `fromInvestments.${index}.amount`
                                        )
                                      }}
                                      inputProps={{
                                        style: { textAlign: 'right' }
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={
                                        values.fromInvestments[index].amount ||
                                        ''
                                      }
                                      variant="outlined"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            <TableRow selected={true}>
                              <TableCell />
                              <TableCell align="right">Total</TableCell>
                              <TableCell align="right">
                                {currencyFormatter(
                                  distributionTotal(values.fromInvestments)
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Distribute to Sub-Trust
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

DistributionForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

DistributionForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default DistributionForm;
