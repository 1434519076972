import axios from 'axios';

export const GET_STAFF = '@staff/get-staff';
export const ADD_STAFF = '@staff/add-staff';
export const UPDATE_STAFF = '@staff/update-staff';

export function getStaff(params) {
  const request = axios.get('/api/staff', {params});

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_STAFF,
        payload: response.data
      })
    );
  };
}

export function addStaff(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/auth/account', values);
      dispatch({ type: ADD_STAFF, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function updateStaff(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/auth/account', values);
      dispatch({ type: UPDATE_STAFF, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}
