import axios from 'axios';

export const GET_SETTING = '@setting/get-setting';
export const GET_SYNDICATETYPE = '@setting/get-syndicatetype';
export const GET_SYNDICATESTAGE = '@setting/get-syndicatestage';
export const GET_SYNDICATEPURPOSE = '@setting/get-syndicatepurpose';
export const GET_BUSINESSSTREAM = '@setting/get-businessstream';
export const GET_INVESTMENTSTATUS = '@setting/get-investmentstatus';

export function getSetting() {
  const request = axios.get('/api/settings');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SETTING,
        payload: response.data
      })
    );
  };
}

export function getRiskLevel() {
  const request = axios.get('/api/syndicatetype');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SYNDICATETYPE,
        payload: response.data
      })
    );
  };
}

export function getSyndicateStage() {
  const request = axios.get('/api/syndicatestage');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SYNDICATESTAGE,
        payload: response.data
      })
    );
  };
}

export function getSyndicatePurpose() {
  const request = axios.get('/api/syndicatepurpose');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SYNDICATEPURPOSE,
        payload: response.data
      })
    );
  };
}

export function getBusinessStream() {
  const request = axios.get('/api/businessstream');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_BUSINESSSTREAM,
        payload: response.data
      })
    );
  };
}

export function getInvestmentStatus() {
  const request = axios.get('/api/investmentstatus');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_INVESTMENTSTATUS,
        payload: response.data
      })
    );
  };
}
