import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  CardHeader,
  Divider,
  Link,
  List,
  colors,
  makeStyles
} from '@material-ui/core';
import {
  FlagRounded as FlagIcon,
  Check as CheckIcon,
  WarningRounded as WarningIcon
} from '@material-ui/icons';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { getTasks } from 'src/actions/dashboardActions';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  entity: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function OnboardingTasks({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);
  const { tasks } = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(getTasks());
  }, [dispatch]);

  const data = tasks.allIds.map(id => ({
    ...tasks.byId[id]
  }));

  const columns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowdata => {
        return (
          <Link
            component={RouterLink}
            underline="none"
            to={`/app/management/syndicates/${rowdata.syndicate_id}/`}
          >
            {rowdata.syndicate_name}
          </Link>
        );
      }
    },

    {
      field: 'investor_name',
      title: 'Investor',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowdata => {
        return (
          <Link
            component={RouterLink}
            underline="none"
            to={`/app/management/investors/${rowdata.investor_id}/`}
          >
            {rowdata.account_name ? (
              <span>
                {rowdata.investor_name}
                {' || '}
                {rowdata.account_name}
              </span>
            ) : (
              <div>
                {rowdata.investor_name}
                {'|| '}
                <span className={classes.entity}>Entity Missing</span>
              </div>
            )}
          </Link>
        );
      }
    },
    { field: 'account_name', title: 'Entity', hidden: true },
    {
      field: 'application_status',
      title: 'Application Status',
      render: rowData => {
        if (rowData.application_status == null) {
          return 'To be sent';
        } else if (
          ['sent', 'clicked', 'delivered', 'opened'].includes(
            rowData.application_status
          )
        ) {
          return 'Investor to sign';
        } else if (rowData.application_status == 'signed') {
          return 'To be reviewed';
        } else if (rowData.application_status == 'verified') {
          return '';
        }
        return rowData.application_status;
      }
    },
    {
      field: 'certificate_email_status',
      title: 'Certificate Status',
      disableClickEventBubbling: true,
      render: rowData => {
        if (rowData.certificate_email_status == null) {
          return 'To be sent';
        } else if (
          ['sent', 'clicked', 'delivered', 'opened'].includes(
            rowData.certificate_email_status
          )
        ) {
          return '';
        }
        return rowData.application_status;
      }
    }
  ];

  return (
    <CustomMaterialTable
      title={
        <Typography variant="h5" color="primary">
          Onboarding Tasks
        </Typography>
      }
      data={data}
      columns={columns}
      isLoading={tasks.isLoading}
      options={{
        padding: 'dense',
        paging: false
      }}
    />
  );
}

OnboardingTasks.propTypes = {
  className: PropTypes.string
};

export default OnboardingTasks;
