import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import {
  addTransactions,
  updateTransaction
} from 'src/actions/syndicateActions';
import Label from 'src/components/Label';
import { CurrencyMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function TransactionForm({
  transaction,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { transactionType } = useSelector(state => state.setting);
  const { syndicate } = useSelector(state => state.syndicate);
  const { user } = useSelector(state => state.account);

  const initialValues = transaction;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   transaction_type_id: Yup.number().required(),
            //   transaction_dt: Yup.date().nullable()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                if (transaction.id) {
                  await dispatch(updateTransaction(transaction.id, values));
                } else {
                  await dispatch(addTransactions(values));
                }
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar(
                  transaction ? 'Transaction updated' : 'Transaction added',
                  {
                    variant: 'success'
                  }
                );
              } catch (error) {
                setErrors({ submit: error.response.data });
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="textPrimary">
                      {transaction.investor_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <Label className={classes.label} color="primary">
                        {transaction.account_name}
                      </Label>
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            required
                            disabled={values.id}
                            error={Boolean(
                              errors.submit && errors.submit.transaction_type_id
                            )}
                            helperText={
                              errors.submit &&
                              errors.submit.transaction_type_id &&
                              errors.submit.transaction_type_id[0]
                            }
                            label="Transaction Type"
                            name="transaction_type_id"
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: !!values.transaction_type_id
                            }}
                            select
                            SelectProps={{ native: true }}
                            value={values.transaction_type_id || ''}
                            variant="outlined"
                          >
                            <option></option>
                            {transactionType.allIds.map(id => (
                              <option
                                key={id}
                                value={id}
                                disabled={
                                  !user.groups.some(r => [1, 5].includes(r)) &&
                                  id > 2
                                }
                              >
                                {transactionType.byId[id].name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            required
                            fullWidth
                            error={Boolean(
                              errors.submit && errors.submit.amount
                            )}
                            helperText={
                              errors.submit &&
                              errors.submit.amount &&
                              errors.submit.amount[0]
                            }
                            label="Amount"
                            name="amount"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('amount')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Transaction Date"
                              value={values.transaction_dt}
                              onChange={newValue => {
                                setFieldValue(
                                  'transaction_dt',
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  value={values.transaction_dt}
                                  name="transaction_dt"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {JSON.stringify(errors.submit)}
                        </FormHelperText>
                      </Box>
                    )} */}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {transaction.id
                          ? 'Update Transaction'
                          : 'Add Transaction'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

TransactionForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

TransactionForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default TransactionForm;
