import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  CardHeader,
  Divider,
  Link,
  SvgIcon,
  List,
  colors,
  makeStyles
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import { MTableToolbar } from 'material-table';
import { AccessAlarm as OverdueIcon } from '@material-ui/icons';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  alarm: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function SydicateDue({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);

  const isMountedRef = useIsMountedRef();
  const [syndicates, setSyndicates] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSyndicates = useCallback(() => {
    axios
      .get('/api/view/syndicate', {
        params: { due: true }
      })
      .then(response => {
        if (isMountedRef.current) {
          setSyndicates(_.orderBy(response.data, 'end_dt', 'asc'));
          setLoading(false);
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getSyndicates();
  }, [getSyndicates]);

  const columns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowdata => {
        return (
          <Link
            component={RouterLink}
            underline="none"
            to={`/app/management/syndicates/${rowdata.syndicate_id}/`}
          >
            {rowdata.syndicate_name}
          </Link>
        );
      }
    },
    {
      field: 'project_manager',
      title: 'Manager'
    },
    {
      field: 'end_dt',
      title: 'Maturity Date',
      render: rowdata => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <span>{format(parseISO(rowdata.end_dt), 'dd/MM/yyyy')}</span>
            {parseISO(rowdata.end_dt) <= new Date() && (
              <OverdueIcon style={{ color: colors.red[600] }} />
            )}
          </div>
        );
      }
    }
  ];

  return loading ? (
    <Skeleton animation="wave" variant="rect" width="100%" height={150} />
  ) : (
    <CustomMaterialTable
      title={
        <Typography variant="h5" color="primary">
          Loans Due
        </Typography>
      }
      components={{
        Toolbar: props => (
          <div style={{ height: '50px' }}>
            <MTableToolbar {...props} />
          </div>
        )
      }}
      data={syndicates}
      columns={columns}
      options={{
        padding: 'dense',
        paging: false,
        search: false
      }}
    />
  );
}

SydicateDue.propTypes = {
  className: PropTypes.string
};

export default SydicateDue;
