import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { currencyFormatter } from 'src/utils/mask';

function BarProgress({ theme, value, stripe, progress, height }) {
  const Parentdiv = stripe
    ? {
        height: height,
        width: '100%',
        // backgroundColor: bgcolor || 'whitesmoke',
        // borderRadius: 5,
        background: `repeating-linear-gradient(315deg, #f5f5f5, #f5f5f5 2.5px,#b3fff7 2.5px,#b3fff7 5px)`
      }
    : {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke'
      };

  const Childdiv = {
    height: '100%',
    lineHeight: '100%',
    width: `${progress}%`,
    backgroundColor: '#00C7B1',
    // borderRadius: 5,
    textAlign: 'left'
  };

  const progresstext = {
    padding: 10,
    color: 'black',
    verticalAlign: 'middle'
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${currencyFormatter(value)}`}</span>
      </div>
    </div>
  );
}

BarProgress.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired
};

export default BarProgress;
