import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import InterestAbaForm from 'src/components/InterestAbaForm';
import OnboardingTasks from './OnboardingTasks';
import SydicateDue from './SyndicateDue';
import OpenSyndicates from './OpenSyndicates';
import InterestPayments from './InterestPayments';
import LoanInterest from './LoanInterest';
import Stats from './Stats';
import PoolOverview from './PoolOverview';
import CalendarView from './CalendarView';
import DocForm from './DocForm';
import UnpaidCommissions from './UnpaidCommission';
import InvestorSegmentation from './InvestorSegmentation';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

function DashboardView() {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const [openAbaForm, setOpenAbaForm] = useState(false);
  const [abaParams, setAbaParams] = useState(null);
  const [openDocForm, setOpenDocForm] = useState(false);
  const [docParams, setDocParams] = useState(null);

  const handleAbaFormOpen = aba => {
    console.log(aba);
    setAbaParams(aba);
    setOpenAbaForm(true);
  };

  const handleAbaFormClose = () => {
    setOpenAbaForm(false);
  };

  const handleDocFormOpen = value => {
    setDocParams(value);
    setOpenDocForm(true);
  };

  const handleDocFormClose = () => {
    setOpenDocForm(false);
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Stats />
          </Grid>
          <Grid item xs={12} md={6}>
            <CalendarView />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            spacing={1}
            alignItems="flex-start"
          >
            {/* <Grid item xs={12} md={12}>
              <InvestorSegmentation />
            </Grid> */}
            {user.group_names.some(r =>
              ['Fund Manager', 'Loan Analyst', 'Finance'].includes(r)
            ) && (
              <Grid item md={12} xs={12}>
                <LoanInterest />
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <SydicateDue />
            </Grid>
          </Grid>
          <Grid item lg={12} xl={12} md={12} xs={12}>
            <OpenSyndicates />
          </Grid>

          {user.group_names.some(r =>
            ['Fund Manager', 'Finance'].includes(r)
          ) && (
            <Grid item lg={12} xl={12} md={12} xs={12}>
              <InterestPayments
                onOpenAbaForm={handleAbaFormOpen}
                onOpenDocForm={handleDocFormOpen}
              />
            </Grid>
          )}
          <Grid item lg={12} xl={12} md={12} xs={12}>
            <UnpaidCommissions onOpenAbaForm={handleAbaFormOpen} />
          </Grid>
          <Grid item lg={12} xl={12} xs={12}>
            <OnboardingTasks />
          </Grid>
        </Grid>
        <InterestAbaForm
          abaParams={abaParams}
          onAdd={handleAbaFormClose}
          onClose={handleAbaFormClose}
          open={openAbaForm}
        />
        <DocForm
          docParams={docParams}
          onAdd={handleDocFormClose}
          onClose={handleDocFormClose}
          open={openDocForm}
        />
      </Container>
    </Page>
  );
}

export default DashboardView;
