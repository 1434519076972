import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SlashScreen from 'src/components/SplashScreen';
import { getFundraisers } from 'src/actions/fundraiserActions';
import { getStaff } from 'src/actions/staffActions';
import { getSetting } from 'src/actions/settingActions';
import { getInvestor } from 'src/actions/investorActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import InvestorForm from './InvestorForm';
import Header from './Header';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function InvestorCRUDView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { investorId } = useParams();
  const { user } = useSelector(state => state.account);
  const { investor, isLoading } = useSelector(state => state.investor);

  useEffect(() => {
    dispatch(getInvestor(investorId));
    dispatch(getFundraisers());
    dispatch(getSetting());
    if (user.is_admin) {
      dispatch(getStaff());
    }
  }, [dispatch]);

  if (investorId && isLoading) {
    return <SlashScreen />;
  }

  return (
    <Page className={classes.root} title="Investor Edit">
      <Container maxWidth="lg">
        <Header investor={investor} />
        <Box mt={3}>
          <InvestorForm investor={investor} />
        </Box>
      </Container>
    </Page>
  );
}

export default InvestorCRUDView;
