import produce from 'immer';
import _ from 'lodash';
import {
  INIT,
  GET_TASKS,
  UPDATE_TASK,
  GET_EVENTS,
  UPDATE_EVENT,
  GET_UNPAID_INTEREST,
  GET_UNPAID_COMMISSIONS,
  UPDATE_COMMISSION,
  GET_LOAN_INTEREST,
  UPDATE_INTEREST_BATCH
} from 'src/actions/dashboardActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  interestBatches: { byId: {}, allIds: [], isLoading: true },
  loanInterests: { loanInterests: [], isLoading: true },
  unpaidCommissions: { unpaidCommissions: [], isLoading: true },
  tasks: { byId: {}, allIds: [], isLoading: true },
  events: { byId: {}, allIds: [], isLoading: true }
};

// function customizer(objValue, srcValue) {
//   if (_.isArray(objValue)) {
//     return srcValue;
//   }
// }

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT: {
      return initialState;
    }
    case GET_TASKS: {
      const tasks = action.payload;
      return produce(state, draft => {
        draft.tasks.byId = objFromArray(tasks);
        draft.tasks.allIds = tasks.map(task => task.id);
        draft.tasks.isLoading = false;
      });
    }

    case UPDATE_TASK: {
      const task = action.payload;
      return produce(state, draft => {
        _.merge(draft.tasks.byId[task.id], task);
      });
    }

    case GET_EVENTS: {
      const events = action.payload;
      return produce(state, draft => {
        draft.events.byId = objFromArray(events);
        draft.events.allIds = events.map(event => event.id);
        draft.events.isLoading = false;
      });
    }

    case UPDATE_EVENT: {
      const event = action.payload;
      return produce(state, draft => {
        _.merge(draft.events.byId[event.id], event);
      });
    }

    case GET_UNPAID_INTEREST: {
      const interestBatches = action.payload;
      return produce(state, draft => {
        draft.interestBatches.byId = objFromArray(interestBatches);
        draft.interestBatches.allIds = interestBatches.map(
          interestBatch => interestBatch.id
        );
        draft.interestBatches.isLoading = false;
      });
    }

    case UPDATE_INTEREST_BATCH: {
      const batch = action.payload;

      return produce(state, draft => {
        _.merge(draft.interestBatches.byId[batch.id], batch);
      });
    }

    case GET_LOAN_INTEREST: {
      const loanInterests = action.payload;
      return produce(state, draft => {
        draft.loanInterests.loanInterests = loanInterests;
        draft.loanInterests.isLoading = false;
      });
    }

    case GET_UNPAID_COMMISSIONS: {
      const unpaidCommissions = action.payload;
      return produce(state, draft => {
        draft.unpaidCommissions.unpaidCommissions = unpaidCommissions;
        draft.unpaidCommissions.isLoading = false;
      });
    }
    case UPDATE_COMMISSION: {
      const fundraiserId = action.payload;
      return produce(state, draft => {
        draft.unpaidCommissions.unpaidCommissions = draft.unpaidCommissions.unpaidCommissions.filter(
          commission => commission.fundraiser_id !== fundraiserId
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
