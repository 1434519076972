import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {  Divider, makeStyles } from '@material-ui/core';
import { getCampaigns } from 'src/actions/campaignActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import getInitials from 'src/utils/getInitials';
import Toolbar from './Toolbar';
import CampaignItem from './CampaignItem';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.dark
  },

}));

function CampaignList() {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const params = useParams();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { campaigns } = useSelector(state => state.campaign);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  // const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);

  const getLogs = useCallback(() => {
    axios.get('/api/logs', { params: { getCampaign: true } }).then(response => {
      if (isMountedRef.current) {
        setLogs(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  const handleSearch = value => {
    if (value) {
      setFilteredCampaigns(
        campaigns.allIds.filter(id =>
          campaigns.byId[id].subject.toUpperCase().includes(value.toUpperCase())
        )
      );
    } else setFilteredCampaigns(campaigns.allIds);
  };

  // const handleSelectAllCampaigns = () => {
  //   setSelectedCampaigns(campaigns.allIds);
  // };

  // const handleDeselectAllCampaigns = () => {
  //   setSelectedCampaigns([]);
  // };

  const handleSelectOneCampaign = campaignId => {
    setSelectedCampaign(campaignId);
  };

  const handleDeselectOneCampaign = () => {
    setSelectedCampaign(null);
  };

  useEffect(() => {
    if (params.label == 'Logs') {
      getLogs();
    } else {
      dispatch(getCampaigns(params));
    }
  }, [dispatch, params]);

  useEffect(() => {
    setFilteredCampaigns(campaigns.allIds);
  }, [campaigns]);

  const columns = [
    {
      title: 'Date',
      field: 'sent_dt',
      type: 'date',
      dateSetting: { locale: 'en-AU' },
      defaultSort: 'desc'
    },
    {
      title: 'Sender',
      field: 'sender'
    },
    {
      title: 'Type',
      field: 'doc_type'
    },
    {
      title: 'Campaign',
      field: 'campaign',
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      title: 'Receiver',
      field: 'investor_name'
    },
    {
      title: 'Status',
      field: 'email_status'
    }
  ];
  return (
    <div className={classes.root}>
      {params.label == 'Logs' ? (
        <CustomMaterialTable
          title="Email Logs"
          data={logs}
          columns={columns}
          isLoading={loading}
          options={{
            padding: 'dense',
            pageSize: 10,
            pageSizeOptions: [5, 10]
          }}
        />
      ) : (
        <>
          <Toolbar
            onSearch={handleSearch}
            selectedCampaign={selectedCampaign}
            campaigns={campaigns}
          />
          <Divider />
          {_.uniq(filteredCampaigns.map(Number)).map(campaignId => (
            <CampaignItem
              campaign={campaigns.byId[campaignId]}
              key={campaignId}
              onDeselect={() => handleDeselectOneCampaign()}
              onSelect={() => handleSelectOneCampaign(campaignId)}
              selected={selectedCampaign}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default CampaignList;
