import axios from 'axios';
import _ from 'lodash';

export const INIT = '@application/init';
export const GET_APPLICATION = '@application/get-application';
export const UPDATE_APPLICATION = '@application/update-application';
export const SET_ADDRESS = '@application/update-address';
export const ERROR = '@application/error';

export function getApplication(investmentId) {
  const request = axios.get(`/api/applications/${investmentId}`);

  return dispatch => {
    dispatch({ type: INIT });
    request.then(response =>
      dispatch({
        type: GET_APPLICATION,
        payload: response.data
      })
    );
  };
}

export function updateApplication(applicationId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/applications/${applicationId}`,
        values
      );
      dispatch({ type: UPDATE_APPLICATION, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function setAddress(values) {
  return async dispatch => {
    try {
      const address = {
        address_1: values.investor_id.address_1,
        address_2: values.investor_id.address_2,
        city: values.investor_id.city,
        state: values.investor_id.state,
        postcode: values.investor_id.postcode,
        country: values.investor_id.country,
        full_address: [
          values.investor_id.address_1,
          values.investor_id.address_2,
          values.investor_id.city,
          values.investor_id.state,
          values.investor_id.postcode,
          values.investor_id.country
        ]
          .filter(function(entry) {
            return entry.trim() != '';
          })
          .join(', ')
      };
      dispatch({ type: SET_ADDRESS, payload: address });
    } catch (error) {
      throw error;
    }
  };
}
