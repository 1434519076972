import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, FieldArray } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  IconButton,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { DeleteOutlined as TrashIcon } from '@mui/icons-material';
import { Plus as PlusIcon } from 'src/components/icons/plus';
import { format, addMonths, subDays } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { CurrencyMask, PercentMask, BSBMask, NumberMask } from 'src/utils/mask';
import { updateSyndicate, addSyndicate } from 'src/actions/syndicateActions';
import SecurityForm from './SecurityForm';

const useStyles = makeStyles(() => ({
  root: {},
  editor: {
    '& .ql-editor': {
      height: 400
    }
  }
}));

const filter = createFilterOptions();

function SyndicateForm({
  className,
  pools,
  poolLoading,
  securityLoading,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    syndicatePurpose,
    riskLevel,
    syndicateStage,
    businessStream,
    accrualType,
    paymentFreq,
    entityType
  } = useSelector(state => state.setting);
  const { user } = useSelector(state => state.account);
  const { syndicateId } = useParams();
  const { syndicate } = useSelector(state => state.syndicate);
  const { staff } = useSelector(state => state.staff);
  const { securities } = useSelector(state => state.security);
  const { developers } = useSelector(state => state.developer);
  const [redict, setRedirect] = useState(false);
  const [security, setSecurity] = useState(null);
  const [openSecurityForm, setOpenSecurityForm] = useState(false);

  const handleSecurityFormOpen = values => {
    setSecurity(values);
    setOpenSecurityForm(true);
  };

  const handleSecurityFormClose = () => {
    setOpenSecurityForm(false);
  };

  useEffect(() => {
    if (redict) {
      if (syndicateId) {
        history.push(`/app/management/syndicates/${syndicateId}`);
      } else if (syndicate) {
        history.push(`/app/management/syndicates/${syndicate.id}`);
      }
    }
  }, [redict]);

  const developerOptions = developers.allIds.map(id => {
    return {
      id: id,
      developer_name: developers.byId[id]['developer_name']
    };
  });

  const initialValues = syndicate
    ? syndicate
    : {
        start_dt: null,
        end_dt: null,
        default_surcharge: 5,
        trust_deed_dt: null,
        syndicate_stage_id: 1,
        business_stream_id: 1,
        payment_freq_id: 1,
        accu_type_id: 1,
        syndicate_purpose_id: null,
        is_public_offering: false,
        is_contributory: false,
        is_internal: true,
        is_pool: false,
        entity_id: null,
        assigned_to: [user.id],
        staff_id: user.id,
        entity_type_id: 4,
        interest_collect_freq_id: 2,
        borrower_interest_accu_type_id: 1,
        bank_acct_name: 'Wealth Pi Investor Account',
        bsb: '033-086',
        bank_acct_no: '497118',
        spv_tfn: null,
        spv_abn: null,
        unit_classes: [
          {
            unit_class: 'Ordinary',
            portion: 100,
            is_senior: true,
            premium_cutoff: 500000
          }
        ],
        staged_coupon: [],
        offer: []
      };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do api call
          if (values.syndicate_purpose_id != 2) {
            delete values.staged_coupon;
          }
          if (syndicateId) {
            await dispatch(updateSyndicate(syndicateId, values));
          } else {
            await dispatch(addSyndicate(values));
          }
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Syndicate Updated', {
            variant: 'success'
          });
          setRedirect(true);
        } catch (error) {
          setErrors({ submit: JSON.stringify(error.response.data) });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setTouched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mb={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <SecurityForm
            security={security}
            onClose={handleSecurityFormClose}
            open={openSecurityForm}
            tempSecurities={values.security}
            setSecurity={setFieldValue}
          />
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Syndicate Name"
                    error={Boolean(
                      errors.submit && errors.submit.syndicate_name
                    )}
                    helperText={
                      errors.submit &&
                      errors.submit.syndicate_name &&
                      errors.submit.syndicate_name[0]
                    }
                    name="syndicate_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.syndicate_name || ''}
                    variant="outlined"
                  />
                </Grid>
                {user.is_admin && (
                  <Grid item xs={12} md={2}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      label="Project Manager"
                      name="staff_id"
                      InputLabelProps={{
                        shrink: !!values.staff_id
                      }}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.staff_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {staff.allIds
                        .filter(id => staff.byId[id].is_employee)
                        .map(id => (
                          <option key={id} value={id}>
                            {staff.byId[id].full_name}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item md={2} xs={12} display="grid">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={values.is_pool || false}
                        onChange={handleChange}
                        name="is_pool"
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Is Pool Fund
                      </Typography>
                    }
                  />
                </Grid>
                {values.is_pool ? (
                  <Grid item md={2} xs={12} display="grid">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={values.is_contributory || false}
                          onChange={handleChange}
                          name="is_contributory"
                        />
                      }
                      label={
                        <Typography className={classes.formControlLabel}>
                          Is Contributory
                        </Typography>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item md={2} xs={12} display="grid">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={values.is_internal || false}
                          onChange={handleChange}
                          name="is_internal"
                        />
                      }
                      label={
                        <Typography className={classes.formControlLabel}>
                          Internal SPV
                        </Typography>
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Syndicate Stage"
                    name="syndicate_stage_id"
                    error={Boolean(errors && errors.syndicate_stage_id)}
                    helperText={errors && errors.syndicate_stage_id}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: !!values.syndicate_stage_id
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={values.syndicate_stage_id || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {syndicateStage.allIds.map(id => (
                      <option key={id} value={id}>
                        {syndicateStage.byId[id].name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    size="small"
                    fullWidth
                    required
                    label="Business Stream"
                    name="business_stream_id"
                    InputLabelProps={{
                      shrink: !!values.business_stream_id
                    }}
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.business_stream_id || ''}
                    variant="outlined"
                  >
                    <option></option>
                    {businessStream.allIds.map(id => (
                      <option key={id} value={id}>
                        {businessStream.byId[id].name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {!values.is_pool && values.business_stream_id == 1 && (
                  <>
                    <Grid item md={3} xs={12}>
                      <Autocomplete
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        name="developer_id"
                        value={
                          values.developer_id
                            ? developerOptions.filter(
                                option => option.id == values.developer_id
                              )[0]
                            : null
                        }
                        options={developerOptions}
                        getOptionLabel={option => option.developer_name}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            'developer_id',
                            newValue ? newValue.id : null
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            label="Developer"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Borrower Entity"
                        name="borrower_spv_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.borrower_spv_name || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
                {values.is_pool && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Linked Investment Entity"
                      name="entity_id"
                      InputLabelProps={{
                        shrink: !!values.entity_id
                      }}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.entity_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {pools.map(pool => (
                        <option key={pool.id} value={pool.id}>
                          {pool.account_name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {!values.is_pool && (
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      multiple
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      name="security"
                      value={
                        values.security
                          ? values.security.map(id => {
                              return securities.byId[id];
                            })
                          : []
                      }
                      options={securities.allIds.map(id => {
                        return securities.byId[id];
                      })}
                      getOptionLabel={option => option.full_address}
                      onChange={(event, newValues) => {
                        if (
                          newValues &&
                          newValues.full_address === 'Add New Security'
                        ) {
                          handleSecurityFormOpen();
                        } else {
                          setFieldValue(
                            'security',
                            newValues
                              .filter(
                                value => !value.hasOwnProperty('inputValue')
                              )
                              .map(value => value.id) || []
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            full_address: 'Add New Security'
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option, newValues) => {
                        return (
                          <>
                            {option.inputValue ? (
                              <Button
                                fullWidth
                                onClick={() =>
                                  handleSecurityFormOpen({
                                    address: newValues.inputValue
                                  })
                                }
                                size="small"
                                startIcon={<PlusIcon fontSize="small" />}
                              >
                                {option.full_address}
                              </Button>
                            ) : (
                              // <Button
                              //   fullWidth
                              //   size="small"
                              //   onClick={() => openSecurityForm()}
                              // >
                              //   <SvgIcon fontSize="small" color="primary">
                              //     <AddIcon />
                              //   </SvgIcon>
                              //   {option.full_address}
                              // </Button>
                              option.full_address
                            )}
                          </>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          label="Security"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {securityLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {/* 
                    <Grid item md={6} xs={12}>
                      <Autocomplete
                        autoSelect
                        value={values.broker_id}
                        options={brokers}
                        getOptionLabel={option => option.broker_name}
                        onChange={(event, value) => {
                          setFieldValue('broker_id', value.broker_id);
                        }}
                        onSelect={val =>
                          setFieldValue('broker_id', val.broker_id)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                      fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            label="Broker"
                            value={values.broker_id}
                            name="broker_id"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {brokerLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid> */}
              </Grid>
            </CardContent>
          </Card>
          <Box mt={3}>
            <Card>
              <CardHeader title="Loan Parameters" />
              <Divider />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      label="Risk Level"
                      name="risk_level_id"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: !!values.risk_level_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={values.risk_level_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {riskLevel.allIds.map(id => (
                        <option key={id} value={id}>
                          {riskLevel.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  {!values.is_pool && (
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required={!values.is_pool}
                        label="Syndicate Purpose"
                        name="syndicate_purpose_id"
                        InputLabelProps={{
                          shrink: !!values.syndicate_purpose_id
                        }}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.syndicate_purpose_id || ''}
                        variant="outlined"
                      >
                        <option></option>
                        {syndicatePurpose.allIds.map(id => (
                          <option key={id} value={id}>
                            {syndicatePurpose.byId[id].name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      label="Loan Amount"
                      name="sought_amt"
                      InputProps={{
                        inputComponent: CurrencyMask,
                        onChange: handleChange('sought_amt')
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.sought_amt || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {values.is_pool && (
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="New Batch"
                        name="new_batch_amt"
                        InputProps={{
                          inputComponent: CurrencyMask,
                          onChange: handleChange('new_batch_amt')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.new_batch_amt || ''}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  {!values.is_pool && values.syndicate_purpose_id == 2 && (
                    <>
                      <Grid item xs={12} md={3}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Construction Cost"
                          name="construction_cost_amt"
                          InputProps={{
                            inputComponent: CurrencyMask,
                            onChange: handleChange('construction_cost_amt')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.construction_cost_amt || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Contingency to Retain"
                          name="construction_retain_pct"
                          InputProps={{
                            inputComponent: PercentMask,
                            onChange: handleChange('construction_retain_pct')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.construction_retain_pct || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      label="Term (in months)"
                      name="term"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.term || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {!values.is_pool && (
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required={!values.is_pool}
                        label="LVR"
                        name="lvr"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('lvr')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lvr || ''}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Max LVR"
                      required={values.is_pool}
                      name="max_lvr"
                      InputProps={{
                        inputComponent: PercentMask,
                        onChange: handleChange('max_lvr')
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.max_lvr || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {values.is_pool && (
                    <>
                      <Grid item xs={12} md={3}>
                        <TextField
                          size="small"
                          fullWidth
                          required
                          label="Max Exposure"
                          name="max_exposure"
                          InputProps={{
                            inputComponent: PercentMask,
                            onChange: handleChange('max_exposure')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.max_exposure || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )}
                  {!values.is_pool && (
                    <>
                      <Grid item xs={12} md={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Settlement Date"
                            value={values.start_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'start_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                              setFieldValue(
                                'end_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? null
                                  : format(
                                      subDays(
                                        addMonths(newValue, values.term),
                                        1
                                      ),
                                      'yyyy-MM-dd'
                                    )
                              );
                              // setFieldValue(
                              //   `staged_coupon.${0}.start_dt`,
                              //   !newValue ||
                              //     newValue.toString() == 'Invalid Date'
                              //     ? null
                              //     : format(newValue, 'yyyy-MM-dd')
                              // );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                required={!values.is_pool}
                                variant="outlined"
                                value={values.start_dt}
                                name="start_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Repayment Date"
                            value={values.end_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'end_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                required={!values.is_pool}
                                variant="outlined"
                                value={values.end_dt}
                                name="end_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Referral Fee"
                      name="referral_fee"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.referral_fee || ''}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {!values.is_pool && values.is_internal && (
            <Box mt={3}>
              <Card>
                <CardHeader title="Pricing" />
                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        label="Establishment Fee"
                        name="est_fee"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('est_fee')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.est_fee || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Risk Fee"
                        name="risk_fee"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('risk_fee')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.risk_fee || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        label="Interest"
                        name="interest"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('interest')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.interest || ''}
                        variant="outlined"
                      />
                    </Grid>
                    {values.syndicate_purpose_id == 2 && (
                      <Grid item xs={12} md={3}>
                        <TextField
                          size="small"
                          fullWidth
                          required
                          label="Line Fee"
                          name="line_fee"
                          InputProps={{
                            inputComponent: PercentMask,
                            onChange: handleChange('line_fee')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.line_fee || ''}
                          variant="outlined"
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        label="Default Surcharge"
                        name="default_surcharge"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('default_surcharge')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.default_surcharge || ''}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Brokerage Fee"
                        name="brokerage_fee"
                        InputProps={{
                          inputComponent: PercentMask,
                          onChange: handleChange('brokerage_fee')
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.brokerage_fee || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Interest Collection Frequency"
                        name="interest_collect_freq_id"
                        InputLabelProps={{
                          shrink: !!values.interest_collect_freq_id
                        }}
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.interest_collect_freq_id || ''}
                        variant="outlined"
                      >
                        <option></option>
                        {paymentFreq.allIds.map(id => (
                          <option key={id} value={id}>
                            {paymentFreq.byId[id].name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Interest Calculation Method"
                        name="borrower_interest_accu_type_id"
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: !!values.borrower_interest_accu_type_id
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.borrower_interest_accu_type_id || ''}
                        variant="outlined"
                      >
                        <option></option>
                        {accrualType.allIds.map(id => (
                          <option key={id} value={id}>
                            {accrualType.byId[id].name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}
          <Box mt={3}>
            <Card>
              <CardHeader title="Offer to Investors" />
              <Divider />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      gutterBottom
                    >
                      Payment Frequency
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      label="Coupon Payment Frequency"
                      name="payment_freq_id"
                      InputLabelProps={{
                        shrink: !!values.payment_freq_id
                      }}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.payment_freq_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {paymentFreq.allIds.map(id => (
                        <option key={id} value={id}>
                          {paymentFreq.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      label="Coupon Calculation Method"
                      name="accu_type_id"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: !!values.accu_type_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={values.accu_type_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {accrualType.allIds.map(id => (
                        <option key={id} value={id}>
                          {accrualType.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Topup Payment Frequency"
                      name="topup_payment_freq_id"
                      InputLabelProps={{
                        shrink: !!values.topup_payment_freq_id
                      }}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.topup_payment_freq_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {paymentFreq.allIds.map(id => (
                        <option key={id} value={id}>
                          {paymentFreq.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Topup Calculation Method"
                      name="topup_accu_type_id"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: !!values.topup_accu_type_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={values.topup_accu_type_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {accrualType.allIds.map(id => (
                        <option key={id} value={id}>
                          {accrualType.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  {user.group_names.some(r => ['Fund Manager'].includes(r)) &&
                    values.syndicate_purpose_id != 2 && (
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Coupon Rate
                          </Typography>
                        </Grid>
                        <FieldArray
                          name="unit_classes"
                          render={({ insert, remove, push }) => (
                            <>
                              {values.unit_classes &&
                                values.unit_classes.length > 0 &&
                                values.unit_classes.map((unit_class, index) => (
                                  <Fragment key={index}>
                                    <Grid item xs={12} md={1}>
                                      <TextField
                                        size="small"
                                        required
                                        fullWidth
                                        label="Unit Class"
                                        name={`unit_classes.${index}.unit_class`}
                                        InputLabelProps={{
                                          shrink: !!values.unit_classes[index]
                                            .unit_class
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.unit_classes[index]
                                            .unit_class || ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <TextField
                                        size="small"
                                        required
                                        fullWidth
                                        label="Coupon"
                                        name={`unit_classes.${index}.coupon`}
                                        InputProps={{
                                          inputComponent: PercentMask,
                                          onChange: handleChange(
                                            `unit_classes.${index}.coupon`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.unit_classes[index].coupon ||
                                          ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <TextField
                                        size="small"
                                        fullWidth
                                        label="Premium Coupon"
                                        name={`unit_classes.${index}.premium`}
                                        InputProps={{
                                          inputComponent: PercentMask,
                                          onChange: handleChange(
                                            `unit_classes.${index}.premium`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.unit_classes[index].premium ||
                                          ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <TextField
                                        size="small"
                                        fullWidth
                                        label="Premium Criteria"
                                        required={
                                          values.unit_classes[index].premium
                                        }
                                        name={`unit_classes.${index}.premium_cutoff`}
                                        InputProps={{
                                          inputComponent: CurrencyMask,
                                          onChange: handleChange(
                                            `unit_classes.${index}.premium_cutoff`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.unit_classes[index]
                                            .premium_cutoff || ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                      <TextField
                                        size="small"
                                        fullWidth
                                        label="Top-up"
                                        name={`unit_classes.${index}.topup`}
                                        InputProps={{
                                          inputComponent: PercentMask,
                                          onChange: handleChange(
                                            `unit_classes.${index}.topup`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.unit_classes[index].topup || ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                      <TextField
                                        size="small"
                                        fullWidth
                                        label="Portion"
                                        name={`unit_classes.${index}.portion`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        InputProps={{
                                          inputComponent: PercentMask,
                                          onChange: handleChange(
                                            `unit_classes.${index}.portion`
                                          )
                                        }}
                                        value={
                                          values.unit_classes[index].portion
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12} display="grid">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.unit_classes[index]
                                                .is_senior || false
                                            }
                                            onChange={handleChange}
                                            name={`unit_classes.${index}.is_senior`}
                                          />
                                        }
                                        label="Senior"
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12} display="grid">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.unit_classes[index]
                                                .is_equity || false
                                            }
                                            onChange={handleChange}
                                            name={`unit_classes.${index}.is_equity`}
                                          />
                                        }
                                        label="Equity"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                      <IconButton
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <SvgIcon fontSize="small">
                                          <TrashIcon />
                                        </SvgIcon>
                                      </IconButton>
                                    </Grid>
                                  </Fragment>
                                ))}
                              <Grid item xs={12} md={12}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() =>
                                    push({
                                      unit_class: '',
                                      portion: '',
                                      is_senior: false,
                                      is_equity: false,
                                      premium_cutoff: 500000
                                    })
                                  }
                                >
                                  Add Unit Class
                                </Button>
                              </Grid>
                            </>
                          )}
                        />
                      </>
                    )}
                  {user.group_names.some(r => ['Fund Manager'].includes(r)) &&
                    values.syndicate_purpose_id == 2 && (
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="secondary"
                            gutterBottom
                          >
                            Staged Coupon Rate
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            size="small"
                            fullWidth
                            multiline
                            rows={2}
                            label="Offer Overview"
                            name="offer_desc"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.offer_desc || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <FieldArray
                          name="staged_coupon"
                          render={({ insert, remove, push }) => (
                            <>
                              {values.staged_coupon &&
                                values.staged_coupon.length > 0 &&
                                values.staged_coupon.map((interest, index) => (
                                  <Fragment key={index}>
                                    <Grid item xs={12} md={3}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={enAU}
                                      >
                                        <DatePicker
                                          label="Start Date"
                                          value={
                                            values.staged_coupon[index].start_dt
                                          }
                                          onChange={newValue => {
                                            setFieldValue(
                                              `staged_coupon.${index}.start_dt`,
                                              !newValue ||
                                                newValue.toString() ==
                                                  'Invalid Date'
                                                ? newValue
                                                : format(newValue, 'yyyy-MM-dd')
                                            );
                                          }}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              size="small"
                                              fullWidth
                                              required
                                              variant="outlined"
                                              name={`staged_coupon.${index}.start_dt`}
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <TextField
                                        size="small"
                                        required
                                        fullWidth
                                        label="Coupon"
                                        name={`staged_coupon.${index}.coupon`}
                                        InputProps={{
                                          inputComponent: PercentMask,
                                          onChange: handleChange(
                                            `staged_coupon.${index}.coupon`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.staged_coupon[index].coupon ||
                                          ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <TextField
                                        size="small"
                                        fullWidth
                                        label="Premium Coupon"
                                        name={`staged_coupon.${index}.premium`}
                                        InputProps={{
                                          inputComponent: PercentMask,
                                          onChange: handleChange(
                                            `staged_coupon.${index}.premium`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.staged_coupon[index].premium ||
                                          ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <TextField
                                        size="small"
                                        fullWidth
                                        required={
                                          values.staged_coupon[index].premium
                                        }
                                        label="Premium Criteria"
                                        name={`staged_coupon.${index}.premium_cutoff`}
                                        InputProps={{
                                          inputComponent: CurrencyMask,
                                          onChange: handleChange(
                                            `staged_coupon.${index}.premium_cutoff`
                                          )
                                        }}
                                        onChange={handleChange}
                                        value={
                                          values.staged_coupon[index]
                                            .premium_cutoff || ''
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                      <IconButton
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        <SvgIcon fontSize="small">
                                          <TrashIcon />
                                        </SvgIcon>
                                      </IconButton>
                                    </Grid>
                                  </Fragment>
                                ))}
                              <Grid item xs={12} md={12}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() =>
                                    push({
                                      start_dt: null,
                                      premium_cutoff: 500000
                                    })
                                  }
                                >
                                  Add Stage
                                </Button>
                              </Grid>
                            </>
                          )}
                        />
                      </>
                    )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box mt={3}>
            <Card>
              <CardHeader title="SPV & Bank Account" />
              <Divider />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={9} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="SPV Name"
                      name="spv_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.spv_name || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="SPV Type"
                      name="entity_type_id"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: !!values.entity_type_id
                      }}
                      select
                      SelectProps={{ native: true }}
                      value={values.entity_type_id || ''}
                      variant="outlined"
                    >
                      <option></option>
                      {entityType.allIds.map(id => (
                        <option key={id} value={id}>
                          {entityType.byId[id].name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={enAU}
                    >
                      <DatePicker
                        label="Trust Deed Date"
                        value={values.trust_deed_dt}
                        onChange={newValue => {
                          setFieldValue(
                            'trust_deed_dt',
                            !newValue || newValue.toString() == 'Invalid Date'
                              ? newValue
                              : format(newValue, 'yyyy-MM-dd')
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={values.trust_deed_dt}
                            name="trust_deed_dt"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_public_offering}
                          onChange={handleChange}
                          name="is_public_offering"
                        />
                      }
                      label="Public Offering"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="ABN"
                      name="spv_abn"
                      InputProps={{
                        inputComponent: NumberMask,
                        onChange: handleChange('spv_abn'),
                        maxLength: 11
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.spv_abn || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="TFN"
                      name="spv_tfn"
                      InputProps={{
                        inputComponent: NumberMask,
                        onChange: handleChange('spv_tfn'),
                        maxLength: 9
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.spv_tfn || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Payment Reference"
                      name="reference"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 6 }}
                      value={values.reference || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Bank Account Name"
                      name="bank_acct_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 32 }}
                      value={values.bank_acct_name || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="BSB"
                      name="bsb"
                      InputProps={{
                        inputComponent: BSBMask,
                        onChange: handleChange('bsb')
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bsb || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Bank Account"
                      name="bank_acct_no"
                      InputProps={{
                        inputComponent: NumberMask,
                        onChange: handleChange('bank_acct_no')
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bank_acct_no || ''}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          <Box mt={2}>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              {syndicate ? 'Update syndicate' : 'Create syndicate'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

SyndicateForm.propTypes = {
  className: PropTypes.string
};

export default SyndicateForm;
