import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Chip,
  Typography,
  ClickAwayListener,
  Divider,
  IconButton,
  Input,
  Menu,
  MenuItem,
  OutlinedInput
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { DotsHorizontal } from 'src/components/icons/dots-horizontal';
import { updateList } from 'src/actions/kanbanActions';
import { currencyFormatter } from 'src/utils/mask';
// import { clearColumn, deleteColumn, updateColumn } from '../../../slices/kanban';
import { KanbanCard } from './kanban-card';
import { KanbanCardAdd } from './kanban-card-add';

const columnSelector = (state, columnId) => {
  const { lists } = state.kanban;

  return lists.byId[columnId];
};

export const KanbanColumn = props => {
  const { columnId, ...other } = props;
  const dispatch = useDispatch();
  const moreRef = useRef(null);
  const { cards, filter } = useSelector(state => state.kanban);
  const column = useSelector(state => columnSelector(state, columnId));
  const [openMenu, setOpenMenu] = useState(false);
  const [name, setName] = useState(column.name);
  const [isRenaming, setIsRenaming] = useState(false);

  const total = column.cardIds
    .map(cardId => cards.byId[cardId])
    .reduce((n, { sought_amt }) => n + parseFloat(sought_amt || 0), 0);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleChange = event => {
    setName(event.target.value);
  };

  const handleRenameInit = () => {
    setIsRenaming(true);
  };

  const handleRename = async () => {
    try {
      // If name is empty use the initial column name
      if (!name) {
        setName(column.name);
        setIsRenaming(false);
        return;
      }

      // If name is equal to column name, it means there are no changes
      if (name === column.name) {
        setIsRenaming(false);
        return;
      }

      const update = { name: name };

      await dispatch(updateList(column.id, update));
      setIsRenaming(false);
      toast.success('Column updated!');
    } catch (err) {
      console.error(err);
      toast.error('Renaming column failed!');
    }
  };

  const handleDelete = async () => {
    try {
      setOpenMenu(false);
      await dispatch(deleteColumn(column.id));
      toast.success('Column deleted!');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };

  const handleClear = async () => {
    try {
      setOpenMenu(false);
      await dispatch(clearColumn(column.id));
      toast.success('Column cleared!');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };

  return (
    <div {...other}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
          mx: 2,
          overflowX: 'hidden',
          overflowY: 'hidden',
          width: {
            xs: 300,
            sm: 380
          }
        }}
      >
        <Box sx={{ borderRadius: 3, backgroundColor: 'rgb(229, 231, 235)' }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              pl: 1,
              pr: 2,
              py: 1
            }}
          >
            {isRenaming ? (
              <ClickAwayListener onClickAway={handleRename}>
                <OutlinedInput
                  autoFocus
                  fullWidth
                  onBlur={handleRename}
                  onChange={handleChange}
                  value={name}
                  sx={{
                    backgroundColor: 'background.paper',
                    '& .MuiInputBase-input': {
                      px: 1,
                      py: 1
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'grey'
                      }
                    }
                  }}
                />
              </ClickAwayListener>
            ) : (
              <Input
                disableUnderline
                fullWidth
                onClick={handleRenameInit}
                value={column.name}
                sx={{
                  borderColor: 'transparent',
                  borderRadius: 1,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  cursor: 'text',
                  m: '-1px',
                  '&:hover': {
                    backgroundColor: 'action.selected'
                  },
                  '& .MuiInputBase-input': {
                    fontWeight: 500,
                    px: 1,
                    py: 1
                  }
                }}
              />
            )}
            {/* <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <IconButton
                sx={{ ml: 2 }}
                edge="end"
                onClick={handleMenuOpen}
                ref={moreRef}
              >
                <DotsHorizontal fontSize="small" />
              </IconButton>
            </Box> */}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              pl: 2,
              pr: 2,
              py: 1
            }}
          >
            <Typography>
              {column.cardIds.length == 1
                ? `${column.cardIds.length} loan`
                : `${column.cardIds.length} loans`}
            </Typography>
            <Typography>{currencyFormatter(total)}</Typography>
          </Box>
        </Box>
        <Box
          mt={1}
          sx={{
            backgroundColor: 'rgb(229, 231, 235)',
            borderRadius: 3,
            // height: '100%'
            overflow: 'auto'
          }}
        >
          <Droppable droppableId={column.id.toString()} type="card">
            {provided => (
              <Box
                ref={provided.innerRef}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  overflow: 'auto',
                  px: 2,
                  py: 1
                }}
              >
                {column.cardIds
                  .filter(id =>
                    cards.byId[id].name
                      .toUpperCase()
                      .includes((filter || '').toUpperCase())
                  )
                  .map((cardId, index) => (
                    <Draggable
                      draggableId={cardId.toString()}
                      index={index}
                      key={cardId}
                    >
                      {(_provided, snapshot) => (
                        <KanbanCard
                          cardId={cardId}
                          dragging={snapshot.isDragging}
                          index={index}
                          key={cardId}
                          column={column}
                          ref={_provided.innerRef}
                          style={{ ..._provided.draggableProps.style }}
                          {..._provided.draggableProps}
                          {..._provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
          <Divider
            sx={{
              borderColor: theme =>
                theme.palette.mode === 'dark' ? 'neutral.700' : 'neutral.300'
            }}
          />
          <Box sx={{ p: 2 }}>
            <KanbanCardAdd column={column} position={column.cardIds.length} />
          </Box>
        </Box>
        <Menu
          anchorEl={moreRef.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          keepMounted
          onClose={handleMenuClose}
          open={openMenu}
        >
          <MenuItem onClick={handleClear}>Clear</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

KanbanColumn.propTypes = {
  columnId: PropTypes.string.isRequired
};
