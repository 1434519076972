import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  Button,
  Backdrop,
  Box,
  Grid,
  TextField,
  FormHelperText,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  FileDownloadOutlined as DownloadIcon,
  DeleteOutlineOutlined as DeleteIcon
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import bytesToSize from 'src/utils/bytesToSize';
import { updateInvestor } from 'src/actions/investorActions';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function Files({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const { investorId } = useParams();
  const { investor, isLoading } = useSelector(state => state.investor);

  const documentRef = useRef();
  const clearDocumentRef = () => {
    documentRef.current.value = '';
  };

  const handleClose = () => {
    setUploading(false);
  };

  const handleDownload = file_path => {
    const filename = file_path.split('/')[file_path.split('/').length - 1];
    setUploading(true);
    axios
      .get('/api/file', {
        responseType: 'arraybuffer',
        params: { path: file_path }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = encodeURI(url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        setUploading(false);
        link.click();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const initialValues = {
    document: {}
  };

  const file_dropbox_path = `investors/${investorId}_${investor.display_name}`;

  if (isLoading) {
    return null;
  }
  const data = investor.files;
  const columns = [
    {
      field: 'name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      field: 'modify_ts',
      title: 'Last Modify',
      type: 'datetime',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'size',
      title: 'Size',
      render: rowData => bytesToSize(rowData.size)
    }
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Files" />
      <Divider />
      <Backdrop
        className={classes.backdrop}
        open={uploading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardContent>
        <Formik
          initialValues={initialValues}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }
          ) => {
            try {
              // Do API call to store step data in server session
              // It is important to have it on server to be able to reuse it if user
              // decides to continue later.
              setUploading(true);
              const formData = new FormData();
              formData.set('path', file_dropbox_path);
              formData.set('document', values.document);
              axios
                .post('/api/file', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  dispatch(
                    updateInvestor(investorId, {
                      files: _.unionBy([response.data], investor.files, 'id')
                    })
                  );
                  clearDocumentRef();
                  resetForm();
                  setUploading(false);
                  setStatus({ success: true });
                })
                .catch(error => {
                  throw error;
                });
            } catch (err) {
              resetForm();
              setUploading(false);
              setErrors({ submit: err.message });
              setStatus({ success: false });
              // setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            resetForm,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Box>
                    <Typography gutterBottom variant="subtitle2">
                      Select & Upload
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={8} md={11}>
                  <TextField
                    fullWidth
                    name="document"
                    onChange={event => {
                      const file = event.target.files[0];
                      setFieldValue('document', file);
                    }}
                    inputRef={documentRef}
                    type="file"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                  md={1}
                >
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        uploading ||
                        !documentRef.current ||
                        (documentRef.current && documentRef.current.value == '')
                      }
                      type="submit"
                      size="small"
                    >
                      upload
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <Box mt={3}>
                <CustomMaterialTable
                  title="Supporting Documents"
                  data={data}
                  columns={columns}
                  actions={[
                    {
                      icon: DownloadIcon,
                      tooltip: 'Download',
                      onClick: (evt, data) => {
                        const file_path = investor.files.filter(
                          file => file.id == data.id
                        )[0].path;
                        handleDownload(file_path);
                      }
                    },
                    {
                      icon: DeleteIcon,
                      tooltip: 'Delete',
                      onClick: (evt, data) => {
                        dispatch(
                          updateInvestor(investorId, {
                            files: investor.files.filter(
                              file => file.id != data.id
                            )
                          })
                        );
                      }
                    }
                  ]}
                  options={{
                    padding: 'dense',
                    paging: false
                  }}
                />
              </Box>
              <Box mt={2} />
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

Files.propTypes = {
  className: PropTypes.string
  // files: PropTypes.array.isRequired
};

export default Files;
