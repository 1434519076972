/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Hidden,
  FormHelperText,
  FormControlLabel,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { UserX as DeleteIcon } from 'react-feather';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { NumberMask } from 'src/utils/mask';
import { properName } from 'src/utils/properName';
import { updateEntity, addEntity } from 'src/actions/entityActions';
import { validateACN, validateABN, validateTFN } from 'src/utils/validation';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function CompanyForm({ className, entity_type_id, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { investorId, entityId } = useParams();
  const { entity } = useSelector(state => state.entity);
  const { address } = useSelector(state => state.investor);

  const defaultCompany = {
    abn: '',
    business_name: '',
    rego_address_1: address.address_1,
    rego_address_2: address.address_2 || '',
    rego_city: address.city,
    rego_state: address.state,
    rego_postcode: address.postcode,
    rego_country: address.country,
    business_addr_same_as_rego_addr: true,
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state: '',
    business_postcode: '',
    business_country: '',
    directors: [''],
    beneficial_owners: [''],
    signatories: [
      {
        title: '',
        first_name: '',
        last_name: '',
        birth_dt: '',
        address: '',
        email: '',
        mobile: '',
        is_us_tax_resident: false,
        is_pep: false
      }
    ]
  };

  const initialValues = entity
    ? entity.entity_details
    : {
        ...entitySchema,
        company: defaultCompany
      };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          if (entity) {
            dispatch(
              updateEntity(entityId, {
                ...entity,
                entity_details: values,
                is_tax_resident: values.company.rego_country == 'Australia',
                is_us_tax_resident:
                  values.company.rego_country == 'United States',
                entity_details: values,
                account_name: properName(values.company.business_name || ''),
                address_1: properName(values.company.rego_address_1 || ''),
                address_2: properName(values.company.rego_address_2 || ''),
                city: properName(values.company.rego_city || ''),
                state: values.company.rego_state || '',
                postcode: values.company.rego_postcode,
                country: values.company.rego_country
              })
            );
          } else {
            dispatch(
              addEntity({
                investor_id: investorId,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident: values.company.rego_country == 'Australia',
                is_us_tax_resident:
                  values.company.rego_country == 'United States',
                entity_details: values,
                account_name: properName(values.company.business_name || ''),
                address_1: properName(values.company.rego_address_1 || ''),
                address_2: properName(values.company.rego_address_2 || ''),
                city: properName(values.company.rego_city || ''),
                state: properName(values.company.rego_state || ''),
                postcode: values.company.rego_postcode,
                country: values.company.rego_country
              })
            );
          }
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                required
                label="Company Name"
                name="company.business_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.business_name || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                label="Incorporated Country"
                name="company.tax_country"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.company.tax_country || ''}
                variant="outlined"
                size="small"
              >
                <option></option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required={values.company.tax_country == 'Australia'}
                helperText={
                  values.company.tax_country == 'Australia' &&
                  values.company.acn > 0 &&
                  !validateACN(values.company.acn) &&
                  'Invalid ACN'
                }
                error={
                  values.company.tax_country == 'Australia' &&
                  values.company.acn > 0 &&
                  !validateACN(values.company.acn)
                }
                label="ACN"
                name="company.acn"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.acn || ''}
                InputProps={{
                  inputComponent: NumberMask,
                  onChange: handleChange('company.acn'),
                  maxLength: 9
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                helperText={
                  values.company.tax_country == 'Australia' &&
                  values.company.abn > 0 &&
                  !validateABN(values.company.abn) &&
                  'Invalid ABN'
                }
                error={
                  values.company.tax_country == 'Australia' &&
                  values.company.abn > 0 &&
                  !validateABN(values.company.abn)
                }
                label="ABN"
                name="company.abn"
                onBlur={handleBlur}
                InputProps={{
                  inputComponent: NumberMask,
                  onChange: handleChange('company.abn'),
                  maxLength: 11
                }}
                onChange={handleChange}
                value={values.company.abn || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="TFN or Exemption number"
                name="company.tfn"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.tfn || ''}
                InputProps={{
                  inputComponent: NumberMask,
                  onChange: handleChange('company.tfn'),
                  maxLength: 9
                }}
                // helperText={
                //   values.company.tax_country == 'Australia' &&
                //   values.company.tfn > 0 &&
                //   !validateTFN(values.company.tfn) &&
                //   'Invalid TFN'
                // }
                // error={
                //   values.company.tax_country == 'Australia' &&
                //   values.company.tfn > 0 &&
                //   !validateTFN(values.company.tfn)
                // }
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography variant="h5">Registration Address</Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size="small"
                inputProps={{ maxLength: 38 }}
                label="Registered Office Line 1 (cannot be a PO Box Address)"
                name="company.rego_address_1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.rego_address_1 || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 38 }}
                size="small"
                label="Registered Office Line 2"
                name="company.rego_address_2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.rego_address_2 || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                required
                label="City"
                size="small"
                name="company.rego_city"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.rego_city || ''}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Autocomplete
                freeSolo
                name="rego_state"
                value={values.company.rego_state || ''}
                options={states}
                onInputChange={(event, newValue) => {
                  setFieldValue('company.rego_state', newValue ? newValue : '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="State/Province"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                required
                label="Postcode"
                name="company.rego_postcode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company.rego_postcode || ''}
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                label="Country"
                name="company.rego_country"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.company.rego_country || ''}
                size="small"
                variant="outlined"
              >
                <option></option>
                {countries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <Box mt={1}>
                <Typography variant="h5">Business Address</Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      values.company.business_addr_same_as_rego_addr || false
                    }
                    size="small"
                    onChange={handleChange}
                    name="company.business_addr_same_as_rego_addr"
                  />
                }
                label="Same as Registration Address"
              />
            </Grid>
            {!values.company.business_addr_same_as_rego_addr && (
              <>
                <Grid item md={12} xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    inputProps={{ maxLength: 38 }}
                    label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                    name="company.business_address_1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company.business_address_1 || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 38 }}
                    size="small"
                    label="Principal Place of Business Line 2"
                    name="company.business_address_2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company.business_address_2 || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="City"
                    name="company.business_city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company.business_city || ''}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Autocomplete
                    freeSolo
                    name="company.business_state"
                    value={values.company.business_state || ''}
                    options={states}
                    onInputChange={(event, newValue) => {
                      setFieldValue(
                        'company.business_state',
                        newValue ? newValue : ''
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        label="State/Province"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Postcode"
                    name="company.business_postcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company.business_postcode || ''}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Country"
                    name="company.business_country"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.company.business_country || ''}
                    size="small"
                    variant="outlined"
                  >
                    <option></option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
          </Grid>
          <FieldArray
            name="company.beneficial_owners"
            subscription={{}} // This is required so that the whole array does not re-render on any change.
            render={({ insert, remove, push }) => (
              <>
                <Box mt={2} mb={2}>
                  <Typography variant="h5">
                    Beneficial Owners of the Company
                  </Typography>
                  <Typography variant="caption">
                    Please list Full Name/s or Class of ALL Beneficiaries (if
                    individual beneficial owners are not named) who through one
                    or more shareholdings own more than 25% of the company’s
                    issued capital
                  </Typography>
                </Box>
                {values.company.beneficial_owners.map(
                  (beneficial_owner, index) => (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item md={11} xs={10}>
                          <TextField
                            fullWidth
                            label="Full Name (and address)"
                            name={`company.beneficial_owners.${index}`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.company.beneficial_owners[index] || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        {index > 0 && (
                          <Grid item md={1} xs={2}>
                            <Tooltip title="Delete beneficial owner">
                              <IconButton
                                size="small"
                                color="error"
                                variant="contained"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <SvgIcon>
                                  <DeleteIcon />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  )
                )}
                <Box mt={2}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      push(address.full_address);
                    }}
                  >
                    Add Beneficial Owner
                  </Button>
                </Box>
              </>
            )}
          />
          <FieldArray
            name="company.directors"
            subscription={{}} // This is required so that the whole array does not re-render on any change.
            render={({ insert, remove, push }) => (
              <>
                <Box mt={2} mb={2}>
                  <Typography variant="h5">Directors</Typography>
                  <Typography variant="caption">
                    If the company is propriety/private please provide full
                    names of all Directors
                  </Typography>
                </Box>
                {values.company.directors.map((director, index) => (
                  <div key={index}>
                    <Grid container spacing={1}>
                      <Grid item md={11} xs={10}>
                        <TextField
                          fullWidth
                          label="Full Name"
                          name={`company.directors.${index}`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.company.directors[index] || ''}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      {index > 0 && (
                        <Grid item md={1} xs={2}>
                          <IconButton
                            variant="contained"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <SvgIcon>
                              <DeleteIcon />
                            </SvgIcon>
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                ))}
                <Box mt={2}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      push('');
                    }}
                  >
                    Add Director
                  </Button>
                </Box>
              </>
            )}
          />
          <FieldArray
            name="company.signatories"
            subscription={{}} // This is required so that the whole array does not re-render on any change.
            render={({ insert, remove, push }) => (
              <>
                <Box mt={2} mb={2}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 600 }}
                    color="secondary"
                    gutterBottom
                  >
                    AUTHORISED SIGNATORIES
                  </Typography>
                  <Typography variant="caption">
                    This Section is to be completed by the Authorised
                    Signatories on the Account. With the exception of where
                    there is a Sole Director (who must be the Authorised
                    Signatory) these must be at least two Authorised Signatories
                    on the account (eg two Directors or a Director and a Company
                    Secretary). You must nominate at least one Director as an
                    Authorised Signatory.
                  </Typography>
                </Box>
                {values.company.signatories.map((signatory, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="subtitle2" color="textPrimary">
                        Signatory {index + 1}
                      </Typography>
                      {index > 0 && (
                        <IconButton
                          variant="contained"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <SvgIcon>
                            <DeleteIcon />
                          </SvgIcon>
                        </IconButton>
                      )}
                    </div>
                    <Box mt={1}>
                      <Grid container spacing={1}>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            select
                            label="Title"
                            name={`company.signatories.${index}.title`}
                            onChange={handleChange}
                            SelectProps={{
                              native: true
                            }}
                            value={
                              values.company.signatories[index].title || ''
                            }
                            variant="outlined"
                            size="small"
                          >
                            <option></option>
                            {titles.map(option => (
                              <option key={option.code} value={option.code}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="First Name"
                            name={`company.signatories.${index}.first_name`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.company.signatories[index].first_name || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Last Name"
                            name={`company.signatories.${index}.last_name`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.company.signatories[index].last_name || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Date of Birth"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              name={`company.signatories.${index}.birth_dt`}
                              value={
                                values.company.signatories[index].birth_dt ||
                                null
                              }
                              onChange={newValue => {
                                setFieldValue(
                                  `company.signatories.${index}.birth_dt`,
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Country of Residency for Tax Purposes"
                            name={`company.signatories.${index}.tax_country`}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={
                              values.company.signatories[index].tax_country ||
                              ''
                            }
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.company.signatories[index].is_pep ||
                                  false
                                }
                                onChange={handleChange}
                                size="small"
                                name={`company.signatories.${index}.is_pep`}
                              />
                            }
                            label="Politically Exposed Person"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.company.signatories[index]
                                    .is_us_tax_resident || false
                                }
                                onChange={handleChange}
                                size="small"
                                name={`company.signatories.${index}.is_us_tax_resident`}
                              />
                            }
                            label="US Citizen / US Tax Resident"
                          />
                        </Grid>
                        {values.company.signatories[index]
                          .is_us_tax_resident && (
                          <>
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="TIN"
                                name={`company.signatories.${index}.tin`}
                                onChange={handleChange}
                                value={
                                  values.company.signatories[index].tin || ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Hidden mdDown>
                              <Grid item md={6} xs={12}></Grid>
                            </Hidden>
                          </>
                        )}
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Email"
                            type="email"
                            name={`company.signatories.${index}.email`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.company.signatories[index].email || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Mobile"
                            name={`company.signatories.${index}.mobile`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.company.signatories[index].mobile || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Residential Address (cannot be a PO Box address)"
                            name={`company.signatories.${index}.address`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.company.signatories[index].address || ''
                            }
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                ))}
                <Box mt={2}>
                  <Button
                    size="small"
                    variant="contained"
                    // disabled={values.company.signatories.length > 1}
                    onClick={() => {
                      push({ first_name: '', last_name: '' });
                    }}
                  >
                    Add Signatory
                  </Button>
                </Box>
              </>
            )}
          />
          <Box mt={6} display="flex">
            {onBack && (
              <Button onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

CompanyForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default CompanyForm;
