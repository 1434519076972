import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  Hidden,
  Tooltip,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import LabelImportantOutlinedIcon from '@material-ui/icons/LabelImportantOutlined';
import getInitials from 'src/utils/getInitials';
import { openCampaign } from 'src/actions/campaignActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  unread: {
    position: 'relative',
    '&:before': {
      content: '" "',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      width: 4,
      backgroundColor: theme.palette.error.main
    },
    '& $name, & $subject': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  selected: {
    backgroundColor: theme.palette.action.selected
  },
  filterActive: {
    color: colors.amber[400]
  },
  content: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  details: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    }
  },
  name: {
    [theme.breakpoints.up('md')]: {
      minWidth: 180,
      flexBasis: 180
    }
  },
  subject: {
    maxWidth: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  message: {
    maxWidth: 800,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 'auto'
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 2,
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  date: {
    whiteSpace: 'nowrap'
  }
}));

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 10)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getTo(params, campaignId) {
  const { label } = params;
  const baseUrl = '/app/crm';

  return `${baseUrl}/${label}/${campaignId}`;

  return baseUrl;
}

function CampaignItem({
  className,
  campaign,
  onDeselect,
  onSelect,
  selected,
  ...rest
}) {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const { labels } = useSelector(state => state.campaign);

  const handleCheckboxChange = event =>
    event.target.checked ? onSelect() : onDeselect();

  const handleOpenCampaign = () => {
    dispatch(openCampaign(campaign));
  };

  const to = campaign ? getTo(params, campaign.id) : '/app/crm';

  if (!campaign) {
    return null;
  }

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.selected]: selected == campaign.id
        },
        className
      )}
      {...rest}
    >
      <Box mr={1} display="flex" alignItems="center">
        <Checkbox
          checked={selected == campaign.id}
          onChange={handleCheckboxChange}
        />
      </Box>
      <Box
        minWidth="1px"
        display="flex"
        flexGrow={1}
        onClick={handleOpenCampaign}
        className={classes.content}
      >
        <Box minWidth="1px" ml={1} className={classes.details}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.subject}
          >
            {campaign.subject}
          </Typography>
          <Box ml={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.subject}
            >
              {campaign.syndicate_name}
            </Typography>
          </Box>
          <Hidden smDown>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.message}
            >
              <Box component="span" ml={2}></Box>
            </Typography>
            <Box display="flex" mx={2}>
              {campaign.label_id && (
                <span
                  style={{ backgroundColor: stringToColor(campaign.label) }}
                  className={classes.label}
                >
                  {campaign.label}
                </span>
              )}
              {campaign.is_archive && (
                <span
                  style={{ backgroundColor: 'grey' }}
                  className={classes.label}
                >
                  {'Archive'}
                </span>
              )}
            </Box>
          </Hidden>
          <Typography
            className={classes.date}
            color="textSecondary"
            variant="caption"
          >
            {moment(campaign.updated_dt).format('DD MMM YYYY')}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

CampaignItem.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.object.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func
};

export default CampaignItem;
