// import axios from 'src/utils/axios';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const GET_BOARD = '@kanban/get-board';
export const CREATE_LIST = '@kanban/create-list';
export const UPDATE_LIST = '@kanban/update-list';
export const CLEAR_LIST = '@kanban/clear-list';
export const DELETE_LIST = '@kanban/delete-list';
export const CREATE_CARD = '@kanban/create-card';
export const OPEN_CARD = '@kanban/open-card';
export const CLOSE_CARD = '@kanban/close-card';
export const UPDATE_CARD = '@kanban/update-card';
export const MOVE_CARD = '@kanban/move-card';
export const DELETE_CARD = '@kanban/delete-card';
export const ADD_COMMENT = '@kanban/add-comment';
export const ADD_CHECKLIST = '@kanban/add-checklist';
export const UPDATE_CHECKLIST = '@kanban/update-checklist';
export const DELETE_CHECKLIST = '@kanban/delete-checklist';
export const ADD_CHECK_ITEM = '@kanban/add-check-item';
export const UPDATE_CHECK_ITEM = '@kanban/update-check-item';
export const DELETE_CHECK_ITEM = '@kanban/delete-check-item';
export const SET_FILTER = '@kanban/set-filter';

export function getBoard() {
  const request = axios.get('/api/boards/1');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_BOARD,
        payload: response.data
      })
    );
  };
}

export function createList(values) {
  const request = axios.post('/api/lists', values);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: CREATE_LIST,
        payload: response.data
      })
    );
  };
}

export function updateList(listId, values) {
  const request = axios.patch(`/api/lists/${listId}`, values);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_LIST,
        payload: response.data
      })
    );
  };
}

export function clearList(listId) {
  const request = axios.post('/api/kanban/board/lists/clear', {
    listId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        type: CLEAR_LIST,
        payload: {
          listId
        }
      })
    );
  };
}

export function deleteList(listId) {
  const request = axios.post('/api/kanban/board/lists/remove', {
    listId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        type: DELETE_LIST,
        payload: {
          listId
        }
      })
    );
  };
}

export function createCard(values, list) {
  const request = axios.post('/api/cards', values);

  return dispatch => {
    request.then(response => {
      dispatch({
        type: CREATE_CARD,
        payload: response.data
      });
      dispatch(
        updateList(list.id, {
          cardIds: list.cardIds.concat([response.data.id.toString()])
        })
      );
      dispatch({
        type: OPEN_CARD,
        payload: { cardId: response.data.id, listId: response.data.list_id }
      });
    });
  };
}

export function updateCard(cardId, update) {
  const request = axios.patch(`/api/cards/${cardId}`, update);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_CARD,
        payload: response.data
      })
    );
  };
}

export function deleteCard(cardId, list) {
  return async dispatch => {
    await axios.patch(`/api/lists/${list.id}`, {
      cardIds: list.cardIds.filter(_id => _id !== cardId)
    });
    await axios.patch(`/api/cards/${cardId}`, { list_id: null });
    dispatch({
      type: DELETE_CARD,
      payload: { cardId: cardId, listId: list.id }
    });
  };
}

export function addComment(userId, cardId, message) {
  const request = axios.post('/api/kanban/board/cards/comments', {
    userId,
    cardId,
    message
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: ADD_COMMENT,
        payload: response.data
      })
    );
  };
}

export function addChecklist(cardId, name) {
  const request = axios.post('/api/kanban/board/checklists/new', {
    cardId,
    name
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: ADD_CHECKLIST,
        payload: {
          cardId,
          ...response.data
        }
      })
    );
  };
}

export function updateChecklist(cardId, checklistId, update) {
  const request = axios.post('/api/kanban/board/checklists/update', {
    cardId,
    checklistId,
    update
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_CHECKLIST,
        payload: {
          cardId,
          checklistId,
          ...response.data
        }
      })
    );
  };
}

export function deleteChecklist(cardId, checklistId) {
  const request = axios.post('/api/kanban/board/checklists/remove', {
    cardId,
    checklistId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        type: DELETE_CHECKLIST,
        payload: {
          cardId,
          checklistId
        }
      })
    );
  };
}

export function addCheckItem(cardId, checklistId, name) {
  const request = axios.post('/api/kanban/board/checkitems/new', {
    cardId,
    checklistId,
    name
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: ADD_CHECK_ITEM,
        payload: {
          cardId,
          checklistId,
          ...response.data
        }
      })
    );
  };
}

export function updateCheckItem(cardId, checklistId, checkItemId, update) {
  const request = axios.post('/api/kanban/board/checkitems/update', {
    cardId,
    checklistId,
    checkItemId,
    update
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_CHECK_ITEM,
        payload: {
          cardId,
          checklistId,
          checkItemId,
          ...response.data
        }
      })
    );
  };
}

export function deleteCheckItem(cardId, checklistId, checkItemId) {
  const request = axios.post('/api/kanban/board/checkitems/remove', {
    cardId,
    checklistId,
    checkItemId
  });

  return dispatch => {
    request.then(() =>
      dispatch({
        type: DELETE_CHECK_ITEM,
        payload: {
          cardId,
          checklistId,
          checkItemId
        }
      })
    );
  };
}

export function setFilter(value) {
  return dispatch => {
    dispatch({
      type: SET_FILTER,
      payload: value
    });
  };
}
