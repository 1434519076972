import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import enAU from 'date-fns/locale/en-AU';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function ScheduleForm({ campaignId, open, onClose, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={{ send_at: new Date() }}
            // validationSchema={Yup.object().shape({
            //   transaction_type_id: Yup.number().required(),
            //   transaction_dt: Yup.date().nullable()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                axios
                  .post('/api/emailcampaign', {
                    campaignId: campaignId,
                    send_at: values.send_at
                  })
                  .then(response => {
                    toast.success('Emails Scheduled');
                  })
                  .catch(error => {
                    toast.error('Failed to schedule emails');
                  });
                onClose();
                setStatus({ success: true });
                setSubmitting(true);
              } catch (error) {
                setErrors({ submit: error.response.data });
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="textPrimary">
                      Schedule Campaign
                    </Typography>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DateTimePicker
                              label="Send Date"
                              value={values.send_at || null}
                              name="send_at"
                              onChange={newValue => {
                                setFieldValue('send_at', newValue);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Schedule Email
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

ScheduleForm.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

ScheduleForm.defaultProps = {
  onClose: () => {}
};

export default ScheduleForm;
