import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { properName } from 'src/utils/properName';
import { updateApplication } from 'src/actions/applicationActions';
import ResponsiveButton from 'src/components/ResponsiveButton';
import SignCard from './SignCard';
import Alert from './Alert';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  Card: {
    fontWeight: '500',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: '2vw',
    margin: '1vw',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.1)'
  },
  SigContainer: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: '1px gray solid',
    borderRadius: '10px',
    width: '100%',
    height: '20vh'
  },
  SigPad: { width: '100%', height: '100%' }
}));

function Declaration({ className, onBack, onComplete, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector(state => state.account);
  const { application } = useSelector(state => state.application);
  const { secret } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const entity_details = application.entity_id.entity_details;

  const generateSignatories = application => {
    switch (application.entity_id.entity_type_id) {
      case 1: {
        return entity_details.individuals.map((individual, index) => {
          return {
            name: properName(
              individual.first_name.concat(' ', individual.last_name)
            ),
            position: '',
            sign_dt: format(new Date(), 'yyyy-MM-dd'),
            signature: null,
            draft_signature: null
          };
        });
      }
      case 2: {
        return [
          {
            name: properName(
              entity_details.soleTrader.first_name.concat(
                ' ',
                entity_details.soleTrader.last_name
              )
            ),
            position: '',
            sign_dt: format(new Date(), 'yyyy-MM-dd'),
            signature: null,
            draft_signature: null
          }
        ];
      }
      case 3: {
        switch (application.entity_id.entity_details.trust.trustee_type) {
          case 'Individual': {
            return entity_details.trust.trustee_individuals.map(
              (individual, index) => {
                return {
                  name: properName(
                    individual.first_name.concat(' ', individual.last_name)
                  ),
                  position: '',
                  sign_dt: format(new Date(), 'yyyy-MM-dd'),
                  signature: null,
                  draft_signature: null
                };
              }
            );
          }
          case 'Corporate': {
            return entity_details.trust.trustee_company.is_regulated
              ? [
                  {
                    name: null,
                    position: null,
                    sign_dt: format(new Date(), 'yyyy-MM-dd'),
                    signaure: null,
                    draft_signature: null
                  },
                  {
                    name: null,
                    position: null,
                    sign_dt: format(new Date(), 'yyyy-MM-dd'),
                    signaure: null,
                    draft_signature: null
                  }
                ]
              : entity_details.trust.trustee_company.signatories.map(
                  (individual, index) => {
                    return {
                      name: properName(
                        individual.first_name.concat(' ', individual.last_name)
                      ),
                      position: '',
                      sign_dt: format(new Date(), 'yyyy-MM-dd'),
                      signature: null,
                      draft_signature: null
                    };
                  }
                );
          }
        }
      }
      case 4: {
        switch (application.entity_id.entity_details.trust.trustee_type) {
          case 'Individual': {
            return entity_details.trust.trustee_individuals.map(
              (individual, index) => {
                return {
                  name: properName(
                    individual.first_name.concat(' ', individual.last_name)
                  ),
                  position: '',
                  sign_dt: format(new Date(), 'yyyy-MM-dd'),
                  signature: null
                };
              }
            );
          }
          case 'Corporate': {
            return entity_details.trust.trustee_company.is_regulated
              ? [
                  {
                    name: null,
                    position: null,
                    sign_dt: format(new Date(), 'yyyy-MM-dd'),
                    signaure: null,
                    draft_signature: null
                  },
                  {
                    name: null,
                    position: null,
                    sign_dt: format(new Date(), 'yyyy-MM-dd'),
                    signaure: null,
                    draft_signature: null
                  }
                ]
              : entity_details.trust.trustee_company.signatories.map(
                  (individual, index) => {
                    return {
                      name: properName(
                        individual.first_name.concat(' ', individual.last_name)
                      ),
                      position: '',
                      sign_dt: format(new Date(), 'yyyy-MM-dd'),
                      signature: null,
                      draft_signature: null
                    };
                  }
                );
          }
        }
      }
      case 5: {
        return entity_details.company.signatories.map((individual, index) => {
          return {
            name: properName(
              individual.first_name.concat(' ', individual.last_name)
            ),
            position: '',
            sign_dt: format(new Date(), 'yyyy-MM-dd'),
            signature: null,
            draft_signature: null
          };
        });
      }
      case 6: {
        return entity_details.partnership.individuals.map(
          (individual, index) => {
            return {
              name: properName(
                individual.first_name.concat(' ', individual.last_name)
              ),
              position: '',
              sign_dt: format(new Date(), 'yyyy-MM-dd'),
              signature: null,
              draft_signature: null
            };
          }
        );
      }
    }
  };

  const initialSignatories = generateSignatories(application);

  const signatories =
    application.signature.length > 0
      ? application.signature
      : initialSignatories;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = values => {
    const final_values = values.signatories.map(signatory => {
      return {
        ...signatory,
        signature: signatory.draft_signature
      };
    });
    dispatch(
      updateApplication(application.id, {
        signature: final_values
      })
    );
    handleOpen();
  };

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
        DECLARATION
      </Typography>
      <Box mt={4}>
        <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
          By signing and lodging this Form, you agree and acknowledge:
        </Typography>
        <Box mt={2} ml={4}>
          <div>
            <ul>
              <li>
                <Typography variant="body1" gutterBottom>
                  you consent to the Arranger and the Trustee obtaining and
                  using your personal information;
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  the arranger is required to comply with the AML/CTF Act and
                  you must provide the Arranger and/or the Trustee or its agent
                  with such additional information or documentation as either of
                  them or their agent may request of you at any time prior to
                  the issue of Units to you or while you hold any Units,
                  otherwise your application for Units may be refused, Units you
                  hold may be compulsorily redeemed, and any disposal request by
                  you may be delayed or refused and neither the Arranger nor the
                  Trustee will be liable for any loss arising as a result
                  thereof;
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  all information contained in this Verification Form is true
                  and correct;
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  you will promptly notify the Trustee or its agent of and
                  provide the Trustee with any changes to the information
                  provided in connection with FATCA and on request with any
                  further information which is necessary or convenient for the
                  Trustee to comply with any obligations it may have in
                  connection with FATCA;
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  if signing this application form under power of attorney, the
                  agent warrants that, at the time of signing, they had not
                  received notice of revocation of that power of attorney;
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  the monies used to fund your investment in the Trust are not
                  derived from or related to any money laundering, terrorism
                  financing or other illegal activities, whether prohibited
                  under Australian law, international law or convention (Illegal
                  Activity) and the proceeds of your investment in the Trust
                  will not be used to finance any Illegal Activities; and
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  except as provided in this form, you are not, and none of the
                  applicant’s directors or beneficial owners (if applicable)
                  are, a PEP.
                </Typography>
              </li>
            </ul>
          </div>
        </Box>
      </Box>
      <Alert onClose={handleClose} open={open} />
      <Box mt={4}>
        <Formik
          enableReinitialize
          initialValues={{
            signatories: signatories,
            is_verified: application.is_verified
          }}
          validationSchema={Yup.object().shape({
            signatories: Yup.array().of(
              Yup.object().shape({
                draft_signature: Yup.string().required('Signature is required')
              })
            )
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const final_values = values.signatories.map(signatory => {
              return {
                ...signatory,
                signature: signatory.draft_signature
              };
            });
            try {
              await dispatch(
                updateApplication(application.id, {
                  signature: final_values,
                  application_status: 'signed',
                  entity_details: application.entity_id.entity_details
                })
              );
              // if (!secret) {
              //   history.push(
              //     `/app/management/syndicates/${application.investment_id.syndicate_id}`
              //   );
              // }
              setStatus({ success: true });
              setSubmitting(false);
              if (onComplete) {
                onComplete();
              }
            } catch (err) {
              setErrors({ submit: JSON.stringify(err.response.data) });
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Grid container spacing={2}>
                <FieldArray
                  name="signatories"
                  subscription={{}} // This is required so that the whole array does not re-render on any change.
                  render={({ insert, remove, push }) => (
                    <>
                      {values.signatories.map((signatory, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          md={6}
                          container
                          spacing={3}
                        >
                          <div className={classes.Card}>
                            <Grid item md={12} xs={12}>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: 600 }}
                                gutterBottom
                              >
                                Signatory {index + 1}
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              {errors &&
                                errors.signatories &&
                                errors.signatories[index] &&
                                errors.signatories[index].draft_signature && (
                                  <Typography
                                    variant="caption"
                                    color="error"
                                    align="right"
                                  >
                                    Signature is required
                                  </Typography>
                                )}
                              <SignCard
                                setSignature={setFieldValue}
                                errors={errors}
                                draft_signature={
                                  values.signatories[index].draft_signature
                                }
                                draft_field_name={`signatories.${index}.draft_signature`}
                                signature={values.signatories[index].signature}
                                field_name={`signatories.${index}.signature`}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Box mt={6}>
                                <TextField
                                  fullWidth
                                  required
                                  label="Print Name"
                                  size="small"
                                  variant="standard"
                                  onChange={handleChange}
                                  name={`signatories.${index}.name`}
                                  value={values.signatories[index].name || ''}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                variant="standard"
                                size="small"
                                label="Position (if applicable)"
                                name={`signatories.${index}.position`}
                                onChange={handleChange}
                                value={values.signatories[index].position || ''}
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enAU}
                              >
                                <DatePicker
                                  label="Date"
                                  name={`signatories.${index}.sign_dt`}
                                  value={
                                    values.signatories[index].sign_dt || null
                                  }
                                  onChange={newValue => {
                                    setFieldValue(
                                      `signatories.${index}.sign_dt`,
                                      !newValue ||
                                        newValue.toString() == 'Invalid Date'
                                        ? newValue
                                        : format(newValue, 'yyyy-MM-dd')
                                    );
                                  }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      required
                                      size="small"
                                      fullWidth
                                      variant="standard"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </div>
                        </Grid>
                      ))}
                    </>
                  )}
                />
              </Grid>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={6} display="flex">
                {onBack && (
                  <ResponsiveButton
                    variant="contained"
                    onClick={onBack}
                    size="large"
                  >
                    Previous
                  </ResponsiveButton>
                )}
                <Box flexGrow={1} />
                <Box display="flex">
                  <Box mx={1}>
                    <ResponsiveButton
                      onClick={() => handleSave(values)}
                      variant="contained"
                      size="large"
                    >
                      Save & Submit Later
                    </ResponsiveButton>
                  </Box>
                  <ResponsiveButton
                    color="secondary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    Save & Submit
                  </ResponsiveButton>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

Declaration.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default Declaration;
