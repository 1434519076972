import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Switch
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { CurrencyMask } from 'src/utils/mask';
import { states } from 'src/utils/options';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function SecurityForm({ security, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = security
    ? security
    : {
        address: null,
        valuation: null,
        valuation_dt: null,
        state: null
      };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              address: Yup.string().required(),
              valuation_dt: Yup.date().nullable()
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                if (security) {
                  axios
                    .patch(`/api/securities/${security.id}`, values)
                    .then(response => {
                      setStatus({ success: true });
                      setSubmitting(true);
                      onAdd(response.data);
                      enqueueSnackbar('Security updated', {
                        variant: 'success'
                      });
                    });
                } else {
                  axios.post('/api/securities', values).then(response => {
                    setStatus({ success: true });
                    setSubmitting(true);
                    onAdd(response.data);
                    enqueueSnackbar('Security added', {
                      variant: 'success'
                    });
                  });
                }
              } catch (error) {
                console.log(error);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          fullWidth
                          helperText={touched.address && errors.address}
                          label="Security Address"
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          error={Boolean(touched.city && errors.city)}
                          fullWidth
                          helperText={touched.city && errors.city}
                          label="City"
                          name="city"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.city || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          error={Boolean(touched.postcode && errors.postcode)}
                          fullWidth
                          helperText={touched.postcode && errors.postcode}
                          label="Postcode"
                          name="postcode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.postcode || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Security State"
                          name="state"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: !!values.state
                          }}
                          select
                          SelectProps={{ native: true }}
                          value={values.state || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {states.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          error={Boolean(touched.valuation && errors.valuation)}
                          fullWidth
                          helperText={touched.valuation && errors.valuation}
                          label="Valuation"
                          name="valuation"
                          InputProps={{
                            inputComponent: CurrencyMask,
                            onChange: handleChange('valuation')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.valuation || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Valuation Date"
                            value={values.valuation_dt}
                            maxDate={new Date()}
                            onChange={newValue => {
                              setFieldValue(
                                'valuation_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                value={values.valuation_dt}
                                name="valuation_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          error={Boolean(touched.comment && errors.comment)}
                          fullWidth
                          multiline
                          rows={3}
                          helperText={touched.comment && errors.comment}
                          label="Notes"
                          name="comment"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.comment || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {security ? 'Update Security' : 'Add Security'}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

SecurityForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

SecurityForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default SecurityForm;
