import produce from 'immer';
import _ from 'lodash';
import { GET_STAFF, ADD_STAFF, UPDATE_STAFF } from 'src/actions/staffActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  staff: {
    byId: {},
    allIds: []
  }
};

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF: {
      const staff = action.payload;

      return produce(state, draft => {
        draft.staff.byId = objFromArray(staff);
        draft.staff.allIds = staff.map(s => s.id);

        draft.isLoading = false;
      });
    }
    case ADD_STAFF: {
      const staff = action.payload;

      return produce(state, draft => {
        draft.staff.byId[staff.id] = staff;
        draft.staff.allIds.unshift(staff.id);
      });
    }

    case UPDATE_STAFF: {
      const staff = action.payload;
      return produce(state, draft => {
        _.mergeWith(draft.staff.byId[staff.id], staff, customizer);
      });
    }
    default: {
      return state;
    }
  }
};

export default staffReducer;
