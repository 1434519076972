import React, { forwardRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar, AvatarGroup, Box, Card, CardMedia, Chip } from '@mui/material';
import { Typography } from '@material-ui/core';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import { Check as CheckIcon } from 'src/components/icons/check';
import { ChatAlt as ChatAltIcon } from 'src/components/icons/chat-alt';
import { OPEN_CARD } from 'src/actions/kanbanActions';
import { DocumentText as DocumentTextIcon } from 'src/components/icons/document-text';
import { BadgeCheckOutlined as BadgeCheckOutlinedIcon } from 'src/components/icons/badge-check-outlined';
import { Eye as EyeIcon } from 'src/components/icons/eye';
import getInitials from 'src/utils/getInitials';
import { currencyFormatter } from 'src/utils/mask';
import { KanbanCardModal } from './kanban-card-modal';

const cardSelector = (state, cardId) => {
  const { cards } = state.kanban;

  const card = cards.byId[cardId];

  return {
    ...card
  };
};

export const KanbanCard = forwardRef((props, ref) => {
  const { cardId, dragging, column, ...other } = props;
  const dispatch = useDispatch();
  const { staff } = useSelector(state => state.staff);
  const { user } = useSelector(state => state.account);
  const card = useSelector(state => cardSelector(state, cardId));

  // const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // setOpen(true);
    dispatch({
      type: OPEN_CARD,
      payload: { cardId: cardId, listId: column.id }
    });
  };

  return (
    <Box
      ref={ref}
      sx={{
        outline: 'none',
        py: 1
      }}
      {...other}
    >
      <Card
        onClick={handleOpen}
        raised={dragging}
        sx={{
          ...(dragging && {
            backgroundColor: 'background.paper'
          }),
          '&:hover': {
            backgroundColor: 'background.default'
          }
        }}
        variant="elevation"
      >
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              ml: 1,
              my: 1
            }}
          >
            <Typography color="secondary" variant="caption">
              {card.name} || {card.syndicate_purpose} || {card.risk_level}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2
            }}
          >
            <Typography variant="subtitle1">
              {`${currencyFormatter(card.sought_amt)}`}{' '}
              <span style={{ fontSize: 12 }}>@ {card.interest || '?'}%</span>
              {card.syndicate_purpose == 'Construction' && (
                <span style={{ fontSize: 12 }}> + {card.line_fee || '?'}%</span>
              )}
            </Typography>
            <Typography variant="subtitle2">
              {`${card.term || '?'} months`} | {`LVR ${card.lvr || '?'}%`}
            </Typography>
          </Box>
          {card.securities.length > 0 && (
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                ml: 1,
                mt: 2
              }}
            >
              <HomeWorkOutlinedIcon color="secondary" fontSize="small" />
              <Box sx={{ ml: 1 }}>
                <Typography variant="caption" display="block">
                  {card.securities.map((security, index) => (
                    <div key={index}>{security.full_address}</div>
                  ))}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mx: 1,
              my: 1,
              color: 'action.active',
              '& svg:not(:first-of-type)': {
                ml: 2
              }
            }}
          >
            {card.checklists.length > 0 && <CheckIcon fontSize="small" />}
            {card.comments.length > 0 && <ChatAltIcon fontSize="small" />}
            <Box sx={{ flexGrow: 1 }} />
            {card.assigned_to.length > 0 && (
              <AvatarGroup max={5}>
                {card.assigned_to.map(memberId => (
                  <Avatar key={memberId}>
                    {getInitials(staff.byId[memberId].full_name)}
                  </Avatar>
                ))}
              </AvatarGroup>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
});

KanbanCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  dragging: PropTypes.bool,
  index: PropTypes.number,
  column: PropTypes.object.isRequired,
  style: PropTypes.object
};

KanbanCard.defaultProps = {
  dragging: false
};
