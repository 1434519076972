import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import toast from 'react-hot-toast';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  SvgIcon,
  Card,
  CardContent,
  IconButton,
  Grid,
  Switch
} from '@material-ui/core';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import {
  addLoanTransaction,
  updateLoanTransaction,
  getLoanBalance
} from 'src/actions/syndicateActions';
import { CurrencyMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function PaymentForm({ loanTransaction, open, onClose, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { loanTransactionType } = useSelector(state => state.setting);
  const { syndicate } = useSelector(state => state.syndicate);
  const { user } = useSelector(state => state.account);

  const initialValues = loanTransaction
    ? {
        transaction_amt: (
          parseFloat(loanTransaction.transaction_amt) -
          parseFloat(loanTransaction.received_amt)
        ).toFixed(2),
        transaction_dt: format(new Date(), 'yyyy-MM-dd')
      }
    : {};

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                await dispatch(
                  updateLoanTransaction(loanTransaction.id, {
                    received_amt:
                      parseFloat(loanTransaction.received_amt) +
                      parseFloat(values.transaction_amt)
                  })
                );
                await dispatch(
                  addLoanTransaction({
                    ...values,
                    syndicate_id: loanTransaction.syndicate_id,
                    loan_transaction_type_id: 3,
                    is_capitalised: false
                  })
                );
                await dispatch(getLoanBalance(syndicateId));
                onClose();
                setStatus({ success: true });
                setSubmitting(true);
                toast.success('Interest payment added');
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: JSON.stringify(error.response.data) });
                toast.error('Failed to add interest payment');
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary">
                        Interest Payment Received
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Interest Amount"
                            name="transaction_amt"
                            InputProps={{
                              inputComponent: CurrencyMask,
                              onChange: handleChange('transaction_amt')
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.transaction_amt || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Transaction Date"
                              value={values.transaction_dt}
                              onChange={newValue => {
                                setFieldValue(
                                  'transaction_dt',
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  value={values.transaction_dt}
                                  name="transaction_dt"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add Interest Payment
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

PaymentForm.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

PaymentForm.defaultProps = {
  onClose: () => {}
};

export default PaymentForm;
