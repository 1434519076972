import axios from 'axios';
import _ from 'lodash';

export const INIT_SYNDICATE = '@syndicate/init';
export const GET_SYNDICATE = '@syndicate/get-syndicate';
export const UPDATE_SYNDICATE = '@syndicate/update-syndicate';
export const GET_STATS = '@syndicate/get-stats';
export const GET_LOAN_BALANCE = '@syndicate/update-loan-balance';
export const GET_INVESTMENTS = '@syndicate/get-investments';
export const ADD_INVESTMENT = '@syndicate/add-investment';
export const UPDATE_INVESTMENTS = '@syndicate/update-investments';
export const DELETE_INVESTMENT = '@syndicate/delete-investment';
export const DELETE_CHILD_CALLS = '@syndicate/delete-child-calls';
export const ADD_FUNDMOVEMENT = '@syndicate/add-fundmovement';
export const UPDATE_TRANSACTION = '@syndicate/update-fundmovement';
export const DELETE_TRANSACTION = '@syndicate/delete-transaction';
export const GET_INTERESTBATCHES = '@syndicate/get-interest-batches';
export const DELETE_INTEREST_BATCH = '@syndicate/delete-interest-batch';
export const ADD_INTEREST_BATCH = '@syndicate/add-interest-batch';
export const UPDATE_INTEREST_BATCH = '@syndicate/update-interest-batch';
export const GET_LOAN_TRANSACTIONS = '@syndicate/get-loan-transactions';
export const ADD_LOAN_TRANSACTION = '@syndicate/add-loan-transaction';
export const UPDATE_LOAN_TRANSACTION = '@syndicate/update-loan-transaction';
export const DELETE_LOAN_TRANSACTION = '@syndicate/delete-loan-transaction';
export const GET_COMMISSIONS = '@syndicate/get-commissions';
export const ADD_COMMISSION = '@syndicate/add-commission';
export const UPDATE_COMMISSION = '@syndicate/update-commission';
export const DELETE_COMMISSION = '@syndicate/delete-commission';
export const GET_EMAIL_LOGS = '@syndicate/get-email-logs';
export const ERROR = '@syndicate/error';
export const INTERST_BATCH_ERROR = '@syndicate/interest-batch-error';
export const GET_MANAGEMENT_FEES = '@syndicate/get-management-fees';
export const ADD_MANAGEMENT_FEE = '@syndicate/add-management-fee';
export const UPDATE_MANAGEMENT_FEE = '@syndicate/update-management-fee';
export const DELETE_MANAGEMENT_FEE = '@syndicate/delete-management-fee';

// Syndicate
export function getSyndicate(syndicateId) {
  return async dispatch => {
    if (syndicateId) {
      dispatch({ type: INIT_SYNDICATE });
      const response = await axios.get(`/api/syndicates/${syndicateId}`);
      dispatch({ type: GET_SYNDICATE, payload: response.data });
    } else {
      dispatch({ type: INIT_SYNDICATE });
    }
  };
}

export function addSyndicate(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/syndicates', values);
      dispatch({ type: GET_SYNDICATE, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function updateSyndicate(syndicateId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/syndicates/${syndicateId}`,
        values
      );
      dispatch({ type: UPDATE_SYNDICATE, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

// Syndicate Stats
export function getStats(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get(`/api/view/syndicate/${syndicateId}`);
      dispatch({
        type: GET_STATS,
        payload: response.data
      });
    } catch (error) {
      throw error;
    }
  };
}
// Syndicate Loan Balances
export function getLoanBalance(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get(`/api/view/loanbalance/${syndicateId}`);
      dispatch({
        type: GET_LOAN_BALANCE,
        payload: response.data
      });
    } catch (error) {
      throw error;
    }
  };
}

// Investments
export function getInvestments(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/investments', {
        params: { syndicateId: syndicateId }
      });
      dispatch({ type: GET_INVESTMENTS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function addInvestment(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/investments', values);
      dispatch({ type: ADD_INVESTMENT, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

export function updateInvestment(investmentId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/investments/${investmentId}`,
        values
      );
      dispatch({ type: UPDATE_INVESTMENTS, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteChildCalls(investmentIds) {
  return async dispatch => {
    dispatch({ type: DELETE_CHILD_CALLS, payload: investmentIds });
  };
}

export function deleteInvestment(values) {
  return async dispatch => {
    try {
      const response = await axios.delete(`/api/investments/${values.id}`);
      dispatch({ type: DELETE_INVESTMENT, payload: values.id });
      dispatch(getStats(values.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

export function refreshInvestments(investmentIds) {
  // take in ids in array so that it can cater for multiple investment updates
  return async dispatch => {
    try {
      const response = await axios.get(
        `/api/investments?${investmentIds
          .map(id => `investmentId=${id}`)
          .join('&')}`
      );
      dispatch({ type: UPDATE_INVESTMENTS, payload: response.data });
      dispatch(getStats(response.data[0].syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

// Transactions
// Transaction includes: funds deposit/withdrawal and payments
export function addTransactions(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/transactions', values);
      const investmentIds = Array.isArray(response.data)
        ? response.data.map(tran => tran.investment_id)
        : [response.data.investment_id];
      dispatch(refreshInvestments(investmentIds));
    } catch (error) {
      throw error;
    }
  };
}

export function updateTransaction(transactionId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/transactions/${transactionId}`,
        values
      );
      dispatch({ type: UPDATE_TRANSACTION, payload: response.data });
      dispatch(refreshInvestments([response.data.investment_id]));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteTransaction(values) {
  return async dispatch => {
    try {
      const response = await axios.delete(`/api/transactions/${values.id}`);
      dispatch({ type: DELETE_TRANSACTION, payload: values });
      dispatch(refreshInvestments([values.investment_id]));
    } catch (error) {
      throw error;
    }
  };
}

// bulk redemption in pro rata format
export function bulkRedeem(values) {
  return async dispatch => {
    try {
      const redemptions = await axios.post('/api/bulkredemptions', values);
      const response = await axios.post('/api/transactions', redemptions.data);
      const investmentIds = Array.isArray(response.data)
        ? response.data.map(tran => tran.investment_id)
        : [response.data.investment_id];
      dispatch(refreshInvestments(investmentIds));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

// Distribute cash reserve from master trust to sub trust
export function Distribute(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/distribute', values);
      await dispatch(refreshInvestments(values.investmentIds));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

// bulk redemption in pro rata format
export function bulkDeposit(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/bulkdrawdown', values);
      const investmentIds = Array.isArray(response.data)
        ? response.data.map(tran => tran.investment_id)
        : [response.data.investment_id];
      dispatch(refreshInvestments(investmentIds));
    } catch (error) {
      throw error;
    }
  };
}

export function createTransfers(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/transactions', values);
      // update associated investments
      const investmentIds = response.data.map(tran => tran.investment_id);
      dispatch(refreshInvestments(investmentIds));
      // create corresponding transfers
      const transactionIds = response.data.map(tran => tran.id);
      const transfer_response = await axios.post('/api/createtransfers', {
        transactionIds: transactionIds
      });
      dispatch(addTransfers(transfer_response.data));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

// Email logs
export function getEmailLogs(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/logs', {
        params: { syndicateId: syndicateId }
      });
      dispatch({ type: GET_EMAIL_LOGS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

// Interest Batches
export function getInterestBatches(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/interestbatches', {
        params: { syndicateId: syndicateId }
      });
      dispatch({ type: GET_INTERESTBATCHES, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function addInterestBatch(values) {
  return async dispatch => {
    try {
      console.log(values);
      const response = await axios.get(
        `/api/interestcalc?syndicate_id=${values.syndicate_id}&interest_type_id=${values.interest_type_id}&default_interest=${values.default_interest}&period_start_dt=${values.period_start_dt}&period_end_dt=${values.period_end_dt}`
      );
      dispatch({ type: ADD_INTEREST_BATCH, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

export function updateInterestBatch(batchId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/interestbatches/${batchId}`,
        values
      );
      const paymentTransactions = await axios.post('/api/paymentupdates', {
        batchId: batchId,
        is_paid: values.is_paid
      });
      dispatch({ type: UPDATE_INTEREST_BATCH, payload: response.data });
      dispatch(refreshInvestments(paymentTransactions.data.investmentIds));
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function refreshInterestBatch(batchId) {
  // take in ids in array so that it can cater for multiple investment updates
  return async dispatch => {
    try {
      const response = await axios.get(`/api/interestbatches/${batchId}`);
      dispatch({ type: UPDATE_INTEREST_BATCH, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function deleteInterestBatch(values) {
  return async dispatch => {
    try {
      const response = await axios.delete(`/api/interestbatches/${values.id}`);
      dispatch({ type: DELETE_INTEREST_BATCH, payload: values.id });
      dispatch(getStats(values.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

// Loan Transctions
export function getLoanTransactions(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/loantransactions', {
        params: { syndicateId: syndicateId }
      });
      dispatch({ type: GET_LOAN_TRANSACTIONS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function addLoanTransaction(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/loantransactions', values);
      dispatch({ type: ADD_LOAN_TRANSACTION, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function updateLoanTransaction(loanTransactionId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/loantransactions/${loanTransactionId}`,
        values
      );
      dispatch({ type: UPDATE_LOAN_TRANSACTION, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function deleteLoanTransaction(values) {
  return async dispatch => {
    try {
      const response = await axios.delete(`/api/loantransactions/${values.id}`);
      dispatch({ type: DELETE_LOAN_TRANSACTION, payload: values.id });
      dispatch(getStats(values.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}

// Commissions

export function getCommissions(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/commissions', {
        params: { syndicateId: syndicateId }
      });
      dispatch({ type: GET_COMMISSIONS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export const addCommissions = values => async dispatch => {
  try {
    //commissions are system calculated first
    const response = await axios.post('/api/commissioncalc', values);
    dispatch({
      type: ADD_COMMISSION,
      payload: response.data
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export function updateCommission(commissionId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/commissions/${commissionId}`,
        values
      );
      dispatch({ type: UPDATE_COMMISSION, payload: response.data });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function deleteCommission(values) {
  return async dispatch => {
    try {
      const response = await axios.delete(`/api/commissions/${values.id}`);
      dispatch({ type: DELETE_COMMISSION, payload: values.id });
    } catch (error) {
      throw error;
    }
  };
}

// Management Fee
export function getManagementFees(syndicateId) {
  return async dispatch => {
    try {
      const response = await axios.get('/api/managementfees', {
        params: { syndicateId: syndicateId }
      });
      dispatch({ type: GET_MANAGEMENT_FEES, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function addManagementFee(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/managementfees', values);
      dispatch({ type: ADD_MANAGEMENT_FEE, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function updateManagementFee(loanTransactionId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/managementfees/${loanTransactionId}`,
        values
      );
      dispatch({ type: UPDATE_MANAGEMENT_FEE, payload: response.data });
      dispatch(getStats(response.data.syndicate_id));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function deleteManagementFee(values) {
  return async dispatch => {
    try {
      const response = await axios.delete(`/api/managementfees/${values.id}`);
      dispatch({ type: DELETE_MANAGEMENT_FEE, payload: values.id });
      dispatch(getStats(values.syndicate_id));
    } catch (error) {
      throw error;
    }
  };
}
