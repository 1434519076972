import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from 'src/components/Page';
import { getSetting } from 'src/actions/settingActions';
import Header from './Header';
import Results from './Results';
import SecurityForm from './SecurityForm';
import { getFormInitialValues } from 'redux-form';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: 100
  }
}));

const tabs = [
  { value: 'syndicate', label: 'Syndicates' },
  { value: 'securities', label: 'Securities' }
];

function SecurityListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [securities, setSecurities] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [selectedSecurity, setSelectedSecurity] = useState(null);

  const handleFresh = value => {
    if (securities.map(security => security.id).includes(value.id)) {
      setSecurities(
        securities.map(security => (security.id == value.id ? value : security))
      );
    } else {
      // push to securities didn't incur rerender
      setSecurities(securities.concat([value]));
    }
  };

  const handleFormOpen = value => {
    setSelectedSecurity(value);
    setOpenForm(true);
  };

  const handleFormClose = value => {
    setOpenForm(false);
    if (value) {
      handleFresh(value);
    }
  };

  const getSecurities = useCallback(() => {
    axios.get('/api/securities').then(response => {
      if (isMountedRef.current) {
        setSecurities(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getSecurities();
  }, [getSecurities]);

  return (
    <Page className={classes.root} title="Security List">
      {/* <Header onClick={handleFormOpen} /> */}
      <Box mt={1}>
        <Results
          securities={securities}
          isLoading={isLoading}
          onEdit={handleFormOpen}
        />
      </Box>
      <SecurityForm
        security={selectedSecurity}
        onAdd={handleFormClose}
        onClose={handleFormClose}
        open={openForm}
      />
    </Page>
  );
}

export default SecurityListView;
