/* eslint-disable max-len */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  makeStyles,
  CircularProgress,
  colors
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import {
  FlagRounded as PaidIcon,
  FlagOutlined as UnpaidIcon,
  AddBoxOutlined as AddIcon,
  PictureAsPdfOutlined as PDFIcon,
  Calculate as CalculatorIcon,
  DeleteOutlineOutlined as DeleteIcon,
  NoteAltOutlined as EditIcon,
  MonetizationOnOutlined as PayoutIcon
} from '@mui/icons-material';
import { SpeakerNotesOutlined as NotesIcon } from '@material-ui/icons';
import {
  updateCommission,
  deleteCommission
} from 'src/actions/syndicateActions';
import Label from 'src/components/Label';
import { LightTooltip } from 'src/components/LightTooltip';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  badgeIcon: {
    marginRight: theme.spacing(1)
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  checkedButton: {
    color: colors.red[600]
  },
  uncheckedButton: {
    color: colors.grey[400]
  }
}));

function Results({
  className,
  onOpenCalcForm,
  onOpenAbaForm,
  onOpenCommissionForm,
  ...rest
}) {
  const classes = useStyles();
  const { commissions, isLoading } = useSelector(
    state => state.syndicate.commissions
  );
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);

  const columns = [
    {
      title: 'update_dt',
      field: 'updated_dt',
      type: 'date',
      dateSetting: { locale: 'en-AU' },
      hidden: true,
      defaultSort: 'desc'
    },
    {
      title: 'Fund Raiser',
      field: 'fundraiser',
      render: rowData => (
        <div>
          {rowData.fundraiser}
          <Typography variant="body2" color="textSecondary">
            {`${rowData.investor_name} ${currencyFormatter(
              rowData.investment_amt
            )}`}
          </Typography>
        </div>
      )
    },
    {
      title: 'Commission',
      field: 'commission',
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            {`${parseFloat(rowData.commission).toString()}% ${
              rowData.commission_type
            }`}
            {rowData.note && (
              <div className={classes.badge}>
                <LightTooltip title={rowData.note}>
                  <NotesIcon color="secondary" />
                </LightTooltip>
              </div>
            )}
          </Box>
        );
      }
    },
    {
      title: 'Commission Period',
      field: 'period_start_dt',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          rowData.period_start_dt &&
          `${format(
            parseISO(rowData.period_start_dt),
            'dd/MM/yyyy'
          )} to ${format(parseISO(rowData.period_end_dt), 'dd/MM/yyyy')}`
        );
      }
    },
    {
      title: 'Amount',
      field: 'commission_amt',
      type: 'currency'
    },
    {
      title: 'Payment Status',
      field: 'is_paid',
      render: rowData => (
        <Box
          display="flex"
          color="text.secondary"
          alignItems="center"
          justifyContent="flex-start"
        >
          <div className={classes.badge}>
            <IconButton
              disabled={!user.groups.includes(5)}
              className={
                !rowData.is_paid
                  ? classes.checkedButton
                  : classes.uncheckedButton
              }
              onClick={() => {
                dispatch(
                  updateCommission(rowData.id, {
                    is_paid: !rowData.is_paid
                  })
                );
              }}
            >
              {rowData.is_paid ? (
                <UnpaidIcon fontSize="small" />
              ) : (
                <PaidIcon fontSize="small" />
              )}
            </IconButton>
            <Typography variant="body2" color="inherit" component="span">
              {rowData.is_paid ? 'Paid' : 'Unpaid'}
            </Typography>
          </div>
        </Box>
      )
    }
  ];

  return (
    <CustomMaterialTable
      title="Commissions"
      isLoading={isLoading}
      columns={columns}
      data={commissions}
      actions={[
        {
          icon: CalculatorIcon,
          tooltip: 'Calc Commission',
          isFreeAction: true,
          onClick: event => onOpenCalcForm()
        },
        rowData => ({
          icon: EditIcon,
          disabled: rowData.is_paid,
          tooltip: 'Update Commission',
          onClick: (event, rowData) => onOpenCommissionForm(rowData)
        }),
        rowData => ({
          icon: DeleteIcon,
          disabled: rowData.is_paid,
          tooltip: 'Delete Commission',
          onClick: async (event, rowData) => {
            try {
              await dispatch(deleteCommission(rowData));
              toast.success('Commission entry deleted!');
            } catch {
              toast.error('Commission entry deletion failed!');
            }
          }
        })
      ]}
      options={{
        padding: 'dense',
        pageSize: 15,
        pageSizeOptions: [15, 30, 50]
      }}
    />
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
