
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.775,"time":46500,"words":155}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "support"
    }}>{`Support`}</h1>
    <p>{`Our support mainly covers pre-sale questions, basic theme questions and bug reports through our support email: `}<a href="mailto:support@deviasio.zendesk.com"><a parentName="p" {...{
          "href": "mailto:support@deviasio.zendesk.com"
        }}>{`support@deviasio.zendesk.com`}</a></a>{`. `}</p>
    <p>{`To be eligible to request the technical support you must have purchased the theme and have at least one regular or extended license.`}</p>
    <p>{`When you send a support request please do describe your issue with more details. If you can provide a link to your developing site then this can help us to solve your issue more faster.`}</p>
    <p>{`All 3rd party plugins used in the theme are provided as bonus and we do not give any guarantee to their functionalities. Also our support do not cover any 3rd party plugin customization or bug fixes that are not in our control.`}</p>
    <p>{`Customers are always welcome to ask for feature requests and give suggestions that can improve our premium themes. All feature requests definitely will be considered and the new features will be released with upcoming releases.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

