import axios from 'axios';

export const GET_SECURITIES = '@security/get-securities';
export const ADD_SECURITY = '@security/add-security';
export const UPDATE_SECURITY = '@security/update-security';
export const DELETE_SECURITY = '@security/delete-security';

export function getSecurities(params) {
  const request = axios.get('/api/securities', params);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SECURITIES,
        payload: response.data
      })
    );
  };
}

export function addSecurity(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/securities', values);
      dispatch({ type: ADD_SECURITY, payload: response.data });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function updateSecurity(securityId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/securities/${securityId}`,
        values
      );
      dispatch({ type: UPDATE_SECURITY, payload: response.data });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}
