/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormData from 'form-data';
import clsx from 'clsx';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Trash2 as DeleteIcon } from 'react-feather';
import bytesToSize from 'src/utils/bytesToSize';
import { updateSyndicate } from 'src/actions/syndicateActions';
import { updateInvestor } from 'src/actions/investorActions';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function FilesDropzone({ className, syndicate, investor, prevFiles, ...rest }) {
  const classes = useStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const handleDrop = useCallback(acceptedFiles => {
    setFiles(prevFiles => [...prevFiles].concat(acceptedFiles));
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = filename => {
    setFiles(files.filter(file => file.name !== filename));
  };

  const path = syndicate
    ? `syndicates/${syndicate.id}_${syndicate.syndicate_name}`
    : `investors/${investor.id}_${investor.display_name}`;

  const upLoadFile = async file => {
    const formData = new FormData();
    formData.set('path', path);
    formData.append('file', file);
    const response = await axios.post('/api/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  };

  const handleUpload = async () => {
    setIsUploading(true);
    const unresolvedPromises = files.map(file => upLoadFile(file));
    const newFiles = await Promise.all(unresolvedPromises);
    setFiles([]);
    syndicate
      ? dispatch(
          updateSyndicate(syndicate.id, {
            id: syndicate.id,
            is_pool: syndicate.is_pool,
            files: _.unionBy(newFiles, new Array().concat(prevFiles), 'id')
          })
        )
      : dispatch(
          updateInvestor(investor.id, {
            id: investor.id,
            files: _.unionBy(newFiles, new Array().concat(prevFiles), 'id')
          })
        );

    setIsUploading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/static/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Select files
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              Drop files here or click <Link underline="always">browse</Link>{' '}
              thorough your machine
            </Typography>
          </Box>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleRemove(file.name)}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small">
              Remove all
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleUpload}
                color="secondary"
                size="small"
                variant="contained"
                disabled={isUploading || files.length == 0}
              >
                Upload files
              </Button>
              {isUploading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
