import React from 'react';
import NumberFormat from 'react-number-format';

export function properString(str) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function lowerString(str) {
  return str.replace(/\s/g, '').toLowerCase();
}

export function currencyFormatter(x) {
  return '$'.concat(
    Number(x)
      .toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      .toString()
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // .replace('.00', '')
  );
}

export function CurrencyMask(props) {
  const { inputRef, onChange, ...other } = props;
  let floatValue = 0;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => (floatValue = values.value)}
      onChange={() => onChange(floatValue)}
      thousandSeparator
      prefix="$"
    />
  );
}

export function PercentMask(props) {
  const { inputRef, onChange, ...other } = props;
  let floatValue = 0;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => (floatValue = values.value)}
      onChange={() => onChange(floatValue)}
      // decimalSeparator
      suffix="%"
    />
  );
}

export function BSBMask(props) {
  const { inputRef, onChange, ...other } = props;
  let stringValue = '';
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        stringValue = values.formattedValue;
      }}
      onChange={() => {
        onChange(stringValue);
      }}
      format="###-###"
    />
  );
}

export function NumberMask(props) {
  const { inputRef, onChange, ...other } = props;
  let stringValue = '';
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        stringValue = values.formattedValue;
      }}
      onChange={() => {
        onChange(stringValue);
      }}
      mask={[/\d/]}
    />
  );
}

export function MobileMask(props) {
  const { inputRef, onChange, ...other } = props;
  let stringValue = '';
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => (stringValue = values.formattedValue)}
      onChange={() => onChange(stringValue)}
      placeholder="0400 000 000"
      format="#### ### ###"
    />
  );
}
