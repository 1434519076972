import axios from 'axios';

export const GET_FUNDRAISERS = '@investor/get-fundraisers';

export function getFundraisers() {
  const request = axios.get('/api/fundraisers');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_FUNDRAISERS,
        payload: response.data
      })
    );
  };
}
