import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import Label from 'src/components/Label';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Chart from './Chart';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&': {
        borderRight: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

function Stats({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const getStats = useCallback(() => {
    axios.get('/api/view/dashboard').then(response => {
      if (isMountedRef.current) {
        setStats(response.data[0]);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const roi = loading
    ? { current: [0, 0, 0], overall: [0, 0, 0] }
    : {
        current: [
          parseFloat(stats.current_roi_1st).toFixed(1),
          parseFloat(stats.current_roi_mezz).toFixed(1),
          parseFloat(stats.current_roi_equity).toFixed(1)
        ],
        overall: [
          parseFloat(stats.roi_1st).toFixed(1),
          parseFloat(stats.roi_mezz).toFixed(1),
          parseFloat(stats.roi_equity).toFixed(1)
        ]
      };
  const labels = ['1st', 'Mezz', 'Equity'];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Fund Overview" />
      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            alignItems="center"
            item
            md={9}
            xs={12}
            container
            justifyContent="space-between"
          >
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                variant="h6"
                variant="overline"
                color="textSecondary"
                gutterBottom
              >
                Total Loan Size
              </Typography>
              <div className={classes.valueContainer}>
                <Typography variant="h6" color="textPrimary">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.total_loan_size)
                  )}
                </Typography>
                {/* <Tooltip title="Number of Loans Since Inception"> */}
                <Label className={classes.label} color="success">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    stats.total_spv_cnt
                  )}
                </Label>
                {/* </Tooltip> */}
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                Current Loan Size
              </Typography>
              <div className={classes.valueContainer}>
                <Typography variant="h6" color="textPrimary">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.current_loan_size)
                  )}
                </Typography>
                {/* <Tooltip title="Number of Current Loans"> */}
                <Label className={classes.label} color="success">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    stats.current_spv_cnt
                  )}
                </Label>
                {/* </Tooltip> */}
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                Total FUM
              </Typography>
              <div className={classes.valueContainer}>
                <Typography variant="h6" color="textPrimary">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.total_funds_under_management)
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                Pipeline
              </Typography>
              <div className={classes.valueContainer}>
                <Typography variant="h6" color="textPrimary">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.pipeline_amt)
                  )}
                </Typography>
                <Label className={classes.label} color="success">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    stats.pipeline_spv_cnt
                  )}
                </Label>
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                FUM in Pool
              </Typography>
              <div className={classes.valueContainer}>
                <Typography variant="h6" color="textPrimary">
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.funds_in_pool)
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                Idle Cash in Pool
              </Typography>
              <div className={classes.valueContainer}>
                <Typography
                  variant="h6"
                  color={
                    loading
                      ? 'textPrimary'
                      : parseFloat(stats.total_pool_idle_amt) > 0
                      ? 'error'
                      : 'textPrimary'
                  }
                >
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.total_pool_idle_amt)
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                Liquidity Due
              </Typography>
              <div className={classes.valueContainer}>
                <Typography
                  variant="h6"
                  color={
                    loading
                      ? 'textPrimary'
                      : parseFloat(stats.liquidity_amt) > 0
                      ? 'error'
                      : 'textPrimary'
                  }
                >
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.liquidity_amt)
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography
                component="h6"
                gutterBottom
                variant="overline"
                color="textSecondary"
              >
                Open for EOI
              </Typography>
              <div className={classes.valueContainer}>
                <Typography
                  variant="h6"
                  color={
                    loading
                      ? 'textPrimary'
                      : parseFloat(stats.open_amt) > 0
                      ? 'error'
                      : 'textPrimary'
                  }
                >
                  {loading ? (
                    <Skeleton animation="wave" variant="text" />
                  ) : (
                    currencyFormatter(stats.open_amt)
                  )}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width="100%"
                height="100%"
              />
            ) : (
              <Chart className={classes.chart} data={roi} labels={labels} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Stats;
