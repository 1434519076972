import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import kanbanOldReducer from './kanbanOldReducer';
// import brokerReducer from './brokerReducer';
import developerReducer from './developerReducer';
import entityReducer from './entityReducer';
import fundraiserReducer from './fundraiserReducer';
import investorReducer from './investorReducer';
import settingReducer from './settingReducer';
import syndicateReducer from './syndicateReducer';
import securityReducer from './securityReducer';
import staffReducer from './staffReducer';
import dashboardReducer from './dashboardReducer';
import applicationReducer from './applicationReducer';
import campaignReducer from './campaignReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  application: applicationReducer,
  // broker: brokerReducer,
  campaign: campaignReducer,
  chat: chatReducer,
  dashboard: dashboardReducer,
  developer: developerReducer,
  entity: entityReducer,
  form: formReducer,
  fundraiser: fundraiserReducer,
  investor: investorReducer,
  mail: mailReducer,
  notifications: notificationsReducer,
  kanban: kanbanReducer,
  kanbanOld: kanbanOldReducer,
  staff: staffReducer,
  security: securityReducer,
  setting: settingReducer,
  syndicate: syndicateReducer
});

export default rootReducer;
