import produce from 'immer';
import _ from 'lodash';
import { GET_FUNDRAISERS } from 'src/actions/fundraiserActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  fundraisers: {
    byId: {},
    allIds: []
  }
};

const fundraiserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FUNDRAISERS: {
      const fundraisers = action.payload;

      return produce(state, draft => {
        draft.fundraisers.byId = objFromArray(fundraisers);
        draft.fundraisers.allIds = fundraisers.map(raiser => raiser.id);

        draft.isLoading = false;
      });
    }
    default: {
      return state;
    }
  }
};

export default fundraiserReducer;
