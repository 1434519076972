/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { getCommissions } from 'src/actions/syndicateActions';
import CalcForm from './CalcForm';
import CommissionForm from './CommissionForm';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

function Commission({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const [openCalcForm, setOpenCalcForm] = useState(false);
  const [openCommissionForm, setOpenCommissionForm] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState(null);

  useEffect(() => {
    dispatch(getCommissions(syndicateId));
  }, [dispatch]);

  const handleCalcFormOpen = () => {
    setOpenCalcForm(true);
  };

  const handleCalcFormClose = () => {
    setOpenCalcForm(false);
  };

  const handleCommissionFormOpen = value => {
    setSelectedCommission(value);
    setOpenCommissionForm(true);
  };

  const handleCommissionFormClose = () => {
    setOpenCommissionForm(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Results
        // onOpenAbaForm={handleAbaFormOpen}
        onOpenCommissionForm={handleCommissionFormOpen}
        onOpenCalcForm={handleCalcFormOpen}
      />
      <CalcForm
        onAdd={handleCalcFormClose}
        onClose={handleCalcFormClose}
        open={openCalcForm}
      />
      <CommissionForm
        commission={selectedCommission}
        onAdd={handleCommissionFormClose}
        onClose={handleCommissionFormClose}
        open={openCommissionForm}
      />
    </Card>
  );
}

Commission.propTypes = {
  className: PropTypes.string
};

export default Commission;
