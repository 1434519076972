import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { bulkDeposit } from 'src/actions/syndicateActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function BulkDrawdownForm({
  bulkDrawdown,
  open,
  onClose,
  onAdd,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = bulkDrawdown;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   transaction_dt: Yup.date().required()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(bulkDeposit(values));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Interet Start Date Set', {
                  variant: 'success'
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Typography variant="body1" color="textPrimary">
                      This form is to set interest start date for selected
                      investors
                    </Typography>
                    <Box mt={2}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={enAU}
                      >
                        <DatePicker
                          label="Interest Start Date"
                          value={values.transaction_dt}
                          onChange={newValue => {
                            setFieldValue(
                              'transaction_dt',
                              !newValue || newValue.toString() == 'Invalid Date'
                                ? newValue
                                : format(newValue, 'yyyy-MM-dd')
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              variant="outlined"
                              value={values.transaction_dt}
                              name="transaction_dt"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Drawdown
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

BulkDrawdownForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

BulkDrawdownForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default BulkDrawdownForm;
