import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  NoteAltOutlined as EditIcon,
  FileDownloadOutlined as DownloadIcon,
  PictureAsPdfOutlined as StatementIcon
} from '@mui/icons-material';
import { updateEntity } from 'src/actions/entityActions';
import Label from 'src/components/Label';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import bytesToSize from 'src/utils/bytesToSize';

const statusColors = {
  false: 'error',
  true: 'success'
};

const useStyles = makeStyles(theme => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function EntityList({ className, investor, onOpenStatementForm, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { investorId } = useParams();
  const [uploading, setUploading] = useState(false);
  const [entities, setEntities] = useState(investor.entities);

  const handleClose = () => {
    setUploading(false);
  };

  const handleDownload = file_path => {
    const filename = file_path.split('/')[file_path.split('/').length - 1];
    setUploading(true);
    axios
      .get('/api/file', {
        responseType: 'arraybuffer',
        params: { path: file_path }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = encodeURI(url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        setUploading(false);
        link.click();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const columns = [
    {
      field: 'account_name',
      title: 'Entity Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => (
        <div>
          {rowData.account_name}
          <Typography variant="body2" color="textSecondary">
            {rowData.entity_type}
          </Typography>
        </div>
      )
    },
    {
      field: 'modify_ts',
      title: 'Bank Account',
      render: rowData => (
        <div>
          {rowData.bank_acct_name}
          <Typography variant="body2" color="textSecondary">
            {rowData.bsb
              ? `BSB ${rowData.bsb} Bank Account ${rowData.bank_acct_no}`
              : `Swift Code ${rowData.swift_code || ''} Bank Account ${
                  rowData.bank_acct_no
                }`}
          </Typography>
        </div>
      )
    },
    {
      field: 'is_tax_resident',
      title: 'Tax Resident',
      render: rowData => (
        <Label color={statusColors[rowData.is_tax_resident]}>
          {rowData.is_tax_resident ? 'Yes' : 'No'}
        </Label>
      )
    },
    {
      field: 'is_us_tax_resident',
      title: 'US Tax Resident',
      render: rowData => (
        <Label color={statusColors[rowData.is_us_tax_resident]}>
          {rowData.is_us_tax_resident ? 'Yes' : 'No'}
        </Label>
      )
    }
  ];

  const fileColumns = [
    { field: 'document_type', title: 'Type' },
    {
      field: 'name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      field: 'modify_ts',
      title: 'Last Modify',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'size',
      title: 'Size',
      render: rowData => bytesToSize(rowData.size)
    }
  ];

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Backdrop
        className={classes.backdrop}
        open={uploading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CustomMaterialTable
          title="Investment Entities"
          data={entities}
          columns={columns}
          detailPanel={rowData => {
            if (rowData.files.length == 0) {
              return null;
            }
            return (
              <Box m={1} sx={{ color: 'text.disabled' }}>
                <CustomMaterialTable
                  title="Supporting Documents"
                  columns={fileColumns}
                  data={rowData.files.map(file => ({
                    ...file,
                    entity_id: rowData.id
                  }))}
                  actions={[
                    {
                      icon: DownloadIcon,
                      tooltip: 'Download Document',
                      onClick: (event, data) => {
                        handleDownload(data.path);
                      }
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Delete File',
                      onClick: (event, data) => {
                        console.log(data);
                        dispatch(
                          updateEntity(data.entity_id, {
                            files: entities
                              .filter(entity => entity.id == data.entity_id)[0]
                              .files.filter(file => file.id != data.id)
                          })
                        );
                        setEntities(
                          entities.map(entity =>
                            entity.id == data.entity_id
                              ? {
                                  ...entity,
                                  files: entity.files.filter(
                                    f => f.id != data.id
                                  )
                                }
                              : entity
                          )
                        );
                      }
                    }
                  ]}
                  options={{
                    paging: false,
                    padding: 'dense'
                  }}
                />
              </Box>
            );
          }}
          actions={[
            {
              icon: AddIcon,
              tooltip: 'Add Entity',
              isFreeAction: true,
              onClick: (event, rowData) =>
                history.push(
                  `/app/management/investors/${investorId}/entities/create`
                )
            },
            {
              icon: StatementIcon,
              tooltip: 'Download Financial Statement',
              onClick: (evt, data) => {
                onOpenStatementForm(data.id);
              }
            },
            {
              icon: EditIcon,
              tooltip: 'Edit',
              onClick: (evt, data) => {
                history.push(
                  `/app/management/investors/${investorId}/${data.id}/edit`
                );
              }
            }
          ]}
          options={{
            padding: 'dense',
            paging: false,
            actionsColumnIndex: -1
          }}
        />
      </Card>
    </div>
  );
}

EntityList.propTypes = {
  className: PropTypes.string,
  entities: PropTypes.array
};

EntityList.defaultProps = {
  entities: []
};

export default EntityList;
