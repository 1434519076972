import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  Grid,
  Switch
} from '@material-ui/core';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function AbaForm({ aba, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicate, isLoading } = useSelector(state => state.syndicate);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = aba;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   lodgement_ref: Yup.string()
            //     .max(18, 'Length needs to be within 18 charactors')
            //     .required('Description is required'),
            //   payment_dt: Yup.date().required(),
            //   transaction_dt: Yup.date().required()
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // TODO: properly catch and present error message
                axios
                  .post('/api/aba', values, {
                    responseType: 'arraybuffer'
                  })
                  .then(response => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', response.headers['filename']); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch(error => console.log(error));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Redemption Date"
                            value={values.transaction_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'transaction_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                variant="outlined"
                                value={values.transaction_dt}
                                name="transaction_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Payment Date"
                            value={values.payment_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'payment_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                variant="outlined"
                                value={values.payment_dt}
                                name="payment_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          required
                          label="Payment Description"
                          name="lodgement_ref"
                          inputProps={{ maxLength: 18 }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lodgement_ref || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="h5" color="textPrimary">
                                From Trust account
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={values.from_spv_account}
                                color="secondary"
                                edge="start"
                                name="from_spv_account"
                                onChange={handleChange}
                                value={values.from_spv_account}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h5" color="textPrimary">
                                From SPV account
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Downloand Payment File
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

AbaForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

AbaForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default AbaForm;
