import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormControlLabel,
  FormHelperText,
  SvgIcon,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Grid,
  Switch
} from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { createTransfers } from 'src/actions/syndicateActions';
import Label from 'src/components/Label';
import { currencyFormatter } from 'src/utils/mask';
import { CurrencyMask } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function transferTotal(transfers) {
  return transfers
    .map(({ amount }) => parseFloat(amount || 0))
    .reduce((sum, i) => sum + i, 0)
    .toFixed(2);
}

function SellForm({ investment, open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(state => state.account);
  const { investments, isLoading } = useSelector(state => state.syndicate);

  const toInvestments =
    investment && !isLoading
      ? investments.allIds
          .map(id => {
            if (
              (investments.byId[id].managing_staff == user.id ||
                user.groups.some(r => [1, 5].includes(r))) &&
              investments.byId[id].entity_id !== investment.entity_id &&
              investments.byId[id].entity_id &&
              ![
                'Funds Received',
                'Funds Repaid',
                'Funds Partially Repaid'
              ].includes(investments.byId[id].investment_status)
            )
              return investments.byId[id];
          })
          .filter(x => !!x) // remove nulls
      : [];

  const initialValues = {
    transaction_dt: null,
    from_transaction: investment
      ? {
          investment_id: investment.id,
          syndicate_id: investment.syndicate_id,
          entity_id: investment.entity_id,
          transaction_type_id: 2,
          amount: null
        }
      : null,
    to_transactions: toInvestments.map(investment => {
      return {
        investment_id: investment.id,
        syndicate_id: investment.syndicate_id,
        entity_id: investment.entity_id,
        transaction_type_id: 1,
        amount: null,
        limit:
          parseFloat(investment.amount) - parseFloat(investment.current_amt)
      };
    })
  };

  if (isLoading) {
    return null;
  }
  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                values.from_transaction.transaction_dt = values.transaction_dt;
                values.from_transaction.amount = transferTotal(
                  values.to_transactions
                );
                const transactions = values.to_transactions
                  .map(transaction => {
                    if (transaction.amount) {
                      transaction.transaction_dt = values.transaction_dt;
                      delete transaction['limit'];
                      return transaction;
                    }
                  })
                  .concat(values.from_transaction)
                  .filter(x => !!x); // remove nulls;
                // await dispatch(addTransactions(transactions));
                await dispatch(createTransfers(transactions));
                onAdd();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Transfer added', {
                  variant: 'success'
                });
              } catch (error) {
                // TODO catch proper error msg
                setStatus({ success: false });
                setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setTouched,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item md={8} xs={12}>
                        <Typography variant="overline" color="textPrimary">
                          {`Transfer from ${investment.investor_name} under ${investment.account_name} to: `}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <Label className={classes.label} color="primary">
                            {'Current Balance: '}
                            {currencyFormatter(
                              parseFloat(investment.current_amt)
                            )}
                          </Label>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Transfer Date"
                            value={values.transaction_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'transaction_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={values.transaction_dt}
                                name="transaction_dt"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Box mt={2}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">#</TableCell>
                              <TableCell>To Investor</TableCell>
                              <TableCell align="right">
                                Transfer Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {toInvestments.map((investment, index) => {
                              // const isItemSelected = isSelected(investment.id);
                              const labelId = `enhanced-table-checkbox-${investment.id}`;
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={investment.id}
                                >
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="investment"
                                    padding="none"
                                  >
                                    <Box>
                                      {investment.investor_name}
                                      {investment.account_name && (
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          <Label
                                            className={classes.label}
                                            color="primary"
                                          >
                                            {investment.account_name} |{' '}
                                            {currencyFormatter(
                                              parseFloat(investment.amount) -
                                                parseFloat(
                                                  investment.current_amt
                                                )
                                            )}
                                            {' open'}
                                          </Label>
                                        </Typography>
                                      )}
                                    </Box>
                                  </TableCell>
                                  <TableCell align="right">
                                    <TextField
                                      size="small"
                                      name={`to_transactions.${index}.amount`}
                                      InputProps={{
                                        inputComponent: CurrencyMask,
                                        onChange: handleChange(
                                          `to_transactions.${index}.amount`
                                        )
                                      }}
                                      inputProps={{
                                        style: { textAlign: 'right' }
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={
                                        values.to_transactions[index].amount ||
                                        ''
                                      }
                                      variant="outlined"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            <TableRow selected={true}>
                              <TableCell />
                              <TableCell align="right">Total</TableCell>
                              <TableCell align="right">
                                {currencyFormatter(
                                  transferTotal(values.to_transactions)
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        {errors.submit && (
                          <Box mt={3}>
                            <FormHelperText error>
                              {errors.submit}
                            </FormHelperText>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Sell Units
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

SellForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

SellForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default SellForm;
