import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getStaff } from 'src/actions/staffActions';
import Header from './Header';
import General from './General';
import UserManagement from './UserManagement';
import Security from './Security';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function AccountView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);
  const [currentTab, setCurrentTab] = useState('general');
  const tabs = [
    { value: 'general', label: 'General' },
    { value: 'security', label: 'Security' },
    { value: 'user', label: 'User Management' }
  ];

  useEffect(() => {
    if (user.is_admin) {
      dispatch(getStaff({ allStaff: true }));
    }
  }, [dispatch]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => {
              if (!user.is_admin && tab.value == 'user') {
                return null;
              } else {
                return (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                );
              }
            })}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'general' && <General />}
          {currentTab === 'security' && <Security />}
          {currentTab === 'user' && <UserManagement />}
        </Box>
      </Container>
    </Page>
  );
}

export default AccountView;
