import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
// import { Box, Typography, makeStyles } from '@material-ui/core';
import { Avatar, Box, Paper, Input, SvgIcon, Typography } from '@mui/material';
import {
  getBoard,
  updateCard,
  updateList,
  MOVE_CARD,
  setFilter
} from 'src/actions/kanbanActions';
import SearchIcon from '@mui/icons-material/Search';
import { KanbanColumn } from './kanban-column';
import { KanbanColumnAdd } from './kanban-column-add';
import { getSetting } from 'src/actions/settingActions';
import { getSecurities } from 'src/actions/securityActions';
import { getStaff } from 'src/actions/staffActions';
import { KanbanCardModal } from './kanban-card-modal';

function kanban() {
  const dispatch = useDispatch();
  const { cards, lists, isLoading: boardLoading } = useSelector(
    state => state.kanban
  );
  const { isLoading: staffLoading } = useSelector(state => state.staff);
  const { isLoading: settingLoading } = useSelector(state => state.setting);
  const { isLoading: securityLoading } = useSelector(state => state.security);

  useEffect(
    () => {
      dispatch(getBoard());
      dispatch(getSetting());
      dispatch(getSecurities());
      dispatch(getStaff());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const moveCard = async (cardId, position, columnId) => {
    try {
      // Find the card that will be moved
      const card = cards.byId[cardId];

      // Find the source column of the card
      const sourceList = lists.byId[card.list_id];
      // Remove the cardId reference from the source list
      const sourceCardIds = sourceList.cardIds.filter(
        _cardId => _cardId !== cardId
      );
      if (columnId) {
        // Find the destination column for the card
        const destinationList = lists.byId[columnId];
        // Add the cardId reference to the destination list
        const destinationCardIds = destinationList.cardIds.slice();
        destinationCardIds.splice(position, 0, card.id.toString());
        // update the card's list
        await dispatch(updateCard(cardId, { list_id: columnId }));
        // // update the source list
        await dispatch(updateList(card.list_id, { cardIds: sourceCardIds }));
        // // update the destination list
        await dispatch(updateList(columnId, { cardIds: destinationCardIds }));
      } else {
        // If columnId is not provided, it means that we move the card in the same list
        sourceCardIds.splice(position, 0, card.id.toString());
        await dispatch(updateList(card.list_id, { cardIds: sourceCardIds }));
      }
      dispatch({ type: MOVE_CARD, payload: { cardId, position, columnId } });
    } catch (err) {
      console.log(err);
      toast.error('Move card failed');
    }
  };

  const handleDragEnd = async ({ source, destination, draggableId }) => {
    try {
      // Dropped outside the column
      if (!destination) {
        return;
      }

      // Card has not been moved
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        // Moved to the same column on different position
        moveCard(draggableId, destination.index);
      } else {
        // Moved to another column
        moveCard(draggableId, destination.index, destination.droppableId);
      }
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  };

  if (boardLoading || staffLoading || settingLoading || securityLoading) {
    return null;
  }

  return (
    <>
      <KanbanCardModal />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
          position: 'relative',
          height: '100%'
        }}
      >
        <Box
          sx={{
            pl: 3,
            pt: 8,
            display: 'flex'
          }}
        >
          <Typography variant="h5">In Progress Loans</Typography>
          <Box flexGrow={1} />
          <Paper
            component={Box}
            display="flex"
            alignItems="center"
            variant="outlined"
            py={0.5}
            px={1}
          >
            <SvgIcon color="action" fontSize="small">
              <SearchIcon />
            </SvgIcon>
            <Input
              onChange={event => {
                dispatch(setFilter(event.target.value));
              }}
              sx={{
                flexGrow: 1
              }}
              disableUnderline
              placeholder="Search card"
            />
          </Paper>
        </Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              overflowX: 'auto',
              overflowY: 'hidden'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                px: 1,
                py: 3
              }}
            >
              {lists.allIds.map(columnId => (
                <KanbanColumn columnId={columnId} key={columnId} />
              ))}
              <KanbanColumnAdd />
            </Box>
          </Box>
        </DragDropContext>
      </Box>
    </>
  );
}

export default kanban;
