import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Header({ investor, className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app"
            component={RouterLink}
          >
            Home
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to="/app/management/investors"
            component={RouterLink}
          >
            All Investors
          </Link>
          {investor && (
            <Link
              variant="body1"
              color="inherit"
              to={`/app/management/investors/${investor.id}`}
              component={RouterLink}
            >
              <Typography variant="body1" color="textPrimary">
                {investor.display_name}
              </Typography>
            </Link>
          )}
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          {investor ? 'Edit Investor' : 'Create Investor'}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={RouterLink}
          to={
            investor
              ? `/app/management/investors/${investor.id}`
              : '/app/management/investors'
          }
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
