import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
  TextField,
  colors,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Switch,
  SvgIcon,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { AlertTriangle as AlertIcon } from 'react-feather';
import { updateInvestor } from 'src/actions/investorActions';

const useStyles = makeStyles(theme => ({
  root: {},
  jobAvatar: {
    backgroundColor: theme.palette.secondary.main
  },
  cityAvatar: {
    backgroundColor: colors.red[600]
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

function ContactInfo({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { investor, isLoading } = useSelector(state => state.investor);
  const { riskLevel } = useSelector(state => state.setting);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader
          action={
            <Button
              component={RouterLink}
              to={
                isLoading
                  ? '/#'
                  : `/app/management/investors/${investor.id}/edit`
              }
              color="secondary"
              size="small"
            >
              Edit
            </Button>
          }
          title="Contact Info"
        />
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Managing Staff</TableCell>
              <TableCell>
                <div>
                  <Typography variant="body2" color="textPrimary">
                    {investor.contact_manager}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" color="textSecondary">
                    {investor.fundraiser}
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Primary Contact</TableCell>
              <TableCell>
                <div>
                  <Typography variant="body2" color="textPrimary">
                    {investor.display_name}
                  </Typography>
                </div>
                <div>{investor.mobile}</div>
                <div>{investor.email}</div>
                <div>
                  {investor.address_1} {investor.address_2} {investor.city}{' '}
                  {investor.state} {investor.postcode} {investor.country}
                </div>
                <div>
                  <Typography variant="caption" color="textSecondary">
                    {investor.accept_marketing
                      ? 'Accepting marketing'
                      : "Don't accept marketing"}
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Risk Preferences</TableCell>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {investor.risk_preferences.map(
                    id => `${riskLevel.byId[id].name} ` || ' '
                  )}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Notes</TableCell>
              <TableCell>{investor.note}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

ContactInfo.propTypes = {
  className: PropTypes.string,
  investor: PropTypes.object.isRequired
};

export default ContactInfo;
