import React, { useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  Typography,
  makeStyles,
  FormHelperText,
  TextField,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  format,
  startOfMonth,
  startOfQuarter,
  endOfMonth,
  endOfQuarter
} from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { addCommissions } from 'src/actions/syndicateActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  appBar: {
    position: 'relative'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function CalcForm({ open, onClose, onAdd, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { syndicate, investments, isLoading } = useSelector(
    state => state.syndicate
  );

  const investmentIds = investments.allIds.filter(
    id => investments.byId[id].fundraiser_id
  );

  const fundraisers = _.uniqBy(
    investmentIds.map(id => {
      return {
        id: investments.byId[id].fundraiser_id,
        name: investments.byId[id].fundraiser
      };
    }),
    'id'
  );

  const initialValues = {
    syndicate_id: syndicateId,
    period_start_dt:
      syndicate.payment_freq == 'Monthly'
        ? format(startOfMonth(new Date()), 'yyyy-MM-dd')
        : format(startOfQuarter(new Date()), 'yyyy-MM-dd'),
    period_end_dt:
      syndicate.payment_freq == 'Monthly'
        ? format(endOfMonth(new Date()), 'yyyy-MM-dd')
        : format(endOfQuarter(new Date()), 'yyyy-MM-dd'),
    fundraiser: null,
    fundraiserId: null
  };

  return (
    <Dialog maxWidth="md" onClose={() => onClose()} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({})}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(addCommissions(values));
                setStatus({ success: true });
                setSubmitting(true);
                toast.success('Commission calculated & added');
                onClose();
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.response.data });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Box mt={2}>
                      <Typography variant="h5" color="textPrimary">
                        Commission
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Autocomplete
                            // multiple
                            name="fundraiser"
                            value={values.fundraiser}
                            options={fundraisers}
                            getOptionLabel={option => option.name}
                            onChange={(event, newValues) => {
                              setFieldValue('fundraiser', newValues);
                              setFieldValue('fundraiserId', newValues.id);
                              // setFieldValue(
                              //   'fundraiserIds',
                              //   JSON.stringify(
                              //     newValues.map(value => value.id) || []
                              //   )
                              // );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                variant="outlined"
                                label="Fund Raiser"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Period Start Date"
                              value={values.period_start_dt}
                              name="period_start_dt"
                              onChange={newValue => {
                                setFieldValue(
                                  'period_start_dt',
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Period End Date"
                              name="period_end_dt"
                              value={values.period_end_dt}
                              onChange={newValue => {
                                setFieldValue(
                                  'period_end_dt',
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {JSON.stringify(errors.submit)}
                        </FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Calculate
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

CalcForm.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

CalcForm.defaultProps = {
  onAdd: () => {},
  onClose: () => {}
};

export default CalcForm;
