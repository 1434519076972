import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import axios from 'axios';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  SvgIcon,
  Link,
  Typography,
  Tooltip,
  Breadcrumbs,
  makeStyles
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  Calendar as CalendarIcon,
  DollarSign as DollarSignIcon,
  Award as AwardIcon,
  Edit as EditIcon,
  Framer as FramerIcon,
  ShoppingBag as ShoppingBagIcon,
  Shield as ShieldIcon,
  Pocket as PocketIcon,
  BookOpen as GapIcon,
  Download as DownloadIcon
} from 'react-feather';
import {
  HomeWork as SyndicateIcon,
  AttachMoney as AttachMoneyIcon,
  AnnouncementOutlined as EOIIcon,
  NotificationImportantRounded as NotificationIcon,
  AccountBalanceOutlined as BankIcon
} from '@material-ui/icons';
import Label from 'src/components/Label';
import Gap from './Gap';
import FundingSource from './FundingSource';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  badge: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
  badgeIcon: {
    marginRight: theme.spacing(2)
  },
  multiline: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(1)
  },

  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  },
  card: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contrastCard: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  statsItem: {
    padding: theme.spacing(3),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  whiteAvatar: {
    backgroundColor: '#ffffff',
    height: 48,
    width: 48
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  whiteCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

function getStageLabel(syndicate_stage_id) {
  const map = {
    1: {
      text: 'Due Diligence',
      color: 'error'
    },
    2: {
      text: 'Under Management',
      color: 'warning'
    },
    3: {
      text: 'Completed',
      color: 'success'
    },
    4: {
      text: 'Cancelled',
      color: 'greyout'
    }
  };

  const { text, color } = map[syndicate_stage_id];

  return <Label color={color}>{text}</Label>;
}

function Header({ className, ...rest }) {
  const classes = useStyles();
  const { syndicateId } = useParams();
  const { syndicate, isLoading: syndicateLoading } = useSelector(
    state => state.syndicate
  );
  const { stats, isLoading: statsLoading } = useSelector(
    state => state.syndicate.stats
  );
  const { user } = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              variant="body1"
              color="inherit"
              to="/app"
              component={RouterLink}
            >
              Home
            </Link>
            <Link
              variant="body1"
              color="inherit"
              to="/app/management/syndicates"
              component={RouterLink}
            >
              <Typography color="secondary">All Syndicates</Typography>
            </Link>
            {!syndicateLoading && (
              <Typography variant="body1" color="textPrimary">
                {syndicate.syndicate_name}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
        {syndicateLoading || statsLoading ? (
          <Grid item xs={12} md={12}>
            <Skeleton animation="wave" variant="rect" height={200} />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              md={12}
              container
              spacing={1}
              justifyContent="space-between"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Grid item xs={12} md={6}>
                <Box>
                  {getStageLabel(syndicate.syndicate_stage_id)}
                  <Box mt={1}>
                    <Typography variant="body1" color="textPrimary">
                      {syndicate.is_pool
                        ? `Project Manager: ${syndicate.project_manager || ''}`
                        : `Project Manager: ${syndicate.project_manager ||
                            ''} || Borrower: ${syndicate.borrower_spv_name}`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {user.groups.some(r => [1, 2, 5].includes(r)) && (
                    <Button
                      className={classes.action}
                      component={RouterLink}
                      to={
                        syndicateLoading
                          ? '/#'
                          : `/app/management/syndicates/${syndicate.id}/edit`
                      }
                    >
                      <SvgIcon fontSize="small" className={classes.actionIcon}>
                        <EditIcon />
                      </SvgIcon>
                      Update
                    </Button>
                  )}
                  <Button
                    className={classes.action}
                    onClick={() => {
                      axios
                        .get('/api/docs', {
                          responseType: 'arraybuffer',
                          params: {
                            syndicateId: syndicateId,
                            docType: 'payment instruction',
                            actionType: 'download'
                          }
                        })
                        .then(response => {
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute(
                            'download',
                            response.headers['filename']
                          ); //or any other extension
                          document.body.appendChild(link);
                          link.click();
                        })
                        .catch(error => {
                          console.log(JSON.stringify(error.response));
                          enqueueSnackbar(
                            'Please check SPV name and bank details',
                            {
                              variant: 'error'
                            }
                          );
                        });
                    }}
                  >
                    <SvgIcon fontSize="small" className={classes.actionIcon}>
                      <DownloadIcon />
                    </SvgIcon>
                    Payment Instruction
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              lg={12}
              xs={12}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={3}>
                <div className={classes.multiline}>
                  <SvgIcon fontSize="small" className={classes.badgeIcon}>
                    <ShoppingBagIcon />
                  </SvgIcon>
                  <Box>
                    <div>
                      <Typography
                        variant="body2"
                        color="inherit"
                        component="span"
                      >
                        {syndicate.risk_level} ||{' '}
                        {`LVR: ${syndicate.lvr || ''}`}
                      </Typography>
                    </div>
                    {!syndicate.is_pool && (
                      <>
                        <div>
                          <Typography
                            variant="body2"
                            color="inherit"
                            component="span"
                          >
                            {`Loan Term: ${syndicate.start_dt} to ${syndicate.end_dt}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            color="inherit"
                            component="span"
                          >
                            {syndicate.business_stream} ||{' '}
                            {syndicate.syndicate_purpose}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            color="inherit"
                            component="span"
                          >
                            {`Establishment Fee: ${syndicate.est_fee}%  Risk Fee: ${syndicate.risk_fee}%`}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            color="inherit"
                            component="span"
                          >
                            {`Interest: ${syndicate.interest}% Default Surcharge: ${syndicate.default_surcharge}%  `}
                            {syndicate.syndicate_purpose == 'Construction' &&
                              `Line Fee: ${syndicate.line_fee}%`}
                          </Typography>
                        </div>
                        {syndicate.securities.map((security, index) => (
                          <div key={security.id}>
                            <Typography
                              variant="body2"
                              color="inherit"
                              component="span"
                            >
                              {security.full_address}
                            </Typography>
                          </div>
                        ))}
                      </>
                    )}
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.multiline}>
                  <SvgIcon fontSize="small" className={classes.badgeIcon}>
                    <BankIcon />
                  </SvgIcon>
                  <Box>
                    <div>
                      <Typography
                        variant="body2"
                        color="inherit"
                        component="span"
                      >
                        {`SPV Name: ${syndicate.spv_name || ''}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color="inherit"
                        component="span"
                      >
                        {`Trust Deed Date: ${syndicate.trust_deed_dt || ''}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color="inherit"
                        component="span"
                      >
                        {`ABN: ${syndicate.spv_abn || ''}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color="inherit"
                        component="span"
                      >
                        {`TFN: ${syndicate.spv_tfn || ''}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color="secondary"
                        component="span"
                      >
                        {`Account Name: ${syndicate.bank_acct_name || ''}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color="secondary"
                        component="span"
                      >
                        {`BSB: ${syndicate.bsb || ''}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color="secondary"
                        component="span"
                      >
                        {`Bank Account: ${syndicate.bank_acct_no || ''}`}
                      </Typography>
                    </div>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                {' '}
                <div className={classes.multiline}>
                  <SvgIcon fontSize="small" className={classes.badgeIcon}>
                    <DollarSignIcon />
                  </SvgIcon>
                  <Box>
                    <Typography
                      variant="body2"
                      color="inherit"
                      component="span"
                    >
                      {syndicate.accu_type &&
                        `Coupon calculated on a ${syndicate.accu_type.toLowerCase()} basis`}
                      {syndicate.payment_freq &&
                        ` and paid ${syndicate.payment_freq.toLowerCase()}`}
                    </Typography>
                    <div>
                      {syndicate.topup_accu_type_id && (
                        <Typography
                          variant="body2"
                          color="inherit"
                          component="span"
                        >
                          {`Top-up calculated on a ${syndicate.topup_accu_type.toLowerCase()} basis`}
                          {syndicate.topup_payment_freq &&
                            ` and paid ${syndicate.topup_payment_freq.toLowerCase()}`}
                        </Typography>
                      )}
                    </div>
                    {syndicate.syndicate_purpose_id == 2 && (
                      <>
                        <Typography
                          variant="caption"
                          color="secondary"
                          component="span"
                        >
                          {syndicate.offer_desc}
                        </Typography>
                        {syndicate.staged_coupon.map((coupon, index) => (
                          <div key={index}>
                            <Typography
                              variant="caption"
                              color="secondary"
                              component="span"
                            >
                              {`From ${format(
                                parseISO(coupon.start_dt),
                                'dd/MMM/yyyy'
                              )}: ${coupon.coupon}%`}
                              {coupon.premium &&
                                ` or ${
                                  coupon.premium
                                }% if more than ${currencyFormatter(
                                  coupon.premium_cutoff
                                )}`}
                            </Typography>
                          </div>
                        ))}
                      </>
                    )}
                    {syndicate.syndicate_purpose_id != 2 &&
                      syndicate.unit_classes.map((unit_class, index) => (
                        <div key={index}>
                          <Typography
                            variant="caption"
                            color="secondary"
                            component="span"
                          >
                            {`${unit_class.unit_class}: ${unit_class.coupon}%`}
                            {unit_class.premium &&
                              ` or ${
                                unit_class.premium
                              }% if more than ${currencyFormatter(
                                unit_class.premium_cutoff
                              )}`}
                            {unit_class.topup &&
                              unit_class.topup > 0 &&
                              ` + ${unit_class.topup}% top up`}
                          </Typography>
                        </div>
                      ))}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid item container spacing={1} lg={12} xs={12}>
              <Grid item lg={3} md={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Box flexGrow={1}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {currencyFormatter(stats.sought_amt)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Total Amount Sought
                      </Typography>
                    </Box>
                    <Avatar className={classes.avatar}>
                      <SyndicateIcon />
                    </Avatar>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2" color="textPrimary">
                      {currencyFormatter(stats.current_fum_amt)}
                    </Typography>
                    <Tooltip title="Liquidity is included">
                      <Typography variant="body2" color="textSecondary">
                        Current FUM
                      </Typography>
                    </Tooltip>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Box flexGrow={1}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {currencyFormatter(stats.open_amt)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Open Amount to Subscribe
                      </Typography>
                    </Box>
                    <Avatar className={classes.avatar}>
                      <EOIIcon />
                    </Avatar>
                  </CardContent>
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      color={
                        parseFloat(stats.liquidity_amt) > 0
                          ? 'error'
                          : 'textPrimary'
                      }
                    >
                      {currencyFormatter(stats.liquidity_amt)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Liquidity Due
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <Card>
                  <CardContent className={classes.cardContent}>
                    <Box flexGrow={1}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {currencyFormatter(stats.to_receive_amt)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Amount to Receive
                      </Typography>
                    </Box>
                    <Avatar className={classes.avatar}>
                      <AttachMoneyIcon />
                    </Avatar>
                  </CardContent>
                  <CardContent>
                    <Typography variant="subtitle2" color="textPrimary">
                      {currencyFormatter(stats.eoi_amt)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      EOI to Call
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {stats && stats.is_pool && (
                <>
                  <Grid item lg={3} md={3} xs={12}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.management_fee_last_90d)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Management Fee (last 3m)
                          </Typography>
                        </Box>
                        <Avatar className={classes.avatar}>
                          <EOIIcon />
                        </Avatar>
                      </CardContent>
                      <CardContent className={classes.whiteCardContent}>
                        <Box flexGrow={1}>
                          <Typography
                            variant="subtitle2"
                            color={
                              parseFloat(stats.idle_amt) > 0
                                ? 'error'
                                : 'textPrimary'
                            }
                          >
                            {currencyFormatter(stats.idle_amt)}
                          </Typography>
                          <Tooltip title="Junior tranche included">
                            <Typography variant="body2" color="textSecondary">
                              Funds Idle for Allocation
                            </Typography>
                          </Tooltip>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {stats.lvr}%
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Maximum LVR
                          </Typography>
                        </Box>
                        <Avatar className={classes.avatar}>
                          <EOIIcon />
                        </Avatar>
                      </CardContent>
                      <CardContent>
                        <Typography variant="subtitle2" color="textPrimary">
                          {stats.lvr_wgt}%
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Current LVR
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {stats && stats.syndicate_purpose_id == 2 && (
                <>
                  <Grid item lg={3} md={3} xs={12}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.total_pool_idle_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Idle Cash in Pool
                          </Typography>
                        </Box>
                        <Avatar className={classes.avatar}>
                          <EOIIcon />
                        </Avatar>
                      </CardContent>
                      <CardContent className={classes.whiteCardContent}>
                        <Box flexGrow={1}>
                          <Typography
                            variant="subtitle2"
                            color={
                              parseFloat(stats.idle_amt) > 0
                                ? 'error'
                                : 'textPrimary'
                            }
                          >
                            {currencyFormatter(stats.idle_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Idle Cash in SPV
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.total_drawdown_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Total Drawdown Called
                          </Typography>
                        </Box>
                        <Avatar className={classes.avatar}>
                          <EOIIcon />
                        </Avatar>
                      </CardContent>
                      <CardContent className={classes.whiteCardContent}>
                        <Box flexGrow={1}>
                          <Typography
                            variant="subtitle2"
                            color={
                              parseFloat(stats.upcoming_drawdown_amt) > 0
                                ? 'error'
                                : 'textPrimary'
                            }
                          >
                            {currencyFormatter(stats.upcoming_drawdown_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Upcoming Drawdown
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.construction_cost_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Total Construction Cost
                          </Typography>
                        </Box>
                        <Avatar className={classes.avatar}>
                          <EOIIcon />
                        </Avatar>
                      </CardContent>
                      <CardContent className={classes.whiteCardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.cost_to_complete_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Construction Cost to Complete
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.retain_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Total Contingency to Retain
                          </Typography>
                        </Box>
                        <Avatar className={classes.avatar}>
                          <EOIIcon />
                        </Avatar>
                      </CardContent>
                      <CardContent className={classes.whiteCardContent}>
                        <Box flexGrow={1}>
                          <Typography variant="subtitle2" color="textPrimary">
                            {currencyFormatter(stats.to_retain_amt)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Amount Yet to Retain
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {stats.is_pool ? (
                <Grid item xs={12} md={9}>
                  <Gap />
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <FundingSource />
                </Grid>
              )}
              {/* <Grid item xs={12} md={6}>
                {stats.is_pool ? <Gap /> : <FundingSource />}
              </Grid> */}
            </Grid>
            <Box mb={2} />
          </>
        )}
      </Grid>
    </>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

Header.defaultProps = {};

export default Header;
