/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Box, Typography, makeStyles } from '@material-ui/core';
import IndividualForm from './IndividualForm';
import SoleTraderForm from './SoleTraderForm';
import TrustForm from './TrustForm';
import CompanyForm from './CompanyForm';
import PartnershipForm from './PartnershipForm';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function EntityDetails({ className, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const { entity } = useSelector(state => state.entity);
  const { investor } = useSelector(state => state.investor);
  const { entityType } = useSelector(state => state.setting);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    setSelectedType(entity && entity.entity_type_id);
  }, [entity]);

  const handleChange = (event, value) => {
    setSelectedType(value);
  };

  return (
    <div>
      <Typography variant="h5" color="textPrimary">
        Choose Type of Investment Vehicle
      </Typography>
      <Box mt={2}>
        <ToggleButtonGroup
          disabled={entity !== null}
          fullWidth
          value={selectedType}
          size="large"
          exclusive
          onChange={handleChange}
        >
          {entityType.allIds.map(id => (
            <ToggleButton key={id} value={id}>
              {entityType.byId[id].name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      <Box mt={2}>
        {selectedType == 1 && (
          <IndividualForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 2 && (
          <SoleTraderForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 3 && (
          <TrustForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 4 && (
          <TrustForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 5 && (
          <CompanyForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 6 && (
          <PartnershipForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
      </Box>
    </div>
  );
}

EntityDetails.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default EntityDetails;
