import axios from 'axios';

export const GET_LABELS = '@campaign/get-labels';
export const GET_CAMPAIGNS = '@campaign/get-campaigns';
export const ADD_CAMPAIGN = '@campaign/create-campaign';
export const GET_CAMPAIGN = '@campaign/get-campaign';
export const GET_SYNDICATES = '@campaign/get-syndicates';
export const UPDATE_CAMPAIGN = '@campaign/get-campaign';
export const OPEN_SIDEBAR = '@campaign/open-sidebar';
export const CLOSE_SIDEBAR = '@campaign/close-sidebar';
export const OPEN_NEW_CAMPAIGN = '@campaign/open-new-campaign';
export const CLOSE_NEW_CAMPAIGN = '@campaign/close-new-campaign';
export const OPEN_CAMPAIGN = '@campaign/open-campaign';
export const CLOSE_CAMPAIGN = '@campaign/close-campaign';

export function addCampaign(values) {
  const request = axios.post('/api/campaigns', values);

  return dispatch => {
    request.then(response =>
      dispatch({ type: ADD_CAMPAIGN, payload: response.data })
    );
  };
}

export function getCampaigns(params) {
  const request = axios.get('/api/campaigns', {
    params
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_CAMPAIGNS,
        payload: response.data
      })
    );
  };
}

export function getSyndicates() {
  const request = axios.get('/api/view/syndicate');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_SYNDICATES,
        payload: response.data
      })
    );
  };
}

export function getCampaign(campaignId) {
  const request = axios.get(`/api/campaigns/${campaignId}`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_CAMPAIGN,
        payload: response.data
      })
    );
  };
}

export function updateCampaign(campaignId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/campaigns/${campaignId}`,
        values
      );
      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: response.data
      });
    } catch (error) {
      throw error;
    }
  };
}

// export function updateCampaign(campaignId, values) {
//   const request = axios.patch(`/api/campaigns/${campaignId}`, values);

//   return dispatch => {
//     request.then(response =>
//       dispatch({
//         type: UPDATE_CAMPAIGN,
//         payload: response.data
//       })
//     );
//   };
// }

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function openNewCampaign() {
  return {
    type: OPEN_NEW_CAMPAIGN
  };
}

export function closeNewCampaign() {
  return {
    type: CLOSE_NEW_CAMPAIGN
  };
}

export function openCampaign(campaign) {
  return {
    payload: campaign,
    type: OPEN_CAMPAIGN
  };
}

export function closeCampaign() {
  return {
    type: CLOSE_CAMPAIGN
  };
}
