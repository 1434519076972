export function validateACN(value) {
  var acn = value.replace(/[^\d]/, ''),
    digits = acn.split('');
  if (acn.length > 9) {
    return false;
  }
  //do the calcs
  var sum =
      digits[0] * 8 +
      digits[1] * 7 +
      digits[2] * 6 +
      digits[3] * 5 +
      digits[4] * 4 +
      digits[5] * 3 +
      digits[6] * 2 +
      digits[7] * 1,
    remainder = sum % 10;
  if (10 - remainder == digits[8]) {
    return true;
  } else {
    return false;
  }
}

export function validateABN(value) {
  var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
    abn = value.replace(/[^\d]/, ''),
    result = false;
  if (abn.length > 11) {
    return result;
  }
  // check length is 11 digits
  if (abn.length === 11) {
    // apply ato check method
    var sum = 0,
      weight;

    for (var index = 0; index <= weights.length - 1; index++) {
      weight = weights[index];
      var digit = abn[index] - (index ? 0 : 1);
      sum += weight * digit;
    }

    result = sum % 89 === 0;
  }
  return result;
}

export function validateTFN(value) {
  var tfn = value.replace(/\s+/g, '').replace(/[-]/g, ''),
    digits = tfn.split(''),
    isNumber = /^[0-9]+$/.test(tfn);
  if (tfn.length > 9) {
    return false;
  }
  if (
    [
      '333333333',
      '444444441',
      '444444442',
      '555555555',
      '666666666',
      '777777777'
    ].includes(tfn)
  ) {
    return true;
  }

  //do the calcs
  var sum =
      digits[0] * 1 +
      digits[1] * 4 +
      digits[2] * 3 +
      digits[3] * 7 +
      digits[4] * 5 +
      digits[5] * 8 +
      digits[6] * 6 +
      digits[7] * 9 +
      digits[8] * 10,
    remainder = sum % 11;
  if (remainder == 0) {
    return true;
  } else {
    return false;
  }
}

export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
