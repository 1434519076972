import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Switch
} from '@material-ui/core';
import axios from 'axios';
import { format } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { ADD_SECURITY } from 'src/actions/securityActions';
import { updateCard } from 'src/actions/kanbanActions';
import { CurrencyMask } from 'src/utils/mask';
import { states } from 'src/utils/options';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  }
}));

function SecurityForm({
  card,
  security,
  securities,
  setSecurity,
  open,
  onClose,
  className,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = security;

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open}>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box mt={3}>
          <Formik
            initialValues={initialValues}
            // validationSchema={Yup.object().shape({
            //   address: Yup.string().required('Required')
            // })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // Make API request
                // Do api call
                const response = await axios.post('/api/securities', values);
                await dispatch({ type: ADD_SECURITY, payload: response.data });
                await setSecurity({
                  security: securities.concat([response.data.id])
                });
                onClose();
                setStatus({ success: true });
                setSubmitting(true);
                enqueueSnackbar('Security added', {
                  variant: 'success'
                });
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: JSON.stringify(error.response.data) });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                className={clsx(classes.root, className)}
                onSubmit={handleSubmit}
                {...rest}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          required
                          fullWidth
                          label="Security Address"
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          required
                          fullWidth
                          label="City"
                          name="city"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.city || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          required
                          fullWidth
                          label="Postcode"
                          name="postcode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.postcode || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          required
                          label="State"
                          name="state"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: !!values.state
                          }}
                          select
                          SelectProps={{ native: true }}
                          value={values.state || ''}
                          variant="outlined"
                        >
                          <option></option>
                          {states.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Valuation"
                          name="valuation"
                          InputProps={{
                            inputComponent: CurrencyMask,
                            onChange: handleChange('valuation')
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.valuation || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enAU}
                        >
                          <DatePicker
                            label="Valuation Date"
                            name="valuation_dt"
                            value={values.valuation_dt}
                            onChange={newValue => {
                              setFieldValue(
                                'valuation_dt',
                                !newValue ||
                                  newValue.toString() == 'Invalid Date'
                                  ? newValue
                                  : format(newValue, 'yyyy-MM-dd')
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          label="Notes"
                          name="comment"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.comment || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add Security
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </Dialog>
  );
}

SecurityForm.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

SecurityForm.defaultProps = {
  onClose: () => {}
};

export default SecurityForm;
