import produce from 'immer';
import _ from 'lodash';
import {
  INIT_SYNDICATE,
  GET_SYNDICATE,
  UPDATE_SYNDICATE,
  GET_STATS,
  GET_LOAN_BALANCE,
  GET_INVESTMENTS,
  ADD_INVESTMENT,
  DELETE_INVESTMENT,
  UPDATE_INVESTMENTS,
  DELETE_CHILD_CALLS,
  UPDATE_TRANSACTION,
  GET_INTERESTBATCHES,
  ADD_INTEREST_BATCH,
  UPDATE_INTEREST_BATCH,
  DELETE_INTEREST_BATCH,
  GET_COMMISSIONS,
  ADD_COMMISSION,
  UPDATE_COMMISSION,
  DELETE_COMMISSION,
  GET_LOAN_TRANSACTIONS,
  ADD_LOAN_TRANSACTION,
  UPDATE_LOAN_TRANSACTION,
  DELETE_LOAN_TRANSACTION,
  DELETE_TRANSACTION,
  GET_EMAIL_LOGS,
  GET_MANAGEMENT_FEES,
  ADD_MANAGEMENT_FEE,
  UPDATE_MANAGEMENT_FEE,
  DELETE_MANAGEMENT_FEE
} from 'src/actions/syndicateActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  syndicate: null,
  files: [],
  stats: { stats: null, isLoading: true },
  loan_balance: { loan_balance: null, isLoading: true },
  investments: { byId: {}, allIds: [], isLoading: true },
  commissions: { commissions: [], isLoading: true },
  interestBatches: { interestBatches: [], isLoading: true },
  emailLogs: { byId: {}, allIds: [], isLoading: true },
  loanTransactions: { byId: {}, allIds: [], isLoading: true },
  managementfees: { managementfees: [], isLoading: true }
};

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

const syndicateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SYNDICATE: {
      return initialState;
    }

    case GET_SYNDICATE: {
      const syndicate = action.payload;
      const { files } = syndicate;

      return produce(state, draft => {
        draft.syndicate = syndicate;
        draft.files = files;
        draft.isLoading = false;
      });
    }

    case UPDATE_SYNDICATE: {
      const syndicate = action.payload;

      return produce(state, draft => {
        _.mergeWith(draft.syndicate, syndicate, customizer);
      });
    }

    case GET_STATS: {
      const stats = action.payload;
      return produce(state, draft => {
        draft.stats.stats = stats;
        draft.stats.isLoading = false;
      });
    }
    case GET_LOAN_BALANCE: {
      const loan_balance = action.payload;
      return produce(state, draft => {
        draft.loan_balance.loan_balance = loan_balance;
        draft.loan_balance.isLoading = false;
      });
    }

    case GET_INVESTMENTS: {
      const investments = action.payload;
      return produce(state, draft => {
        draft.investments.byId = objFromArray(investments);
        draft.investments.allIds = investments.map(investment => investment.id);
        draft.investments.isLoading = false;
      });
    }

    case ADD_INVESTMENT: {
      const investment = action.payload;

      return produce(state, draft => {
        draft.investments.byId[investment.id] = investment;
        draft.investments.allIds.unshift(investment.id);
      });
    }

    case DELETE_INVESTMENT: {
      const investmentId = action.payload;
      return produce(state, draft => {
        draft.investments.byId = _.omit(draft.investments.byId, investmentId);
        _.pull(draft.investments.allIds, investmentId);
      });
    }

    case DELETE_CHILD_CALLS: {
      const investmentIds = action.payload;
      return produce(state, draft => {
        draft.investments.byId = _.omit(draft.investments.byId, investmentIds);
        _.pullAll(draft.investments.allIds, investmentIds);
      });
    }

    case UPDATE_INVESTMENTS: {
      // make investments array to cater for single and multiple investments
      const investments = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      const ids = investments.map(investment => investment.id);
      return produce(state, draft => {
        _.mergeWith(
          ids.map(id => draft.investments.byId[id]),
          investments,
          customizer
        );
      });
    }

    case GET_EMAIL_LOGS: {
      const logs = action.payload;
      return produce(state, draft => {
        draft.emailLogs.byId = objFromArray(logs);
        draft.emailLogs.allIds = logs.map(log => log.id);
        draft.emailLogs.isLoading = false;
      });
    }

    case UPDATE_TRANSACTION: {
      const transaction = action.payload;
      return produce(state, draft => {
        _.merge(
          draft.investments.byId[transaction.investment_id].transactions.filter(
            tran => tran.id == transaction.id
          )[0],
          transaction
        );
      });
    }

    case DELETE_TRANSACTION: {
      const transaction = action.payload;
      return produce(state, draft => {
        draft.investments.byId[
          transaction.investment_id
        ].transactions = draft.investments.byId[
          transaction.investment_id
        ].transactions.filter(tran => tran.id !== transaction.id);
      });
    }

    case GET_INTERESTBATCHES: {
      const interestBatches = action.payload;
      return produce(state, draft => {
        draft.interestBatches.interestBatches = interestBatches;
        draft.interestBatches.isLoading = false;
      });
    }

    case ADD_INTEREST_BATCH: {
      const batch = action.payload;

      return produce(state, draft => {
        const prev_batch = draft.interestBatches.interestBatches.filter(
          _batch =>
            (_batch.syndicate_id == batch.syndicate_id) &
            (_batch.period_start_dt == batch.period_start_dt) &
            (_batch.interest_type_id == batch.interest_type_id)
        )[0];
        if (prev_batch) {
          draft.interestBatches.interestBatches = draft.interestBatches.interestBatches.map(
            _batch => {
              if (_batch.id === prev_batch.id) {
                return batch;
              }
              return _batch;
            }
          );
        } else {
          draft.interestBatches.interestBatches.push(batch);
        }
      });
    }

    case UPDATE_INTEREST_BATCH: {
      const batch = action.payload;

      return produce(state, draft => {
        draft.interestBatches.interestBatches = draft.interestBatches.interestBatches.map(
          _batch => {
            if (_batch.id === batch.id) {
              return batch;
            }
            return _batch;
          }
        );
      });
    }

    case DELETE_INTEREST_BATCH: {
      const batchId = action.payload;

      return produce(state, draft => {
        draft.interestBatches.interestBatches = draft.interestBatches.interestBatches.filter(
          batch => batch.id !== batchId
        );
      });
    }

    case GET_LOAN_TRANSACTIONS: {
      const loanTransactions = action.payload;
      return produce(state, draft => {
        draft.loanTransactions.byId = objFromArray(loanTransactions);
        draft.loanTransactions.allIds = loanTransactions.map(obj => obj.id);
        draft.loanTransactions.isLoading = false;
      });
    }

    case ADD_LOAN_TRANSACTION: {
      const loanTransaction = action.payload;

      return produce(state, draft => {
        draft.loanTransactions.byId[loanTransaction.id] = loanTransaction;
        draft.loanTransactions.allIds.unshift(loanTransaction.id);
      });
    }

    case UPDATE_LOAN_TRANSACTION: {
      const loanTransaction = action.payload;

      return produce(state, draft => {
        _.merge(
          draft.loanTransactions.byId[loanTransaction.id],
          loanTransaction
        );
      });
    }

    case DELETE_LOAN_TRANSACTION: {
      const loanTransactionId = action.payload;

      return produce(state, draft => {
        draft.loanTransactions.byId = _.omit(
          draft.loanTransactions.byId,
          loanTransactionId
        );
        _.pull(draft.loanTransactions.allIds, loanTransactionId);
      });
    }

    case GET_COMMISSIONS: {
      const commissions = action.payload;
      return produce(state, draft => {
        draft.commissions.commissions = commissions;
        draft.commissions.isLoading = false;
      });
    }

    case ADD_COMMISSION: {
      // make commissions a array to cater for single and multiple commission payments
      const commission = action.payload;
      return produce(state, draft => {
        if (Array.isArray(commission)) {
          draft.commissions.commissions = draft.commissions.commissions.concat(
            commission
          );
        } else {
          draft.commissions.commissions.push(commission);
        }
      });
    }

    case UPDATE_COMMISSION: {
      const commission = action.payload;

      return produce(state, draft => {
        draft.commissions.commissions = draft.commissions.commissions.map(
          _commission => {
            if (_commission.id === commission.id) {
              return commission;
            }
            return _commission;
          }
        );
      });
    }

    case DELETE_COMMISSION: {
      const commissionId = action.payload;

      return produce(state, draft => {
        draft.commissions.commissions = draft.commissions.commissions.filter(
          commission => commission.id !== commissionId
        );
      });
    }

    case GET_MANAGEMENT_FEES: {
      const managementfees = action.payload;
      return produce(state, draft => {
        draft.managementfees.managementfees = managementfees;
        draft.managementfees.isLoading = false;
      });
    }

    case ADD_MANAGEMENT_FEE: {
      const managementfee = action.payload;
      return produce(state, draft => {
        draft.managementfees.managementfees.push(managementfee);
      });
    }

    case UPDATE_MANAGEMENT_FEE: {
      const managementfee = action.payload;

      return produce(state, draft => {
        draft.managementfees.managementfees = draft.managementfees.managementfees.map(
          _managementfee => {
            if (_managementfee.id === managementfee.id) {
              return managementfee;
            }
            return _managementfee;
          }
        );
      });
    }

    case DELETE_MANAGEMENT_FEE: {
      const managementfeeId = action.payload;

      return produce(state, draft => {
        draft.managementfees.managementfees = draft.managementfees.managementfees.filter(
          managementfee => managementfee.id !== managementfeeId
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default syndicateReducer;
