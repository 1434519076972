/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormHelperText,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { UserX as DeleteIcon } from 'react-feather';
import { Autocomplete } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { properName } from 'src/utils/properName';
import { NumberMask } from 'src/utils/mask';
import { validateABN, validateTFN } from 'src/utils/validation';
import { updateApplication } from 'src/actions/applicationActions';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  fieldset: {
    border: 0
  },
  [theme.breakpoints.down('lg')]: {
    textFieldStyle: {
      color: 'red',
      fontSize: 19
    }
  },
  [theme.breakpoints.down('md')]: {
    textFieldStyle: {
      color: 'orange',
      fontSize: 17
    }
  },
  [theme.breakpoints.down('sm')]: {
    textFieldStyle: {
      color: 'blue',
      fontSize: 12
    }
  }
}));

function PartnershipForm({
  className,
  entity_type_id,
  onBack,
  onNext,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application } = useSelector(state => state.application);

  const initialValues = application.entity_id
    ? application.entity_id.entity_details
    : entitySchema;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await dispatch(
            updateApplication(application.id, {
              ...application,
              entity_id: {
                ...application.entity_id,
                investor_id: application.investor_id.id,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident: values.partnership.tax_country == 'Australia',
                is_us_tax_resident: values.partnership.is_us_tax_resident,
                account_name:
                  properName(values.partnership.business_name) ||
                  properName(
                    values.partnership.individuals
                      .map(ind => ind.first_name.concat(' ', ind.last_name))
                      .join(' & ')
                  ),
                address_1: properName(values.partnership.business_address_1),
                address_2: properName(values.partnership.business_address_2),
                city: properName(values.partnership.business_city),
                state: values.partnership.business_state,
                postcode: values.partnership.business_postcode,
                country: values.partnership.business_country
              }
            })
          );
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <fieldset className={classes.fieldset}>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  label="Full Business Name (if any)"
                  name="partnership.business_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.partnership.business_name || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={application.entity_id !== null}
                  required
                  helperText={
                    values.partnership.abn > 0 &&
                    !validateABN(values.partnership.abn) &&
                    'Invalid ABN'
                  }
                  error={
                    values.partnership.abn > 0 &&
                    !validateABN(values.partnership.abn)
                  }
                  label="ABN"
                  name="partnership.abn"
                  InputProps={{
                    inputComponent: NumberMask,
                    onChange: handleChange('partnership.abn'),
                    maxLength: 11
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.partnership.abn || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Registration Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  required
                  inputProps={{ maxLength: 38 }}
                  label="Registered Office Line 1 (cannot be a PO Box Address)"
                  name="partnership.rego_address_1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.partnership.rego_address_1 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 38 }}
                  label="Registered Office Line 2"
                  name="partnership.rego_address_2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.partnership.rego_address_2 || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="City"
                  name="partnership.rego_city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.partnership.rego_city || ''}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Autocomplete
                  freeSolo
                  size="small"
                  name="partnership.rego_state"
                  value={values.partnership.rego_state || ''}
                  options={states}
                  onInputChange={(event, newValue) => {
                    setFieldValue(
                      'partnership.rego_state',
                      newValue ? newValue : ''
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="State/Province"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Postcode"
                  name="partnership.rego_postcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.partnership.rego_postcode || ''}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Country"
                  name="partnership.rego_country"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.partnership.rego_country || ''}
                  size="small"
                  variant="outlined"
                >
                  <option></option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box mt={1}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    Business Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        values.partnership.business_addr_same_as_rego_addr ||
                        false
                      }
                      size="small"
                      onChange={handleChange}
                      name="partnership.business_addr_same_as_rego_addr"
                    />
                  }
                  label="Same as Registration Address"
                />
              </Grid>
              {!values.partnership.business_addr_same_as_rego_addr && (
                <>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 38 }}
                      label="Principal Place of Business Line 1 (cannot be a PO Box address)"
                      name="partnership.business_address_1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.partnership.business_address_1 || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 38 }}
                      label="Principal Place of Business Line 2"
                      name="partnership.business_address_2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.partnership.business_address_2 || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label="City"
                      name="partnership.business_city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.partnership.business_city || ''}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Autocomplete
                      freeSolo
                      size="small"
                      name="partnership.business_state"
                      value={values.partnership.business_state || ''}
                      options={states}
                      onInputChange={(event, newValue) => {
                        setFieldValue(
                          'partnership.business_state',
                          newValue ? newValue : ''
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          label="State/Province"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      label="Postcode"
                      name="partnership.business_postcode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.partnership.business_postcode || ''}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="partnership.business_country"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.partnership.business_country || ''}
                      size="small"
                      variant="outlined"
                    >
                      <option></option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
            <FieldArray
              name="partnership.individuals"
              subscription={{}} // This is required so that the whole array does not re-render on any change.
              render={({ insert, remove, push }) => (
                <Box mt={2}>
                  {values.partnership.individuals.map((individual, index) => (
                    <Box mt={2} key={index}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                          color="secondary"
                          gutterBottom
                        >
                          APPLICANT {index + 1}
                        </Typography>
                        {index > 0 && application.entity_id == null && (
                          <Tooltip title="Delete applicant">
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <SvgIcon>
                                <DeleteIcon />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                      <Box mt={1}>
                        <Grid container spacing={1}>
                          <Grid item md={3} xs={12}>
                            <TextField
                              fullWidth
                              required
                              select
                              label="Title"
                              disabled={application.entity_id !== null}
                              name={`partnership.individuals.${index}.title`}
                              onChange={handleChange}
                              SelectProps={{
                                native: true
                              }}
                              value={
                                values.partnership.individuals[index].title ||
                                ''
                              }
                              variant="outlined"
                              size="small"
                            >
                              <option></option>
                              {titles.map(option => (
                                <option key={option.code} value={option.code}>
                                  {option.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              required
                              fullWidth
                              disabled={application.entity_id !== null}
                              label="First Name"
                              name={`partnership.individuals.${index}.first_name`}
                              onChange={handleChange}
                              value={
                                values.partnership.individuals[index]
                                  .first_name || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <TextField
                              required
                              fullWidth
                              disabled={application.entity_id !== null}
                              label="Last Name"
                              name={`partnership.individuals.${index}.last_name`}
                              onChange={handleChange}
                              value={
                                values.partnership.individuals[index]
                                  .last_name || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enAU}
                            >
                              <DatePicker
                                label="Date of Birth"
                                openTo="year"
                                views={['year', 'month', 'day']}
                                name={`partnership.individuals.${index}.birth_dt`}
                                value={
                                  values.partnership.individuals[index]
                                    .birth_dt || null
                                }
                                onChange={newValue => {
                                  setFieldValue(
                                    `partnership.individuals.${index}.birth_dt`,
                                    !newValue ||
                                      newValue.toString() == 'Invalid Date'
                                      ? newValue
                                      : format(newValue, 'yyyy-MM-dd')
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    disabled={application.entity_id !== null}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              required
                              fullWidth
                              disabled={application.entity_id !== null}
                              label="Country of Residency for Tax Purposes"
                              name={`partnership.individuals.${index}.tax_country`}
                              onChange={handleChange}
                              select
                              SelectProps={{ native: true }}
                              value={
                                values.partnership.individuals[index]
                                  .tax_country || ''
                              }
                              size="small"
                              variant="outlined"
                            >
                              <option></option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required={
                                values.partnership.individuals[index]
                                  .tax_country == 'Australia'
                              }
                              disabled={application.entity_id !== null}
                              maxLength={9}
                              helperText={
                                values.partnership.individuals[index]
                                  .tax_country == 'Australia' &&
                                values.partnership.individuals[index].tfn
                                  .length > 0 &&
                                !validateTFN(
                                  values.partnership.individuals[index].tfn
                                ) &&
                                'Invalid TFN'
                              }
                              error={
                                values.partnership.individuals[index]
                                  .tax_country == 'Australia' &&
                                values.partnership.individuals[index].tfn
                                  .length > 0 &&
                                !validateTFN(
                                  values.partnership.individuals[index].tfn
                                )
                              }
                              label="TFN or Exemption number"
                              name={`partnership.individuals.${index}.tfn`}
                              InputProps={{
                                maxLength: 9,
                                inputComponent: NumberMask,
                                onChange: handleChange(
                                  `partnership.individuals.${index}.tfn`
                                )
                              }}
                              onChange={handleChange}
                              value={
                                values.partnership.individuals[index].tfn || ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={application.entity_id !== null}
                                  checked={
                                    values.partnership.individuals[index]
                                      .is_pep || false
                                  }
                                  onChange={handleChange}
                                  name={`partnership.individuals.${index}.is_pep`}
                                  size="small"
                                />
                              }
                              label="Politically Exposed Person"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={application.entity_id !== null}
                                  checked={
                                    values.partnership.individuals[index]
                                      .is_us_tax_resident || false
                                  }
                                  onChange={handleChange}
                                  name={`partnership.individuals.${index}.is_us_tax_resident`}
                                  size="small"
                                />
                              }
                              label="US Citizen / US Tax Resident"
                            />
                          </Grid>
                          {values.partnership.individuals[index]
                            .is_us_tax_resident && (
                            <Grid item md={4} xs={12}>
                              <TextField
                                fullWidth
                                required
                                disabled={application.entity_id !== null}
                                label="TIN"
                                name={`partnership.individuals.${index}.tin`}
                                onChange={handleChange}
                                value={
                                  values.partnership.individuals[index].tin ||
                                  ''
                                }
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          )}
                          <Grid item md={12} xs={12}>
                            <TextField
                              required
                              fullWidth
                              label="Residential Address (cannot be a PO Box address)"
                              name={`partnership.individuals.${index}.address`}
                              onChange={handleChange}
                              value={
                                values.partnership.individuals[index].address ||
                                ''
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  ))}
                  {application && application.entity_id == null && (
                    <Box mt={1}>
                      <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                          push({});
                        }}
                      >
                        Add Applicant
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            />
          </fieldset>
          <Box mt={6} display="flex">
            {onBack && (
              <Button variant="contained" onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

PartnershipForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default PartnershipForm;
