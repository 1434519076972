import axios from 'axios';
import _ from 'lodash';

export const INIT = '@dashboard/init';
export const GET_TASKS = '@dashboard/get-tasks';
export const GET_UNPAID_INTEREST = '@dashboard/get-unpaid-interests';
export const GET_UNPAID_COMMISSIONS = '@dashboard/get-unpaid-commissions';
export const UPDATE_COMMISSION = '@dashboard/update-commission';
export const GET_LOAN_INTEREST = '@dashboard/get-loan-interests';
export const UPDATE_TASK = '@dashboard/update-task';
export const UPDATE_INTEREST_BATCH = '@dashboard/update-interest-batch';
export const GET_EVENTS = '@dashboard/get-events';
export const UPDATE_EVENT = '@dashboard/update-event';
export const ERROR = '@dashboard/error';

export function getTasks() {
  return async dispatch => {
    try {
      const response = await axios.get('/api/investments', {
        params: { getTask: true }
      });
      dispatch({ type: GET_TASKS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function getEvents() {
  return async dispatch => {
    try {
      const eventColor = {
        Repayment: '#ff0000',
        Settlement: '#06a149'
      };
      const response = await axios.get('/api/events');
      const eventData = response.data.map(event => {
        return {
          title: event.description,
          start: event.event_dt,
          end: event.event_dt,
          groupId: event.syndicate_id,
          description: event.description,
          backgroundColor: eventColor[event.event_type],
          borderColor: eventColor[event.event_type]
        };
      });
      dispatch({ type: GET_EVENTS, payload: eventData });
    } catch (error) {
      throw error;
    }
  };
}

export function updateEvent(values) {
  return async dispatch => {
    try {
      const response = await axios.post(
        '/api/syndicates/${values.syndicate_id}',
        values
      );
      dispatch({ type: UPDATE_EVENT, payload: values });
    } catch (error) {
      throw error;
    }
  };
}

export function getUnpaidInterests() {
  return async dispatch => {
    // dispatch({ type: INIT });
    try {
      const response = await axios.get('/api/interestbatches', {
        params: { action_required: 1 }
      });
      dispatch({ type: GET_UNPAID_INTEREST, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function getUnpaidCommissions() {
  return async dispatch => {
    // dispatch({ type: INIT });
    try {
      const response = await axios.get('/api/view/unpaidcommissions', {});
      dispatch({ type: GET_UNPAID_COMMISSIONS, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function updateCommission(values) {
  return async dispatch => {
    // dispatch({ type: INIT });
    try {
      const response = await axios.post('/api/commissioncalc', values);
      dispatch({ type: UPDATE_COMMISSION, payload: values.fundraiserId });
    } catch (error) {
      throw error;
    }
  };
}

export function getUnpaidLoanInterest() {
  return async dispatch => {
    // dispatch({ type: INIT });
    try {
      const response = await axios.get('/api/view/loanbalance', {
        params: { unpaid: 1 }
      });
      dispatch({ type: GET_LOAN_INTEREST, payload: response.data });
    } catch (error) {
      throw error;
    }
  };
}

export function addTransactions(values) {
  return async dispatch => {
    try {
      const response = await axios.post('/api/transactions', values);
      const investmentIds = Array.isArray(response.data)
        ? response.data.map(tran => tran.investment_id)
        : [response.data.investment_id];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function updateInterestBatch(batchId, values) {
  return async dispatch => {
    try {
      const response = await axios.patch(
        `/api/interestbatches/${batchId}`,
        values
      );
      if (values.is_paid) {
        // add payment transactions if change status to paid
        const updates = await axios.post('/api/paymentupdates', { batchId: batchId, is_paid: values.is_paid });
        dispatch(addTransactions(updates.data));
      } else {
        // delete payment transactions if change status to unpaid
        const updates = await axios.post('/api/paymentupdates', { batchId: batchId, is_paid: values.is_paid });
      }
      dispatch({ type: UPDATE_INTEREST_BATCH, payload: response.data });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}
