import produce from 'immer';
import _ from 'lodash';
import {
  INIT,
  GET_APPLICATION,
  UPDATE_APPLICATION,
  SET_ADDRESS
} from 'src/actions/applicationActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  application: null,
  address: null
  // investor: {},
  // entity: {}
};

function customizer(objValue, srcValue) {
  if (_.isArray(objValue) || _.isObject(objValue)) {
    return srcValue;
  }
}

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT: {
      return initialState;
    }
    case GET_APPLICATION: {
      const application = action.payload;
      // const { investor_details, entity_details } = application;

      return produce(state, draft => {
        draft.application = application;
        // draft.investor = investor_details;
        // draft.entity = entity_details;
        draft.isLoading = false;
      });
    }

    case UPDATE_APPLICATION: {
      const application = action.payload;

      return produce(state, draft => {
        _.mergeWith(draft.application, application, customizer);
      });
    }

    case SET_ADDRESS: {
      const address = action.payload;
      return produce(state, draft => {
        draft.address = address;
      });
    }

    default: {
      return state;
    }
  }
};

export default applicationReducer;
