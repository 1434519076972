import React, { useRef } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';

const useStyles = makeStyles(theme => ({
  root: {},
  Card: {
    fontWeight: '500',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: '4vw',
    margin: '2vw',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.1)'
  },
  SigContainer: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderBottom: '1px gray solid',
    borderRadius: '10px',
    width: '100%',
    height: '20vh'
  },
  SigPad: { width: '100%', height: '100%' }
}));

function SigCard({
  className,
  setSignature,
  field_name,
  signature,
  draft_signature,
  draft_field_name,
  ...rest
}) {
  const classes = useStyles();
  const padRef = useRef({});

  return (
    <>
      <p>Sign on the line below.</p>
      {signature ? (
        <div className={classes.SigContainer}>
          <img
            src={signature}
            alt="my signature"
            style={{
              width: '100%',
              height: '100%'
            }}
          />
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              setSignature(field_name, null);
              setSignature(draft_field_name, null);
            }}
          >
            Clear
          </Button>
        </div>
      ) : (
        <div className={classes.SigContainer}>
          <SignaturePad
            ref={padRef}
            penColor="black"
            canvasProps={{ className: `${classes.SigPad}` }}
            onEnd={() => {
              setSignature(
                draft_field_name,
                padRef.current.getTrimmedCanvas().toDataURL('image/png')
              );
            }}
          />
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              padRef.current.clear();
              setSignature(draft_field_name, null);
              setSignature(field_name, null);
            }}
          >
            Clear
          </Button>
        </div>
      )}
    </>
  );
}

export default SigCard;
