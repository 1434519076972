// export const states = [
//   { code: 'ACT', name: 'Australian Capital Territory' },
//   { code: 'NSW', name: 'New South Wales' },
//   { code: 'NT', name: 'Northern Territory' },
//   { code: 'QLD', name: 'Queensland' },
//   { code: 'SA', name: 'South Australia' },
//   { code: 'TAS', name: 'Tasmania' },
//   { code: 'VIC', name: 'Victoria' },
//   { code: 'WA', name: 'Western Australia' },
//   { code: 'OTH', name: 'Overseas address' }
// ];

export const entitySchema = {
  individuals: [
    {
      title: '',
      first_name: '',
      last_name: '',
      birth_dt: '',
      tax_country: '',
      tfn: '',
      is_pep: false,
      is_us_tax_resident: false,
      tin: '',
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '',
      country: ''
    }
  ],
  soleTrader: {
    business_name: '',
    abn: '',
    rego_address_1: '',
    rego_address_2: '',
    rego_city: '',
    rego_state: '',
    rego_postcode: '',
    rego_country: '',
    business_addr_same_as_rego_addr: true,
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state: '',
    business_postcode: '',
    business_country: '',
    title: '',
    first_name: '',
    last_name: '',
    birth_dt: '',
    tax_country: '',
    tfn: '',
    is_pep: false,
    is_us_tax_resident: false,
    tin: '',
    address: ''
  },
  partnership: {
    business_name: '',
    abn: '',
    rego_address_1: '',
    rego_address_2: '',
    rego_city: '',
    rego_state: '',
    rego_postcode: '',
    rego_country: '',
    business_addr_same_as_rego_addr: true,
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state: '',
    business_postcode: '',
    business_country: '',
    individuals: [
      {
        title: '',
        first_name: '',
        last_name: '',
        birth_dt: '',
        tax_country: '',
        tfn: '',
        is_pep: false,
        is_us_tax_resident: false,
        tin: '',
        address: ''
      }
    ]
  },
  company: {
    business_name: '',
    tax_country: '',
    acn: '',
    abn: '',
    tfn: '',
    rego_address_1: '',
    rego_address_2: '',
    rego_city: '',
    rego_state: '',
    rego_postcode: '',
    rego_country: '',
    business_addr_same_as_rego_addr: true,
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state: '',
    business_postcode: '',
    business_country: '',
    beneficial_owners: [''],
    directors: [''],
    signatories: [
      {
        title: '',
        first_name: '',
        last_name: '',
        birth_dt: '',
        tax_country: '',
        is_pep: false,
        is_us_tax_resident: false,
        tin: '',
        email: '',
        mobile: '',
        address: ''
      }
    ]
  },
  trust: {
    trust_name: '',
    trust_type: '',
    fund_number: '',
    arsn: '',
    trustee_type: '',
    abn: '',
    tfn: '',
    tax_country: '',
    trust_deed_dt: '',
    address_1: '',
    address_2: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
    beneficiaries: [''],
    trustee_individuals: [
      {
        title: '',
        first_name: '',
        last_name: '',
        birth_dt: '',
        tax_country: '',
        is_pep: false,
        is_us_tax_resident: false,
        tin: '',
        email: '',
        mobile: '',
        address: ''
      }
    ],
    trustee_company: {
      is_regulated: false,
      business_name: '',
      tax_country: '',
      acn: '',
      abn: '',
      rego_address: '',
      business_addr_same_as_rego_addr: true,
      business_address: '',
      beneficial_owners: [''],
      directors: [''],
      signatories: [
        {
          title: '',
          first_name: '',
          last_name: '',
          birth_dt: '',
          tax_country: '',
          is_pep: false,
          is_us_tax_resident: false,
          tin: '',
          email: '',
          mobile: '',
          address: ''
        }
      ]
    }
  }
};

export const genders = [
  { code: 'F', name: 'Female' },
  { code: 'M', name: 'Male' },
  { code: 'X', name: 'Indetermined' },
  { code: 'U', name: 'Unknown' }
];

export const titles = [
  { code: 'Mr', name: 'Mr' },
  { code: 'Mrs', name: 'Mrs' },
  { code: 'Miss', name: 'Miss' },
  { code: 'Ms', name: 'Ms' }
];

export const trustee_types = [
  { code: 'Individual', name: 'Individual' },
  { code: 'Corporate', name: 'Corporate' }
];

export const trust_types = [
  { code: 'Wholesale Trust', name: 'Wholesale Trust' },
  {
    code: 'Regulated trust',
    name:
      'Regulated Trust/Superannuation Fund (eg. ATO regulated SMSF or APRA regulated Trust)'
  },
  {
    code: 'Government Superannuation Fund',
    name: 'Government Superannuation Fund'
  },
  {
    code: 'Registered managed investment scheme',
    name: 'Registered managed investment scheme'
  },
  {
    code: 'Other trust',
    name: 'Any other trust (eg. family trust)'
  }
];

export const language = ['English', 'Chinese', 'All'];

export const states = [
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
  'OTH'
];
export const countries = [
  { name: 'Afghanistan', code: 'AFG' },
  { name: 'Aland Islands', code: 'ALA' },
  { name: 'Albania', code: 'ALB' },
  { name: 'Algeria', code: 'DZA' },
  { name: 'American Samoa', code: 'ASM' },
  { name: 'Andorra', code: 'AND' },
  { name: 'Angola', code: 'AGO' },
  { name: 'Anguilla', code: 'AIA' },
  { name: 'Antarctica', code: 'ATA' },
  { name: 'Antigua and Barbuda', code: 'ATG' },
  { name: 'Argentina', code: 'ARG' },
  { name: 'Armenia', code: 'ARM' },
  { name: 'Aruba', code: 'ABW' },
  { name: 'Australia', code: 'AUS' },
  { name: 'Austria', code: 'AUT' },
  { name: 'Azerbaijan', code: 'AZE' },
  { name: 'Bahamas', code: 'BHS' },
  { name: 'Bahrain', code: 'BHR' },
  { name: 'Bangladesh', code: 'BGD' },
  { name: 'Barbados', code: 'BRB' },
  { name: 'Belarus', code: 'BLR' },
  { name: 'Belgium', code: 'BEL' },
  { name: 'Belize', code: 'BLZ' },
  { name: 'Benin', code: 'BEN' },
  { name: 'Bermuda', code: 'BMU' },
  { name: 'Bhutan', code: 'BTN' },
  { name: 'Bolivia', code: 'BOL' },
  { name: 'Bonaire Saint Eustatius, and Saba Islands', code: 'BES' },
  { name: 'Bosnia and Herzegovina', code: 'BIH' },
  { name: 'Botswana', code: 'BWA' },
  { name: 'Bouvet Island', code: 'BVT' },
  { name: 'Brazil', code: 'BRA' },
  { name: 'British Indian Ocean Territory', code: 'IOT' },
  { name: 'British Virgin Islands', code: 'VGB' },
  { name: 'Brunei Darussalam', code: 'BRN' },
  { name: 'Bulgaria', code: 'BGR' },
  { name: 'Burkina Faso', code: 'BFA' },
  { name: 'Burundi', code: 'BDI' },
  { name: 'Cambodia', code: 'KHM' },
  { name: 'Cameroon', code: 'CMR' },
  { name: 'Canada', code: 'CAN' },
  { name: 'Cape Verde', code: 'CPV' },
  { name: 'Cayman Islands', code: 'CYM' },
  { name: 'Central African Republic', code: 'CAF' },
  { name: 'Chad', code: 'TCD' },
  { name: 'Chile', code: 'CHL' },
  { name: 'China', code: 'CHN' },
  { name: 'Christmas Island', code: 'CXR' },
  { name: 'Cocos (Keeling) Islands', code: 'CCK' },
  { name: 'Colombia', code: 'COL' },
  { name: 'Comoros', code: 'COM' },
  { name: 'Congo, Democratic Republic of (was Zaire)', code: 'COD' },
  { name: "Congo, People's Republic of", code: 'COG' },
  { name: 'Cook Islands', code: 'COK' },
  { name: 'Costa Rica', code: 'CRI' },
  { name: "Cote D'Ivoire (Ivory Coast)", code: 'CIV' },
  { name: 'Croatia (Hrvatska)', code: 'HRV' },
  { name: 'Cuba', code: 'CUB' },
  { name: 'Curacao', code: 'CUW' },
  { name: 'Cyprus', code: 'CYP' },
  { name: 'Czech Republic', code: 'CZE' },
  { name: 'Denmark', code: 'DNK' },
  { name: 'Djibouti', code: 'DJI' },
  { name: 'Dominica', code: 'DMA' },
  { name: 'Dominican Republic', code: 'DOM' },
  { name: 'East Timor (Timor Leste)', code: 'TLS' },
  { name: 'Ecuador', code: 'ECU' },
  { name: 'Egypt', code: 'EGY' },
  { name: 'El Salvador', code: 'SLV' },
  { name: 'Equatorial Guinea', code: 'GNQ' },
  { name: 'Eritrea', code: 'ERI' },
  { name: 'Estonia', code: 'EST' },
  { name: 'Ethiopia', code: 'ETH' },
  { name: 'Falkland Islands (Malvinas)', code: 'FLK' },
  { name: 'Faroe Islands', code: 'FRO' },
  { name: 'Fiji', code: 'FJI' },
  { name: 'Finland', code: 'FIN' },
  { name: 'France', code: 'FRA' },
  { name: 'French Guiana', code: 'GUF' },
  { name: 'French Polynesia', code: 'PYF' },
  { name: 'French Southern Territories', code: 'ATF' },
  { name: 'Gabon', code: 'GAB' },
  { name: 'Gambia', code: 'GMB' },
  { name: 'Georgia', code: 'GEO' },
  { name: 'Germany', code: 'DEU' },
  { name: 'Ghana', code: 'GHA' },
  { name: 'Gibraltar', code: 'GIB' },
  { name: 'Greece', code: 'GRC' },
  { name: 'Greenland', code: 'GRL' },
  { name: 'Grenada', code: 'GRD' },
  { name: 'Guadeloupe', code: 'GLP' },
  { name: 'Guam', code: 'GUM' },
  { name: 'Guatemala', code: 'GTM' },
  { name: 'Guernsey', code: 'GGY' },
  { name: 'Guinea', code: 'GIN' },
  { name: 'Guinea-Bissau', code: 'GNB' },
  { name: 'Guyana', code: 'GUY' },
  { name: 'Haiti', code: 'HTI' },
  { name: 'Heard and McDonald Islands', code: 'HMD' },
  { name: 'Holy See (Vatican City State)', code: 'VAT' },
  { name: 'Honduras', code: 'HND' },
  { name: 'Hong Kong', code: 'HKG' },
  { name: 'Hrvatska (Croatia)', code: 'HRV' },
  { name: 'Hungary', code: 'HUN' },
  { name: 'Iceland', code: 'ISL' },
  { name: 'India', code: 'IND' },
  { name: 'Indonesia', code: 'IDN' },
  { name: 'Iran', code: 'IRN' },
  { name: 'Iraq', code: 'IRQ' },
  { name: 'Ireland', code: 'IRL' },
  { name: 'Isle of Man, The', code: 'IMN' },
  { name: 'Israel', code: 'ISR' },
  { name: 'Italy', code: 'ITA' },
  { name: "Ivory Coast (Cote D'Ivoire)", code: 'CIV' },
  { name: 'Jamaica', code: 'JAM' },
  { name: 'Japan', code: 'JPN' },
  { name: 'Jersey', code: 'JEY' },
  { name: 'Jordan', code: 'JOR' },
  { name: 'Kazakhstan', code: 'KAZ' },
  { name: 'Kenya', code: 'KEN' },
  { name: 'Kiribati', code: 'KIR' },
  { name: "Korea, Democratic People's Republic of (North Korea)", code: 'PRK' },
  { name: 'Korea, Republic of (South Korea)', code: 'KOR' },
  { name: 'Kuwait', code: 'KWT' },
  { name: 'Kyrgyzstan', code: 'KGZ' },
  { name: 'Laos', code: 'LAO' },
  { name: 'Latvia', code: 'LVA' },
  { name: 'Lebanon', code: 'LBN' },
  { name: 'Lesotho', code: 'LSO' },
  { name: 'Liberia', code: 'LBR' },
  { name: 'Libya', code: 'LBY' },
  { name: 'Liechtenstein', code: 'LIE' },
  { name: 'Lithuania', code: 'LTU' },
  { name: 'Luxembourg', code: 'LUX' },
  { name: 'Macau', code: 'MAC' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MKD' },
  { name: 'Madagascar', code: 'MDG' },
  { name: 'Malawi', code: 'MWI' },
  { name: 'Malaysia', code: 'MYS' },
  { name: 'Maldives', code: 'MDV' },
  { name: 'Mali', code: 'MLI' },
  { name: 'Malta', code: 'MLT' },
  { name: 'Marshall Islands', code: 'MHL' },
  { name: 'Martinique', code: 'MTQ' },
  { name: 'Mauritania', code: 'MRT' },
  { name: 'Mauritius', code: 'MUS' },
  { name: 'Mayotte', code: 'MYT' },
  { name: 'Mexico', code: 'MEX' },
  { name: 'Micronesia, Federated States of', code: 'FSM' },
  { name: 'Moldova', code: 'MDA' },
  { name: 'Monaco', code: 'MCO' },
  { name: 'Mongolia', code: 'MNG' },
  { name: 'Montenegro', code: 'MNE' },
  { name: 'Montserrat', code: 'MSR' },
  { name: 'Morocco', code: 'MAR' },
  { name: 'Mozambique', code: 'MOZ' },
  { name: 'Myanmar (was Burma)', code: 'MMR' },
  { name: 'Namibia', code: 'NAM' },
  { name: 'Nauru', code: 'NRU' },
  { name: 'Nepal', code: 'NPL' },
  { name: 'Netherlands', code: 'NLD' },
  { name: 'New Caledonia', code: 'NCL' },
  { name: 'New Zealand', code: 'NZL' },
  { name: 'Nicaragua', code: 'NIC' },
  { name: 'Niger', code: 'NER' },
  { name: 'Nigeria', code: 'NGA' },
  { name: 'Niue', code: 'NIU' },
  { name: 'Norfolk Island', code: 'NFK' },
  { name: 'Northern Mariana Islands', code: 'MNP' },
  { name: 'North Korea', code: 'PRK' },
  { name: 'Norway', code: 'NOR' },
  { name: 'Oman', code: 'OMN' },
  { name: 'Pakistan', code: 'PAK' },
  { name: 'Palau', code: 'PLW' },
  { name: 'Palestinian Territory, Occupied', code: 'PSE' },
  { name: 'Panama', code: 'PAN' },
  { name: 'Papua New Guinea', code: 'PNG' },
  { name: 'Paraguay', code: 'PRY' },
  { name: 'Peru', code: 'PER' },
  { name: 'Philippines', code: 'PHL' },
  { name: 'Pitcairn Island', code: 'PCN' },
  { name: 'Poland', code: 'POL' },
  { name: 'Portugal', code: 'PRT' },
  { name: 'Puerto Rico', code: 'PRI' },
  { name: 'Qatar', code: 'QAT' },
  { name: 'R union', code: 'REU' },
  { name: 'Romania', code: 'ROU' },
  { name: 'Russian Federation', code: 'RUS' },
  { name: 'Rwanda', code: 'RWA' },
  { name: 'Saint Barth lemy', code: 'BLM' },
  { name: 'Saint Helena', code: 'SHN' },
  { name: 'Saint Kitts and Nevis', code: 'KNA' },
  { name: 'Saint Lucia', code: 'LCA' },
  { name: 'Saint Martin (Dutch part)', code: 'SXM' },
  { name: 'Saint Martin (French part)', code: 'MAF' },
  { name: 'Saint Pierre and Miquelon', code: 'SPM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VCT' },
  { name: 'Samoa', code: 'WSM' },
  { name: 'San Marino', code: 'SMR' },
  { name: 'Sao Tome and Principe', code: 'STP' },
  { name: 'Saudi Arabia', code: 'SAU' },
  { name: 'Senegal', code: 'SEN' },
  { name: 'Serbia', code: 'SRB' },
  { name: 'Seychelles', code: 'SYC' },
  { name: 'Sierra Leone', code: 'SLE' },
  { name: 'Singapore', code: 'SGP' },
  { name: 'Slovakia (Slovak Republic)', code: 'SVK' },
  { name: 'Slovenia', code: 'SVN' },
  { name: 'Solomon Islands', code: 'SLB' },
  { name: 'Somalia', code: 'SOM' },
  { name: 'South Africa', code: 'ZAF' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'SGS' },
  { name: 'South Korea', code: 'KOR' },
  { name: 'South Sudan', code: 'SSD' },
  { name: 'Spain', code: 'ESP' },
  { name: 'Sri Lanka', code: 'LKA' },
  { name: 'Sudan', code: 'SDN' },
  { name: 'Suriname', code: 'SUR' },
  { name: 'Svalbard and Jan Mayen Islands', code: 'SJM' },
  { name: 'Swaziland', code: 'SWZ' },
  { name: 'Sweden', code: 'SWE' },
  { name: 'Switzerland', code: 'CHE' },
  { name: 'Syria', code: 'SYR' },
  { name: 'Taiwan', code: 'TWN' },
  { name: 'Tajikistan', code: 'TJK' },
  { name: 'Tanzania', code: 'TZA' },
  { name: 'Thailand', code: 'THA' },
  { name: 'Timor-Leste (East Timor)', code: 'TLS' },
  { name: 'Togo', code: 'TGO' },
  { name: 'Tokelau', code: 'TKL' },
  { name: 'Tonga', code: 'TON' },
  { name: 'Trinidad and Tobago', code: 'TTO' },
  { name: 'Tunisia', code: 'TUN' },
  { name: 'Turkey', code: 'TUR' },
  { name: 'Turkmenistan', code: 'TKM' },
  { name: 'Turks and Caicos Islands', code: 'TCA' },
  { name: 'Tuvalu', code: 'TUV' },
  { name: 'Uganda', code: 'UGA' },
  { name: 'Ukraine', code: 'UKR' },
  { name: 'United Arab Emirates', code: 'ARE' },
  { name: 'United Kingdom', code: 'GBR' },
  { name: 'United States', code: 'USA' },
  { name: 'United States Minor Outlying Islands', code: 'UMI' },
  { name: 'United States Virgin Islands', code: 'VIR' },
  { name: 'Uruguay', code: 'URY' },
  { name: 'Uzbekistan', code: 'UZB' },
  { name: 'Vanuatu', code: 'VUT' },
  { name: 'Vatican City State (Holy See)', code: 'VAT' },
  { name: 'Venezuela', code: 'VEN' },
  { name: 'Vietnam', code: 'VNM' },
  { name: 'Wallis and Futuna Islands', code: 'WLF' },
  { name: 'Western Sahara', code: 'ESH' },
  { name: 'Yemen', code: 'YEM' },
  { name: 'Zambia', code: 'ZMB' },
  { name: 'Zimbabwe', code: 'ZWE' }
];
