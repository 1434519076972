/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Hidden,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import IndividualForm from './IndividualForm';
import SoleTraderForm from './SoleTraderForm';
import TrustForm from './TrustForm';
import CompanyForm from './CompanyForm';
import PartnershipForm from './PartnershipForm';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function EntityDetails({ className, onBack, onNext, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { application } = useSelector(state => state.application);
  const { entityType } = useSelector(state => state.setting);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    setSelectedType(
      application &&
        application.entity_id &&
        application.entity_id.entity_type_id
    );
  }, [application]);

  const handleChange = (event, value) => {
    setSelectedType(value);
  };

  return (
    <Box>
      <Typography variant="caption" gutterBottom>
        * Required fields
      </Typography>
      <Hidden smDown>
        <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
          CHOOSE TYPE OF INVESTMENT VEHICLE
        </Typography>
      </Hidden>
      <Box mt={2}>
        <Hidden smDown>
          <ToggleButtonGroup
            disabled={application && application.entity_id !== null}
            fullWidth
            value={selectedType}
            size="large"
            exclusive
            onChange={handleChange}
          >
            {entityType.allIds.map(id => (
              <ToggleButton key={id} value={id}>
                {entityType.byId[id].name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Hidden>
        <Hidden mdUp>
          <TextField
            fullWidth
            required
            select
            disabled={application && application.entity_id}
            label="Investment Vehicle Type"
            value={selectedType}
            onChange={handleChange}
            SelectProps={{
              native: true
            }}
            variant="outlined"
            size="small"
          >
            <option></option>
            {entityType.allIds.map(id => (
              <option key={id} value={id}>
                {entityType.byId[id].name}
              </option>
            ))}
          </TextField>
        </Hidden>
      </Box>
      <Box my={2}>
        {selectedType == 1 && (
          <IndividualForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 2 && (
          <SoleTraderForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 3 && (
          <TrustForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 4 && (
          <TrustForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 5 && (
          <CompanyForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
        {selectedType == 6 && (
          <PartnershipForm
            entity_type_id={selectedType}
            onNext={onNext}
            onBack={onBack}
          />
        )}
      </Box>
    </Box>
  );
}

EntityDetails.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default EntityDetails;
