import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import { Formik } from 'formik';
import {
  CircularProgress,
  Backdrop,
  Button,
  IconButton,
  Box,
  Grid,
  TextField,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  FileDownloadOutlined as DownloadIcon,
  DeleteOutlineOutlined as DeleteIcon
} from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format, parseISO } from 'date-fns';
import bytesToSize from 'src/utils/bytesToSize';
import { updateEntity } from 'src/actions/entityActions';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function Files({ className, onBack, onComplete, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const { investor, isLoading } = useSelector(state => state.investor);
  const { entity } = useSelector(state => state.entity);
  const { investorId, entityId } = useParams();
  const { documentType } = useSelector(state => state.setting);

  const documentRef = useRef();
  const clearDocumentRef = () => {
    documentRef.current.value = '';
  };

  const handleClose = () => {
    setUploading(false);
  };

  const handleDownload = file_path => {
    const filename = file_path.split('/')[file_path.split('/').length - 1];
    setUploading(true);
    axios
      .get('/api/file', {
        responseType: 'arraybuffer',
        params: { path: file_path }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = encodeURI(url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        setUploading(false);
        link.click();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const initialValues = {
    document_type_id: '',
    expiry_dt: null,
    document: {}
  };

  const file_dropbox_path = `investors/${investor.id}_${investor.display_name}/${entity.id}_${entity.account_name}`;

  if (isLoading) {
    return null;
  }
  const data = entity.files;
  const columns = [
    { field: 'document_type', title: 'Type' },
    {
      field: 'expiry_dt',
      title: 'Expiry Date',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    }
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          document_type_id: Yup.number().required(),
          expiry_dt: Yup.date().nullable()
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }
        ) => {
          try {
            // Do API call to store step data in server session
            // It is important to have it on server to be able to reuse it if user
            // decides to continue later.
            setUploading(true);
            const formData = new FormData();
            formData.set('path', file_dropbox_path);
            formData.set(
              'document_type',
              documentType.byId[values.document_type_id].code
            );
            formData.set('expiry_dt', values.expiry_dt);
            formData.set('document', values.document);
            axios
              .post('/api/file', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                dispatch(
                  updateEntity(entity.id, {
                    id: entity.id,
                    files: _.unionBy([response.data], entity.files, 'id')
                  })
                );
                clearDocumentRef();
                resetForm();
                setUploading(false);
                setStatus({ success: true });
              })
              .catch(error => {
                throw error;
              });
          } catch (err) {
            resetForm();
            setUploading(false);
            setErrors({ submit: err.message });
            setStatus({ success: false });
            // setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          resetForm,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  required
                  label="Document Type"
                  name="document_type_id"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.document_type_id}
                  variant="outlined"
                  size="small"
                >
                  <option></option>
                  {documentType.allIds.map(id => (
                    <option key={id} value={id}>
                      {documentType.byId[id].code}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enAU}
                >
                  <DatePicker
                    label="Expiry Date (if applicable)"
                    value={values.expiry_dt}
                    onChange={newValue => {
                      setFieldValue(
                        'expiry_dt',
                        !newValue || newValue.toString() == 'Invalid Date'
                          ? newValue
                          : format(newValue, 'yyyy-MM-dd')
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={values.expiry_dt}
                        name="expiry_dt"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  name="document"
                  onChange={event => {
                    const file = event.target.files[0];
                    setFieldValue('document', file);
                  }}
                  inputRef={documentRef}
                  type="file"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                md={1}
              >
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    disabled={
                      uploading ||
                      !documentRef.current ||
                      (documentRef.current && documentRef.current.value == '')
                    }
                    type="submit"
                    size="small"
                  >
                    upload
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Backdrop
                className={classes.backdrop}
                open={uploading}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <CustomMaterialTable
                title="Supporting Documents"
                data={data}
                columns={columns}
                actions={[
                  {
                    icon: DownloadIcon,
                    tooltip: 'Download Document',
                    onClick: (event, data) => {
                      handleDownload(data.path);
                    }
                  },
                  {
                    icon: DeleteIcon,
                    tooltip: 'Delete File',
                    onClick: (event, rowData) => {
                      dispatch(
                        updateEntity(entityId, {
                          files: data.filter(file => file.id != rowData.id)
                        })
                      );
                    }
                  }
                ]}
                options={{
                  paging: false,
                  padding: 'dense'
                }}
              />
            </Box>
            <Box mt={6} display="flex">
              {onBack && (
                <Button onClick={onBack} size="large">
                  Previous
                </Button>
              )}
              <Box flexGrow={1} />
              <Button
                color="secondary"
                onClick={onComplete}
                variant="contained"
                size="large"
              >
                Complete
              </Button>
            </Box>
            <Box mt={2} />
          </form>
        )}
      </Formik>
    </>
  );
}

Files.propTypes = {
  className: PropTypes.string
  // files: PropTypes.array.isRequired
};

export default Files;
