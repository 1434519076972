import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, makeStyles } from '@material-ui/core';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {}
}));

function FundingSource({ className, ...rest }) {
  const classes = useStyles();
  const { funding_source } = useSelector(state => state.syndicate.stats.stats);
  const columns = [
    {
      field: 'account_name',
      title: 'Source',
      cellStyle: {
        whiteSpace: 'nowrap'
      }
    },
    {
      field: 'current_amt',
      title: 'Amount',
      type: 'currency'
    }
  ];
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        data={funding_source}
        columns={columns}
        options={{
          paging: false,
          toolbar: false,
          padding: 'dense'
        }}
      />
    </Card>
  );
}

FundingSource.propTypes = {
  className: PropTypes.string
};

FundingSource.defaultProps = {};

export default FundingSource;
