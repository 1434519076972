/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_LABELS,
  GET_CAMPAIGNS,
  GET_CAMPAIGN,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  GET_SYNDICATES,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_NEW_CAMPAIGN,
  CLOSE_NEW_CAMPAIGN,
  OPEN_CAMPAIGN,
  CLOSE_CAMPAIGN
} from 'src/actions/campaignActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  campaigns: {
    byId: {},
    allIds: []
  },
  syndicates: {
    byId: {},
    allIds: [],
    isLoading: true
  },
  sidebarOpen: false,
  newCampaignOpen: false,
  openCampaign: { open: false, campaign: null }
};

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS: {
      const { labels } = action.payload;

      return produce(state, draft => {
        draft.labels = labels;
      });
    }

    case GET_CAMPAIGNS: {
      const campaigns = action.payload;

      return produce(state, draft => {
        draft.campaigns.byId = objFromArray(campaigns);
        draft.campaigns.allIds = Object.keys(draft.campaigns.byId);
      });
    }

    case GET_CAMPAIGN: {
      const campaign = action.payload;

      return produce(state, draft => {
        draft.campaigns.byId[campaign.id] = campaign;

        if (!draft.campaigns.allIds.includes(campaign.id)) {
          draft.campaigns.allIds.push(campaign.id);
        }
      });
    }

    case ADD_CAMPAIGN: {
      const campaign = action.payload;

      return produce(state, draft => {
        draft.campaigns.byId[campaign.id] = campaign;
        draft.campaigns.allIds.unshift(campaign.id);
      });
    }

    case UPDATE_CAMPAIGN: {
      const campaign = action.payload;
      return produce(state, draft => {
        draft.campaigns.allIds = _.mergeWith(
          draft.campaigns.byId[campaign.id],
          campaign,
          customizer
        );
      });
    }
    case GET_SYNDICATES: {
      const syndicates = action.payload;

      return produce(state, draft => {
        draft.syndicates.byId = objFromArray(syndicates);
        draft.syndicates.allIds = Object.keys(draft.syndicates.byId);
        draft.isLoading = false;
      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, draft => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, draft => {
        draft.sidebarOpen = false;
      });
    }

    case OPEN_NEW_CAMPAIGN: {
      return produce(state, draft => {
        draft.newCampaignOpen = true;
      });
    }

    case CLOSE_NEW_CAMPAIGN: {
      return produce(state, draft => {
        draft.newCampaignOpen = false;
      });
    }

    case OPEN_CAMPAIGN: {
      const campaign = action.payload;
      return produce(state, draft => {
        draft.openCampaign.open = true;
        draft.openCampaign.campaign = campaign;
      });
    }

    case CLOSE_CAMPAIGN: {
      return produce(state, draft => {
        draft.openCampaign.open = false;
        draft.openCampaign.campaign = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default campaignReducer;
