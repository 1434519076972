
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.7,"time":162000,"words":540}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "v200"
    }}>{`V2.0.0`}</h3>
    <h6 {...{
      "id": "2020-04-09"
    }}>{`2020–04–09`}</h6>
    <ul>
      <li parentName="ul">{`Refactored components to accept multi-theme`}</li>
      <li parentName="ul">{`Update typography`}</li>
      <li parentName="ul">{`Update all views implementing new features`}</li>
      <li parentName="ul">{`Updated the mock adapter, now accepting post requests`}</li>
      <li parentName="ul">{`Added new views`}</li>
      <li parentName="ul">{`Implemented AuthService and AuthGuard`}</li>
      <li parentName="ul">{`Implemented multiple themes`}</li>
      <li parentName="ul">{`Implemented app settings manager`}</li>
      <li parentName="ul">{`Implemented Redux in Chat, Kanban, Mail apps`}</li>
      <li parentName="ul">{`Implemented MDX for docs`}</li>
      <li parentName="ul">{`Removed multiple css classes and replaced the styling with `}<inlineCode parentName="li">{`Box`}</inlineCode>{` component`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`react-modal-image`}</inlineCode>{` dependency to be able to open images as modals`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`formik`}</inlineCode>{` dependecy to create complex forms`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`joy`}</inlineCode>{` dependency to validate forms`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`notistack`}</inlineCode>{` dependecy to control notifications`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`immer`}</inlineCode>{` dependecy to create drafts`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`apexcharts`}</inlineCode>{` dependecy to create extra charts`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "v140"
    }}>{`V1.4.0`}</h3>
    <h6 {...{
      "id": "2020-02-25"
    }}>{`2020–02–25`}</h6>
    <ul>
      <li parentName="ul">{`Fixed `}<inlineCode parentName="li">{`uuid`}</inlineCode>{` import bug in `}<inlineCode parentName="li">{`src/views/Cards/index.js`}</inlineCode></li>
      <li parentName="ul">{`Updated dependencies`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "v130"
    }}>{`V1.3.0`}</h3>
    <h6 {...{
      "id": "2019-11-07"
    }}>{`2019–11–07`}</h6>
    <h4 {...{
      "id": "new"
    }}>{`New`}</h4>
    <ul>
      <li parentName="ul">{`Added Material-UI Container component to each view to replace custom styling`}</li>
      <li parentName="ul">{`Added IE11 support, can be removed from `}<inlineCode parentName="li">{`src/index.js`}</inlineCode>{` to reduce bundle size`}</li>
      <li parentName="ul">{`Added component pages`}</li>
      <li parentName="ul">{`Updated code style to match with Airbnb style`}</li>
      <li parentName="ul">{`Added RTL support`}</li>
      <li parentName="ul">{`Added dependencies`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`eslint-config-airbnb`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`eslint-config-prettier`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`eslint-plugin-import`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`eslint-plugin-jsx-a11y`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`eslint-plugin-react-hooks`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Updated depdendecy versions`}</li>
    </ul>
    <h3 {...{
      "id": "fix"
    }}>{`Fix`}</h3>
    <ul>
      <li parentName="ul">{`Fixed a bug for Edge & Safari where users couldn’t scroll properly`}</li>
    </ul>
    <h4 {...{
      "id": "breaking-changes"
    }}>{`Breaking changes`}</h4>
    <ul>
      <li parentName="ul">{`As we mentioned, we updated code style so you’ll note some changes`}</li>
      <li parentName="ul">{`Updated the layouts to fix scroll issues`}</li>
      <li parentName="ul">{`Removed deep folder nesting to keep a clearer structure`}</li>
      <li parentName="ul">{`Replaced custom `}<inlineCode parentName="li">{`useReact`}</inlineCode>{` hook with `}<inlineCode parentName="li">{`useLocation`}</inlineCode>{`, `}<inlineCode parentName="li">{`useHistory`}</inlineCode>{` and `}<inlineCode parentName="li">{`useParams`}</inlineCode>{` from `}<inlineCode parentName="li">{`react-router`}</inlineCode></li>
      <li parentName="ul">{`With `}<inlineCode parentName="li">{`react-scripts@^3.2.0`}</inlineCode>{` we are now able to use `}<inlineCode parentName="li">{`'.'`}</inlineCode>{` as `}<inlineCode parentName="li">{`baseUrl`}</inlineCode>{` and we updated the imports to avoid confusion`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "v120"
    }}>{`V1.2.0`}</h3>
    <h6 {...{
      "id": "2019-08-07"
    }}>{`2019–08–07`}</h6>
    <h4 {...{
      "id": "new-1"
    }}>{`New`}</h4>
    <ul>
      <li parentName="ul">{`Replaced the Routing System from `}<inlineCode parentName="li">{`1.1.0`}</inlineCode>{` version with an easier solution to avoid complexity`}</li>
      <li parentName="ul">{`Created 3 individual layouts for `}<inlineCode parentName="li">{`Error`}</inlineCode>{`, `}<inlineCode parentName="li">{`Auth`}</inlineCode>{` and `}<inlineCode parentName="li">{`Dashboard`}</inlineCode>{` pages`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`axios`}</inlineCode>{` and `}<inlineCode parentName="li">{`axios-mock-adapter`}</inlineCode>{` modules to simulate server calls for fetch requests`}</li>
      <li parentName="ul">{`Renamed few components`}</li>
      <li parentName="ul">{`Updated `}<inlineCode parentName="li">{`Calendar`}</inlineCode>{` page code`}</li>
      <li parentName="ul">{`Updated `}<inlineCode parentName="li">{`Chat`}</inlineCode>{` page code`}</li>
      <li parentName="ul">{`Updated `}<inlineCode parentName="li">{`Error`}</inlineCode>{` page code`}</li>
      <li parentName="ul">{`Moved all lib mixins to `}<inlineCode parentName="li">{`mixins`}</inlineCode>{` folder`}</li>
      <li parentName="ul">{`Moved `}<inlineCode parentName="li">{`helpers`}</inlineCode>{` files to `}<inlineCode parentName="li">{`utils`}</inlineCode>{` folder`}</li>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`data.js`}</inlineCode>{` files`}</li>
      <li parentName="ul">{`Removed unused helpers`}</li>
      <li parentName="ul">{`Removed unused utils`}</li>
    </ul>
    <h4 {...{
      "id": "fix-1"
    }}>{`Fix`}</h4>
    <ul>
      <li parentName="ul">{`Fixed navigation expand issue`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "v111"
    }}>{`V1.1.1`}</h3>
    <h6 {...{
      "id": "2019-08-01"
    }}>{`2019–08–01`}</h6>
    <h4 {...{
      "id": "fix-2"
    }}>{`Fix`}</h4>
    <ul>
      <li parentName="ul">{`Temporary remove PerfectScrollBar to fix Navbar and content scroll issues`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "v110"
    }}>{`V1.1.0`}</h3>
    <h6 {...{
      "id": "2019-07-31"
    }}>{`2019–07–31`}</h6>
    <h4 {...{
      "id": "new-2"
    }}>{`New`}</h4>
    <ul>
      <li parentName="ul">{`Implemented Code Splitting to lazy load views`}</li>
      <li parentName="ul">{`Implemented Layout System based on Route settings`}</li>
      <li parentName="ul">{`Implemented Routing System`}</li>
      <li parentName="ul">{`Implemented Route Authorization Guard based on authenticated user role and route settings`}</li>
      <li parentName="ul">{`Updated Presentation Page`}</li>
      <li parentName="ul">{`Created Changelog Page`}</li>
      <li parentName="ul">{`Created Development Documentation Pages`}</li>
      <li parentName="ul">{`Added PrismJS library and created CodeBlock component`}</li>
      <li parentName="ul">{`Added Redux library and configured some components using it as state manager`}</li>
      <li parentName="ul">{`Added design Figma source file`}</li>
      <li parentName="ul">{`Updated dependencies`}</li>
    </ul>
    <h4 {...{
      "id": "fix-3"
    }}>{`Fix`}</h4>
    <ul>
      <li parentName="ul">{`Fixed scroll bugs`}</li>
      <li parentName="ul">{`The layouts were similary so we kept one Layout only that can be configured dynamically`}</li>
    </ul>
    <h4 {...{
      "id": "breaking-changes-1"
    }}>{`Breaking changes`}</h4>
    <ul>
      <li parentName="ul">{`Replaced `}<inlineCode parentName="li">{`underscore`}</inlineCode>{` with `}<inlineCode parentName="li">{`lodash`}</inlineCode></li>
      <li parentName="ul">{`Changed the Layout logic`}</li>
      <li parentName="ul">{`Renamed few components`}</li>
      <li parentName="ul">{`Updated folder structure, grouped all views in the same folder`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "v100"
    }}>{`V1.0.0`}</h3>
    <h6 {...{
      "id": "2019-07-20"
    }}>{`2019–07–20`}</h6>
    <p>{`Initial release`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "v200",
    level: 3,
    title: "V2.0.0",
    children: [
        
      ]
  },
{
    id: "v140",
    level: 3,
    title: "V1.4.0",
    children: [
        
      ]
  },
{
    id: "v130",
    level: 3,
    title: "V1.3.0",
    children: [
        
      ]
  },
{
    id: "fix",
    level: 3,
    title: "Fix",
    children: [
        
      ]
  },
{
    id: "v120",
    level: 3,
    title: "V1.2.0",
    children: [
        
      ]
  },
{
    id: "v111",
    level: 3,
    title: "V1.1.1",
    children: [
        
      ]
  },
{
    id: "v110",
    level: 3,
    title: "V1.1.0",
    children: [
        
      ]
  },
{
    id: "v100",
    level: 3,
    title: "V1.0.0",
    children: [
        
      ]
  }
]

export const frontMatter = {}

