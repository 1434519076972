/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  FormHelperText,
  FormControlLabel,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import enAU from 'date-fns/locale/en-AU';
import { format } from 'date-fns';
import { UserX as DeleteIcon } from 'react-feather';
import { countries, states, titles, entitySchema } from 'src/utils/options';
import { properName } from 'src/utils/properName';
import { NumberMask } from 'src/utils/mask';
import { addEntity, updateEntity } from 'src/actions/entityActions';
import { validateTFN } from 'src/utils/validation';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function IndividualForm({
  className,
  entity_type_id,
  onBack,
  onNext,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { investorId, entityId } = useParams();
  const { entity } = useSelector(state => state.entity);
  const { address } = useSelector(state => state.investor);

  const defaultIndividual = {
    address_1: address.address_1,
    address_2: address.address_2 || '',
    city: address.city,
    state: address.state,
    postcode: address.postcode,
    country: address.country,
    is_us_tax_resident: false,
    is_pep: false
  };

  const initialValues = entity
    ? entity.entity_details
    : {
        ...entitySchema,
        individuals: [defaultIndividual]
      };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // Do API call to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          if (entity) {
            dispatch(
              updateEntity(entity.id, {
                ...entity,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident:
                  values.individuals[0].tax_country == 'Australia',
                is_us_tax_resident: values.individuals[0].is_us_tax_resident,
                account_name: properName(
                  values.individuals
                    .map(ind => ind.first_name.concat(' ', ind.last_name))
                    .join(' & ')
                ),
                address_1: properName(values.individuals[0].address_1),
                address_2: properName(values.individuals[0].address_2),
                city: properName(values.individuals[0].city),
                state: properName(values.individuals[0].state),
                postcode: values.individuals[0].postcode,
                country: values.individuals[0].country
              })
            );
          } else {
            dispatch(
              addEntity({
                investor_id: investorId,
                entity_type_id: entity_type_id,
                entity_details: values,
                is_tax_resident:
                  values.individuals[0].tax_country == 'Australia',
                is_us_tax_resident: values.individuals[0].is_us_tax_resident,
                account_name: properName(
                  values.individuals
                    .map(ind => ind.first_name.concat(' ', ind.last_name))
                    .join(' & ')
                ),
                address_1: properName(values.individuals[0].address_1),
                address_2: properName(values.individuals[0].address_2),
                city: properName(values.individuals[0].city),
                state: values.individuals[0].state,
                postcode: values.individuals[0].postcode,
                country: values.individuals[0].country
              })
            );
          }
          setStatus({ success: true });
          setSubmitting(false);
          if (onNext) {
            onNext();
          }
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <FieldArray
            name="individuals"
            subscription={{}} // This is required so that the whole array does not re-render on any change.
            render={({ insert, remove, push }) => (
              <>
                {values.individuals.map((individual, index) => (
                  <div key={index}>
                    {index > 0 && <Box mt={4}></Box>}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        Applicant {index + 1}
                      </Typography>
                      {index > 0 && (
                        <Tooltip title="Delete applicant">
                          <IconButton
                            size="small"
                            color="error"
                            variant="contained"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <SvgIcon>
                              <DeleteIcon />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                    <Box mt={2}>
                      <Grid container spacing={1}>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth
                            required
                            select
                            label="Title"
                            name={`individuals.${index}.title`}
                            onChange={handleChange}
                            SelectProps={{
                              native: true
                            }}
                            value={values.individuals[index].title || ''}
                            variant="outlined"
                            size="small"
                          >
                            <option></option>
                            {titles.map(option => (
                              <option key={option.code} value={option.code}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="First Name"
                            name={`individuals.${index}.first_name`}
                            onChange={handleChange}
                            value={values.individuals[index].first_name || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Last Name"
                            name={`individuals.${index}.last_name`}
                            onChange={handleChange}
                            value={values.individuals[index].last_name || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            locale={enAU}
                          >
                            <DatePicker
                              label="Date of Birth"
                              openTo="year"
                              views={['year', 'month', 'day']}
                              name={`individuals.${index}.birth_dt`}
                              value={values.individuals[index].birth_dt || null}
                              onChange={newValue => {
                                setFieldValue(
                                  `individuals.${index}.birth_dt`,
                                  !newValue ||
                                    newValue.toString() == 'Invalid Date'
                                    ? newValue
                                    : format(newValue, 'yyyy-MM-dd')
                                );
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Country of Residency for Tax Purposes"
                            name={`individuals.${index}.tax_country`}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.individuals[index].tax_country || ''}
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            maxLength={9}
                            // helperText={
                            //   values.individuals[index].tax_country ==
                            //     'Australia' &&
                            //   values.individuals[index].tfn.length > 0 &&
                            //   !validateTFN(values.individuals[index].tfn) &&
                            //   'Invalid TFN'
                            // }
                            // error={
                            //   values.individuals[index].tax_country ==
                            //     'Australia' &&
                            //   values.individuals[index].tfn.length > 0 &&
                            //   !validateTFN(values.individuals[index].tfn)
                            // }
                            label="TFN or Exemption number"
                            name={`individuals.${index}.tfn`}
                            InputProps={{
                              maxLength: 9,
                              inputComponent: NumberMask,
                              onChange: handleChange(`individuals.${index}.tfn`)
                            }}
                            onChange={handleChange}
                            value={values.individuals[index].tfn || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.individuals[index].is_pep || false
                                }
                                onChange={handleChange}
                                name={`individuals.${index}.is_pep`}
                                size="small"
                              />
                            }
                            label="Politically Exposed Person"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  values.individuals[index]
                                    .is_us_tax_resident || false
                                }
                                onChange={handleChange}
                                name={`individuals.${index}.is_us_tax_resident`}
                                size="small"
                              />
                            }
                            label="US Citizen / US Tax Resident"
                          />
                        </Grid>
                        {values.individuals[index].is_us_tax_resident && (
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              required
                              label="TIN"
                              name={`individuals.${index}.tin`}
                              onChange={handleChange}
                              value={values.individuals[index].tin || ''}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        )}
                        <Grid item md={12} xs={12}>
                          <TextField
                            required
                            fullWidth
                            inputProps={{
                              maxLength: 38
                            }}
                            helperText="Provide oversea address if not Australian tax
                            resident (cannot be a PO Box address)"
                            label="Address Line 1"
                            name={`individuals.${index}.address_1`}
                            onChange={handleChange}
                            value={values.individuals[index].address_1 || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            // error={Boolean(
                            //   errors &&
                            //     errors.individuals &&
                            //     errors.individuals[index] &&
                            //     errors.individuals[index].address_2
                            // )}
                            // helperText={
                            //   (errors &&
                            //     errors.individuals &&
                            //     errors.individuals[index] &&
                            //     errors.individuals[index].address_2) ||
                            //   'Provide oversea address if not Australian tax resident'
                            // }
                            inputProps={{ maxLength: 38 }}
                            label="Address Line 2"
                            name={`individuals.${index}.address_2`}
                            onChange={handleChange}
                            value={values.individuals[index].address_2 || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="City"
                            name={`individuals.${index}.city`}
                            onChange={handleChange}
                            value={values.individuals[index].city || ''}
                            variant="outlined"
                            size="small"
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Autocomplete
                            freeSolo
                            size="small"
                            name={`individuals.${index}.state`}
                            value={values.individuals[index].state || ''}
                            options={states}
                            onInputChange={(event, newValue) => {
                              setFieldValue(
                                `individuals.${index}.state`,
                                newValue ? newValue : ''
                              );
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                required
                                label="State/Province"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            fullWidth
                            required
                            label="Postcode"
                            name={`individuals.${index}.postcode`}
                            onChange={handleChange}
                            value={values.individuals[index].postcode || ''}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Country"
                            name={`individuals.${index}.country`}
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.individuals[index].country || ''}
                            size="small"
                            variant="outlined"
                          >
                            <option></option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                ))}
                <Box mt={2}>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      push({});
                    }}
                  >
                    Add Applicant
                  </Button>
                </Box>
              </>
            )}
          />
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={6} display="flex">
            {onBack && (
              <Button color="secondary" onClick={onBack} size="large">
                Previous
              </Button>
            )}
            <Box flexGrow={1} />
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              size="large"
            >
              Save & Next
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

IndividualForm.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default IndividualForm;
