/* eslint-disable max-len */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import { getInvestments } from 'src/actions/syndicateActions';
import InvestmentForm from './InvestmentForm';
import TransactionForm from './TransactionForm';
import DrawdownForm from './DrawdownForm';
import DocForm from './DocForm';
import MailApplication from './MailApplication';
import BulkRedemptionForm from './BulkRedemptionForm';
import BulkDrawdownForm from './BulkDrawdownForm';
import SellForm from './SellForm';
import BuyForm from './BuyForm';
import JournalForm from './JournalForm';
import AbaForm from './AbaForm';
import DistributionForm from './DistributionForm';
import Review from './Review';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  menu: {
    width: 256,
    maxWidth: '100%'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

function InvestorInvestments({ className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const [openInvestmentForm, setOpenInvestmentForm] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const [selectedInvestmentId, setSelectedInvestmentId] = useState(null); //this is for application mail only
  const [transaction, setTransaction] = useState(null);
  const [drawdown, setDrawdown] = useState(null);
  const [distribution, setDistribution] = useState(null);
  const [openTransactionForm, setOpenTransactionForm] = useState(false);
  const [openDrawdownForm, setOpenDrawdownForm] = useState(false);
  const [openDocForm, setOpenDocForm] = useState(false);
  const [docParams, setDocParams] = useState(null);
  const [openSellForm, setOpenSellForm] = useState(false);
  const [openBuyForm, setOpenBuyForm] = useState(false);
  const [bulkRedemption, setBulkRedemption] = useState(null);
  const [openBulkRedemptionForm, setOpenBulkRedemptionForm] = useState(false);
  const [openAbaForm, setOpenAbaForm] = useState(false);
  const [aba, setAba] = useState(null);
  const [bulkDrawdown, setBulkDrawdown] = useState(null);
  const [openBulkDrawdownForm, setOpenBulkDrawdownForm] = useState(false);
  const [openJournalForm, setOpenJournalForm] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openDistributionForm, setOpenDistributionForm] = useState(false);

  useEffect(() => {
    dispatch(getInvestments(syndicateId));
  }, [dispatch, syndicateId]);

  const handleInvestmentFormOpen = investment => {
    setSelectedInvestment(investment);
    setOpenInvestmentForm(true);
  };

  const handleInvestmentFormClose = () => {
    setOpenInvestmentForm(false);
  };

  const handleTransactionFormOpen = value => {
    setTransaction(value);
    setOpenTransactionForm(true);
  };

  const handleTransactionFormClose = () => {
    setOpenTransactionForm(false);
  };

  const handleDrawdownFormOpen = value => {
    setDrawdown(value);
    setOpenDrawdownForm(true);
  };

  const handleDrawdownFormClose = () => {
    setOpenDrawdownForm(false);
  };

  const handleDocFormOpen = value => {
    setDocParams(value);
    setOpenDocForm(true);
  };

  const handleDocFormClose = () => {
    setOpenDocForm(false);
  };

  const handleSellFormOpen = value => {
    setSelectedInvestment(value);
    setOpenSellForm(true);
  };

  const handleSellFormClose = () => {
    setOpenSellForm(false);
  };

  const handleBuyFormOpen = value => {
    setSelectedInvestment(value);
    setOpenBuyForm(true);
  };

  const handleBuyFormClose = () => {
    setOpenBuyForm(false);
  };

  const handleJournalFormOpen = value => {
    setSelectedInvestment(value);
    setOpenJournalForm(true);
  };

  const handleJournalFormClose = () => {
    setOpenJournalForm(false);
  };

  const handleBulkRedemptionFormOpen = value => {
    setBulkRedemption(value);
    setOpenBulkRedemptionForm(true);
  };

  const handleBulkRedemptionFormClose = () => {
    setOpenBulkRedemptionForm(false);
  };

  const handleBulkDrawdownFormOpen = value => {
    setBulkDrawdown(value);
    setOpenBulkDrawdownForm(true);
  };

  const handleBulkDrawdownFormClose = () => {
    setOpenBulkDrawdownForm(false);
  };

  const handleAbaFormOpen = value => {
    setAba(value);
    setOpenAbaForm(true);
  };

  const handleAbaFormClose = () => {
    setOpenAbaForm(false);
  };

  const handleMailOpen = investment => {
    setSelectedInvestment(investment);
    setOpenMail(true);
  };

  const handleMailClose = () => {
    setOpenMail(false);
  };

  const handleReviewOpen = investment => {
    setSelectedInvestment(investment);
    setOpenReview(true);
  };

  const handleReviewClose = () => {
    setOpenReview(false);
  };

  const handleDistributionFormOpen = value => {
    setDistribution(value);
    setOpenDistributionForm(true);
  };

  const handleDistributionFormClose = () => {
    setOpenDistributionForm(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Results
        onEditInvestment={handleInvestmentFormOpen}
        onOpenTransactionForm={handleTransactionFormOpen}
        onOpenDrawdownForm={handleDrawdownFormOpen}
        onOpenSellForm={handleSellFormOpen}
        onOpenBuyForm={handleBuyFormOpen}
        onOpenJournalForm={handleJournalFormOpen}
        onOpenDocForm={handleDocFormOpen}
        onOpenBulkRedemptionForm={handleBulkRedemptionFormOpen}
        onOpenBulkDrawdownForm={handleBulkDrawdownFormOpen}
        onOpenAbaForm={handleAbaFormOpen}
        onOpenMail={handleMailOpen}
        onOpenReview={handleReviewOpen}
        onOpenDistributionForm={handleDistributionFormOpen}
      />
      <InvestmentForm
        investment={selectedInvestment}
        onAdd={handleInvestmentFormClose}
        onClose={handleInvestmentFormClose}
        open={openInvestmentForm}
      />
      <TransactionForm
        transaction={transaction}
        onAdd={handleTransactionFormClose}
        onClose={handleTransactionFormClose}
        open={openTransactionForm}
      />
      <DrawdownForm
        drawdown={drawdown}
        onAdd={handleDrawdownFormClose}
        onClose={handleDrawdownFormClose}
        open={openDrawdownForm}
      />
      <SellForm
        investment={selectedInvestment}
        onAdd={handleSellFormClose}
        onClose={handleSellFormClose}
        open={openSellForm}
      />
      <BuyForm
        investment={selectedInvestment}
        onAdd={handleBuyFormClose}
        onClose={handleBuyFormClose}
        open={openBuyForm}
      />
      <JournalForm
        investment={selectedInvestment}
        onAdd={handleJournalFormClose}
        onClose={handleJournalFormClose}
        open={openJournalForm}
      />
      <DocForm
        docParams={docParams}
        onAdd={handleDocFormClose}
        onClose={handleDocFormClose}
        open={openDocForm}
      />
      <MailApplication
        investment={selectedInvestment}
        onSend={handleMailClose}
        onClose={handleMailClose}
        open={openMail}
        onOpenReview={handleReviewOpen}
      />
      <Review
        investment={selectedInvestment}
        onClose={handleReviewClose}
        open={openReview}
      />
      <BulkRedemptionForm
        bulkRedemption={bulkRedemption}
        onAdd={handleBulkRedemptionFormClose}
        onClose={handleBulkRedemptionFormClose}
        open={openBulkRedemptionForm}
      />
      <BulkDrawdownForm
        bulkDrawdown={bulkDrawdown}
        onAdd={handleBulkDrawdownFormClose}
        onClose={handleBulkDrawdownFormClose}
        open={openBulkDrawdownForm}
      />
      <AbaForm
        aba={aba}
        onAdd={handleAbaFormClose}
        onClose={handleAbaFormClose}
        open={openAbaForm}
      />
      <DistributionForm
        distribution={distribution}
        onAdd={handleDistributionFormClose}
        onClose={handleDistributionFormClose}
        open={openDistributionForm}
      />
    </Card>
  );
}

InvestorInvestments.propTypes = {
  className: PropTypes.string,
  investments: PropTypes.array
};

InvestorInvestments.defaultProps = {
  investments: []
};

export default InvestorInvestments;
