import produce from 'immer';
import _ from 'lodash';
import {
  INIT,
  INIT_ENTITY,
  GET_ENTITY,
  ADD_ENTITY,
  UPDATE_ENTITY
} from 'src/actions/entityActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoading: true,
  entity: null
};

function customizer(objValue, srcValue) {
  if (_.isArray(objValue) || _.isObject(objValue)) {
    return srcValue;
  }
}

const entityReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT: {
      return initialState;
    }
    case INIT_ENTITY: {
      return produce(state, draft => {
        draft.entity = null;
        draft.isLoading = false;
      });
    }
    case GET_ENTITY: {
      const entity = action.payload;

      return produce(state, draft => {
        draft.entity = entity;
        draft.isLoading = false;
      });
    }

    case ADD_ENTITY: {
      const entity = action.payload;

      return produce(state, draft => {
        draft.entity = entity;
        draft.isLoading = false;
      });
    }

    case UPDATE_ENTITY: {
      const entity = action.payload;

      return produce(state, draft => {
        _.mergeWith(draft.entity, entity, customizer);
      });
    }

    default: {
      return state;
    }
  }
};

export default entityReducer;
