import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  Link,
  colors,
  makeStyles
} from '@material-ui/core';
import toast from 'react-hot-toast';
import {
  FlagRounded as PaidIcon,
  FlagOutlined as UnpaidIcon
} from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { Download as ABAIcon } from 'react-feather';
import { PictureAsPdfOutlined as StatementIcon } from '@material-ui/icons';
import {
  getUnpaidInterests,
  updateInterestBatch
} from 'src/actions/dashboardActions';
import { MTableToolbar } from 'material-table';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  alarm: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

function InterestPayments({
  className,
  onOpenAbaForm,
  onOpenDocForm,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.account);
  const { interestBatches, isLoading } = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(getUnpaidInterests());
  }, [dispatch]);

  const rows = interestBatches.allIds.map(id => ({
    ...interestBatches.byId[id]
  }));

  const columns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Link
            component={RouterLink}
            underline="none"
            to={`/app/management/syndicates/${rowData.syndicate_id}/`}
          >
            {rowData.syndicate_name}
          </Link>
        );
      }
    },
    {
      field: 'period_start_dt',
      title: 'Payment Period',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return `${format(
          parseISO(rowData.period_start_dt),
          'dd/MM/yyyy'
        )} to ${format(parseISO(rowData.period_end_dt), 'dd/MM/yyyy')}`;
      }
    },
    {
      field: 'interest_payable',
      title: 'Interest',
      type: 'currency'
    },
    {
      field: 'interest_paid',
      title: 'After Tax',
      type: 'currency'
    },
    {
      field: 'is_paid',
      title: 'Payment',
      render: rowData => {
        return (
          <Box
            display="flex"
            color="text.secondary"
            alignItems="center"
            justifyContent="flex-start"
          >
            <div className={classes.badge}>
              <IconButton
                disabled={!user.groups.includes(5)}
                className={
                  !rowData.value
                    ? classes.checkedButton
                    : classes.uncheckedButton
                }
                onClick={async () => {
                  try {
                    await dispatch(
                      updateInterestBatch(rowData.id, {
                        is_paid: !rowData.is_paid
                      })
                    );
                    toast.success('Payment status updated');
                  } catch (error) {
                    console.log(error);
                    toast.error('Something went wrong');
                  }
                }}
              >
                {rowData.is_paid ? (
                  <UnpaidIcon fontSize="small" />
                ) : (
                  <PaidIcon fontSize="small" />
                )}
              </IconButton>
              <Typography variant="body2" color="inherit" component="span">
                {rowData.is_paid ? 'Paid' : 'Unpaid'}
              </Typography>
            </div>
          </Box>
        );
      }
    },
    { field: 'statement_email_status', title: 'Statement' },
    {
      field: 'id',
      title: 'Actions',
      render: rowData => {
        return (
          <Box>
            <IconButton
              disabled={!user.groups.includes(5)}
              onClick={() => {
                onOpenAbaForm({
                  syndicateId: rowData.syndicate_id,
                  batchIds: [rowData.id],
                  syndicateName: rowData.syndicate_name
                });
              }}
            >
              <ABAIcon />
            </IconButton>
            <IconButton
              disabled={!user.groups.includes(5)}
              onClick={() => {
                onOpenDocForm({
                  docType: 'statement',
                  syndicateId: rowData.syndicate_id,
                  batchId: rowData.id
                });
              }}
            >
              <StatementIcon />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  return (
    <CustomMaterialTable
      title={
        <Typography variant="h5" color="primary">
          Interest to Pay
        </Typography>
      }
      data={rows}
      columns={columns}
      isLoading={interestBatches.isLoading}
      components={{
        Toolbar: props => (
          <div style={{ height: '50px' }}>
            <MTableToolbar {...props} />
          </div>
        )
      }}
      options={{
        padding: 'dense',
        search: false,
        toolbarButtonAlignment: 'left',
        paging: false,
        exportButton: true,
        exportAllData: true,
        exportFileName: 'Interest Payments'
      }}
    />
  );
}

InterestPayments.propTypes = {
  className: PropTypes.string
};

export default InterestPayments;
