import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  CardHeader,
  Divider,
  Link,
  List,
  colors,
  makeStyles
} from '@material-ui/core';
import {
  FlagRounded as PaidIcon,
  FlagOutlined as UnpaidIcon
} from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { Download as ABAIcon } from 'react-feather';
import { PictureAsPdfOutlined as StatementIcon } from '@material-ui/icons';
import {
  getUnpaidLoanInterest,
  updateInterestBatch
} from 'src/actions/dashboardActions';
import { MTableToolbar } from 'material-table';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  alarm: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

function LoanInterest({ className, onOpenAbaForm, onOpenDocForm, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.account);
  const { loanInterests, isLoading } = useSelector(
    state => state.dashboard.loanInterests
  );

  useEffect(() => {
    dispatch(getUnpaidLoanInterest());
  }, [dispatch]);

  const columns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Link
            component={RouterLink}
            underline="none"
            to={`/app/management/syndicates/${rowData.syndicate_id}/`}
          >
            {rowData.syndicate_name}
          </Link>
        );
      }
    },
    {
      field: 'project_manager',
      title: 'Loan Manager'
    },
    {
      field: 'interest_balance',
      title: 'Interest',
      type: 'currency'
    }
  ];

  return (
    <CustomMaterialTable
      title={
        <Typography variant="h5" color="primary">
          Interest to Collect
        </Typography>
      }
      data={loanInterests}
      columns={columns}
      isLoading={isLoading}
      components={{
        Toolbar: props => (
          <div style={{ height: '50px' }}>
            <MTableToolbar {...props} />
          </div>
        )
      }}
      options={{
        padding: 'dense',
        search: false,
        toolbarButtonAlignment: 'left',
        paging: false
        // exportButton: true,
        // exportAllData: true,
        // exportFileName: 'Interest Payments'
      }}
    />
  );
}

LoanInterest.propTypes = {
  className: PropTypes.string
};

export default LoanInterest;
