/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Grid,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  CircularProgress,
  TableRow,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  NoteAltOutlined as EditIcon
} from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import Label from 'src/components/Label';
import { INIT_SYNDICATE } from 'src/actions/syndicateActions';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  stageField: {
    display: 'flex',
    // flexBasis: 200
    minWidth: 200
  },
  managementField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 300
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  action: {
    // marginBottom: theme.spacing(1),
    // '& + &': {
    //   marginLeft: theme.spacing(1)
    // },
    marginLeft: theme.spacing(1)
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const stageOptions = [
  {
    value: 'all',
    label: 'All',
    ids: [1, 2, 3, 4]
  },
  {
    value: 'in progress',
    label: 'In Progress',
    ids: [1, 2]
  },
  {
    value: 'completed',
    label: 'Completed',
    ids: [3]
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
    ids: [4]
  }
];

function applyFilters(syndicates, query, filters) {
  return syndicates.filter(syndicate => {
    let matches = true;

    if (
      query &&
      !syndicate.syndicate_name.toLowerCase().includes(query.toLowerCase())
    ) {
      matches = false;
    }

    if (
      filters.stage &&
      !stageOptions
        .filter(option => option.value == filters.stage)[0]
        .ids.includes(syndicate.syndicate_stage_id)
    ) {
      matches = false;
    }

    return matches;
  });
}

function getStageLabel(syndicate_stage_id) {
  const map = {
    1: {
      text: 'Due Diligence',
      color: 'error'
    },
    2: {
      text: 'Under Management',
      color: 'warning'
    },
    3: {
      text: 'Completed',
      color: 'success'
    },
    4: {
      text: 'Cancelled',
      color: 'greyout'
    }
  };

  const { text, color } = map[syndicate_stage_id];

  return <Label color={color}>{text}</Label>;
}

function Results({ syndicates, isLoading, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const [filters, setFilters] = useState({
    stage: 'in progress',
    management: null,
    stream: null,
    risk: null
  });

  const handleSyndicateStageChange = event => {
    event.persist();

    let value = null;

    if (event.target.value !== 'All') {
      value = event.target.value;
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      stage: value
    }));
  };

  const handleQueryChange = event => {
    event.persist();
    setQuery(event.target.value);
  };

  // Usually query is done on backend with indexing solutions
  const filteredSyndicates = applyFilters(syndicates, query, filters);

  const columns = [
    {
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      field: 'syndicate_name',
      title: 'Name',
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  component={RouterLink}
                  to={`/app/management/syndicates/${rowData.id}/edit`}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Avatar
              variant="rounded"
              style={{ height: '20px', width: '20px' }}
              src={
                rowData.is_internal ? '/static/images/avatars/avatar.png' : ''
              }
            ></Avatar>
            <Link
              color="inherit"
              component={RouterLink}
              to={`/app/management/syndicates/${rowData.id}`}
              variant="h6"
            >
              {rowData.syndicate_name}
            </Link>
          </Box>
        );
      }
    },
    {
      field: 'syndicate_stage',
      title: 'Stage',
      render: rowData => getStageLabel(rowData.syndicate_stage_id)
    },
    {
      field: 'syndicate_purpose',
      title: 'Purpose'
    },
    {
      field: 'start_dt',
      title: 'Settlement',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'end_dt',
      title: 'Repayment',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'sought_amt',
      title: 'Loan Amount',
      type: 'currency'
    },
    {
      field: 'lvr',
      title: 'LVR',
      render: rowData => (rowData.lvr ? `${rowData.lvr}%` : '')
    },
    {
      field: 'open_amt',
      title: 'Open for EOI',
      type: 'currency'
    },
    {
      field: 'risk_level',
      title: 'Risk Level'
    },
    {
      field: 'business_stream',
      title: 'Business Stream'
    }
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        title={
          <Box my={1} className={classes.stageField}>
            <TextField
              fullWidth
              size="small"
              label="Syndicate Stage"
              name="syndicate_stage_id"
              onChange={handleSyndicateStageChange}
              select
              SelectProps={{ native: true }}
              value={filters.stage || 'all'}
              variant="outlined"
            >
              {stageOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        }
        isLoading={isLoading}
        columns={columns}
        data={filteredSyndicates}
        actions={[
          {
            icon: AddIcon,
            tooltip: 'Add Syndicate',
            isFreeAction: true,
            onClick: async (event, rowData) => {
              await dispatch({ type: INIT_SYNDICATE });
              history.push('/app/management/syndicates/create');
            }
          }
        ]}
        options={{
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [10, 20, 50]
        }}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  syndicates: PropTypes.array
};

Results.defaultProps = {
  syndicates: []
};

export default Results;
