/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2)
  },
  tag: {
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function Wecome({ className, application, onBack, onNext, ...rest }) {
  return (
    <Box>
      <Typography variant="h3" style={{ fontWeight: 600 }} gutterBottom>
        Welcome!
      </Typography>
      <Box mt={4}>
        <Typography variant="body1" gutterBottom>
          Welcome to Wealth Pi Fund Pty Ltd (“Wealth Pi”) online investment
          portal. In order to set you up on our investment platform we need to
          collect and validate certain information about you and your investment
          vehicle. This online form will guide you through the information
          gathering process relevant for the type of Investment Vehicle you wish
          to use (an individual, a company, or a trust including a self-managed
          superannuation fund). Once we collect this information from you, we
          will need to validate and approve it in accordance with various
          government regulations including the following:
        </Typography>
        <Box mt={2}>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            The Anti-Money Laundering and Counter-Terrorism Financing Act 2006
            (Cth.) (“AML/CTF Act”)
          </Typography>
          <Typography variant="body1" gutterBottom>
            The Anti-Money Laundering and Counter-Terrorism Financing Act 2006
            (Cth.) (“AML/CTF Act”) imposes strict investor identification and
            verification obligations on providers of designated services (such
            as the issue of interests in managed investment schemes). As the
            Arranger of the Issue, Wealth Pi has to be “reasonably satisfied”
            that investors are who they claim to be, or, in the case of
            non-individual investors, the investment entity exists and the
            beneficial ownership details of the investment entity are known (if
            applicable). To comply with our obligations, all applicants must
            complete this Form and provide the required documentation. Please
            note we will not accept an application to invest in the Trust until
            we are satisfied the identity of the investor has been verified in
            accordance with the requirements of the AML/CTF Act. If you do not
            provide a completed Form or the correct identification documents,
            the processing of your application may be delayed or refused.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            Foreign Account Tax and Compliance Act (FATCA)
          </Typography>
          <Typography variant="body1" gutterBottom>
            The Foreign Account Tax and Compliance Act (FATCA) is a regime
            enacted by the United States to identify US taxpayers using offshore
            accounts. We are required to ask investors whether they are US
            citizens or residents and provide the ATO with that information. As
            a result of similar laws enacting a Common Reporting Standard (CRS)
            on collection, reporting and exchange of financial on other foreign
            tax residents, we may collect and report this to the ATO. The CRS
            regime applies to 70 jurisdictions.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            Australian Taxation Office – TFN and Withholding Tax
          </Typography>
          <Typography variant="body1" gutterBottom>
            The ATO requires us to obtain the Tax File Number/s (TFN) of the
            relevant Investment Vehicle. Unless there is an ATO approved
            exception for the provision of the TFN, Wealth Pi must retain
            withholding tax at the highest margin tax rate on any distributions
            of income.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5" style={{ fontWeight: 600 }} gutterBottom>
            ASIC - Wholesale and Sophisticated Investor
          </Typography>
          <Typography variant="body1" gutterBottom>
            Investors who wish to invest with Wealth Pi need to qualify as a
            sophisticated or wholesale clients as set out in the Corporations
            Act.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            Once we have received and approved the completed Investment Vehicle
            Onboarding Form, we will set your Investment Vehicle up on our
            system. Thereafter, as you confirm which investments you wish to
            participate in, we will send you an email with a link to our online
            Investment Application Form for you to review and approve. Following
            receipt of your approved application, we will send you an email
            requesting payment. This will include your investment vehicles
            unique BPay code and payment instructions. Once the payment is made,
            we will include your investment in the unit registry and send you a
            note or unit certificate for your records. If you have any
            questions, please do not hesitate to contact our Client Services
            team at clientservices@wealthpifund. We look forward to welcoming
            you to Wealth Pi Fund.
          </Typography>
        </Box>
      </Box>
      <Box mt={6} display="flex">
        <Box flexGrow={1} />
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          size="large"
          onClick={() => {
            if (onNext) {
              onNext();
            }
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

Wecome.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default Wecome;
