import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, Typography, makeStyles } from '@material-ui/core';
import { currencyFormatter } from 'src/utils/mask';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {
    '& .called--cell': {
      backgroundColor: 'rgba(0, 199, 177, 1)'
    },
    '& .total--cell': {
      backgroundColor: 'rgba(147, 218, 207, 0.55)'
    }
  },
  header: {
    // fontWeight: theme.typography.fontWeightMedium,
    // fontSize: 11
  }
}));

function Gap({ className, ...rest }) {
  const classes = useStyles();
  const { gaps } = useSelector(state => state.syndicate.stats.stats);

  const columns = [
    {
      field: 'unit_class',
      title: 'Class',
      render: rowData => `${rowData.unit_class} ${rowData.portion}`
    },
    {
      field: 'called_amt',
      title: 'Funds Called',
      type: 'currency'
    },
    {
      field: 'called_gap_amt',
      title: 'Gap on Called',
      type: 'currency',
      cellStyle: {
        backgroundColor: '#00c7b1',
        color: '#FFF'
      },
      headerStyle: {
        backgroundColor: '#00c7b1',
        color: '#FFF'
      }
    },
    {
      field: 'total_amt',
      title: 'Total incl. EOI',
      type: 'currency'
    },
    {
      field: 'total_gap_amt',
      title: 'Gap on Total',
      type: 'currency',
      cellStyle: {
        backgroundColor: '#cccccc'
      },
      headerStyle: {
        backgroundColor: '#cccccc'
      }
    }
  ];
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CustomMaterialTable
        data={gaps}
        columns={columns}
        options={{
          paging: false,
          toolbar: false,
          padding: 'dense'
        }}
      />
    </Card>
  );
}

Gap.propTypes = {
  className: PropTypes.string
};

Gap.defaultProps = {};

export default Gap;
