import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { getCampaigns, getSyndicates } from 'src/actions/campaignActions';
import { getSetting } from 'src/actions/settingActions';
import Sidebar from './Sidebar';
import CampaignList from './CampaignList';
import Campaign from './Campaign';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative'
  }
}));

function CampaignView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const pageRef = useRef(null);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getSyndicates());
    dispatch(getCampaigns({ label: 'All' }));
  }, [dispatch]);

  return (
    <Page className={classes.root} title="Campaign" ref={pageRef}>
      <Sidebar containerRef={pageRef} />
      <CampaignList />
      <Campaign />
    </Page>
  );
}

export default CampaignView;
