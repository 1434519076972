import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  IconButton,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import {
  FlagRounded as PaidIcon,
  FlagOutlined as UnpaidIcon
} from '@material-ui/icons';
import {
  getUnpaidCommissions,
  updateCommission
} from 'src/actions/dashboardActions';
import { MTableToolbar } from 'material-table';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { currencyFormatter } from 'src/utils/mask';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  alarm: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

function UnpaidCommissions({ className, onOpenAbaForm, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.account);
  const { unpaidCommissions } = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(getUnpaidCommissions());
  }, [dispatch]);

  const columns = [
    {
      field: 'managing_staff',
      title: 'Staff'
    },
    {
      field: 'fundraiser',
      title: 'Fund Raiser'
    },
    {
      field: 'commission_amt',
      title: 'Commission',
      type: 'currency'
    },
    {
      field: 'is_paid',
      title: 'Payment',
      render: rowData => {
        return (
          <Box
            display="flex"
            color="text.secondary"
            alignItems="center"
            justifyContent="flex-start"
          >
            <div className={classes.badge}>
              <IconButton
                disabled={!user.groups.includes(5)}
                className={classes.uncheckedButton}
                onClick={() => {
                  dispatch(
                    updateCommission({
                      fundraiserId: rowData.fundraiser_id,
                      paid: true
                    })
                  );
                }}
              >
                <UnpaidIcon fontSize="small" />
              </IconButton>
              <Typography variant="body2" color="inherit" component="span">
                Unpaid
              </Typography>
            </div>
          </Box>
        );
      }
    }
  ];

  const detailColumns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Link
              component={RouterLink}
              underline="none"
              to={`/app/management/syndicates/${rowData.syndicate_id}/`}
            >
              {rowData.syndicate_name}
            </Link>
          </Box>
        );
      }
    },
    {
      field: 'investor_name',
      title: 'Investment',
      render: rowData => (
        <div>
          {rowData.investor_name}
          <Typography variant="body2" color="textSecondary">
            {currencyFormatter(rowData.investment_amt)}
          </Typography>
        </div>
      )
    },
    {
      field: 'period_start_dt',
      title: 'Start Date',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'period_end_dt',
      title: 'End Date',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'commission_amt',
      title: 'Commission',
      type: 'currency'
    }
  ];

  return (
    <CustomMaterialTable
      title={
        <Typography variant="h5" color="primary">
          Unpaid Commissions
        </Typography>
      }
      data={unpaidCommissions.unpaidCommissions}
      columns={columns}
      isLoading={unpaidCommissions.isLoading}
      components={{
        Toolbar: props => (
          <div style={{ height: '50px' }}>
            <MTableToolbar {...props} />
          </div>
        )
      }}
      detailPanel={rowData => {
        return (
          <Box m={1} sx={{ color: 'text.disabled' }}>
            <CustomMaterialTable
              title="Details"
              columns={detailColumns}
              data={rowData.comissions.filter(
                commission => !commission.is_paid
              )}
              // actions={[
              //   {
              //     icon: EditIcon,
              //     tooltip: 'Update Transaction',
              //     disabled: !user.group_names.some(r =>
              //       ['Fund Manager', 'Finance'].includes(r)
              //     ),
              //     onClick: (event, rowData) => {}
              //   }
              // ]}
              options={{
                paging: false,
                padding: 'dense'
              }}
            />
          </Box>
        );
      }}
      options={{
        padding: 'dense',
        search: false,
        toolbarButtonAlignment: 'left',
        paging: false,
        exportButton: true,
        exportAllData: true,
        exportFileName: 'Unpaid Commissions'
      }}
    />
  );
}

UnpaidCommissions.propTypes = {
  className: PropTypes.string
};

export default UnpaidCommissions;
