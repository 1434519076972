import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Typography,
  Card,
  Grid,
  CardHeader,
  Divider,
  Link,
  colors,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { currencyFormatter } from 'src/utils/mask';
import { MTableToolbar } from 'material-table';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  investor: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(2)
  },
  entity: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 12
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium
    // fontSize: 12
  },
  highlight: {
    color: colors.red[600],
    fontWeight: theme.typography.fontWeightMedium
  }
}));

function FundAllocation({ className, loading, pool, ...rest }) {
  const classes = useStyles();

  const columns = [
    {
      field: 'syndicate_name',
      title: 'Syndicate',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              variant="rounded"
              style={{ height: '20px', width: '20px' }}
              src={
                rowData.is_internal ? '/static/images/avatars/avatar.png' : ''
              }
            ></Avatar>
            <Link
              component={RouterLink}
              underline="none"
              to={`/app/management/syndicates/${rowData.syndicate_id}/`}
            >
              {rowData.syndicate_name}
            </Link>
          </Box>
        );
      }
    },
    {
      field: 'end_dt',
      title: 'Maturity Date',
      type: 'date',
      defaultSort: 'asc',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'lvr',
      title: 'LVR',
      type: 'number',
      render: rowData => `${rowData.lvr}%`
    },
    {
      field: 'current_amt',
      title: 'Current Amount',
      type: 'currency'
    },
    {
      field: 'queueing_amt',
      title: 'EOI',
      type: 'currency'
    }
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Funds Allocation" />
      <Divider />
      {pool.map(fund => {
        return (
          <Box m={1} key={fund.entity_id}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomMaterialTable
                  title={
                    <>
                      <Typography variant="h5">
                        <Link
                          component={RouterLink}
                          underline="none"
                          to={`/app/management/syndicates/${fund.syndicate_id}/`}
                        >
                          <span style={{ color: '#00C7B1' }}>
                            {fund.syndicate_name}
                          </span>{' '}
                          || Idle Cash:{' '}
                          <span
                            className={
                              parseFloat(fund.idle_amt) > 0
                                ? classes.highlight
                                : classes.subtitle
                            }
                          >
                            {currencyFormatter(fund.idle_amt)}
                          </span>{' '}
                          || Max Exposure:{' '}
                          <span>
                            {currencyFormatter(fund.max_exposure_amt)}
                          </span>{' '}
                          || Max LVR:{' '}
                          <span>{`${parseFloat(fund.lvr).toString()}%`}</span>
                        </Link>
                      </Typography>
                      <Divider />
                    </>
                  }
                  columns={columns}
                  data={fund.pool_investments.filter(
                    investment =>
                      parseFloat(investment.current_amt) > 0 ||
                      parseFloat(investment.queueing_amt) > 0
                  )}
                  components={{
                    Toolbar: props => (
                      <div style={{ height: '50px' }}>
                        <MTableToolbar {...props} />
                      </div>
                    )
                  }}
                  actions={[
                    {
                      icon: FileDownloadOutlinedIcon,
                      tooltip: 'Download Reconcile Statement',
                      isFreeAction: true,
                      onClick: () => {
                        try {
                          axios
                            .get('/api/poolreconcile', {
                              responseType: 'arraybuffer',
                              params: { entityId: fund.entity_id }
                            })
                            .then(response => {
                              const url = window.URL.createObjectURL(
                                new Blob([response.data])
                              );
                              const link = document.createElement('a');
                              link.href = encodeURI(url);
                              link.setAttribute(
                                'download',
                                `${fund.syndicate_name}.csv`
                              );
                              document.body.appendChild(link);
                              link.click();
                            })
                            .catch(error => {});
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }
                  ]}
                  isLoading={loading}
                  options={{
                    padding: 'dense',
                    paging: false,
                    search: false
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Card>
  );
}

FundAllocation.propTypes = {
  className: PropTypes.string
};

export default FundAllocation;
