import React, { useCallback, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import format from 'date-fns/format';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
  Paper,
  useMediaQuery,
  makeStyles,
  colors,
  CardHeader
} from '@material-ui/core';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import { updateSyndicate } from 'src/actions/syndicateActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import AddEditEventModal from './AddEditEventModal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  calendar: {
    '& .fc-unthemed th': {
      borderColor: theme.palette.divider
    },
    '& .fc-widget-header': {
      backgroundColor: theme.palette.secondary
    },
    '& .fc-header-title': {
      color: theme.palette.secondary
    },
    '& .fc-unthemed td': {
      borderColor: theme.palette.divider
    },
    '& .fc-unthemed td.fc-today': {
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-head': {
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-body': {
      backgroundColor: theme.palette.background.default
    },
    '& .fc-axis': {
      ...theme.typography.body2
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6
    },
    '& .fc th': {
      borderColor: theme.palette.divider
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-day-top': {
      ...theme.typography.body2
    },
    '& .fc-highlight': {
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-event': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderWidth: 2,
      opacity: 0.9,
      '& .fc-time': {
        ...theme.typography.h6,
        color: 'inherit'
      },
      '& .fc-title': {
        // ...theme.typography.body1,
        // color: 'inherit',
        whiteSpace: 'normal',
        fontSize: 10,
        color: 'white'
      }
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1
    },
    '& .fc-toolbar h2': {
      ...theme.typography.h5,
      display: 'inline'
      // fontSize: 16
    },
    '& .fc-button-primary': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white'
    },
    '& .fc-today-button': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white'
    }
  }
}));

const eventColor = {
  Repayment: '#ff0000',
  Settlement: '#02a147'
};

function CalendarView() {
  const classes = useStyles();
  const calendarRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState(mobileDevice ? 'listWeek' : 'dayGridMonth');
  const [date, setDate] = useState(moment().toDate());
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [changedEvent, setChangedEvent] = useState(null);
  const { user } = useSelector(state => state.account);
  const [modal, setModal] = useState({
    event: null,
    mode: null,
    open: false
  });

  const resetModal = () => {
    setModal({
      event: null,
      mode: null,
      open: false
    });
  };

  const handleEventChange = changeInfo => {
    console.log('im here');
    const event = changeInfo.event;
    const event_type = changeInfo.event.extendedProps.description;
    console.log(event_type);

    if (event_type == 'Settlement') {
      dispatch(
        updateSyndicate(event.groupId, {
          start_dt: format(event.start, 'yyyy-MM-dd')
        })
      );
    } else if (event_type == 'Repayment') {
      dispatch(
        updateSyndicate(event.groupId, {
          end_dt: format(event.start, 'yyyy-MM-dd')
        })
      );
    }
  };

  const handleEventDelete = event => {
    setEvents(prevEvents =>
      prevEvents.filter(prevEvent => prevEvent.id !== event.id)
    );
    resetModal();
    if (event.description == 'Settlement') {
      dispatch(
        updateSyndicate(event.groupId, {
          syndicate_stage_id: 2,
          start_dt: format(event.start, 'yyyy-MM-dd')
        })
      );
    } else if (event.description == 'Repayment') {
      dispatch(
        updateSyndicate(event.groupId, {
          syndicate_stage_id: 3,
          end_dt: format(event.start, 'yyyy-MM-dd')
        })
      );
    }
  };

  const handleEventEdit = event => {
    setEvents(prevEvents =>
      prevEvents.map(prevEvent =>
        prevEvent.id === event.id ? event : prevEvent
      )
    );
    resetModal();
  };

  const handleEventSelect = eventClickInfo => {
    const event = eventClickInfo.event;

    console.log('im in event select');

    setModal({
      event: event,
      mode: 'edit',
      open: true
    });
  };

  const handleModalClose = () => {
    resetModal();
  };

  // const renderEventContent = eventInfo => {
  //   return (
  //     <>
  //       <p style={{ fontSize: 10, whiteSpace: 'normal' }}>
  //         {eventInfo.event.title}
  //       </p>
  //     </>
  //   );
  // };

  const getEvents = useCallback(() => {
    axios.get('/api/events').then(response => {
      if (isMountedRef.current) {
        const eventData = response.data.map(event => {
          return {
            id: event.id,
            title: event.description,
            start: event.event_dt,
            end: event.event_dt,
            groupId: event.syndicate_id,
            description: event.event_type,
            backgroundColor: eventColor[event.event_type],
            borderColor: eventColor[event.event_type]
          };
        });
        setEvents(eventData);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = mobileDevice ? 'listWeek' : 'dayGridMonth';

      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [mobileDevice]);

  return (
    <Card>
      <CardContent>
        {/* <CardHeader title="Loan Events" /> */}
        {loading ? (
          <Skeleton animation="wave" variant="rect" height={150} />
        ) : (
          <div className={classes.calendar}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: '',
                right: 'title'
              }}
              viewClassNames={classes.calendar}
              aspectRatio={1}
              contentHeight="auto"
              initialView="dayGridMonth"
              editable={user.groups.some(r => [1, 2, 5].includes(r))}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={true}
              events={events}
              // eventContent={renderEventContent}
              eventDrop={handleEventChange}
              eventClick={handleEventSelect}
            />
          </div>
        )}
        <AddEditEventModal
          event={modal.event}
          mode={modal.mode}
          onCancel={handleModalClose}
          onDelete={handleEventDelete}
          onEdit={handleEventEdit}
          open={modal.open}
        />
      </CardContent>
    </Card>
  );
}

export default CalendarView;
