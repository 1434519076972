/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Avatar, Box, Link, makeStyles } from '@material-ui/core';
import Label from 'src/components/Label';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomMaterialTable from 'src/components/CustomMaterialTable';

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  }
}));

const sortOptions = [
  {
    value: 'end_dt|desc',
    label: 'Maturity Date (newest first)'
  },
  {
    value: 'end_dt|asc',
    label: 'Maturity Date (oldest first)'
  }
];

const statusOptions = [
  {
    value: 'all',
    label: 'All',
    ids: [null, 1, 2, 3, 4, 5]
  },
  {
    value: 'current',
    label: 'Current Investments',
    ids: [null, 1, 2, 3, 4]
  },
  {
    value: 'redeemed',
    label: 'Redeemed',
    ids: [5]
  }
];

function Results({ className, investments, loading, ...rest }) {
  const classes = useStyles();
  const { syndicate, isLoading } = useSelector(state => state.syndicate);

  // Usually query is done on backend with indexing solutions
  // const filteredInvestments = applyFilters(investments, query, filters);

  const columns = [
    {
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      field: 'syndicate_name',
      title: 'Name',
      render: rowData => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              variant="rounded"
              style={{ height: '20px', width: '20px' }}
              src={
                rowData.is_internal ? '/static/images/avatars/avatar.png' : ''
              }
            ></Avatar>
            <Link
              color="inherit"
              component={RouterLink}
              to={`/app/management/syndicates/${rowData.syndicate_id}`}
              variant="h6"
            >
              {rowData.syndicate_name}
            </Link>
          </Box>
        );
      }
    },
    {
      field: 'end_dt',
      title: 'Maturity Date',
      type: 'date',
      dateSetting: { locale: 'en-AU' }
    },
    {
      title: 'Interest',
      field: 'interest',
      render: rowData => rowData.interest || ''
    },
    {
      title: 'Current Amount',
      field: 'current_amt',
      type: 'currency'
    },
    {
      title: 'EOI',
      field: 'queueing_amt',
      type: 'currency'
    }
  ];

  return (
    <CustomMaterialTable
      title="Fund Allocation"
      data={investments}
      columns={columns}
      actions={[
        {
          icon: FileDownloadOutlinedIcon,
          tooltip: 'Download Reconcile Statement',
          isFreeAction: true,
          onClick: () => {
            try {
              axios
                .get('/api/poolreconcile', {
                  responseType: 'arraybuffer',
                  params: { entityId: syndicate.entity_id }
                })
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement('a');
                  link.href = encodeURI(url);
                  link.setAttribute(
                    'download',
                    `${syndicate.syndicate_name}.csv`
                  );
                  document.body.appendChild(link);
                  link.click();
                })
                .catch(error => {});
            } catch {
              console.log('error');
            }
          }
        }
      ]}
      options={{
        padding: 'dense',
        pageSize: 15,
        pageSizeOptions: [15, 30, 50]
      }}
    />
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
