/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import ApplicationLayout from 'src/layouts/ApplicationLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ApplicationAuthGuard from 'src/components/ApplicationAuthGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/pages/Error404View')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/auth/LoginView')
    )
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/auth/LoginView')
    )
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/auth/RegisterView')
    )
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/auth/RegisterView')
    )
  },
  {
    exact: true,
    path: '/campaign/:campaignUUID/:email',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/campaign')
    )
  },
  {
    exact: true,
    path: '/unsubscribe/:email',
    component: lazy(() =>
      import(/* webpackMode: "eager" */ 'src/views/unsubscribe')
    )
  },
  {
    path: '/applications',
    layout: ApplicationLayout,
    routes: [
      {
        exact: true,
        path: '/applications/:investmentId/:secret',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/auth/ApplicationSecretLogin'
          )
        )
      },
      {
        exact: true,
        guard: ApplicationAuthGuard,
        path: '/applications/:investmentId/:secret/details',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvestmentApplicationView'
          )
        )
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
        // component: lazy(() =>
        //   import(
        //     /* webpackMode: "eager" */ 'src/views/management/SyndicateListPage'
        //   )
        // )
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/pages/AccountView')
        )
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/reports/DashboardView')
        )
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/reports/DashboardAlternativeView'
          )
        )
      },
      {
        exact: true,
        path: '/app/reports/pool',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/reports/PoolManagement')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/syndicates',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/SyndicateListPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/syndicates/create',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/SyndicateCRUDView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/syndicates/:syndicateId',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/SyndicateDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/syndicates/:syndicateId/edit',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/SyndicateCRUDView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/applications/:investmentId',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvestmentApplicationView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/investors',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvestorListPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/investors/create',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvestorCRUDView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/investors/:investorId',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvestorDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/investors/:investorId/edit',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvestorCRUDView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/investors/:investorId/entities/create',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/EntityCRUDView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/investors/:investorId/:entityId/edit',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/EntityCRUDView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/suppliers',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/SupplierListPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/CustomerListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/CustomerDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/CustomerEditView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/ProductListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/ProductCreateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/OrderListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/OrderDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvoiceListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/management/InvoiceDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/calendar/CalendarView')
        )
      },
      {
        exact: true,
        path: '/app/kanban',
        // component: lazy(() =>
        //   import(/* webpackMode: "eager" */ 'src/views/kanban_old/KanbanView')
        // )
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/kanban')
        )
      },
      {
        exact: true,
        path: '/app/kanban_old',
        // component: lazy(() =>
        //   import(/* webpackMode: "eager" */ 'src/views/kanban_old/KanbanView')
        // )
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/kanban_old/KanbanView')
        )
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/chat/ChatView')
        )
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/mail/MailView')
        )
      },

      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/crm/:label/:campaignId?',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/crm/CampaignView')
        )
      },
      {
        exact: true,
        path: '/app/crm',
        component: () => <Redirect to="/app/crm/All" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/projects/OverviewView')
        )
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/projects/ProjectBrowseView'
          )
        )
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/projects/ProjectCreateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/projects/ProjectDetailsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/social/FeedView')
        )
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/social/ProfileView')
        )
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/extra/charts/ApexChartsView'
          )
        )
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/extra/forms/FormikView')
        )
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/extra/forms/ReduxFormView'
          )
        )
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/extra/editors/DraftEditorView'
          )
        )
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/extra/editors/QuillEditorView'
          )
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/WelcomeView')
        )
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/GettingStartedView')
        )
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/docs/EnvironmentVariablesView'
          )
        )
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/DeploymentView')
        )
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/ApiCallsView')
        )
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/AnalyticsView')
        )
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/AuthenticationView')
        )
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/RoutingView')
        )
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/SettingsView')
        )
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() =>
          import(
            /* webpackMode: "eager" */ 'src/views/docs/StateManagementView'
          )
        )
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/ThemingView')
        )
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/SupportView')
        )
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/docs/ChangelogView')
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() =>
          import(/* webpackMode: "eager" */ 'src/views/pages/PricingView')
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
