import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from 'src/components/Page';
import { getStaff } from 'src/actions/staffActions';
import Results from './Results';
import FundRaiserForm from './FundRaiserForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

function FundRaiserListView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { user } = useSelector(state => state.account);
  const [fundraisers, setFundraisers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [selectedFundRaiser, setSelectedFundRaiser] = useState(null);

  const handleFresh = value => {
    if (fundraisers.map(fundraiser => fundraiser.id).includes(value.id)) {
      setFundraisers(
        fundraisers.map(fundraiser =>
          fundraiser.id == value.id ? value : fundraiser
        )
      );
    } else {
      // push to fundraisers didn't incur rerender
      setFundraisers(fundraisers.concat([value]));
    }
  };

  const handleFormOpen = value => {
    setSelectedFundRaiser(value);
    setOpenForm(true);
  };

  const handleFormClose = value => {
    setOpenForm(false);
    if (value) {
      handleFresh(value);
    }
  };

  const getFundraisers = useCallback(() => {
    axios.get('/api/fundraisers').then(response => {
      if (isMountedRef.current) {
        setFundraisers(response.data);
        setLoading(false);
      }
    });
  }, [isMountedRef]);

  useEffect(() => {
    getFundraisers();
  }, [getFundraisers]);

  useEffect(() => {
    if (user.is_admin) {
      dispatch(getStaff());
    }
  }, [dispatch]);

  return (
    <Page className={classes.root} title="FundRaiser List">
      <Box mt={1}>
        <Results
          fundraisers={fundraisers}
          isLoading={isLoading}
          onEdit={handleFormOpen}
        />
      </Box>
      <FundRaiserForm
        fundraiser={selectedFundRaiser}
        onAdd={handleFormClose}
        onClose={handleFormClose}
        open={openForm}
      />
    </Page>
  );
}

export default FundRaiserListView;
