import axios from 'axios';

export const GET_DEVELOPERS = '@developer/get-developers';
export const ADD_DEVELOPER = '@developer/create-developer';
export const GET_DEVELOPER = '@developer/get-developer';
export const UPDATE_DEVELOPER = '@developer/get-developer';

export function addDeveloper(values) {
  const request = axios.post('/api/developers', values);

  return dispatch => {
    request.then(response =>
      dispatch({ type: ADD_DEVELOPER, payload: response.data })
    );
  };
}

export function getDevelopers(params) {
  const request = axios.get('/api/developers', {
    params
  });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_DEVELOPERS,
        payload: response.data
      })
    );
  };
}

export function getDeveloper(developerId) {
  const request = axios.get(`/api/developers/${developerId}`);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_DEVELOPER,
        payload: response.data
      })
    );
  };
}

export function updateDeveloper(developerId, values) {
  const request = axios.patch(`/api/developers/${developerId}`, values);

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_DEVELOPER,
        payload: response.data
      })
    );
  };
}
