/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import clsx from 'clsx';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, Box, Card, makeStyles } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import toast from 'react-hot-toast';
import {
  AddBoxOutlined as AddIcon,
  PictureAsPdfOutlined as PDFIcon,
  Calculate as CalculatorIcon,
  DeleteOutlineOutlined as DeleteIcon,
  NoteAltOutlined as EditIcon,
  MonetizationOnOutlined as PayoutIcon,
  LocalAtmRounded as PaymentIcon
} from '@mui/icons-material';
import { Alert } from '@material-ui/lab';
import Label from 'src/components/Label';
import { currencyFormatter } from 'src/utils/mask';
import CustomMaterialTable from 'src/components/CustomMaterialTable';
import { LightTooltip } from 'src/components/LightTooltip';
import {
  deleteLoanTransaction,
  getLoanBalance
} from 'src/actions/syndicateActions';
import LoanTransactionForm from './LoanTransactionForm';
import CalculatorForm from './CalculatorForm';
import PayoutForm from './PayoutForm';
import PaymentForm from './PaymentForm';

const useStyles = makeStyles(theme => ({
  root: {},
  menu: {
    width: 256,
    maxWidth: '100%'
  },
  bulkOperations: {
    position: 'relative'
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  queryField: {
    width: 500
  },
  statusField: {
    flexBasis: 200
  },
  sortField: {
    marginLeft: theme.spacing(2),
    flexBasis: 200
  },
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0
  },
  image: {
    height: 68,
    width: 68
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  alert: {
    width: '50%'
  }
}));

function Results({ className, onEdit, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syndicateId } = useParams();
  const { loanTransactions, syndicate } = useSelector(state => state.syndicate);
  const { isLoading: loanTransactionsLoading } = useSelector(
    state => state.syndicate.loanTransactions
  );
  const { loan_balance, isLoading: loanBalanceLoading } = useSelector(
    state => state.syndicate.loan_balance
  );
  const { stats, isLoading: statsLoading } = useSelector(
    state => state.syndicate.stats
  );
  const { isLoading } = useSelector(state => state.syndicate.loanTransactions);
  const [open, setOpen] = useState(false);
  const [openCalc, setOpenCalc] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openPayoutCalc, setOpenPayoutCalc] = useState(false);
  const [selectedLoanTransaction, setSelectedLoanTransaction] = useState(null);

  const handleOpen = value => {
    setSelectedLoanTransaction(value);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedLoanTransaction(null);
    setOpen(false);
  };

  const handleOpenCalc = () => {
    setOpenCalc(true);
  };

  const handleCloseCalc = () => {
    setOpenCalc(false);
  };

  const handleOpenPayoutCalc = () => {
    setOpenPayoutCalc(true);
  };

  const handleClosePayoutCalc = () => {
    setOpenPayoutCalc(false);
  };

  const handleOpenPayment = value => {
    setSelectedLoanTransaction(value);
    setOpenPayment(true);
  };

  const handleClosePayment = () => {
    setSelectedLoanTransaction(null);
    setOpenPayment(false);
  };

  const handleDownload = async () => {
    axios
      .get('/api/docs', {
        responseType: 'arraybuffer',
        params: {
          syndicateId: syndicateId,
          docType: 'loan statement',
          actionType: 'download'
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['filename']); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        toast.error(
          JSON.stringify(
            JSON.parse(new TextDecoder().decode(error.response.data))
          )
        );
      });
  };

  const data = loanTransactions.allIds.map(id => ({
    ...loanTransactions.byId[id]
  }));
  const transaction_cnt = data.length;

  const diff =
    !loanBalanceLoading &&
    !statsLoading &&
    parseFloat(
      loan_balance.capital_balance - parseFloat(stats.current_fum_amt)
    ).toFixed(2);

  const columns = [
    {
      field: 'transaction_dt',
      title: 'Date',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'en-AU' }
    },
    {
      field: 'loan_transaction_type',
      title: 'Type',
      cellStyle: {
        whiteSpace: 'nowrap'
      },
      render: rowData => (
        <div>
          {rowData.loan_transaction_type}
          {rowData.loan_transaction_type == 'Interest' &&
            !rowData.is_capitalised &&
            rowData.received_amt !== rowData.transaction_amt && (
              <LightTooltip title="Receive Interest Payment">
                <IconButton
                  onClick={() => handleOpenPayment(rowData)}
                  sx={{ ml: 1 }}
                >
                  <PaymentIcon />
                </IconButton>
              </LightTooltip>
            )}
        </div>
      )
    },
    {
      field: 'transaction_amt',
      title: 'Debit',
      render: rowData =>
        rowData.accounting_type == 'Debit'
          ? currencyFormatter(rowData.transaction_amt)
          : ''
    },
    {
      field: 'transaction_amt',
      title: 'Credit',
      render: rowData =>
        rowData.accounting_type == 'Credit'
          ? currencyFormatter(rowData.transaction_amt)
          : ''
    },

    {
      field: 'is_capitalised',
      title: 'Incur Interest',
      render: rowData => {
        return rowData.is_capitalised ? (
          <Label className={classes.label} color="success">
            Yes
          </Label>
        ) : (
          <Label className={classes.label} color="error">
            No
          </Label>
        );
      }
    },
    {
      field: 'note',
      title: 'Note',
      render: rowData => (
        <LightTooltip title={rowData.note || ''}>
          <div
            style={{
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {rowData.note}
          </div>
        </LightTooltip>
      )
    },
    {
      field: 'construction_cost_amt',
      hidden: syndicate.syndicate_purpose_id !== 2,
      title: 'Construction Cost',
      type: 'currency'
    },
    {
      field: 'variation_amt',
      hidden: syndicate.syndicate_purpose_id !== 2,
      title: 'Variation',
      type: 'currency'
    },
    {
      field: 'retained_amt',
      hidden: syndicate.syndicate_purpose_id !== 2,
      title: 'Retained Cash',
      type: 'currency'
    },
    {
      field: 'Other',
      hidden: syndicate.syndicate_purpose_id !== 2,
      title: 'Construction Cost',
      type: 'currency'
    }
  ];

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <LoanTransactionForm
          loanTransaction={selectedLoanTransaction}
          onAdd={handleClose}
          onClose={handleClose}
          open={open}
        />
        <CalculatorForm
          onAdd={handleCloseCalc}
          onClose={handleCloseCalc}
          open={openCalc}
        />
        <PayoutForm
          onAdd={handleClosePayoutCalc}
          onClose={handleClosePayoutCalc}
          open={openPayoutCalc}
        />
        <PaymentForm
          onClose={handleClosePayment}
          open={openPayment}
          loanTransaction={selectedLoanTransaction}
        />
        <CustomMaterialTable
          title={'Loan Transactions'}
          isLoading={loanTransactionsLoading}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: '0px 10px', textAlign: 'left' }}>
                  {!loanBalanceLoading && (
                    <Box my={1}>
                      <div className={classes.alert}>
                        <Alert severity="info" variant="filled">
                          {`Total Loan balance is ${currencyFormatter(
                            loan_balance.loan_balance
                          )} , where total advanced to borrower is ${currencyFormatter(
                            loan_balance.capital_balance
                          )}`}
                        </Alert>
                      </div>
                    </Box>
                  )}
                  {!loanBalanceLoading &&
                    syndicate.syndicate_stage_id == 2 &&
                    syndicate.syndicate_purpose_id !== 2 &&
                    diff > 0 && (
                      <Box my={1}>
                        <div className={classes.alert}>
                          <Alert severity="error" variant="filled">
                            {`Loan advanced is ${currencyFormatter(
                              loan_balance.capital_balance
                            )}, ${currencyFormatter(diff)} ${
                              diff > 0 ? ` more` : ' less'
                            } than current FUM. Please double check repayments.`}
                          </Alert>
                        </div>
                      </Box>
                    )}
                  {syndicate.syndicate_stage_id == 2 &&
                    syndicate.syndicate_purpose_id !== 2 &&
                    !loanBalanceLoading &&
                    parseFloat(loan_balance.interest_balance) !== 0 && (
                      <Box my={1}>
                        {parseFloat(loan_balance.interest_balance) > 0 ? (
                          <div className={classes.alert}>
                            <Alert severity="error" variant="filled">
                              {`${currencyFormatter(
                                loan_balance.interest_balance
                              )} is yet to receive from borrower`}
                            </Alert>
                          </div>
                        ) : (
                          <div className={classes.alert}>
                            <Alert severity="success" variant="filled">
                              {`${currencyFormatter(
                                -loan_balance.interest_balance
                              )} more interest received than requested`}
                            </Alert>
                          </div>
                        )}
                      </Box>
                    )}
                </div>
              </div>
            )
          }}
          data={data}
          columns={columns}
          actions={[
            {
              icon: AddIcon,
              tooltip: 'Add Loan Transaction',
              isFreeAction: true,
              onClick: event => handleOpen()
            },
            {
              icon: PDFIcon,
              tooltip: 'Download Loan Statement',
              isFreeAction: true,
              disabled: transaction_cnt == 0,
              onClick: event => {
                handleDownload();
              }
            },
            {
              icon: CalculatorIcon,
              tooltip: 'Interest Calculator',
              isFreeAction: true,
              onClick: event => handleOpenCalc()
            },
            {
              icon: PayoutIcon,
              tooltip: 'Payout Calculator',
              isFreeAction: true,
              onClick: event => handleOpenPayoutCalc()
            },
            {
              icon: EditIcon,
              tooltip: 'Update Transaction',
              onClick: (event, rowData) => onEdit(rowData)
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete Transaction',
              onClick: async (event, rowData) => {
                var result = confirm('Delete this transation?');
                if (result) {
                  try {
                    await dispatch(deleteLoanTransaction(rowData));
                    await dispatch(getLoanBalance(syndicateId));
                    toast.success('Transaction deleted');
                  } catch {
                    toast.error('Failed to delete the transaction');
                  }
                }
              }
            }
          ]}
          options={{
            paging: false,
            padding: 'dense'
          }}
        />
      </Card>
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
